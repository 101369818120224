import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getCookie } from "../../../../shared/utils/cookies";
import { Helmet } from "react-helmet";
import axios from "axios";

const PdfFiles = () => {
  const [baseUrl, setBaseUrl] = useState(null);
  const [Name, setName] = useState("");
  const params = useParams();
  const Token = getCookie("access_token");

  useEffect(() => {
    const headerData = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Token}`,
      },
    };
    axios.get(`/api/candidate/cv/${params.id}`, headerData).then((res) => {
      setBaseUrl(res.data.data.url);
    });
  }, []);

  useEffect(() => {
    const data = {
      headers: {
        "Content-Type":
          "multipart/form-data; boundary=<calculated when request is sent>",
      },
    };
    const id = params?.id.slice(0, -4);
    axios.get(`/api/candidate/candidate/get/${id}/`, data).then((response) => {
      setName(response.data.data);
    });
  }, []);

  const pdfUrl = `data:application/pdf;base64,${baseUrl}`;

  return (
    <>
      <Helmet>
        <title>{`CV ${Name?.firstname} ${Name?.lastname}`}</title>
      </Helmet>

      <div>
        <div className="viewer">
          {baseUrl == null ? (
            <h5 className="text-center text-gray-500">Loading</h5>
          ) : (
            <a
              href={pdfUrl}
              download={`CV_${Name.firstname}_${Name.lastname}.pdf`}
            >
              <iframe
                title="PDF Viewer"
                width="100%"
                height={window.innerHeight} // Set a height relative to the screen height
                src={pdfUrl}
              ></iframe>
            </a>
          )}
        </div>
      </div>
    </>
  );
};

export default PdfFiles;
