
export const  funtion = (item) => {
  if(item.colour == 1){
    return (
      <>
      <span className="bg-deep-green-50 relative -bottom-1 -right-1 py-1 px-2.5 ml-auto text-sizing text-deep-green-500 uppercase font-bold inline-block text-center whitespace-nowrap align-baseline rounded">
    Premium
   </span>
    </>
    )
  }
  else if(item.colour == 2){
    return(
      <>
      <span className="bg-deep-teal-50 relative -bottom-1 -right-1 py-1 px-2.5 ml-auto text-sizing text-deep-teal-500 uppercase font-bold inline-block text-center whitespace-nowrap align-baseline rounded">
    Premium
   </span>
    </>
    )
  }
  else if(item.colour == 3){
    return(
      <>
              <span className="bg-deep-red-50 relative -bottom-1 -right-1 py-1 px-2.5 ml-auto text-sizing text-deep-red-500 uppercase font-bold inline-block text-center whitespace-nowrap align-baseline rounded">
            Premium
           </span>
            </>
    )
  }
  else if(item.colour == 4){
    return (
      <>
        <span className="bg-deep-orange-50 relative -bottom-1 -right-1 py-1 px-2.5 ml-auto text-sizing text-deep-orange-500 uppercase font-bold inline-block text-center whitespace-nowrap align-baseline rounded">
      Premium
     </span>
      </>
    )
  }
  else if(item.colour == 5){
    return (
      <>
        <span className="bg-deep-blue-50 relative -bottom-1 -right-1 py-1 px-2.5 ml-auto text-sizing text-deep-blue-500 uppercase font-bold inline-block text-center whitespace-nowrap align-baseline rounded">
      Premium
     </span>
      </>
    )
  }
   else{
          return (
            <>
              <span>
            {""}
           </span>
            </>
          )
        }
}