import URLS from "../../constant/urls";
import axios from "axios";
import { getCookie } from "../../utils/cookies";

// GETTING THE ACCESS_TOEN FROM COOKIES
const Token = getCookie("access_token");

// HEADER FOR THE API CALL
const headerData = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${Token}`,
  },
};
//VENTURES HEADER
const headerDatas = {
  headers: {
    "Content-Type": "application/json",
  },
};

// API FOR DASHBOARD DATA
export const _getDash = async () => {
  const responce = await axios.get(`/api/candidate/candidate/getall/`, headerData);
  return responce;
};

// API FOR DASHBOARD DATA
export const _getNewDash = async (userCompanyId) => {
  const responce = await axios.get(`/api/candidate/candidate/getall/${userCompanyId}/?dash=1/`, headerData);
  return responce;
};

// API FOR DASHBOARD DATA FOR ADVISORY
export const _getAdvisoryDash = async (userCompanyId) => {
  const responce = await axios.get(`/api/advisor/advisor/getall/${userCompanyId}/?dash=1/`, headerData);
  return responce;
};

// API FOR DASHBOARD DATA
export const _getPortfolioComp = async (uniqueId) => {
  const responce = await axios.get(`/api/venture/venture/user/${uniqueId}/`, headerData);
  return responce;
};

// API FOR LIST DATA
export const _getList = async (uniqueId) => {
  const responce = await axios.get(`/api/lists/lists/getall/${uniqueId}`, headerData);
  return responce;
};

// API FOR COMPANY DATA
export const _getCompData = async () => {
  const responce = await axios.get(`/api/company/company/getall/`, headerData);
  return responce;
};

//API FOR COMPONY DATA
export const _getCompanyData = async (uniqueId) => {
  const responce = await axios.get(`/api/companies/getall/${uniqueId}`, headerData);
  return responce;
};

//API FOR UNIQUE LINK
export const _getUniqueLink = async (uniqueId, uniqueRoleId) => {
  const responce = await axios.get(`/api/candidate/unique/${uniqueId}/${uniqueRoleId}`, headerData);
  return responce
}

//API FOR USER DATA
export const _getUserDetails = async (uniqueId) => {
  const responce = await axios.get(`/api/user/get/${uniqueId}/`, headerDatas);
  return responce
}

//API FOR FETCCHING THE ROLES
export const _getRoles = async (uniqueRoleId) => {
  const responce = await axios.get(`/api/role/get/${uniqueRoleId}/`, headerDatas);
  return responce
}

//API FOR UNIQUE LINK
export const _getfetchVentureLog = async (data) => {
  const responce = await axios.get(`/api/venture/profile/login/${data.email}/${data.password}`, headerDatas);
  return responce
}

// API FOR GLOBAL SEARCH DATA
export const _getGlobalSearch = async (globalSearch) => {
  const responce = await axios.get(`/api/candidate/candidate/getall/?search_name=${globalSearch}`, headerData);
  return responce;
};

// API FOR FILTER THE SAME API
export const _getLevel = async (Level, test) => {
  const responce = await axios.get(`/api/candidate/candidate/getall/?page=${test?.params?.page}&&size=${test?.params?.size}&&careerplan.careerlevel=${Level}`, headerData);
  return responce;
};

// API FOR EXPERINCE THE SAME API
export const _getExperience = async (Experience, test) => {
  const responce = await axios.get(`/api/candidate/candidate/getall/?page=${test?.params?.page}&&size=${test?.params?.size}&&careerplan.experience=${Experience}`, headerData);
  return responce;
};

// API FOR LOCATION THE SAME API
export const _getDesire = async (Desire, test) => {
  const responce = await axios.get(`/api/candidate/candidate/getall/?page=${test?.params?.page}&&size=${test?.params?.size}&&careerplan.workplace=${Desire}`, headerData);
  return responce;
};

// API FOR LOCATION THE SAME API
export const _getLocation = async (Location, test) => {
  const responce = await axios.get(`/api/candidate/candidate/getall/?page=${test?.params?.page}&&size=${test?.params?.size}&&about.nativeplace=${Location}`, headerData);
  return responce;
};

// API FOR LOGGIN_OUT
export const _getLogOut = async () => {
  const responce = await axios.get(URLS.LOGOUT_USER, headerData);
  return responce;
};

/**
 * get accesstoken from refresh token
 * @param {Object} data data object with refresh token, client id and client secret
 */
export const _getRetailerAuthToken = async (data) => {
  const response = await axios.post(URLS.GET_ACCESS_TOKEN, data, headerData);
  return response;
};

/**
 * USER LOGIN
 * @param {Object} data data object with refresh token, client id and client secret
 */
export const _addminLog = async (data) => {
  const response = await axios.post(URLS.USER_LOGIN, data);
  return response;
};


export const _AuthToken = async (data) => {
  const response = await axios.post(URLS.GET_ACCESS_TOKEN, data, headerData);
  return response;
};
