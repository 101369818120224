import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./index.css";
import Select, { NonceProvider } from "react-select";
import Clock from "../../images/clock.svg";
import Vector from "../../../ForgotPass/images/error.svg";
import { GooglePlacesSelect } from "../../../../shared/components/googlePlaces";
import { ReactComponent as Image27 } from "../Connect/img/Load.svg";
import AdvisorFormHeader from "./AdvisorFormHeader";
import AdvisorBannerSection from "./AdvisorBannerSerction";
import AdvisorFormFooter from "./AdvisorFormFooter";

const AdvisorForms = ({ authentication_state }) => {
  const authentication_ref = useRef(authentication_state);
  authentication_ref.current = authentication_state;
  const [screen, setScreen] = useState(1);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [location, setLocation] = useState(null);
  const [expertise, setExpertise] = useState(null);
  const [specialities, setSpecialities] = useState(null);
  const [role, setRole] = useState([]);
  const [linkedIn, setLinkedIn] = useState("");
  const [marketPlaces, setMarketPlaces] = useState([]);
  const [remoteWork, setRemoteWork] = useState([]);
  const [time, setTime] = useState([]);
  const [compensation, setCompensation] = useState([]);
  const [stage, setStage] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [boardDirector, setBoardDirector] = useState([]);
  const [FormalAdvisory, setFormalAdvisory] = useState("");
  const [refferedBy, setrefferedBy] = useState("");
  const [textArea, setTestArea] = useState("");
  const [email, setEmail] = useState("");
  const [TextDetails, setDetails] = useState("");
  const [ChariExperience, setChariExperience] = useState("");
  const [FounderExperience, setFounderExperience] = useState("");
  const [consultancyExperience, setconsultancyExperience] = useState("");
  const [highlight, sethighlight] = useState("");
  const [gender, setGender] = useState(null);
  const [Ethnicity, setEthnicity] = useState(null);
  const [FormLoading, setFormLoading] = useState(false);
  const [terms, setTerm] = useState("");
  const [CharCount, setCharCount] = useState(0);
  const [PrimeName, setPrimeName] = useState();
  const params = useParams();
  const OrgName = params.name;

  //Errors States are here ----->
  const [firstenameError, setFirstNameError] = useState("");
  const [lastnameError, setLastNameError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [expertiseError, setExpertiseError] = useState("");
  const [filteredSubCategories, stfilteredSubCategories] = useState([]);
  const [specialitiesError, setSpecialitiesError] = useState("");
  const [refferedByError, setrefferedByError] = useState("");
  const [roleError, setRoleError] = useState("");
  const [boardDirectorError, setBoardDirectorError] = useState("");
  const [FormalAdvisoryError, setFormalAdvisoryError] = useState("");
  const [linkedInError, setLinkedInError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [marketPlacesError, setMarketPlacesError] = useState("");
  const [remoteWorkError, setRemoteWorkError] = useState("");
  const [compensationError, setCompensationError] = useState("");
  const [ChariExperienceError, setChariExperienceError] = useState("");
  const [FounderExperienceError, setFounderExperienceError] = useState("");
  const [consultancyExperienceError, setconsultancyExperienceError] = useState("");
  const [highlightError, sethighlightError] = useState("");
  const [timeError, setTimeError] = useState("");
  const [stageError, setStageError] = useState("");
  const [industriesError, setIndustriesError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [EthnicityError, setEthnicityError] = useState("");
  const [TNCError, setTNCError] = useState("");
  const linkedinRegex = /linkedin\.com/i; // i flag for case-insensitivity
  const httpRegex = /^https?:\/\//; // Updated regex to check for http:// or https://
  const feedRegex = /feed/;

  const specialtyString = specialities?.map(item => item.currItem).join(', ');

    // dynamic form collection
    const [formColor, setFormColor] = useState("");
    const [formLogoHead, setFormLogoHead] = useState("");
    const [privacyPolicy, setprivacyPolicy] = useState("");
    const [formVCname, setFormVcName] = useState("");
    const [formHeadline, setFormHeadline] = useState("");
    const [formHeadline2, setFormHeadline2] = useState("");
    const [formLogo, setFormLogo] = useState("");
    const [formBuzzModel, setFormBuzzModel] = useState();
    const FullBuzzModel = formBuzzModel?.split(',')?.map(option => option?.trim())
    const [formStages, setFormStages] = useState();
    const FullStageModel = formStages?.split(',')?.map(option => option?.trim())
    const [formIndustries, setFormIndustries] = useState();
    const FullIndusModel = formIndustries?.split(',')?.map(option => option?.trim())

  // Gender Array
  let GenderArray = [
    { currItem: "Female" },
    { currItem: "Male" },
    { currItem: "Transgender" },
    { currItem: "Non-binary" },
    { currItem: "Prefer not to say" },
  ];

  // Stages Array
  let EthencityArray = [
    { currItem: "Black or African American" },
    { currItem: "Hispanic or Latino" },
    { currItem: "Middle Eastern" },
    { currItem: "South Asian" },
    { currItem: "East Asian" },
    { currItem: "White" },
    { currItem: "Mixed" },
    { currItem: "Prefer not to say" },
  ];

  // Expertise Array
  let ExpertiseMapStringresult = [
    { currItem: "Coaching" },
    { currItem: "Commercial & Revenue" },
    { currItem: "Customer Success" },
    { currItem: "Finance" },
    { currItem: "Growth & Marketing" },
    { currItem: "Legal" },
    { currItem: "Operations & Strategy" },
    { currItem: "People & Talent" },
    { currItem: "Product" },
    { currItem: "Purchase & Supply Chain" },
    { currItem: "Risk & Compliance" },
    { currItem: "Technology" },
  ];

  const expertiseToSubCategories = {
    Coaching: [
      "CEO Mentorship",
      "Executive Coaching",
      "Management Training",
      "Sales Coaching",
    ],
    "Commercial & Revenue": [
      "Account Based Marketing",
      "Business Transformation / Turn Around",
      "Commercial Strategy",
      "Demand Gen",
      "Diversify Revenue Strategy",
      "Enterprise Sales",
      "GTM Diagnosis & Strategy",
      "Growth Strategy",
      "Mid-Market Sales",
      "New Markets",
      "Pricing",
      "Product-Led Growth",
      "RevOps",
      "Sales Enablement",
      "Strategic Partnerships"
    ],
    "Customer Success": [
      "Technical Onboarding / Integration",
      "Up & Cross Selling",
    ],
    Finance: ["Accounting", "Finance Strategy", "FP&A", "Fundraising", "Governance & Compliance", "IPO Readiness", "Mergers & Acquisitions"],
    "Growth & Marketing": [
      "Account Based Marketing",
      "Branding & Content",
      "Community & Events",
      "Demand Gen",
      "Go-To-Market",
      "Growth Strategy",
      "Product Marketing",
    ],
    Legal: ["Data Protection", "Employment Law", "Fundraising", "General Counsel", "Governance", "IPO Readiness", "IP", "Mergers & Acquisitions"],
    "Operations & Strategy": [
      "BI / Data Analytics",
      "Board Performance",
      "Business Operations",
      "Business Strategy",
      "Business Transformation / Turn Around",
      "Fundraising",
      "International Expansion",
      "IPO Readiness",
    ],
    "People & Talent": [
      "Org Design",
      "People Processes",
      "People Strategy",
      "Talent Acquisition / Recruitment",
    ],
    Product: [
      "Agile Coaching",
      "Expansion",
      "GTM Diagnosis & Strategy",
      "Product Design Processes",
      "Product Design Strategy",
      "Product Management Processess",
      "Product Market Fit",
      "Product Strategy",
      "Product-Led Growth",
    ],
    "Purchase & Supply Chain": [],
    "Risk & Compliance": [],
    Technology: [
      "BI / Data Analytics",
      "Data Science & Machine Learning",
      "Distributed Systems",
      "Hardware / Mechatronics",
      "Infrastructure",
      "Information Security",
      "Research to Commercialisation",
      "R&D",
      "Software Development",
      "System Design",
      "Technical Due Diligence",
    ],
  };

  useEffect(() => {
    const headerData = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios.get(`/api/user/get/${params.id}/`, headerData).then((res) => {
      setPrimeName(res.data.data.access);
    });
    axios.get(`/api/venturecapitalformsadvisor/venturecapitalformsadvisor/get/${params.id}/`, headerData).then((res) => {
      setFormColor(res?.data?.data?.advisor_form_colour);
      setFormLogoHead(res?.data?.data?.advisor_form_logo_header);
      setprivacyPolicy(res?.data?.data?.advisor_privacy_policy_url);
      setFormVcName(res?.data?.data?.vc_name);
      setFormHeadline(res?.data?.data?.advisor_form_description_headerline);
      setFormHeadline2(res?.data?.data?.advisor_form_description);
      setFormLogo(res?.data?.data?.advisor_form_logo);
      setFormBuzzModel(res?.data?.data?.advisor_biz_model_options);
      setFormStages(res?.data?.data?.advisor_stage_options);
      setFormIndustries(res?.data?.data?.advisor_industry_options);
    });
  }, []);

  useEffect(() => {
    if (PrimeName == "noaccess") {
      window.location.href = "/";
    }
  }, [PrimeName]);

  const handleClickPrev = (e) => {
    e.preventDefault();
    setScreen((currPage) => currPage - 1);
    window.scrollTo(0, 0);
  };

  const handleMarketPlace = (e) => {
    const { value, checked } = e?.target;
    if (checked) {
      setMarketPlaces([...marketPlaces, value]);
    } else {
      setMarketPlaces(marketPlaces.filter((e) => e !== value));
    }
  };

  const handleRoleboxChange = (e) => {
    const { value, checked } = e?.target;
    if (checked) {
      setRole([...role, value]);
    } else {
      setRole(role.filter((e) => e !== value));
    }
  };

  const handleTimeboxChange = (e) => {
    const { value, checked } = e?.target;
    if (checked) {
      setTime([...time, value]);
    } else {
      setTime(time.filter((e) => e !== value));
    }
  };

  const handleRemoteboxChange = (e) => {
    const { value, checked } = e?.target;
    if (checked) {
      setRemoteWork([...remoteWork, value]);
    } else {
      setRemoteWork(remoteWork.filter((e) => e !== value));
    }
  };

  const handleCompensationboxChange = (e) => {
    const { value, checked } = e?.target;
    if (checked) {
      setCompensation([...compensation, value]);
    } else {
      setCompensation(compensation.filter((e) => e !== value));
    }
  };

  const handleStagesArray = (e) => {
    const { value, checked } = e?.target;
    if (checked) {
      setStage([...stage, value]);
    } else {
      setStage(stage.filter((e) => e !== value));
    }
  };

  const handleIndustries = (e) => {
    const { value, checked } = e?.target;
    if (checked) {
      setIndustries([...industries, value]);
    } else {
      setIndustries(industries.filter((e) => e !== value));
    }
  };
  

  const handleBoardmember = (e) => {
    const { value, checked } = e?.target;
  
    if (value === "currently") {
      // If "I don’t currently have any of the experience listed above" is checked,
      // handle it separately
      if (checked) {
        // If "currently" is checked, deselect all other checkboxes
        setBoardDirector(["currently"]);
      } else {
        // If "currently" is unchecked, remove it from the list
        setBoardDirector(boardDirector.filter((e) => e !== "currently"));
      }
    } else {
      // If any other checkbox is checked or unchecked
      if (checked) {
        // If the checkbox is checked, add its value to the list
        setBoardDirector((prev) => [...prev.filter((e) => e !== "currently"), value]);
      } else {
        // If the checkbox is unchecked, remove its value from the list
        setBoardDirector(boardDirector.filter((e) => e !== value));
      }
          // Optionally, clear the text area when Chair or NED is unchecked
    if (value === "Chair" || value === "NED") {
      setChariExperience('');
    }
              // Optionally, clear the text area when Chair or NED is unchecked
              if (value === "Founder") {
                setFounderExperience('');
              }
               // Optionally, clear the text area when Chair or NED is unchecked
               if (value === "Advisor") {
                setconsultancyExperience('');
              }
               // Optionally, clear the text area when Chair or NED is unchecked
               if (value === "currently") {
                sethighlight('');
              }
    }
  };
  
  

  const handleCharCount = (e) => {
    const inputName = e.target.value;
    const resetCount = 0 + inputName?.length;
    setTestArea(inputName);
    setCharCount(resetCount > 0 ? resetCount : 0);
  };

  const handleClickNextscreen1 = (e) => {
    e.preventDefault();
    if (screen == 1 && firstName == "") {
      setFirstNameError("Please add your First Name");
    } else if (screen == 1 && lastName == "") {
      setLastNameError("Please add your Last Name");
    } else if (screen == 1 && location == null) {
      setLocationError("Please add your Current Location");
    } else if (screen == 1 && linkedIn == "") {
      setLinkedInError("Please add your LinkedIn Profile");
    }else if(screen == 1 && linkedIn !== "" && !linkedinRegex.test(linkedIn)){
      setLinkedInError("This doesn’t look quite right, please check it’s your personal LinkedIn profile URL")
    }else if(screen == 1 && linkedIn !== "" && linkedIn.includes('feed')){
      setLinkedInError("This doesn’t look quite right, please check it’s your personal LinkedIn profile URL")
    }else if(screen == 1 && linkedIn !== "" && linkedIn.startsWith('http') && !httpRegex.test(linkedIn)){
      setLinkedInError("This doesn’t look quite right, please check it’s your personal LinkedIn profile URL")
    } else if (screen == 1 && email == "") {
      setEmailError("Please add your Email Address");
    } else if (screen == 1 && email != "" && !email.includes("@")) {
      setEmailError("Please add valid Email Address");
    } else if (screen == 1 && gender == null) {
      setGenderError("Please add your Gender Identity");
    } else if (screen == 1 && Ethnicity == null) {
      setEthnicityError("Please add your Ethnicity");
    }else{
      setScreen((currPage) => currPage + 1);
      window.scrollTo(0, 0);
    }
  }

  const handleClickNext = (e) => {
    e.preventDefault();
     if (screen == 2 && expertise == null) {
      setExpertiseError("Please add your main Professional Expertise");
    } else if (screen == 2 && specialities == null && filteredSubCategories?.length > 0 || specialities?.length == 0) {
      setSpecialitiesError("Please select at least one speciality");
    } else if (screen == 2 && marketPlaces.length == 0) {
      setMarketPlacesError(
        "Please select which kinds of businesses you have worked for"
      );
    } else if (screen == 2 && boardDirector.length == 0) {
      setBoardDirectorError("Please let us know if you have served on a Board");
    } else if(screen == 2 && ChariExperience == "" && boardDirector.includes("Chair") || screen == 2 && ChariExperience == "" && boardDirector.includes("NED")){
      setChariExperienceError("Please tell us about your past NED/Chairperson roles")
    }else if(screen == 2 && FounderExperience == "" && boardDirector.includes("Founder")){
      setFounderExperienceError("Please tell us about your past Founder experience")
    }else if(screen == 2 && boardDirector.includes("Advisor") && consultancyExperience == ""){
      setconsultancyExperienceError("Please tell us about your advisory work")
    } else if(screen == 2 && highlight == "" && boardDirector.includes("currently")){
      sethighlightError("Please tell us about your professional background")
    }
     else if (screen == 2 && stage.length == 0) {
      setStageError("Please select which business stages you've worked at");
    } else if (screen == 2 && industries.length == 0) {
      setIndustriesError("Please select your industry preferences");
    }
    else {
      setScreen((currPage) => currPage + 1);
      window.scrollTo(0, 0);
    }
  };

  const isValid = () => {
    if (screen == 3 && role.length == 0) {
      setRoleError(
        "Please let us know what kinds of roles you're interested in"
      );
    } else if (screen == 3 && time.length == 0) {
      setTimeError("Please confirm how much time you'd like to commit");
    } else if (screen == 3 && remoteWork.length == 0) {
      setRemoteWorkError("Please confirm your remote work preferences");
    } else if (screen == 3 && compensation.length == 0) {
      setCompensationError(
        "Please pick at least one compensation model you're open to"
      );
    } 
    else if(screen == 3 && refferedBy == ""){
      setrefferedByError("Please add the email address of the person who shared this form with you.")
      return false;
    }
    else if(screen == 3 && refferedBy != "" && !refferedBy.includes("@")){
      setrefferedByError("Please add valid Email Address")
      return false;
    }
    else if(screen == 3 && refferedBy == email){
      setrefferedByError("Your referral email cannot be your email address")
      return false;
    }
    else if (screen == 3 && terms == false) {
      setTNCError("Please Check Terms and Conditions");
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (locationError) {
      const errorFieldElement = document?.getElementById("location-field");
      errorFieldElement?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    if (firstenameError) {
      const errorFieldElement = document?.getElementById("firstName");
      errorFieldElement?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    if (lastnameError) {
      const errorFieldElement = document?.getElementById("lastName");
      errorFieldElement?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    if (locationError) {
      const errorFieldElement = document?.getElementById("Location");
      errorFieldElement?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    if (expertiseError) {
      const errorFieldElement = document?.getElementById("Expertise");
      errorFieldElement?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    if (roleError) {
      const errorFieldElement = document?.getElementById("role");
      errorFieldElement?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    if (remoteWorkError) {
      const errorFieldElement = document?.getElementById("No-remote");
      errorFieldElement?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    if (stageError) {
      const errorFieldElement = document?.getElementById("Pre-Seed");
      errorFieldElement?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    if (genderError || EthnicityError) {
      const errorFieldElement = document?.getElementById("Gender-Error");
      errorFieldElement?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    if (compensationError) {
      const errorFieldElement = document?.getElementById("compensation");
      errorFieldElement?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    if (specialitiesError) {
      const errorFieldElement = document?.getElementById("specialities");
      errorFieldElement?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    if (timeError) {
      const errorFieldElement = document?.getElementById("availability");
      errorFieldElement?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    if (remoteWorkError) {
      const errorFieldElement = document?.getElementById("remoteWork");
      errorFieldElement?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [
    locationError,
    firstenameError,
    lastnameError,
    stageError,
    expertiseError,
    roleError,
    genderError,
    EthnicityError,
    compensationError,
    specialities,
    timeError,
    remoteWorkError,
  ]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (isValid()) {
      setFormLoading(true);
      const data = {
        firstname: firstName,
        lastname: lastName,
        created_by: params.id,
        email: email,
        profilepic: null,
        about: {
          advisor_description: textArea,
          currentLocation: location,
          functionalexpertise: expertise?.currItem,
          gender: gender.currItem,
          ethnicity: Ethnicity.currItem,
          linkedin: linkedIn,
          specialities: specialtyString,
        },
        careerplan: {
          board_experience: boardDirector.includes("Chair") || boardDirector.includes("NED") ? "Yes" : "No",
          chair_experience: boardDirector.includes("Chair") ? "Yes" : "No",
          board_experience_details: ChariExperience,
          founder_experience: boardDirector.includes("Founder") ? "Yes" : "No",
          founder_experience_details: FounderExperience,
          business_model_experience: marketPlaces.toString(),
          stage_experience: stage.toString(),
          industry_experience: industries.toString(),
          roles_preference: role.toString(),
          commitment_preference: time,
          remote_preference: remoteWork.toString(),
          compensation_preference: compensation.toString(),
          compensation_details: TextDetails,
          advisory_experience: boardDirector.includes("Advisor") ? "Yes" : "No",
          advisory_experience_details: consultancyExperience,
          background_highlights: highlight,
          capital: formVCname,
        },
        referred_by: refferedBy ? refferedBy : null,
        finalbits: {
          document: null,
          extrainformation: textArea,
          termsandconditions: terms,
        },
      };
      const headerData = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios.post(`/api/advisor/advisor/`, data, headerData)
      .then((res) => {
        if (res.status == 201) {
          setScreen((currPage) => currPage + 1);
        }
      })
      .finally(() => {
        setFormLoading(false);
      });
    }
  };

  const handlePrivacyPolicy = () => {
    if (OrgName == "OctopusVentures") {
      window.open("https://octopusventures.com/privacy-policy/");
    }else if(OrgName == "AnzuPartners"){
      window.open("https://www.anzupartners.com/privacy/")
    }
     else {
      window.open("https://www.startuplane.io/legal/privacy");
    }
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      border: "1px solid red",
      // This line disable the blue border
      boxShadow: "red",
    }),
  };

  const handleTerm = (e) => {
    const { checked } = e?.target;
    if (checked) {
      setTerm(checked);
    } else {
      setTerm(false);
    }
  };

  const handleExpertiseChange = (item) => {
    const selectedExpertise = item?.currItem;
    const subCategoryOptions =
      expertiseToSubCategories[selectedExpertise] || [];
    setExpertise(item);
    const filteredSubCategorie = subCategoryOptions.map((subCategory) => ({
      currItem: subCategory,
    }));
    stfilteredSubCategories(filteredSubCategorie);
  };

  return (
    <>
    {formColor !== "" && (
      <>
      <Helmet>
        <title>
          {`${formVCname} Advisor Form`}
        </title>
      </Helmet>
      <div className="FullBody">
        <AdvisorFormHeader          
          OrgName={OrgName}
          formLogoHead={formLogoHead}
          formColor={formColor}
          privacyPolicy={privacyPolicy}
          formVCname={formVCname} />
        {screen != 4 && (
          <section
          class="about-top"
          style={formColor !== "" ? { backgroundColor: `${formColor}` } : null}
        >
          <div class="about-top-inner">
            <div class="about-top-logo">
              <img width={"100px"} height={"100px"} src={formLogo} alt="" />
            </div>
            <div class="about-top-head">
              <h2>{formHeadline}</h2>
              <p>{formHeadline2}</p>
            </div>
          </div>
        </section>
        )}
        <div className="row justify-content-center">
          <div className="form-div">
            {screen != 4 && (
              <div className="section-title">
                <h2>About you...</h2>
              </div>
            )}
            {screen == 1 && (
              <div className="forom-box">
                <div className="tab-list">
                  <ul>
                    <li className="active">
                      <a href="#">
                        <span style={{ color: `${formColor}` }}>
                          <i class="fa fa-circle" aria-hidden="true"></i>
                        </span>
                        Who you are{" "}
                      </a>
                      <hr style={{height: '4px', borderRadius: '4px', color: `${formColor}`, margin: '14px 0', border: `1px solid ${formColor}`, backgroundColor: `${formColor}`}} />
                    </li>
                    <li>
                      <a href="#">
                        <span>
                          <i class="fa fa-circle" aria-hidden="true"></i>
                        </span>
                        Your Experience{" "}
                      </a>
                      <hr style={{height: '4px', borderRadius: '4px', color: `#e6e9f0`, margin: '14px 0', border: `1px solid #e6e9f0`, backgroundColor: `#e6e9f0`}} />
                    </li>
                    <li>
                      <a href="#">
                        <span>
                          <i class="fa fa-circle" aria-hidden="true"></i>
                        </span>
                        Your Preferences{" "}
                      </a>
                      <hr style={{height: '4px', borderRadius: '4px', color: `#e6e9f0`, margin: '14px 0', border: `1px solid #e6e9f0`, backgroundColor: `#e6e9f0`}} />
                    </li>
                  </ul>
                </div>
                <div className="formbg relative">
                  <form id="">
                    <div className="circle"
                    style={
                        formColor !== ""
                          ? { backgroundColor: `${formColor}` }
                          : null
                      }
                    >
                      <img src={Clock} />
                      <div>
                        <b>Estimated:</b>
                      </div>
                      <div>3 mins left</div>
                    </div>
                    <div className="d-sm-flex justify-content-between align-items-center col-gap-21 flex-wrap">
                      <div className="flex1 mb-3">
                        <label for="">First name</label>
                        <input
                          type="text"
                          id="firstName"
                          value={firstName}
                          onChange={(e) => {
                            const result =
                              e.target.value.charAt(0).toUpperCase() +
                              e.target.value.slice(1);
                            setFirstName(result);
                          }}
                          // "appearance-none w-full text-lg leading-6 bg-white font-normal text-gray-800 placeholder:text-gray-400 rounded-lg py-3 px-4 border border-red-300 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition duration-500"
                          placeholder="Jane"
                          style={{
                            // padding: '10px',
                            border:
                              firstenameError !== "" && firstName === ""
                                ? "1px solid red"
                                : "1px solid rgb(230, 233, 240)",
                            transition: "border-color 0.3s ease-in-out",
                            outline: "none",
                            boxShadow: `0 0 0 3px transparent`,
                          }}
                          onFocus={(e) => {
                            e.target.style.borderColor = `${formColor}`; // Change border color on focus
                            e.target.style.boxShadow = `${formColor}`; // Change focus ring color on focus
                          }}
                          onBlur={(e) => {
                            e.target.style.borderColor = "rgb(230, 233, 240)"; // Reset border color on blur
                            e.target.style.boxShadow = "0 0 0 3px transparent"; // Reset focus ring color on blur
                          }}
                          className={
                            firstenameError !== "" && firstName === ""
                              ? "appearance-none w-full text-lg leading-6 bg-white font-normal text-gray-800 placeholder:text-gray-400 rounded-lg py-3 px-4 border border-red-500 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition duration-500"
                              : "appearance-none block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 placeholder:text-gray-400 bg-white bg-clip-padding border border-gray-300 rounded-lg m-0 focus:outline-none focus:bg-white transition ease-in-out"
                          }
                        />
                        {firstenameError != "" && firstName == "" && (
                          <div className="inline-flex items-baseline mt-2">
                            <img className="self-center" src={Vector} />
                            <h5 className="errorClass">{firstenameError}</h5>
                          </div>
                        )}
                        {/* <input type="text" class="form-control inputtext" id="" placeholder="Jane" /> */}
                      </div>
                      <div className="flex1 mb-3">
                        <label for="">Last name</label>
                        <input
                          type="text"
                          id="lastName"
                          value={lastName}
                          onChange={(e) => {
                            setLastName(e.target.value);
                          }}
                          style={{
                            // padding: '10px',
                            border:
                            lastnameError != "" && lastName == ""
                                ? "1px solid red"
                                : "1px solid rgb(230, 233, 240)",
                            transition: "border-color 0.3s ease-in-out",
                            outline: "none",
                            boxShadow: `0 0 0 3px transparent`,
                          }}
                          onFocus={(e) => {
                            e.target.style.borderColor = `${formColor}`; // Change border color on focus
                            e.target.style.boxShadow = `${formColor}`; // Change focus ring color on focus
                          }}
                          onBlur={(e) => {
                            e.target.style.borderColor = "rgb(230, 233, 240)"; // Reset border color on blur
                            e.target.style.boxShadow = "0 0 0 3px transparent"; // Reset focus ring color on blur
                          }}
                          placeholder="Doe"
                          className={
                            lastnameError != "" && lastName == ""
                              ? "appearance-none w-full text-lg leading-6 bg-white font-normal text-gray-800 placeholder:text-gray-400 rounded-lg py-3 px-4 border border-red-500 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition duration-500"
                              : "appearance-none block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 placeholder:text-gray-400 bg-white bg-clip-padding border border-gray-300 rounded-lg m-0 focus:outline-none focus:bg-white transition ease-in-out"
                          }
                        />
                        {lastnameError != "" && lastName == "" && (
                          <div className="inline-flex items-baseline mt-2">
                            <img className="self-center" src={Vector} />
                            <h5 className="errorClass">{lastnameError}</h5>
                          </div>
                        )}
                        {/* <input type="text" className="form-control inputtext" id="" placeholder="Doe" /> */}
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-12 mb-3">
                        <label for="exampleFormControlSelect1">
                          Where do you <b>live?</b>
                        </label>
                        <GooglePlacesSelect
                          value={location}
                          onChange={(location) => setLocation(location)}
                          placeholder={"Type your location"}
                          form={"formCandidate"}
                          formColor={formColor}
                        />
                        {locationError != "" && location == null && (
                          <div
                            className="inline-flex items-baseline mt-2"
                            id="location-field"
                          >
                            <img className="self-center" src={Vector} />
                            <h5 className="errorClass">{locationError}</h5>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-8">
                        <label for="">
                          What’s your LinkedIn <b>profile?</b>
                        </label>
                        <input
                          type="url"
                          value={linkedIn}
                          autoComplete="off"
                          onChange={(e) => {
                            setLinkedIn(e.target.value);
                          }}
                          style={{
                            // padding: '10px',
                            border:
                            linkedInError != "" && linkedIn == "" ||
                            linkedInError != "" &&
                              linkedIn != "" &&
                              !linkedinRegex.test(linkedIn) ||
                              linkedInError != "" &&
                              linkedIn != "" &&
                              linkedIn.includes("feed") ||
                              linkedInError != "" &&
                              linkedIn != "" &&
                              linkedIn.startsWith("http") &&
                              !httpRegex.test(linkedIn)
                                ? "1px solid red"
                                : "1px solid rgb(230, 233, 240)",
                            transition: "border-color 0.3s ease-in-out",
                            outline: "none",
                            boxShadow: `0 0 0 3px transparent`,
                          }}
                          onFocus={(e) => {
                            e.target.style.borderColor = `${formColor}`; // Change border color on focus
                            e.target.style.boxShadow = `${formColor}`; // Change focus ring color on focus
                          }}
                          onBlur={(e) => {
                            e.target.style.borderColor = "rgb(230, 233, 240)"; // Reset border color on blur
                            e.target.style.boxShadow = "0 0 0 3px transparent"; // Reset focus ring color on blur
                          }}
                          className={
                            (linkedInError != "" && linkedIn == "") ||
                            (linkedInError != "" &&
                              linkedIn != "" &&
                              !linkedinRegex.test(linkedIn)) ||
                            (linkedInError != "" &&
                              linkedIn != "" &&
                              linkedIn.includes("feed")) ||
                            (linkedInError != "" &&
                              linkedIn != "" &&
                              linkedIn.startsWith("http") &&
                              !httpRegex.test(linkedIn))
                              ? "appearance-none w-full text-lg leading-6 bg-white font-normal text-gray-800 placeholder:text-gray-400 rounded-lg py-3 px-4 border border-red-500 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition duration-500"
                              : "appearance-none block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 placeholder:text-gray-400 bg-white bg-clip-padding border border-gray-300 rounded-lg m-0 focus:outline-none focus:bg-white transition ease-in-out"
                          }
                          id="linkedIn"
                          placeholder="www.linkedin.com/in/..."
                        />
                        {linkedInError != "" && linkedIn == "" || linkedInError != "" && linkedIn != "" && !linkedinRegex.test(linkedIn) || linkedInError != "" && linkedIn != "" && linkedIn.includes('feed') || linkedInError != "" && linkedIn != "" && linkedIn.startsWith('http') && !httpRegex.test(linkedIn) ? (
                          <div className="inline-flex items-baseline mt-2">
                            <img className="self-center" src={Vector} />
                            <h5 className="errorClass">{linkedInError}</h5>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-row mt-19">
                      <div className="col-md-8 ">
                        <label for="">
                          What’s your <b>email address?</b>
                        </label>
                        <input
                          type="text"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                          style={{
                            // padding: '10px',
                            border:
                            email != "" && !email.includes("@") || emailError != "" && email == ""
                                ? "1px solid red"
                                : "1px solid rgb(230, 233, 240)",
                            transition: "border-color 0.3s ease-in-out",
                            outline: "none",
                            boxShadow: `0 0 0 3px transparent`,
                          }}
                          onFocus={(e) => {
                            e.target.style.borderColor = `${formColor}`; // Change border color on focus
                            e.target.style.boxShadow = `${formColor}`; // Change focus ring color on focus
                          }}
                          onBlur={(e) => {
                            e.target.style.borderColor = "rgb(230, 233, 240)"; // Reset border color on blur
                            e.target.style.boxShadow = "0 0 0 3px transparent"; // Reset focus ring color on blur
                          }}
                          className={
                            (email != "" && !email.includes("@")) ||
                            (emailError != "" && email == "")
                              ? "appearance-none w-full text-lg leading-6 bg-white font-normal text-gray-800 placeholder:text-gray-400 rounded-lg py-3 px-4 border border-red-500 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition duration-500"
                              : "appearance-none block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 placeholder:text-gray-400 bg-white bg-clip-padding border border-gray-300 rounded-lg m-0 focus:outline-none focus:bg-white transition ease-in-out"
                          }
                          id="email"
                          placeholder="me@email.com"
                        />
                        {(email != "" &&
                          emailError != "" &&
                          !email.includes("@")) ||
                        (emailError != "" && email == "") ? (
                          <div className="inline-flex items-baseline mt-2">
                            <img className="self-center" src={Vector} />
                            <h5 className="errorClass">{emailError}</h5>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-row mt-5">
                      <div className="col-md-12 mb-3">
                        <label for="" className="mb-12">
                          You can select <b>prefer not to say,</b> but we’d love
                          to know:{" "}
                        </label>
                        <div
                          className="d-sm-flex align-items-center justify-content-between flex-wrap col-gap-90"
                          id="Gender-Error"
                        >
                          <div className="flex1 ">
                            <label for="" className="GenderMargin">
                              Gender Identity*
                            </label>
                            <Select
                              placeholder="Choose"
                              name="Gender"
                              isClearable={true}
                              getOptionLabel={(item) => item?.currItem}
                              getOptionValue={(item) => item?.currItem}
                              onChange={(item) => {
                                setGender(item);
                              }}
                              styles={ genderError != "" && gender == null ? {
                                control: (provided, state) => ({
                              ...provided,
                              borderColor: `red`,
                              // boxShadow: state.isFocused ? `0 0 0 1px red` : provided.boxShadow,
                              minHeight: '47px',
                              '&:hover': {
                               borderColor: `${formColor}`, // Change border color on hover
                               },
                              }),
                              indicatorsContainer: (provided) => ({
                              ...provided,
                              svg: {
                               fill: `${formColor}`, // Change the fill color of the SVG indicator
                              },
                              }),
                              } : {
                              control: (provided, state) => ({
                              ...provided,
                              borderColor: state.isFocused ? `${formColor}` : "none",
                              boxShadow: state.isFocused ? `0 0 0 1px ${formColor}` : provided.boxShadow,
                              minHeight: '47px',
                              '&:hover': {
                               borderColor: `${formColor}`, // Change border color on hover
                               },
                              }),
                              indicatorsContainer: (provided) => ({
                              ...provided,
                              svg: {
                               fill: `${formColor}`, // Change the fill color of the SVG indicator
                              },
                              }),
                              }}
                              value={gender}
                              options={GenderArray}
                            />
                          </div>
                          <div className="flex1 ">
                            <label for="" className="GenderMargin">
                              Ethnicity*
                            </label>
                            <Select
                              placeholder="Choose"
                              name="Ethnicity"
                              isClearable={true}
                              getOptionLabel={(item) => item?.currItem}
                              getOptionValue={(item) => item?.currItem}
                              styles={ EthnicityError != "" &&
                                Ethnicity == null  ? {
                                control: (provided, state) => ({
                              ...provided,
                              borderColor: `red`,
                              // boxShadow: state.isFocused ? `0 0 0 1px red` : provided.boxShadow,
                              minHeight: '47px',
                              '&:hover': {
                               borderColor: `${formColor}`, // Change border color on hover
                               },
                              }),
                              indicatorsContainer: (provided) => ({
                              ...provided,
                              svg: {
                               fill: `${formColor}`, // Change the fill color of the SVG indicator
                              },
                              }),
                              } : {
                              control: (provided, state) => ({
                              ...provided,
                              borderColor: state.isFocused ? `${formColor}` : "none",
                              boxShadow: state.isFocused ? `0 0 0 1px ${formColor}` : provided.boxShadow,
                              minHeight: '47px',
                              '&:hover': {
                               borderColor: `${formColor}`, // Change border color on hover
                               },
                              }),
                              indicatorsContainer: (provided) => ({
                              ...provided,
                              svg: {
                               fill: `${formColor}`, // Change the fill color of the SVG indicator
                              },
                              }),
                              }}
                              onChange={(item) => {
                                setEthnicity(item);
                              }}
                              value={Ethnicity}
                              options={EthencityArray}
                            />
                          </div>
                        </div>
                        {genderError != "" && gender == null && (
                          <div className="inline-flex items-baseline mt-2">
                            <img className="self-center" src={Vector} />
                            <h5 className="errorClass">{genderError}</h5>
                          </div>
                        )}
                        {EthnicityError != "" && Ethnicity == null && (
                          <div className="inline-flex float-right ethencity items-baseline mt-2">
                            <img className="self-center" src={Vector} />
                            <h5 className="errorClass">{EthnicityError}</h5>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-12 mb-3 important-para">
                        <p className="head">Why is this important?</p>
                        <br />
                        <p>
                          We want to build more inclusive teams, starting at the
                          top. To reach our goal, we need to understand a bit
                          more about who you are so we know how we’re doing.
                        </p>
                        <br />
                        <p>
                          This data is just for us and won’t be shared with
                          employers (or anyone else). Thank you for supporting
                          us in this goal.🙂🙏
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}
            {screen == 2 && (
              <div class="row justify-content-center">
                <div class="form-div">
                  <div class="forom-box">
                    <div class="tab-list">
                      <ul>
                        <li>
                          <a href="#">
                            <span>
                              <i class="fa fa-circle" aria-hidden="true"></i>
                            </span>
                            Who you are{" "}
                          </a>
                          <hr style={{height: '4px', borderRadius: '4px', color: `#e6e9f0`, margin: '14px 0', border: `1px solid #e6e9f0`, backgroundColor: `#e6e9f0`}} />
                        </li>
                        <li class="active">
                          <a href="#">
                          <span style={{ color: `${formColor}` }}>
                              <i class="fa fa-circle" aria-hidden="true"></i>
                            </span>
                            Your Experience{" "}
                          </a>
                          <hr style={{height: '4px', borderRadius: '4px', color: `${formColor}`, margin: '14px 0', border: `1px solid ${formColor}`, backgroundColor: `${formColor}`}} />
                        </li>
                        <li>
                          <a href="#">
                            <span>
                              <i class="fa fa-circle" aria-hidden="true"></i>
                            </span>
                            Your Preferences{" "}
                          </a>
                          <hr style={{height: '4px', borderRadius: '4px', color: `#e6e9f0`, margin: '14px 0', border: `1px solid #e6e9f0`, backgroundColor: `#e6e9f0`}} />
                        </li>
                      </ul>
                    </div>
                    <div class="formbg relative">
                      <form id="">
                        <div className="circle"
                        style={
                            formColor !== ""
                              ? { backgroundColor: `${formColor}` }
                              : null
                          }
                        >
                          <img src={Clock} />
                          <div>
                            <b>Estimated:</b>
                          </div>
                          <div>2 mins left</div>
                        </div>
                        <div className="form-row">
                          <div className="col-md-12 mb-3">
                            <label for="exampleFormControlSelect1">
                              What are you <b>best at?</b>
                            </label>
                            <Select
                              placeholder="Choose Expertise"
                              name="Expertise"
                              id="Expertise"
                              className="text-base md:text-lg font-normal text-gray-800"
                              isClearable={true}
                              getOptionLabel={(item) => item?.currItem}
                              getOptionValue={(item) => item?.currItem}
                              value={expertise}
                              styles={ expertiseError != "" &&
                              expertise == null  ? {
                                control: (provided, state) => ({
                              ...provided,
                              borderColor: `red`,
                              // boxShadow: state.isFocused ? `0 0 0 1px red` : provided.boxShadow,
                              minHeight: '47px',
                              '&:hover': {
                               borderColor: `${formColor}`, // Change border color on hover
                               },
                              }),
                              indicatorsContainer: (provided) => ({
                              ...provided,
                              svg: {
                               fill: `${formColor}`, // Change the fill color of the SVG indicator
                              },
                              }),
                              } : {
                              control: (provided, state) => ({
                              ...provided,
                              borderColor: state.isFocused ? `${formColor}` : "none",
                              boxShadow: state.isFocused ? `0 0 0 1px ${formColor}` : provided.boxShadow,
                              minHeight: '47px',
                              '&:hover': {
                               borderColor: `${formColor}`, // Change border color on hover
                               },
                              }),
                              indicatorsContainer: (provided) => ({
                              ...provided,
                              svg: {
                               fill: `${formColor}`, // Change the fill color of the SVG indicator
                              },
                              }),
                              }}
                              // onChange={(item) => setExpertise(item)}
                              onChange={(item) => handleExpertiseChange(item)}
                              options={ExpertiseMapStringresult}
                            />
                            {expertiseError != "" && expertise == null && (
                              <div
                                className="inline-flex items-baseline mt-2"
                                id="expert-field"
                              >
                                <img className="self-center" src={Vector} />
                                <h5 className="errorClass">{expertiseError}</h5>
                              </div>
                            )}
                          </div>
                        </div>
                        {filteredSubCategories?.length > 0 && (
                          <div className="form-row">
                            <div className="col-md-12 mb-3">
                              <label for="exampleFormControlSelect1">
                                What are your <b>specialities?</b>
                              </label>
                              <Select
                                placeholder="Select your specialities"
                                name="specialities"
                                id="specialities"
                                isMulti
                                className="text-base md:text-lg font-normal text-gray-800"
                                isClearable={true}
                                getOptionLabel={(item) => item?.currItem}
                                getOptionValue={(item) => item?.currItem}
                                value={specialities}
                                styles={ specialitiesError != "" &&
                                specialities == null  ? {
                                control: (provided, state) => ({
                              ...provided,
                              borderColor: `red`,
                              // boxShadow: state.isFocused ? `0 0 0 1px red` : provided.boxShadow,
                              minHeight: '47px',
                              '&:hover': {
                               borderColor: `${formColor}`, // Change border color on hover
                               },
                              }),
                              indicatorsContainer: (provided) => ({
                              ...provided,
                              svg: {
                               fill: `${formColor}`, // Change the fill color of the SVG indicator
                              },
                              }),
                              } : {
                              control: (provided, state) => ({
                              ...provided,
                              borderColor: state.isFocused ? `${formColor}` : "none",
                              boxShadow: state.isFocused ? `0 0 0 1px ${formColor}` : provided.boxShadow,
                              minHeight: '47px',
                              '&:hover': {
                               borderColor: `${formColor}`, // Change border color on hover
                               },
                              }),
                              indicatorsContainer: (provided) => ({
                              ...provided,
                              svg: {
                               fill: `${formColor}`, // Change the fill color of the SVG indicator
                              },
                              }),
                              }}
                                // styles={
                                //   specialitiesError != "" &&
                                //   specialities == null &&
                                //   customStyles
                                // }
                                onChange={(item) => {
                                    setSpecialities(item)
                                }
                                 }
                                options={filteredSubCategories}
                              />
                              {specialitiesError != "" &&
                                specialities == null || specialitiesError != "" && specialities?.length == 0 ? (
                                  <div
                                    className="inline-flex items-baseline mt-2"
                                    id="expert-field"
                                  ><img className="self-center" src={Vector} />
                                    <h5 className="errorClass">
                                      {specialitiesError}
                                    </h5>
                                  </div>
                                ) : null}
                            </div>
                          </div>
                        )}
                        <div className="form-row">
                          <div className="col-md-12 mb-3">
                            <div className="mb-23">
                              <label for="">
                                What <b>kinds of businesses</b> have you worked
                                for?{" "}
                              </label>
                            </div>
                            <div className="d-sm-flex align-items-center flex-wrap">
                            {FullBuzzModel?.map((place, index) => (
                            <div className="w-full sm:w-1/2 md:w-1/3" key={place}>
      
          <label htmlFor={place} className="checkbox" style={{cursor: 'default'}}>
            <input
              className="checkbox__input"
              type="checkbox"
              id={place + "businesses"}
              style={{cursor: 'pointer'}}
              value={place}
              checked={marketPlaces.includes(place)}
              onChange={handleMarketPlace}
            />
            <svg
              className="checkbox__icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 22 22"
            >
              <rect
                width="21"
                height="21"
                x=".5"
                y=".5"
                fill="#FFF"
                stroke="transparent"
                rx="3"
              />
              <path
                className="tick"
                stroke={`${formColor}`} // Assuming formColor is defined
                fill="none"
                strokeLinecap="round" // Corrected attribute name
                strokeWidth="1" // Corrected attribute name
                d="M4 10l5 5 9-9"
              />
            </svg>
            <span className="checkbox__label">{place}</span>
          </label>
        </div>
  ))}
                            </div>
                            {marketPlacesError != "" &&
                              marketPlaces.length == 0 && (
                                <div className="inline-flex items-baseline mt-2">
                                  <img className="self-center" src={Vector} />
                                  <h5 className="errorClass">
                                    {marketPlacesError}
                                  </h5>
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-md-12 mb-3" style={{marginBottom:"16px"}}>
                            <div className="mb-23">
                              <label for="">
                              Do you have any of the following experience?
                              </label>
                            </div>
                            <div className="d-sm-flex align-items-center justify-content-between flex-wrap">
                              <div className="flex1 flex137">
                                <label for="Chair" className="checkbox" style={{cursor: 'default'}}>
                                  <input
                                    className="checkbox__input"
                                    type="checkbox"
                                    id="Chairexperience"
                                    style={{cursor: 'pointer'}}
                                    value={"Chair"}
                                    checked={boardDirector.includes("Chair")}
                                    onChange={(e) => handleBoardmember(e)}
                                  />
                                  <svg
                                    className="checkbox__icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                  >
                                    <rect
                                      width="21"
                                      height="21"
                                      x=".5"
                                      y=".5"
                                      fill="#FFF"
                                      stroke="transparent"
                                      rx="3"
                                    />
                                    <path
                                      className="tick"
                                      stroke={`${formColor}`}
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                      d="M4 10l5 5 9-9"
                                    />
                                  </svg>
                                  <span className="checkbox__label">Chair</span>
                                </label>
                              </div>
                              <div className="flex1 flex137">
                                <label for="NED" className="checkbox" style={{cursor: 'default'}}>
                                  <input
                                    className="checkbox__input"
                                    type="checkbox"
                                    id="NEDexperience"
                                    style={{cursor: 'pointer'}}
                                    value={"NED"}
                                    checked={boardDirector.includes("NED")}
                                    onChange={(e) => handleBoardmember(e)}
                                  />
                                  <svg
                                    className="checkbox__icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                  >
                                    <rect
                                      width="21"
                                      height="21"
                                      x=".5"
                                      y=".5"
                                      fill="#FFF"
                                      stroke="transparent"
                                      rx="3"
                                    />
                                    <path
                                      className="tick"
                                      stroke={`${formColor}`}
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                      d="M4 10l5 5 9-9"
                                    />
                                  </svg>
                                  <span className="checkbox__label">NED</span>
                                </label>
                              </div>
                              <div className="flex1 flex137">
                                <label for="Founder" className="checkbox" style={{cursor: 'default'}}>
                                  <input
                                    className="checkbox__input"
                                    type="checkbox"
                                    id="Founderexperience"
                                    style={{cursor: 'pointer'}}
                                    value={"Founder"}
                                    checked={boardDirector.includes("Founder")}
                                    onChange={(e) => handleBoardmember(e)}
                                  />
                                  <svg
                                    className="checkbox__icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                  >
                                    <rect
                                      width="21"
                                      height="21"
                                      x=".5"
                                      y=".5"
                                      fill="#FFF"
                                      stroke="transparent"
                                      rx="3"
                                    />
                                    <path
                                      className="tick"
                                      stroke={`${formColor}`}
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                      d="M4 10l5 5 9-9"
                                    />
                                  </svg>
                                  <span className="checkbox__label">Founder</span>
                                </label>
                              </div>
                              <div className="flex1 flex137">
                                <label for="Advisor" className="checkbox" style={{cursor: 'default'}}>
                                  <input
                                    className="checkbox__input"
                                    type="checkbox"
                                    id="Advisorexperience"
                                    style={{cursor: 'pointer'}}
                                    value={"Advisor"}
                                    checked={boardDirector.includes("Advisor")}
                                    onChange={(e) => handleBoardmember(e)}
                                  />
                                  <svg
                                    className="checkbox__icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                  >
                                    <rect
                                      width="21"
                                      height="21"
                                      x=".5"
                                      y=".5"
                                      fill="#FFF"
                                      stroke="transparent"
                                      rx="3"
                                    />
                                    <path
                                      className="tick"
                                      stroke={`${formColor}`}
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                      d="M4 10l5 5 9-9"
                                    />
                                  </svg>
                                  <span className="checkbox__label">Advisor</span>
                                </label>
                              </div>
                              <div className="flex1 flex137">
                                <label for="currently" className="checkbox" style={{cursor: 'default'}}>
                                  <input
                                    className="checkbox__input"
                                    type="checkbox"
                                    id="currentlyexperience"
                                    style={{cursor: 'pointer'}}
                                    value={"currently"}
                                    checked={boardDirector.includes("currently")}
                                    onChange={(e) => handleBoardmember(e)}
                                  />
                                  <svg
                                    className="checkbox__icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                  >
                                    <rect
                                      width="21"
                                      height="21"
                                      x=".5"
                                      y=".5"
                                      fill="#FFF"
                                      stroke="transparent"
                                      rx="3"
                                    />
                                    <path
                                      className="tick"
                                      stroke={`${formColor}`}
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                      d="M4 10l5 5 9-9"
                                    />
                                  </svg>
                                  <span className="checkbox__label">I don’t currently have any of the experience listed above</span>
                                </label>
                              </div>
                            </div>
                            {boardDirectorError != "" &&
                            boardDirector.length == 0 && (
                                <div className="inline-flex items-baseline mt-2">
                                  <img className="self-center" src={Vector} />
                                  <h5 className="errorClass">
                                    {boardDirectorError}
                                  </h5>
                                </div>
                              )}
                          </div>
                        </div>
                        {boardDirector.includes("Chair") || boardDirector.includes("NED") ? (
                          <div className="w-full" style={{marginBottom:"16px"}}>
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 mb-2"
                            for="password"
                          >
                          Please give us some details on your <strong>NED/Chair experience:</strong>
                          </label>
                          <textarea
                            id="textarea"
                            placeholder={`I was chair of a fintech business, that IPO’d in 2014. I also have had Non-executive-director roles in 3 different B2B companies and I sat on the remuneration and audit committees `}
                            // rows="3"
                            onChange={(e) => setChariExperience(e.target.value)}
                            style={{
                            // padding: '10px',
                            height: "236px",
                            border: "1px solid rgb(230, 233, 240)",
                            transition: "border-color 0.3s ease-in-out",
                            outline: "none",
                            boxShadow: `0 0 0 3px transparent`,
                          }}
                          onFocus={(e) => {
                            e.target.style.borderColor = `${formColor}`; // Change border color on focus
                            e.target.style.boxShadow = `${formColor}`; // Change focus ring color on focus
                          }}
                          onBlur={(e) => {
                            e.target.style.borderColor = "rgb(230, 233, 240)"; // Reset border color on blur
                            e.target.style.boxShadow = "0 0 0 3px transparent"; // Reset focus ring color on blur
                          }}
                          className="appearance-none block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 placeholder:text-gray-400 bg-white bg-clip-padding border border-gray-300 rounded-lg m-0 focus:outline-none focus:bg-white transition ease-in-out"
                          >
                            {ChariExperience}
                          </textarea>
                          {ChariExperienceError != "" && boardDirector.includes("Chair") && ChariExperience == "" || ChariExperienceError != "" && boardDirector.includes("NED") && ChariExperience == ""  ? (
                                <div className="inline-flex items-baseline mt-2">
                                  <img className="self-center" src={Vector} />
                                  <h5 className="errorClass pt-1">
                                    {ChariExperienceError}
                                  </h5>
                                </div>
                              ): null}
                        </div>
                        ) : null}
                        {boardDirector.includes("Founder") ? (
                          <div className="w-full" style={{marginBottom:"16px"}}>
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 mb-2"
                            for="password"
                          >
                          Please share some details about your <strong>Founder experience:</strong>
                          </label>
                          <textarea
                            id="textarea"
                            placeholder={`I am a serial Founder with 3 exits under my belt:
• A SaaS payments platform that got acquired in 2006 by a PE     
  firm 
• A banking app that got acquired in 2010 for $200 mil
• A  propriety management company that IPO-ed in 2022`}
                            // rows="3"
                            onChange={(e) => setFounderExperience(e.target.value)}
                            style={{
                            // padding: '10px',
                            height: "236px",
                            border: "1px solid rgb(230, 233, 240)",
                            transition: "border-color 0.3s ease-in-out",
                            outline: "none",
                            boxShadow: `0 0 0 3px transparent`,
                          }}
                          onFocus={(e) => {
                            e.target.style.borderColor = `${formColor}`; // Change border color on focus
                            e.target.style.boxShadow = `${formColor}`; // Change focus ring color on focus
                          }}
                          onBlur={(e) => {
                            e.target.style.borderColor = "rgb(230, 233, 240)"; // Reset border color on blur
                            e.target.style.boxShadow = "0 0 0 3px transparent"; // Reset focus ring color on blur
                          }}
                          className="appearance-none block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 placeholder:text-gray-400 bg-white bg-clip-padding border border-gray-300 rounded-lg m-0 focus:outline-none focus:bg-white transition ease-in-out"
                          >
                            {FounderExperience}
                          </textarea>
                          {FounderExperienceError != "" && boardDirector.includes("Founder") && FounderExperience == "" && (
                                <div className="inline-flex items-baseline mt-2">
                                  <img className="self-center" src={Vector} />
                                  <h5 className="errorClass pt-1">
                                    {FounderExperienceError}
                                  </h5>
                                </div>
                              )}
                        </div>
                        ) : null}
                        {boardDirector.includes("Advisor") ? (
                          <div className="w-full" style={{marginBottom:"16px"}}>
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 mb-2"
                            for="password"
                          >
                          Please share some details about your <strong>Advisor/Consultant experience:</strong>
                          </label>
                          <textarea
                            id="textarea"
                            placeholder={`I’m a senior finance leader with extensive experience advising on IPO readiness. I have specific operational expertise across risk management and strategy functions within a public company setting.
                                                                                                                   I’ve consulted on VC and capital fundraising and facilitated £120M+ equity and debt financing.`}
                            // rows="3"
                            onChange={(e) => setconsultancyExperience(e.target.value)}
                            style={{
                            // padding: '10px',
                            height: "236px",
                            border: "1px solid rgb(230, 233, 240)",
                            transition: "border-color 0.3s ease-in-out",
                            outline: "none",
                            boxShadow: `0 0 0 3px transparent`,
                          }}
                          onFocus={(e) => {
                            e.target.style.borderColor = `${formColor}`; // Change border color on focus
                            e.target.style.boxShadow = `${formColor}`; // Change focus ring color on focus
                          }}
                          onBlur={(e) => {
                            e.target.style.borderColor = "rgb(230, 233, 240)"; // Reset border color on blur
                            e.target.style.boxShadow = "0 0 0 3px transparent"; // Reset focus ring color on blur
                          }}
                          className="appearance-none block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 placeholder:text-gray-400 bg-white bg-clip-padding border border-gray-300 rounded-lg m-0 focus:outline-none focus:bg-white transition ease-in-out"
                          >
                            {consultancyExperience}
                          </textarea>
                          {consultancyExperienceError != "" && boardDirector.includes("Advisor") && consultancyExperience == "" && (
                                <div className="inline-flex items-baseline mt-2">
                                  <img className="self-center" src={Vector} />
                                  <h5 className="errorClass pt-1">
                                    {consultancyExperienceError}
                                  </h5>
                                </div>
                              )}
                        </div>
                        ) : null}
                        {boardDirector.includes("currently") ? (
                          <div className="w-full" style={{marginBottom:"16px"}}>
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 mb-2"
                            for="password"
                          >
                          Please <strong>highlight</strong> the aspects of your professional background that are most relevant for the type of work you'd like to do with our portfolio companies:
                          </label>
                          <textarea
                            id="textarea"
                            placeholder={`Having previously served as a CFO of a fintech start-up in London, I am currently advising early stage start-ups on finance, strategy, and fundraising.

I work with investors and companies to identify and maximise  sustainable business value. My approach is to work with teams to understand levers of change that will be most efficient in your organisation and then establish methods of effective implementation to achieve goals.`}
                            // rows="3"
                            onChange={(e) => sethighlight(e.target.value)}
                            style={{
                            // padding: '10px',
                            height: "236px",
                            border: "1px solid rgb(230, 233, 240)",
                            transition: "border-color 0.3s ease-in-out",
                            outline: "none",
                            boxShadow: `0 0 0 3px transparent`,
                          }}
                          onFocus={(e) => {
                            e.target.style.borderColor = `${formColor}`; // Change border color on focus
                            e.target.style.boxShadow = `${formColor}`; // Change focus ring color on focus
                          }}
                          onBlur={(e) => {
                            e.target.style.borderColor = "rgb(230, 233, 240)"; // Reset border color on blur
                            e.target.style.boxShadow = "0 0 0 3px transparent"; // Reset focus ring color on blur
                          }}
                          className="appearance-none block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 placeholder:text-gray-400 bg-white bg-clip-padding border border-gray-300 rounded-lg m-0 focus:outline-none focus:bg-white transition ease-in-out"
                          >
                            {highlight}
                          </textarea>
                          {highlightError != "" &&  boardDirector.includes("currently") && highlight == "" && (
                                <div className="inline-flex items-baseline mt-2">
                                  <img className="self-center" src={Vector} />
                                  <h5 className="errorClass pt-1">
                                    {highlightError}
                                  </h5>
                                </div>
                              )}
                        </div>
                        ) : null}
                        <div class="form-row">
                          <div class="col-md-12 mb-3">
                            <div class="mb-23">
                              <label for="">
                              What growth <b>stages</b> have you experienced in past roles?
                              </label>
                            </div>
                            <div class="d-sm-flex align-items-center flex-wrap">
                            {FullStageModel?.map((place, index) => (
                                  <div class="w-full sm:w-1/2 md:w-1/3" key={place}>
                                    <label htmlFor={place} className="checkbox" style={{cursor: 'default'}}>
                                      <input
                                        className="checkbox__input"
                                        type="checkbox"
                                        id={place + "stages"}
                                        style={{cursor: 'pointer'}}
                                        value={place}
                                        checked={stage.includes(place)}
                                        onChange={(e) => handleStagesArray(e)}
                                      />
                                      <svg
                                        className="checkbox__icon"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 22 22"
                                      >
                                        <rect
                                          width="21"
                                          height="21"
                                          x=".5"
                                          y=".5"
                                          fill="#FFF"
                                          stroke="transparent"
                                          rx="3"
                                        />
                                        <path
                                          className="tick"
                                          stroke={`${formColor}`} // Assuming formColor is defined
                                          fill="none"
                                          strokeLinecap="round" // Corrected attribute name
                                          strokeWidth="1" // Corrected attribute name
                                          d="M4 10l5 5 9-9"
                                        />
                                      </svg>
                                      <span className="checkbox__label">
                                        {place}
                                      </span>
                                    </label>
                                  </div>
                                ))}
                              {stageError != "" && stage.length == 0 && (
                                <div className="inline-flex items-baseline mt-2">
                                  <img className="self-center" src={Vector} />
                                  <h5 className="errorClass mt-1">
                                    {stageError}
                                  </h5>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div class="form-row">
                          <div class="col-md-12 mb-3">
                            <div class="mb-23">
                              <label for="">
                                Which <b>industries</b> do you have experience
                                in?
                              </label>
                            </div>
                              <div class="d-sm-flex align-items-center flex-wrap">
                              {FullIndusModel?.map((place, index) => (
                                  <div class="w-full sm:w-1/2 md:w-1/3" key={place}>
                                    <label htmlFor={place} className="checkbox" style={{cursor: 'default'}}>
                                      <input
                                        className="checkbox__input"
                                        type="checkbox"
                                        id={place + "industries"}
                                        style={{cursor: 'pointer'}}
                                        value={place}
                                        checked={industries.includes(place)}
                                        onChange={(e) => handleIndustries(e)}
                                      />
                                      <svg
                                        className="checkbox__icon"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 22 22"
                                      >
                                        <rect
                                          width="21"
                                          height="21"
                                          x=".5"
                                          y=".5"
                                          fill="#FFF"
                                          stroke="transparent"
                                          rx="3"
                                        />
                                        <path
                                          className="tick"
                                          stroke={`${formColor}`} // Assuming formColor is defined
                                          fill="none"
                                          strokeLinecap="round" // Corrected attribute name
                                          strokeWidth="1" // Corrected attribute name
                                          d="M4 10l5 5 9-9"
                                        />
                                      </svg>
                                      <span className="checkbox__label">
                                        {place}
                                      </span>
                                    </label>
                                  </div>
                                ))}
                              </div>
                            {industriesError != "" &&
                              industries.length == 0 && (
                                <div className="inline-flex items-baseline mt-2">
                                  <img className="self-center" src={Vector} />
                                  <h5 className="errorClass">
                                    {industriesError}
                                  </h5>
                                </div>
                              )}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  {screen == 2 && (
                    <div class="col-md-12 mb-5 ">
                      <div class="d-sm-flex justify-content-center btn-gap text-center ">
                        <div>
                          <button
                            class="btn back"
                            type="submit"
                            style={{color: `${formColor}`}}
                            onClick={(e) => handleClickPrev(e)}
                          >
                            Back
                          </button>
                        </div>
                        <div>
                          <button
                            class="btn submit"
                            type="submit"
                            style={{backgroundColor: `${formColor}`}}
                            onClick={(e) => handleClickNext(e)}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {screen == 3 && (
              <div class="row justify-content-center">
                <div class="form-div">
                  <div class="forom-box">
                    <div class="tab-list">
                      <ul>
                        <li>
                          <a href="#">
                            <span>
                              <i class="fa fa-circle" aria-hidden="true"></i>
                            </span>
                            Who you are{" "}
                          </a>
                          <hr style={{height: '4px', borderRadius: '4px', color: `#e6e9f0`, margin: '14px 0', border: `1px solid #e6e9f0`, backgroundColor: `#e6e9f0`}} />
                        </li>
                        <li>
                          <a href="#">
                            <span>
                              <i class="fa fa-circle" aria-hidden="true"></i>
                            </span>
                            Your Experience{" "}
                          </a>
                          <hr style={{height: '4px', borderRadius: '4px', color: `#e6e9f0`, margin: '14px 0', border: `1px solid #e6e9f0`, backgroundColor: `#e6e9f0`}} />
                        </li>
                        <li class="active">
                          <a href="#">
                          <span style={{ color: `${formColor}` }}>
                              <i class="fa fa-circle" aria-hidden="true"></i>
                            </span>
                            Your Preferences{" "}
                          </a>
                          <hr style={{height: '4px', borderRadius: '4px', color: `${formColor}`, margin: '14px 0', border: `1px solid ${formColor}`, backgroundColor: `${formColor}`}} />
                        </li>
                      </ul>
                    </div>
                    <div class="formbg relative">
                      <form id="">
                        <div className="circle"
                        style={
                            formColor !== ""
                              ? { backgroundColor: `${formColor}` }
                              : null
                          }
                        >
                          <img src={Clock} />
                          <div>
                            <b>Estimated:</b>
                          </div>
                          <div>1 mins left</div>
                        </div>
                        <div class="form-row">
                          <div class="col-md-12 mb-3" id="role">
                            <div class="mb-23">
                              <label for="">
                                What roles are <b>you interested in?</b>
                              </label>
                            </div>
                            <div class="d-sm-flex align-items-center justify-content-between flex-wrap">
                              <div class="flex1 flex140">
                                <label for="Advisor" class="checkbox" style={{cursor: 'default'}}>
                                  <input
                                    class="checkbox__input"
                                    type="checkbox"
                                    id="Advisorinterested"
                                    style={{cursor: 'pointer'}}
                                    value={"Advisor"}
                                    checked={role.includes("Advisor")}
                                    onChange={(e) => handleRoleboxChange(e)}
                                  />
                                  <svg
                                    class="checkbox__icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                  >
                                    <rect
                                      width="21"
                                      height="21"
                                      x=".5"
                                      y=".5"
                                      fill="#FFF"
                                      stroke="transparent"
                                      rx="3"
                                    ></rect>
                                    <path
                                      class="tick"
                                      stroke={`${formColor}`}
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                      d="M4 10l5 5 9-9"
                                    ></path>
                                  </svg>
                                  <span class="checkbox__label">Advisor</span>
                                </label>
                              </div>
                              <div class="flex1 flex140">
                                <label for="Chair" class="checkbox" style={{cursor: 'default'}}>
                                  <input
                                    class="checkbox__input"
                                    type="checkbox"
                                    id="Chairinterested"
                                    style={{cursor: 'pointer'}}
                                    value={"Chair"}
                                    checked={role.includes("Chair")}
                                    onChange={(e) => handleRoleboxChange(e)}
                                  />
                                  <svg
                                    class="checkbox__icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                  >
                                    <rect
                                      width="21"
                                      height="21"
                                      x=".5"
                                      y=".5"
                                      fill="#FFF"
                                      stroke="transparent"
                                      rx="3"
                                    ></rect>
                                    <path
                                      class="tick"
                                      stroke={`${formColor}`}
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                      d="M4 10l5 5 9-9"
                                    ></path>
                                  </svg>
                                  <span class="checkbox__label">Chair</span>
                                </label>
                              </div>
                              <div class="flex1 flex140">
                                <label for="Mentor" class="checkbox" style={{cursor: 'default'}}>
                                  <input
                                    class="checkbox__input"
                                    type="checkbox"
                                    id="Mentorinterested"
                                    style={{cursor: 'pointer'}}
                                    value={"Mentor"}
                                    checked={role.includes("Mentor")}
                                    onChange={(e) => handleRoleboxChange(e)}
                                  />
                                  <svg
                                    class="checkbox__icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                  >
                                    <rect
                                      width="21"
                                      height="21"
                                      x=".5"
                                      y=".5"
                                      fill="#FFF"
                                      stroke="transparent"
                                      rx="3"
                                    ></rect>
                                    <path
                                      class="tick"
                                      stroke={`${formColor}`}
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                      d="M4 10l5 5 9-9"
                                    ></path>
                                  </svg>
                                  <span class="checkbox__label">Mentor</span>
                                </label>
                              </div>
                              <div class="flex1 flex140">
                                <label for="Consultant" class="checkbox" style={{cursor: 'default'}}>
                                  <input
                                    class="checkbox__input"
                                    type="checkbox"
                                    id="Consultantinterested"
                                    style={{cursor: 'pointer'}}
                                    value={"Consultant"}
                                    checked={role.includes("Consultant")}
                                    onChange={(e) => handleRoleboxChange(e)}
                                  />
                                  <svg
                                    class="checkbox__icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                  >
                                    <rect
                                      width="21"
                                      height="21"
                                      x=".5"
                                      y=".5"
                                      fill="#FFF"
                                      stroke="transparent"
                                      rx="3"
                                    ></rect>
                                    <path
                                      class="tick"
                                      stroke={`${formColor}`}
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                      d="M4 10l5 5 9-9"
                                    ></path>
                                  </svg>
                                  <span class="checkbox__label">
                                    Consultant
                                  </span>
                                </label>
                              </div>
                              <div class="flex1 flex140">
                                <label for="NED" class="checkbox" style={{cursor: 'default'}}>
                                  <input
                                    class="checkbox__input"
                                    type="checkbox"
                                    id="NEDinterested"
                                    style={{cursor: 'pointer'}}
                                    value={"NED"}
                                    checked={role.includes("NED")}
                                    onChange={(e) => handleRoleboxChange(e)}
                                  />
                                  <svg
                                    class="checkbox__icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                  >
                                    <rect
                                      width="21"
                                      height="21"
                                      x=".5"
                                      y=".5"
                                      fill="#FFF"
                                      stroke="transparent"
                                      rx="3"
                                    ></rect>
                                    <path
                                      class="tick"
                                      stroke={`${formColor}`}
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                      d="M4 10l5 5 9-9"
                                    ></path>
                                  </svg>
                                  <span class="checkbox__label">NED</span>
                                </label>
                              </div>
                              <div class="flex1 flex140">
                                <label for="Coach" class="checkbox" style={{cursor: 'default'}}>
                                  <input
                                    class="checkbox__input"
                                    type="checkbox"
                                    id="Coachinterested"
                                    style={{cursor: 'pointer'}}
                                    value={"Coach"}
                                    checked={role.includes("Coach")}
                                    onChange={(e) => handleRoleboxChange(e)}
                                  />
                                  <svg
                                    class="checkbox__icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                  >
                                    <rect
                                      width="21"
                                      height="21"
                                      x=".5"
                                      y=".5"
                                      fill="#FFF"
                                      stroke="transparent"
                                      rx="3"
                                    ></rect>
                                    <path
                                      class="tick"
                                      stroke={`${formColor}`}
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                      d="M4 10l5 5 9-9"
                                    ></path>
                                  </svg>
                                  <span class="checkbox__label">Coach</span>
                                </label>
                              </div>
                            </div>
                            {roleError != "" && role.length == 0 && (
                              <div className="inline-flex items-baseline mt-2">
                                <img className="self-center" src={Vector} />
                                <h5 className="errorClass mt-1">{roleError}</h5>
                              </div>
                            )}
                          </div>
                        </div>
                        <div class="form-row">
                          <div class="col-md-12 mb-3" id="availability">
                            <div class="mb-23">
                              <label for="">
                              What's your <b>availability </b> to work with our portfolio?
                              </label>
                            </div>
                            <div class="d-sm-flex align-items-center justify-content-between flex-wrap">
                              <div class="flex1 flex140">
                                <label for="No-remote" class="checkbox" style={{cursor: 'default'}}>
                                  <input
                                    class="checkbox__input"
                                    type="checkbox"
                                    id="No-remoteavailability"
                                    style={{cursor: 'pointer'}}
                                    value={"Full Time"}
                                    checked={time.includes("Full Time")}
                                    onChange={(e) => handleTimeboxChange(e)}
                                  />
                                  <svg
                                    class="checkbox__icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                  >
                                    <rect
                                      width="21"
                                      height="21"
                                      x=".5"
                                      y=".5"
                                      fill="#FFF"
                                      stroke="transparent"
                                      rx="3"
                                    ></rect>
                                    <path
                                      class="tick"
                                      stroke={`${formColor}`}
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                      d="M4 10l5 5 9-9"
                                    ></path>
                                  </svg>
                                  <span class="checkbox__label">
                                    Full Time
                                  </span>
                                </label>
                              </div>
                              <div class="flex1 flex140">
                                <label for="Fractional" class="checkbox" style={{cursor: 'default'}}>
                                  <input
                                    class="checkbox__input"
                                    type="checkbox"
                                    id="Fractionalavailability"
                                    style={{cursor: 'pointer'}}
                                    value={"Fractional"}
                                    checked={time.includes("Fractional")}
                                    onChange={(e) => handleTimeboxChange(e)}
                                  />
                                  <svg
                                    class="checkbox__icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                  >
                                    <rect
                                      width="21"
                                      height="21"
                                      x=".5"
                                      y=".5"
                                      fill="#FFF"
                                      stroke="transparent"
                                      rx="3"
                                    ></rect>
                                    <path
                                      class="tick"
                                      stroke={`${formColor}`}
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                      d="M4 10l5 5 9-9"
                                    ></path>
                                  </svg>
                                  <span class="checkbox__label">
                                    Fractional
                                  </span>
                                </label>
                              </div>
                              <div class="flex1 flex140">
                                {/* <label for="Fractional" class="checkbox">
                                <input
                                  class="checkbox__input"
                                  type="checkbox"
                                  id="Fractional"
                                  value={"Fractional"}
                                  checked={time.includes("Fractional")}
                                  onChange={(e) => handleTimeboxChange(e)}
                                />
                                <svg
                                  class="checkbox__icon"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 22 22"
                                >
                                  <rect
                                    width="21"
                                    height="21"
                                    x=".5"
                                    y=".5"
                                    fill="#FFF"
                                    stroke="transparent"
                                    rx="3"
                                  ></rect>
                                  <path
                                    class="tick"
                                    stroke={`${formColor}`}
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-width="1"
                                    d="M4 10l5 5 9-9"
                                  ></path>
                                </svg>
                                <span class="checkbox__label">
                                  Fractional
                                </span>
                              </label> */}
                              </div>
                              {timeError != "" && time.length == 0 && (
                                <div className="inline-flex items-baseline mt-2">
                                  <img className="self-center" src={Vector} />
                                  <h5 className="errorClass mt-1">
                                    {timeError}
                                  </h5>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div class="form-row">
                          <div class="col-md-12 mb-3" id="remoteWork">
                            <div class="mb-23">
                              <label for="">
                                What are your <b>remote work </b>expectations?
                              </label>
                            </div>
                            <div class="d-sm-flex align-items-center justify-content-between flex-wrap">
                              <div class="flex1 flex137">
                                <label for="No-remotes" class="checkbox" style={{cursor: 'default'}}>
                                  <input
                                    class="checkbox__input"
                                    type="checkbox"
                                    id="No-remotesremote"
                                    style={{cursor: 'pointer'}}
                                    value={"Fully in office"}
                                    checked={remoteWork.includes(
                                      "Fully in office"
                                    )}
                                    onChange={(e) => handleRemoteboxChange(e)}
                                  />
                                  <svg
                                    class="checkbox__icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                  >
                                    <rect
                                      width="21"
                                      height="21"
                                      x=".5"
                                      y=".5"
                                      fill="#FFF"
                                      stroke="transparent"
                                      rx="3"
                                    ></rect>
                                    <path
                                      class="tick"
                                      stroke={`${formColor}`}
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                      d="M4 10l5 5 9-9"
                                    ></path>
                                  </svg>
                                  <span class="checkbox__label">
                                    Fully in office
                                  </span>
                                </label>
                              </div>
                              <div class="flex1 flex137">
                                <label for="Hybrid" class="checkbox" style={{cursor: 'default'}}>
                                  <input
                                    class="checkbox__input"
                                    type="checkbox"
                                    id="Hybridremote"
                                    style={{cursor: 'pointer'}}
                                    value={"Hybrid"}
                                    checked={remoteWork.includes("Hybrid")}
                                    onChange={(e) => handleRemoteboxChange(e)}
                                  />
                                  <svg
                                    class="checkbox__icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                  >
                                    <rect
                                      width="21"
                                      height="21"
                                      x=".5"
                                      y=".5"
                                      fill="#FFF"
                                      stroke="transparent"
                                      rx="3"
                                    ></rect>
                                    <path
                                      class="tick"
                                      stroke={`${formColor}`}
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                      d="M4 10l5 5 9-9"
                                    ></path>
                                  </svg>
                                  <span class="checkbox__label">Hybrid</span>
                                </label>
                              </div>
                              <div class="flex1 flex137">
                                <label for="Fully-remote" class="checkbox" style={{cursor: 'default'}}>
                                  <input
                                    class="checkbox__input"
                                    type="checkbox"
                                    id="Fully-remoteremote"
                                    style={{cursor: 'pointer'}}
                                    value={"Fully remote"}
                                    checked={remoteWork.includes(
                                      "Fully remote"
                                    )}
                                    onChange={(e) => handleRemoteboxChange(e)}
                                  />
                                  <svg
                                    class="checkbox__icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                  >
                                    <rect
                                      width="21"
                                      height="21"
                                      x=".5"
                                      y=".5"
                                      fill="#FFF"
                                      stroke="transparent"
                                      rx="3"
                                    ></rect>
                                    <path
                                      class="tick"
                                      stroke={`${formColor}`}
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                      d="M4 10l5 5 9-9"
                                    ></path>
                                  </svg>
                                  <span class="checkbox__label">
                                    Fully remote
                                  </span>
                                </label>
                              </div>
                              {remoteWorkError != "" &&
                                remoteWork.length == 0 && (
                                  <div className="inline-flex items-baseline mt-2">
                                    <img className="self-center" src={Vector} />
                                    <h5 className="errorClass mt-1">
                                      {remoteWorkError}
                                    </h5>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                        <div class="form-row" id="compensation">
                          <div class="col-md-12 mb-3">
                            <div class="mb-23">
                              <label for="">
                                What <b>compensation </b> models are you open
                                to?
                              </label>
                            </div>
                            <div class="d-sm-flex align-items-center justify-content-between flex-wrap">
                              <div class="flex1 flex140">
                                <label for="Salary-Only" class="checkbox" style={{cursor: 'default'}}>
                                  <input
                                    class="checkbox__input"
                                    type="checkbox"
                                    id="Salary-Onlycompensation"
                                    style={{cursor: 'pointer'}}
                                    value={"Salary Only"}
                                    checked={compensation.includes(
                                      "Salary Only"
                                    )}
                                    onChange={(e) =>
                                      handleCompensationboxChange(e)
                                    }
                                  />
                                  <svg
                                    class="checkbox__icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                  >
                                    <rect
                                      width="21"
                                      height="21"
                                      x=".5"
                                      y=".5"
                                      fill="#FFF"
                                      stroke="transparent"
                                      rx="3"
                                    ></rect>
                                    <path
                                      class="tick"
                                      stroke={`${formColor}`}
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                      d="M4 10l5 5 9-9"
                                    ></path>
                                  </svg>
                                  <span class="checkbox__label">
                                    Salary Only
                                  </span>
                                </label>
                              </div>
                              <div class="flex1 flex140">
                                <label for="Fixed-Daily-Rate" class="checkbox" style={{cursor: 'default'}}>
                                  <input
                                    class="checkbox__input"
                                    type="checkbox"
                                    id="Fixed-Daily-Ratecompensation"
                                    style={{cursor: 'pointer'}}
                                    value={"Fixed Daily Rate"}
                                    checked={compensation.includes(
                                      "Fixed Daily Rate"
                                    )}
                                    onChange={(e) =>
                                      handleCompensationboxChange(e)
                                    }
                                  />
                                  <svg
                                    class="checkbox__icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                  >
                                    <rect
                                      width="21"
                                      height="21"
                                      x=".5"
                                      y=".5"
                                      fill="#FFF"
                                      stroke="transparent"
                                      rx="3"
                                    ></rect>
                                    <path
                                      class="tick"
                                      stroke={`${formColor}`}
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                      d="M4 10l5 5 9-9"
                                    ></path>
                                  </svg>
                                  <span class="checkbox__label">
                                    Fixed Daily Rate
                                  </span>
                                </label>
                              </div>
                              <div class="flex1 flex140">
                                <label for="Pro-Bono" class="checkbox" style={{cursor: 'default'}}>
                                  <input
                                    class="checkbox__input"
                                    type="checkbox"
                                    id="Pro-Bonocompensation"
                                    style={{cursor: 'pointer'}}
                                    value={"Pro-Bono"}
                                    checked={compensation.includes("Pro-Bono")}
                                    onChange={(e) =>
                                      handleCompensationboxChange(e)
                                    }
                                  />
                                  <svg
                                    class="checkbox__icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                  >
                                    <rect
                                      width="21"
                                      height="21"
                                      x=".5"
                                      y=".5"
                                      fill="#FFF"
                                      stroke="transparent"
                                      rx="3"
                                    ></rect>
                                    <path
                                      class="tick"
                                      stroke={`${formColor}`}
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                      d="M4 10l5 5 9-9"
                                    ></path>
                                  </svg>
                                  <span class="checkbox__label">Pro-Bono</span>
                                </label>
                              </div>
                              <div class="flex1 flex140">
                                <label for="Equity-Only" class="checkbox" style={{cursor: 'default'}}>
                                  <input
                                    class="checkbox__input"
                                    type="checkbox"
                                    id="Equity-Onlycompensation"
                                    style={{cursor: 'pointer'}}
                                    value={"Equity Only"}
                                    checked={compensation.includes(
                                      "Equity Only"
                                    )}
                                    onChange={(e) =>
                                      handleCompensationboxChange(e)
                                    }
                                  />
                                  <svg
                                    class="checkbox__icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                  >
                                    <rect
                                      width="21"
                                      height="21"
                                      x=".5"
                                      y=".5"
                                      fill="#FFF"
                                      stroke="transparent"
                                      rx="3"
                                    ></rect>
                                    <path
                                      class="tick"
                                      stroke={`${formColor}`}
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                      d="M4 10l5 5 9-9"
                                    ></path>
                                  </svg>
                                  <span class="checkbox__label">
                                    Equity Only
                                  </span>
                                </label>
                              </div>
                              <div class="flex1 flex180">
                                <label for="Mix-Equity-Salary" class="checkbox" style={{cursor: 'default'}}>
                                  <input
                                    class="checkbox__input"
                                    type="checkbox"
                                    id="Mix-Equity-Salarycompensation"
                                    style={{cursor: 'pointer'}}
                                    value={"Mix of Equity & Salary"}
                                    checked={compensation.includes(
                                      "Mix of Equity & Salary"
                                    )}
                                    onChange={(e) =>
                                      handleCompensationboxChange(e)
                                    }
                                  />
                                  <svg
                                    class="checkbox__icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                  >
                                    <rect
                                      width="21"
                                      height="21"
                                      x=".5"
                                      y=".5"
                                      fill="#FFF"
                                      stroke="transparent"
                                      rx="3"
                                    ></rect>
                                    <path
                                      class="tick"
                                      stroke={`${formColor}`}
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                      d="M4 10l5 5 9-9"
                                    ></path>
                                  </svg>
                                  <span class="checkbox__label">
                                    Mix of Equity & Salary
                                  </span>
                                </label>
                              </div>
                              <div class="flex1 flex108"></div>
                              {compensationError != "" &&
                                compensation.length == 0 && (
                                  <div className="inline-flex items-baseline mt-2">
                                    <img className="self-center" src={Vector} />
                                    <h5 className="errorClass mt-1">
                                      {compensationError}
                                    </h5>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="w-full mb-4">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 mb-2"
                            for="password"
                          >
                            Any additional <strong>details</strong> you’d like
                            to share about compensation?
                          </label>
                          <p className="text-sm md:text-base text-gray-800 mb-4">
                          Our portfolio companies are at various growth stages and offer different compensation packages. If you have any specific requirements please let us know here:
                          </p>
                          <textarea
                            id="textarea"
                            placeholder={`I typically charge around £500 a day for consultancy work. For board / advisory commitments I’m open to an equity only package.`}
                            rows="3"
                            maxlength="800"
                            onChange={(e) => setDetails(e.target.value)}
                            style={{
                            // padding: '10px',
                            height: "97px",
                            border: "1px solid rgb(230, 233, 240)",
                            transition: "border-color 0.3s ease-in-out",
                            outline: "none",
                            boxShadow: `0 0 0 3px transparent`,
                          }}
                          onFocus={(e) => {
                            e.target.style.borderColor = `${formColor}`; // Change border color on focus
                            e.target.style.boxShadow = `${formColor}`; // Change focus ring color on focus
                          }}
                          onBlur={(e) => {
                            e.target.style.borderColor = "rgb(230, 233, 240)"; // Reset border color on blur
                            e.target.style.boxShadow = "0 0 0 3px transparent"; // Reset focus ring color on blur
                          }}
                          className="appearance-none block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 placeholder:text-gray-400 bg-white bg-clip-padding border border-gray-300 rounded-lg m-0 focus:outline-none focus:bg-white transition ease-in-out"
                          >
                            {TextDetails}
                          </textarea>
                        </div>
                        {/* <div className="w-full mb-4">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 mb-2"
                            for="password"
                          >
                            Please add a <strong>short bio:</strong>
                          </label>
                          <p className="text-sm md:text-base text-gray-800 mb-4">
                            You can use this section to emphasise the aspects of
                            your professional experience that would be most
                            relevant for the type of work you'd like to do with
                            our portfolio companies.
                          </p>
                          <textarea
                            id="textarea"
                            placeholder={`Having previously served as a CFO of a fintech start-up in London, I am currently advising early stage start-ups on finance, strategy, and fundraising.

I work with investors and companies to identify and maximise  sustainable business value. My approach is to work with teams to understand levers of change that will be most efficient in your organisation and then establish methods of effective implementation to achieve goals.`}
                            rows="11"
                            maxlength="800"
                            onChange={(e) => handleCharCount(e)}
                            style={{ height: "236px" }}
                            className="appearance-none block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 placeholder:text-gray-400 bg-white bg-clip-padding border border-gray-300 rounded-lg m-0 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition ease-in-out"
                          >
                            {textArea}
                          </textarea>
                          <p class="number-left">{CharCount} / 800</p>
                          <div className="mainCharDiv">
                            {CharCount >= 800 && (
                              <p className="char_text">
                                You have reached the character limit.
                              </p>
                            )}
                          </div>
                        </div> */}
                        <div className="w-full mb-4 mt-5">
                      <label
                          className="block text-base md:text-lg font-normal text-gray-1000 mb-2"
                          for="password"
                        >
                        Who <strong>referred you</strong> to our network?
                        </label>
                        <p className="text-sm md:text-base text-gray-800 mb-4">
                        Please add the email address of the person who shared this form with you. We’d like to let them know that you’ve completed it.
                        </p>
                        <input
                        type="text"
                        value={refferedBy}
                          onChange={(e) => {
                            setrefferedBy(e.target.value);
                          }}
                          style={{
                            // padding: '10px',
                            border:
                            refferedBy == email && refferedByError != "" ||
                            refferedBy == "" && refferedByError != "" ||
                            refferedBy != "" &&
                                !refferedBy.includes("@") &&
                                refferedByError != ""
                                ? "1px solid red"
                                : "1px solid rgb(230, 233, 240)",
                            transition: "border-color 0.3s ease-in-out",
                            outline: "none",
                            boxShadow: `0 0 0 3px transparent`,
                          }}
                          onFocus={(e) => {
                            e.target.style.borderColor = `${formColor}`; // Change border color on focus
                            e.target.style.boxShadow = `${formColor}`; // Change focus ring color on focus
                          }}
                          onBlur={(e) => {
                            e.target.style.borderColor = "rgb(230, 233, 240)"; // Reset border color on blur
                            e.target.style.boxShadow = "0 0 0 3px transparent"; // Reset focus ring color on blur
                          }}
                            className={
                              (refferedBy == email && refferedByError != "") ||
                              (refferedBy == "" && refferedByError != "") ||
                              (refferedBy != "" &&
                                !refferedBy.includes("@") &&
                                refferedByError != "")
                                ? "appearance-none w-full text-lg leading-6 bg-white font-normal text-gray-800 placeholder:text-gray-400 rounded-lg py-3 px-4 border border-red-500 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition duration-500"
                                : "appearance-none block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 placeholder:text-gray-400 bg-white bg-clip-padding border border-gray-300 rounded-lg m-0 focus:outline-none focus:bg-white transition ease-in-out"
                            }
                        id="refferedBy"
                        placeholder="person@company.com"
                      />
                       {refferedBy == email && refferedByError != "" || refferedBy == "" && refferedByError != "" || refferedBy != "" &&  !refferedBy.includes("@") && refferedByError != "" ? (
                          <div className="inline-flex items-baseline mt-2">
                            <img className="self-center" src={Vector} />
                            <h5 className="errorClass">{refferedByError}</h5>
                          </div>
                        ) : null}
                      </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-check form-check-inline mr-32">
                      <label for="termAndCondition" className="checkbox">
                        <input
                          className="checkbox__input"
                          type="checkbox"
                          id="termAndCondition"
                          checked={terms}
                          onChange={(e) => handleTerm(e)}
                        />
                        <svg
                          className="checkbox__icon"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 22 22"
                        >
                          <rect
                            width="21"
                            height="21"
                            x=".5"
                            y=".5"
                            fill="#FFF"
                            stroke="transparent"
                            rx="3"
                          ></rect>
                          <path
                            className="tick"
                            stroke={`${formColor}`}
                            fill="none"
                            stroke-linecap="round"
                            stroke-width="1"
                            d="M4 10l5 5 9-9"
                          ></path>
                        </svg>
                        <span className="checkbox__label">
                          Terms and conditions*
                        </span>
                      </label>
                    </div>
                    <p style={{ paddingBottom: "10px" }}>
                      I hereby grant{" "}
                      {formVCname}{" "}
                      
                      consent to collect data for my candidate profile and use
                      it for this purpose only. For further information on how
                      we collect, manage and store your personal data please
                      read our{" "}
                      <a
                        className="text-deep-purple-500"
                        href={"javascript:void(0)"}
                        onClick={handlePrivacyPolicy}
                        style={{color: `${formColor}`}}
                        target="_blank"
                      >
                        Privacy Policy
                      </a>
                    </p>
                    {TNCError != "" && terms == false && (
                      <div className="inline-flex items-baseline mt-2">
                        <img className="self-center" src={Vector} />
                        <h5 className="errorClass">{TNCError}</h5>
                      </div>
                    )}
                    <div class="d-sm-flex justify-content-center btn-gap text-center ">
                      <div>
                        <button
                          className="btn back mt-5 mb-4"
                          type="submit"
                          style={{color: `${formColor}`}}
                          disabled={FormLoading == true ? true : false}
                          onClick={(e) => handleClickPrev(e)}
                        >
                          Back
                        </button>
                      </div>
                      <div>
                        <button
                          className="btn submit mt-5 mb-4"
                          type="submit"
                          style={{backgroundColor: `${formColor}`}}
                          onClick={(e) => handleFormSubmit(e)}
                        >
                        {FormLoading == true ? (
                          <div className="flex">
                          <Image27 className="spinner mr-2 mt-1"/> Submitting
                          {/* <i class="fa fa-spinner spinner mr-2" aria-hidden="true" /> */}
                          </div>
                          ) : (
                            <>Submit</>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {screen == 4 && (
              <>
                {/* Thank you Start */}
                <h2 className="text-2xl md:text-3xl font-bold mb-2 mt-5">
                  Nicely Done 🎉
                </h2>
                <hr className="my-4 -mx-5" />
                <p className="text-base md:text-lg mb-4">
                  Thank you for submitting the form!
                </p>
                <p className="text-base md:text-lg mb-4">
                Your profile has been added to our network of trusted Advisors. We will reach out to you directly with suitable opportunities based on your experience and preferences
                </p>
                <p className="text-base md:text-lg mb-4">Good luck!</p>
                {OrgName == "OctopusVentures" ? (
                  <img
                    className="w-60 h-60 rounded-full mx-auto"
                    src="https://i.giphy.com/media/WwMHQqMG0yirepFPP8/giphy.webp"
                    alt="Octopus Ventures"
                    width="48"
                    height="48"
                  />
                ) : (
                  <img
                    className="w-full"
                    src="https://i.giphy.com/media/l41YmQjOz9qg2Ecow/giphy.webp"
                    alt="Octopus Ventures"
                    width="48"
                    height="48"
                  />
                )}

                {/* Thank End */}
              </>
            )}
            {screen == 1 && (
              <div className="col-md-12 mb-5 text-center">
                <button
                  className="btn submit"
                  type="submit"
                  style={{backgroundColor: `${formColor}`}}
                  onClick={(e) => handleClickNextscreen1(e)}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </div>
        {screen != 4 && (
          <div className={"FooterImage"} style={{backgroundColor: `${formColor}`}}>
            <h1 style={{ minHeight: "30px" }}></h1>
          </div>
        )}
      </div>
      </>
    )}
    </>
  );
};

const mapStateToProps = ({ authentication }) => ({
  authentication_state: authentication,
});

export default connect(mapStateToProps)(AdvisorForms);
