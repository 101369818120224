import React, { useRef, useEffect } from "react";
import Arrow from "../NewCAndidatePage/images/arr.svg";
import dateFormat from "dateformat";
import "./index.css";

const UiComp = ({ candata, handledetailsModelClose }) => {
  const DateDecending = candata?.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );

  // function groupAndSortExperts(candata) {
  //   const groupedExperts = candata.reduce((acc, expert) => {
  //     const location = expert?.about?.currentLocation?.formatted_address;
  //     if (!acc[location]) {
  //       acc[location] = {
  //         Product: [],
  //         Finance: [],
  //         BizOpsStrategy: [],
  //         Commercial: [],
  //         Legal: [],
  //         RiskCompliance: [],
  //         GrowthMarketing: [],
  //         PeopleTalent: [],
  //         CustomerSuccess: [],
  //         SoftwareEngineering: [],
  //         DataScienceMachineLearning: [],
  //         BIDataAnalytics: [],
  //         ProcurementSupplyChain: [],
  //         Administrative: [],
  //       };
  //     }

  //     if (expert.about.functionalexpertise === "Product") {
  //       acc[location].Product.push(expert);
  //     } else if (expert.about.functionalexpertise === "Finance") {
  //       acc[location].Finance.push(expert);
  //     } else if (expert.about.functionalexpertise === "Biz Ops & Strategy") {
  //       acc[location].BizOpsStrategy.push(expert);
  //     } else if (expert.about.functionalexpertise === "Commercial") {
  //       acc[location].Commercial.push(expert);
  //     } else if (expert.about.functionalexpertise === "Legal") {
  //       acc[location].Legal.push(expert);
  //     } else if (expert.about.functionalexpertise === "Risk & Compliance") {
  //       acc[location].RiskCompliance.push(expert);
  //     } else if (expert.about.functionalexpertise === "Growth & Marketing") {
  //       acc[location].GrowthMarketing.push(expert);
  //     } else if (expert.about.functionalexpertise === "People & Talent") {
  //       acc[location].PeopleTalent.push(expert);
  //     } else if (expert.about.functionalexpertise === "Customer Success") {
  //       acc[location].CustomerSuccess.push(expert);
  //     } else if (expert.about.functionalexpertise === "Software Engineering") {
  //       acc[location].SoftwareEngineering.push(expert);
  //     } else if (
  //       expert.about.functionalexpertise === "Data Science / Machine Learning"
  //     ) {
  //       acc[location].DataScienceMachineLearning.push(expert);
  //     } else if (expert.about.functionalexpertise === "BI / Data Analytics") {
  //       acc[location].BIDataAnalytics.push(expert);
  //     } else if (
  //       expert.about.functionalexpertise === "Procurement & Supply Chain"
  //     ) {
  //       acc[location].ProcurementSupplyChain.push(expert);
  //     } else if (expert.about.functionalexpertise === "Administrative") {
  //       acc[location].Administrative.push(expert);
  //     }

  //     return acc;
  //   }, {});

  //   for (const location in groupedExperts) {
  //     const {
  //       Product,
  //       Finance,
  //       BizOpsStrategy,
  //       Commercial,
  //       Legal,
  //       RiskCompliance,
  //       GrowthMarketing,
  //       PeopleTalent,
  //       CustomerSuccess,
  //       SoftwareEngineering,
  //       DataScienceMachineLearning,
  //       BIDataAnalytics,
  //       ProcurementSupplyChain,
  //       Administrative,
  //     } = groupedExperts[location];
  //     Product.sort((a, b) =>
  //       a.about.functionalexpertise.localeCompare(b.about.functionalexpertise)
  //     );
  //     Finance.sort((a, b) =>
  //       a.about.functionalexpertise.localeCompare(b.about.functionalexpertise)
  //     );
  //     BizOpsStrategy.sort((a, b) =>
  //       a.about.functionalexpertise.localeCompare(b.about.functionalexpertise)
  //     );
  //     Commercial.sort((a, b) =>
  //       a.about.functionalexpertise.localeCompare(b.about.functionalexpertise)
  //     );
  //     Legal.sort((a, b) =>
  //       a.about.functionalexpertise.localeCompare(b.about.functionalexpertise)
  //     );
  //     RiskCompliance.sort((a, b) =>
  //       a.about.functionalexpertise.localeCompare(b.about.functionalexpertise)
  //     );
  //     SoftwareEngineering.sort((a, b) =>
  //       a.about.functionalexpertise.localeCompare(b.about.functionalexpertise)
  //     );
  //     GrowthMarketing.sort((a, b) =>
  //       a.about.functionalexpertise.localeCompare(b.about.functionalexpertise)
  //     );
  //     PeopleTalent.sort((a, b) =>
  //       a.about.functionalexpertise.localeCompare(b.about.functionalexpertise)
  //     );
  //     CustomerSuccess.sort((a, b) =>
  //       a.about.functionalexpertise.localeCompare(b.about.functionalexpertise)
  //     );
  //     DataScienceMachineLearning.sort((a, b) =>
  //       a.about.functionalexpertise.localeCompare(b.about.functionalexpertise)
  //     );
  //     BIDataAnalytics.sort((a, b) =>
  //       a.about.functionalexpertise.localeCompare(b.about.functionalexpertise)
  //     );
  //     ProcurementSupplyChain.sort((a, b) =>
  //       a.about.functionalexpertise.localeCompare(b.about.functionalexpertise)
  //     );
  //     Administrative.sort((a, b) =>
  //       a.about.functionalexpertise.localeCompare(b.about.functionalexpertise)
  //     );
  //   }

  //   return groupedExperts;
  // }

  function groupAndSortExperts(candata) {
    // Create a mapping for clean expertise names
    const expertiseMapping = {
      "Biz Ops & Strategy": "BizOpsStrategy",
      "Risk & Compliance": "RiskCompliance",
      "Growth & Marketing": "GrowthMarketing",
      "Data Science / Machine Learning": "DataScienceMachineLearning",
      "BI / Data Analytics": "BIDataAnalytics",
      "Procurement & Supply Chain": "ProcurementSupplyChain",
      "People & Talent": "PeopleTalent",
      "Software Engineering": "SoftwareEngineering",
      "Customer Success": "CustomerSuccess",
    };

    // Grouping experts by location
    const groupedExperts = candata.reduce((acc, expert) => {
      const locationComponents =
        expert?.about?.currentLocation?.address_components;

      if (locationComponents) {
        const countryOrContinentComponent = locationComponents.find(
          (component) =>
            component.types.includes("country") ||
            component.types.includes("continent")
        );

        if (countryOrContinentComponent) {
          const groupKey = countryOrContinentComponent.long_name;

          if (!acc[groupKey]) {
            acc[groupKey] = {
              Product: [],
              Finance: [],
              BizOpsStrategy: [],
              Commercial: [],
              Legal: [],
              RiskCompliance: [],
              GrowthMarketing: [],
              PeopleTalent: [],
              CustomerSuccess: [],
              SoftwareEngineering: [],
              DataScienceMachineLearning: [],
              BIDataAnalytics: [],
              ProcurementSupplyChain: [],
              Administrative: [],
            };
          }

          const expertiseCategory =
            expertiseMapping[expert.about.functionalexpertise] ||
            expert.about.functionalexpertise;
          acc[groupKey][expertiseCategory]?.push(expert);
        }
      }
      return acc;
    }, {});

    // Sorting experts by expertise within each group
    for (const groupKey in groupedExperts) {
      const expertiseCategories = Object.keys(groupedExperts[groupKey]);
      expertiseCategories.forEach((category) => {
        groupedExperts[groupKey][category].sort((a, b) =>
          a.about.functionalexpertise.localeCompare(b.about.functionalexpertise)
        );
      });
    }

    // Sort group keys (location names) alphabetically
    const sortedGroupedExperts = Object.keys(groupedExperts)
      .sort((a, b) => a.localeCompare(b))
      .reduce((sortedAcc, key) => {
        sortedAcc[key] = groupedExperts[key];
        return sortedAcc;
      }, {});
    return sortedGroupedExperts;
  }

  // function groupAndSortExperts(candata) {
  //   // Grouping experts by location
  //   const groupedExperts = candata.reduce((acc, expert) => {
  //     console.log(expert, "expert?.about?.currentLocation")
  //     const locationComponents = expert?.about?.currentLocation?.address_components;

  //     if (locationComponents) {
  //       const countryOrContinentComponent = locationComponents.find(component =>
  //         component.types.includes("country") || component.types.includes("continent")
  //       );

  //       if (countryOrContinentComponent) {
  //         const groupKey = countryOrContinentComponent.long_name;

  //         if (!acc[groupKey]) {
  //           acc[groupKey] = {
  //             Product: [],
  //             Finance: [],
  //             BizOpsStrategy: [],
  //             Commercial: [],
  //             Legal: [],
  //             RiskCompliance: [],
  //             GrowthMarketing: [],
  //             PeopleTalent: [],
  //             CustomerSuccess: [],
  //             SoftwareEngineering: [],
  //             DataScienceMachineLearning: [],
  //             BIDataAnalytics: [],
  //             ProcurementSupplyChain: [],
  //             Administrative: [],
  //           };
  //         }

  //         const expertiseCategory = expert.about.functionalexpertise;
  //         acc[groupKey][expertiseCategory]?.push(expert);
  //       }
  //     }

  //     return acc;
  //   }, {});

  //   // Sorting experts by expertise within each group
  //   for (const groupKey in groupedExperts) {
  //     const expertiseCategories = Object.keys(groupedExperts[groupKey]);
  //     expertiseCategories.forEach(category => {
  //       groupedExperts[groupKey][category].sort((a, b) =>
  //         a.about.functionalexpertise.localeCompare(b.about.functionalexpertise)
  //       );
  //     });
  //   }

  //   return groupedExperts;
  // }

  const groupedAndSortedExperts = groupAndSortExperts(candata);

  const NameingHref = (item) => {
    const FirstName = item.firstname.trim();
    const LastName = item.lastname.trim();
    var new_url = `/candidate/${item.id}-${FirstName?.charAt(
      0
    )?.toLowerCase()}${FirstName?.slice(1)
      ?.toLowerCase()
      .replaceAll(" ", "-")}-${LastName?.charAt(
      0
    )?.toLowerCase()}${LastName?.slice(1)?.toLowerCase().replaceAll(" ", "-")}`;
    return new_url;
  };

  const handleStages = (expert) => {
    const stages = expert?.careerplan?.stage;
    const stageLabels = [];

    if (
      stages.includes("Pre-seed") ||
      stages.includes("Seed") ||
      stages.includes("Series A")
    ) {
      stageLabels.push("Early Stage");
    }
    if (stages.includes("Series B") || stages.includes("Series C")) {
      stageLabels.push("Growth Stage");
    }
    if (stages.includes("Series D") || stages.includes("Series E")) {
      stageLabels.push("Late Stage");
    }

    // Determine the "Open to" value based on the candidate's stages
    const candidateStage =
      stageLabels.length > 0
        ? stageLabels.join(", ")
        : "No Specific Stage Preference";
    return candidateStage;
  };

  // const handleCopy = (event) => {
  //   const selectedText = window.getSelection().toString();
  //   event.clipboardData.setData('text/plain', formatText(selectedText));
  //   event.preventDefault();
  // };

  // const formatText = (text) => {
  //   console.log(text)
  // };

  return (
    <div className="wrapper">
      <div
        id="mid-container"
        style={{
          padding: "20px",
          boxSizing: "border-box",
          background: "#F8FAFF",
          minHeight: "100vh",
          height: "auto",
        }}
      >
        <button className="arrow" onClick={handledetailsModelClose}>
          <img src={Arrow} />
          Manage Candidates
        </button>
        {Object.entries(groupedAndSortedExperts).map(([location, experts]) => (
          <>
            <div className="upperLineHr">
              <hr className="mx-auto upperLine" />
              {/* onCopy={handleCopy} */}
              <section className="mx-auto box-status" key={location}>
                <h4 className="fontSize block">{location.toUpperCase()}</h4>
                <br />
                {experts.CustomerSuccess.length > 0 && (
                  <>
                    <h4 className="block fontSizezzz">Customer Success:</h4>
                    <br />
                    {experts.CustomerSuccess.map((expert) => (
                      <div className="flex flex-col">
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            <a
                              style={{
                                textDecoration: "underline",
                                color: "blue",
                              }}
                              href={`${NameingHref(expert)}`}
                            >
                              {expert.firstname} {expert.lastname}
                            </a>
                            {" - "}
                            {expert.email}
                          </label>
                        </div>
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            {expert?.careerplan?.work} at{" "}
                            {expert?.careerplan?.employer}
                          </label>
                        </div>
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            Looking for:{" "}
                            {expert?.careerplan?.careerlevel?.replace(
                              /,/g,
                              ", "
                            )}{" "}
                            roles
                          </label>
                        </div>
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            Current Location:{" "}
                            {expert?.about?.currentLocation?.formatted_address}
                          </label>
                        </div>
                        {/* <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            Target Location:{" "}
                            {expert?.careerplan.workplace.replaceAll(",", ", ")}
                          </label>
                        </div> */}
                        <br />
                      </div>
                    ))}
                    <br />
                  </>
                )}
                {experts?.GrowthMarketing?.length > 0 && (
                  <>
                    <h4 className="block fontSizezzz">
                      Growth & Marketing:
                    </h4>
                    <br />
                    {experts.GrowthMarketing.map((expert) => (
                      <div className="flex flex-col">
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            <a
                              style={{
                                textDecoration: "underline",
                                color: "blue",
                              }}
                              href={`${NameingHref(expert)}`}
                            >
                              {expert.firstname} {expert.lastname}
                            </a>
                            {" - "}
                            {expert.email}
                          </label>
                        </div>
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            {expert?.careerplan?.work} at{" "}
                            {expert?.careerplan?.employer}
                          </label>
                        </div>
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            Looking for:{" "}
                            {expert?.careerplan?.careerlevel?.replace(
                              /,/g,
                              ", "
                            )}{" "}
                            roles
                          </label>
                        </div>
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            Current Location:{" "}
                            {expert?.about?.currentLocation?.formatted_address}
                          </label>
                        </div>
                        {/* <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            Target Location:{" "}
                            {expert?.careerplan.workplace.replaceAll(",", ", ")}
                          </label>
                        </div> */}
                        <br />
                      </div>
                    ))}
                    <br />
                  </>
                )}
                {experts.BizOpsStrategy.length > 0 && (
                  <>
                    <h4 className="block fontSizezzz">Biz Ops & Strategy:</h4>
                    <br />
                    {experts.BizOpsStrategy.map((expert) => (
                      <div className="flex flex-col">
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            <a
                              style={{
                                textDecoration: "underline",
                                color: "blue",
                              }}
                              href={`${NameingHref(expert)}`}
                            >
                              {expert.firstname} {expert.lastname}
                            </a>
                            {" - "}
                            {expert.email}
                          </label>
                        </div>
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            {expert?.careerplan?.work} at{" "}
                            {expert?.careerplan?.employer}
                          </label>
                        </div>
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            Looking for:{" "}
                            {expert?.careerplan?.careerlevel?.replace(
                              /,/g,
                              ", "
                            )}{" "}
                            roles
                          </label>
                        </div>
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            Current Location:{" "}
                            {expert?.about?.currentLocation?.formatted_address}
                          </label>
                        </div>
                        {/* <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            Target Location:{" "}
                            {expert?.careerplan.workplace.replaceAll(",", ", ")}
                          </label>
                        </div> */}
                        <br />
                      </div>
                    ))}
                    <br />
                  </>
                )}
                {experts.Product.length > 0 && (
                  <>
                    <h4 className="block fontSizezzz">Product:</h4>
                    <br />
                    {experts.Product.map((expert) => (
                      <div className="flex flex-col">
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            <a
                              style={{
                                textDecoration: "underline",
                                color: "blue",
                              }}
                              href={`${NameingHref(expert)}`}
                            >
                              {expert.firstname} {expert.lastname}
                            </a>
                            {" - "}
                            {expert.email}
                          </label>
                        </div>
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            {expert?.careerplan?.work} at{" "}
                            {expert?.careerplan?.employer}
                          </label>
                        </div>
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            Looking for:{" "}
                            {expert?.careerplan?.careerlevel?.replace(
                              /,/g,
                              ", "
                            )}{" "}
                            roles
                          </label>
                        </div>
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            Current Location:{" "}
                            {expert?.about?.currentLocation?.formatted_address}
                          </label>
                        </div>
                        {/* <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            Target Location:{" "}
                            {expert?.careerplan.workplace.replaceAll(",", ", ")}
                          </label>
                        </div> */}
                        <br />
                      </div>
                    ))}
                    <br />
                  </>
                )}
                {experts.Commercial.length > 0 && (
                  <>
                    <h4 className="block fontSizezzz">Commercial:</h4>
                    <br />
                    {experts.Commercial.map((expert) => (
                      <div className="flex flex-col">
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            <a
                              style={{
                                textDecoration: "underline",
                                color: "blue",
                              }}
                              href={`${NameingHref(expert)}`}
                            >
                              {expert.firstname} {expert.lastname}
                            </a>
                            {" - "}
                            {expert.email}
                          </label>
                        </div>
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            {expert?.careerplan?.work} at{" "}
                            {expert?.careerplan?.employer}
                          </label>
                        </div>
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            Looking for:{" "}
                            {expert?.careerplan?.careerlevel?.replace(
                              /,/g,
                              ", "
                            )}{" "}
                            roles
                          </label>
                        </div>
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            Current Location:{" "}
                            {expert?.about?.currentLocation?.formatted_address}
                          </label>
                        </div>
                        {/* <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            Target Location:{" "}
                            {expert?.careerplan.workplace.replaceAll(",", ", ")}
                          </label>
                        </div> */}
                        <br />
                      </div>
                    ))}
                    <br />
                  </>
                )}
                {experts.PeopleTalent.length > 0 && (
                  <>
                    <h4 className="block fontSizezzz">People & Talent:</h4>
                    <br />
                    {experts.PeopleTalent.map((expert) => (
                      <div className="flex flex-col">
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            <a
                              style={{
                                textDecoration: "underline",
                                color: "blue",
                              }}
                              href={`${NameingHref(expert)}`}
                            >
                              {expert.firstname} {expert.lastname}
                            </a>
                            {" - "}
                            {expert.email}
                          </label>
                        </div>
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            {expert?.careerplan?.work} at{" "}
                            {expert?.careerplan?.employer}
                          </label>
                        </div>
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            Looking for:{" "}
                            {expert?.careerplan?.careerlevel?.replace(
                              /,/g,
                              ", "
                            )}{" "}
                            roles
                          </label>
                        </div>
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            Current Location:{" "}
                            {expert?.about?.currentLocation?.formatted_address}
                          </label>
                        </div>
                        {/* <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            Target Location:{" "}
                            {expert?.careerplan.workplace.replaceAll(",", ", ")}
                          </label>
                        </div> */}
                        <br />
                      </div>
                    ))}
                    <br />
                  </>
                )}
                {experts.Finance.length > 0 && (
                  <>
                    <h4 className="block fontSizezzz">Finance:</h4>
                    <br />
                    {experts.Finance.map((expert) => (
                      <div className="flex flex-col">
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            <a
                              style={{
                                textDecoration: "underline",
                                color: "blue",
                              }}
                              href={`${NameingHref(expert)}`}
                            >
                              {expert.firstname} {expert.lastname}
                            </a>
                            {" - "}
                            {expert.email}
                          </label>
                        </div>
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            {expert?.careerplan?.work} at{" "}
                            {expert?.careerplan?.employer}
                          </label>
                        </div>
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            Looking for:{" "}
                            {expert?.careerplan?.careerlevel?.replace(
                              /,/g,
                              ", "
                            )}{" "}
                            roles
                          </label>
                        </div>
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            Current Location:{" "}
                            {expert?.about?.currentLocation?.formatted_address}
                          </label>
                        </div>
                        {/* <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            Target Location:{" "}
                            {expert?.careerplan.workplace.replaceAll(",", ", ")}
                          </label>
                        </div> */}
                        <br />
                      </div>
                    ))}
                    <br />
                  </>
                )}
                {experts.Legal.length > 0 && (
                  <>
                    <h4 className="block fontSizezzz">Legal:</h4>
                    <br />
                    {experts.Legal.map((expert) => (
                      <div className="flex flex-col">
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            <a
                              style={{
                                textDecoration: "underline",
                                color: "blue",
                              }}
                              href={`${NameingHref(expert)}`}
                            >
                              {expert.firstname} {expert.lastname}
                            </a>
                            {" - "}
                            {expert.email}
                          </label>
                        </div>
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            {expert?.careerplan?.work} at{" "}
                            {expert?.careerplan?.employer}
                          </label>
                        </div>
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            Looking for:{" "}
                            {expert?.careerplan?.careerlevel?.replace(
                              /,/g,
                              ", "
                            )}{" "}
                            roles
                          </label>
                        </div>
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            Current Location:{" "}
                            {expert?.about?.currentLocation?.formatted_address}
                          </label>
                        </div>
                        {/* <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            Target Location:{" "}
                            {expert?.careerplan.workplace.replaceAll(",", ", ")}
                          </label>
                        </div> */}
                        <br />
                      </div>
                    ))}
                    <br />
                  </>
                )}
                {experts.RiskCompliance.length > 0 && (
                  <>
                    <h4 className="block fontSizezzz">Risk & Compliance:</h4>
                    <br />
                    {experts.RiskCompliance.map((expert) => (
                      <div className="flex flex-col">
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            <a
                              style={{
                                textDecoration: "underline",
                                color: "blue",
                              }}
                              href={`${NameingHref(expert)}`}
                            >
                              {expert.firstname} {expert.lastname}
                            </a>
                            {" - "}
                            {expert.email}
                          </label>
                        </div>
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            {expert?.careerplan?.work} at{" "}
                            {expert?.careerplan?.employer}
                          </label>
                        </div>
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            Looking for:{" "}
                            {expert?.careerplan?.careerlevel?.replace(
                              /,/g,
                              ", "
                            )}{" "}
                            roles
                          </label>
                        </div>
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            Current Location:{" "}
                            {expert?.about?.currentLocation?.formatted_address}
                          </label>
                        </div>
                        {/* <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            Target Location:{" "}
                            {expert?.careerplan.workplace.replaceAll(",", ", ")}
                          </label>
                        </div> */}
                        <br />
                      </div>
                    ))}
                    <br />
                  </>
                )}
                {experts.SoftwareEngineering.length > 0 && (
                  <>
                    <h4 className="block fontSizezzz">Software Engineering:</h4>
                    <br />
                    {experts.SoftwareEngineering.map((expert) => (
                      <div className="flex flex-col">
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            <a
                              style={{
                                textDecoration: "underline",
                                color: "blue",
                              }}
                              href={`${NameingHref(expert)}`}
                            >
                              {expert.firstname} {expert.lastname}
                            </a>
                            {" - "}
                            {expert.email}
                          </label>
                        </div>
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            {expert?.careerplan?.work} at{" "}
                            {expert?.careerplan?.employer}
                          </label>
                        </div>
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            Looking for:{" "}
                            {expert?.careerplan?.careerlevel?.replace(
                              /,/g,
                              ", "
                            )}{" "}
                            roles
                          </label>
                        </div>
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            Current Location:{" "}
                            {expert?.about?.currentLocation?.formatted_address}
                          </label>
                        </div>
                        {/* <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            Target Location:{" "}
                            {expert?.careerplan.workplace.replaceAll(",", ", ")}
                          </label>
                        </div> */}
                        <br />
                      </div>
                    ))}
                    <br />
                  </>
                )}
                {experts.DataScienceMachineLearning.length > 0 && (
                  <>
                    <h4 className="block fontSizezzz">
                      Data Science / Machine Learning:
                    </h4>
                    <br />
                    {experts.DataScienceMachineLearning.map((expert) => (
                      <div className="flex flex-col">
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            <a
                              style={{
                                textDecoration: "underline",
                                color: "blue",
                              }}
                              href={`${NameingHref(expert)}`}
                            >
                              {expert.firstname} {expert.lastname}
                            </a>
                            {" - "}
                            {expert.email}
                          </label>
                        </div>
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            {expert?.careerplan?.work} at{" "}
                            {expert?.careerplan?.employer}
                          </label>
                        </div>
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            Looking for:{" "}
                            {expert?.careerplan?.careerlevel?.replace(
                              /,/g,
                              ", "
                            )}{" "}
                            roles
                          </label>
                        </div>
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            Current Location:{" "}
                            {expert?.about?.currentLocation?.formatted_address}
                          </label>
                        </div>
                        {/* <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            Target Location:{" "}
                            {expert?.careerplan.workplace.replaceAll(",", ", ")}
                          </label>
                        </div> */}
                        <br />
                      </div>
                    ))}
                    <br />
                  </>
                )}
                {experts.BIDataAnalytics.length > 0 && (
                  <>
                    <h4 className="block fontSizezzz">BI / Data Analytics:</h4>
                    <br />
                    {experts.BIDataAnalytics.map((expert) => (
                      <div className="flex flex-col">
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            <a
                              style={{
                                textDecoration: "underline",
                                color: "blue",
                              }}
                              href={`${NameingHref(expert)}`}
                            >
                              {expert.firstname} {expert.lastname}
                            </a>
                            {" - "}
                            {expert.email}
                          </label>
                        </div>
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            {expert?.careerplan?.work} at{" "}
                            {expert?.careerplan?.employer}
                          </label>
                        </div>
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            Looking for:{" "}
                            {expert?.careerplan?.careerlevel?.replace(
                              /,/g,
                              ", "
                            )}{" "}
                            roles
                          </label>
                        </div>
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            Current Location:{" "}
                            {expert?.about?.currentLocation?.formatted_address}
                          </label>
                        </div>
                        {/* <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            Target Location:{" "}
                            {expert?.careerplan.workplace.replaceAll(",", ", ")}
                          </label>
                        </div> */}
                        <br />
                      </div>
                    ))}
                    <br />
                  </>
                )}
                {experts.ProcurementSupplyChain.length > 0 && (
                  <>
                    <h4 className="block fontSizezzz">
                      Procurement & Supply Chain:
                    </h4>
                    <br />
                    {experts.ProcurementSupplyChain.map((expert) => (
                      <div className="flex flex-col">
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            <a
                              style={{
                                textDecoration: "underline",
                                color: "blue",
                              }}
                              href={`${NameingHref(expert)}`}
                            >
                              {expert.firstname} {expert.lastname}
                            </a>
                            {" - "}
                            {expert.email}
                          </label>
                        </div>
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            {expert?.careerplan?.work} at{" "}
                            {expert?.careerplan?.employer}
                          </label>
                        </div>
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            Looking for:{" "}
                            {expert?.careerplan?.careerlevel?.replace(
                              /,/g,
                              ", "
                            )}{" "}
                            roles
                          </label>
                        </div>
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            Current Location:{" "}
                            {expert?.about?.currentLocation?.formatted_address}
                          </label>
                        </div>
                        {/* <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            Target Location:{" "}
                            {expert?.careerplan.workplace.replaceAll(",", ", ")}
                          </label>
                        </div> */}
                        <br />
                      </div>
                    ))}
                    <br />
                  </>
                )}
                {experts.Administrative.length > 0 && (
                  <>
                    <h4 className="block fontSizezzz">Administrative:</h4>
                    <br />
                    {experts.Administrative.map((expert) => (
                      <div className="flex flex-col">
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            <a
                              style={{
                                textDecoration: "underline",
                                color: "blue",
                              }}
                              href={`${NameingHref(expert)}`}
                            >
                              {expert.firstname} {expert.lastname}
                            </a>
                            {" - "}
                            {expert.email}
                          </label>
                        </div>
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            {expert?.careerplan?.work} at{" "}
                            {expert?.careerplan?.employer}
                          </label>
                        </div>
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            Looking for:{" "}
                            {expert?.careerplan?.careerlevel?.replace(
                              /,/g,
                              ", "
                            )}{" "}
                            roles
                          </label>
                        </div>
                        <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            Current Location:{" "}
                            {expert?.about?.currentLocation?.formatted_address}
                          </label>
                        </div>
                        {/* <div className="w-full">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 "
                            htmlFor="email"
                          >
                            Target Location:{" "}
                            {expert?.careerplan.workplace.replaceAll(",", ", ")}
                          </label>
                        </div> */}
                        <br />
                      </div>
                    ))}
                    <br />
                  </>
                )}
              </section>
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

export default UiComp;
