import React, { useRef, useState, useEffect } from "react";
import "./index.css";
import axios from "axios";
import Image1 from "./images/modal-list-img.png"

const LoaderPopUp = () => {
  return (
    <>
        <div class="modal-main">
        <div class="modal-header">
        <a href="#">Close</a>
        <h2>Add to List</h2>
        </div>
        <div class="modal-list-body" style={{backgroundColor: '#ffff'}}>
        <div class="modal-list-name" style={{backgroundColor: '#ffff'}}>
        <figure><img src=" " alt="" /></figure>
        <div class="select-list-loader-row">
                <div></div>
                <div></div>
                <div></div>
              </div>
        </div>
        <div class="modal-list-data">
        <div class="select-list">
            <div class="modal-search">
              <input type="search" value="" placeholder="Search" />
            </div>
            <p>Select a list to add them to</p>
            <button style={{display: 'flex'}}>
            <svg className="mr-2" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.5 0C3.80545 0 0 3.80545 0 8.5C0 13.1946 3.80545 17 8.5 17C13.1946 17 17 13.1946 17 8.5C17 3.80545 13.1946 0 8.5 0ZM11.05 9.35H9.35V11.05C9.35 11.5192 8.9692 11.9 8.5 11.9C8.0308 11.9 7.65 11.5192 7.65 11.05V9.35H5.95C5.4808 9.35 5.1 8.9692 5.1 8.5C5.1 8.0308 5.4808 7.65 5.95 7.65H7.65V5.95C7.65 5.4808 8.0308 5.1 8.5 5.1C8.9692 5.1 9.35 5.4808 9.35 5.95V7.65H11.05C11.5192 7.65 11.9 8.0308 11.9 8.5C11.9 8.9692 11.5192 9.35 11.05 9.35Z" fill="#434343"/>
</svg>
            New List</button>
          </div>
          <div class="select-list-data-cont">
          <div class="select-list-loader">
              <div class="select-list-loader-row">
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div class="select-list-loader-row">
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div class="select-list-loader-row">
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div class="select-list-loader-row">
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div class="select-list-loader-row">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        <div class="right-align"><button class="modal-close">Close</button></div>
        </div>
        </div>
    </>
  );
};

export default LoaderPopUp;
