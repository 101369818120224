import {useGetFullProfile} from "../../../../shared/httpService/apis/enrichedApi";
import "./timeline.css";
import ExitSVG from "../../images/exit.svg";
import AttentionSVG from "../../images/icon_attention.svg";
import TeamSVG from "../../images/team.svg";
import TrendSVG from "../../images/trend.svg";
import CategoriesSVG from "../../images/categories.svg";
import UsdSVG from "../../images/usd-circle.svg";
import JoinSVG from "../../images/join.svg";
import LoadingOverlay from "react-loading-overlay";
import React from "react";
import FadeLoader from 'react-spinners/FadeLoader'
import moment from "moment";

const marginTopVal = "20px";

export function WorkHistory({mongoId}) {
    const {isLoading, data, isError} = useGetFullProfile(mongoId);

    if (isLoading) {
        return <div className={"animate-pulse"}><LoadingOverlay
            active={true}
            fadeSpeed={1000}
            spinner={<FadeLoader color="#6A35FF" />}
            styles={{overlay: base => ({...base, background: "rgba(0,0,0,0)"})}}
        >
            <div style={{height: "300px", marginTop: marginTopVal}}></div>
        </LoadingOverlay></div>;
    }

    if (isError) {
        return <div></div>;
    }

    let employments = [...data.employments].reverse();
    // backend controls this now
    // employments = employments.slice(0, 5);

    if (employments.length === 0) {
        return <div></div>;
    }

    return (
        <>
            <div className="border-t border-gray-300" style={{marginTop: marginTopVal, paddingTop: "20px"}}>
                {employments.map((elem, index) => <WorkHistoryElem key={index} data={elem} isFirst={index === 0}
                                                                   isLast={index === employments.length - 1}/>)}
            </div>
            <div className="border-t border-gray-300" style={{marginTop: marginTopVal, paddingTop: "20px", display: "flex", justifyContent: "space-between", marginBottom: "-10px"}}>
                {/* <div style={{display: "flex", alignItems: "center"}}>
                    <img
                        src={AttentionSVG}
                        style={{width: "18px", marginRight: "6px"}}
                        alt="Left"
                    /> 
                    Only showing the most recent roles
                </div> */}
                <div className="ml-auto" style={{color: "#A0A0A0", fontStyle: "italic", fontSize: "13px"}}>
                    Company data powered by <a style={{fontWeight: 700, textDecoration: "underline"}} href="https://www.crunchbase.com">Crunchbase</a>
                </div>
            </div>
        </>
    )
}

function WorkHistoryElem({data, isFirst, isLast}) {
    const positions = [...data.positions].reverse();

    const numItems = data.company.fundingRounds.length + (data.company.dateJoined ? 1 : 0) + (data.company.dateLeft ? 1 : 0);
    const isBelowMode = numItems > 5;

    return (
        <div className={`${!isFirst ? "border-t border-gray-300 pt-4" : ""} ${!isLast ? "pb-3" : ""}`}
             style={{display: isBelowMode ? "block" : "flex", alignItems: !isBelowMode ? "center" : "inherit"}}>
            <div className={"equalColumn"} style={{paddingRight: "22px"}}>
                <CompanyPart data={data.company}/>
                {positions.map((elem, index) => <PositionElem key={index} data={elem}/>)}
            </div>
            <div className={"equalColumn hideMobile"}>
                {data.company.fundingRounds.length > 0 &&
                    <Timeline dateJoined={data.company.dateJoined} dateLeft={data.company.dateLeft}
                              fundingRounds={data.company.fundingRounds} companyName={data.company.name}/>}
            </div>
        </div>
    )
}

const IconStyle = {width: "22px", marginRight: "8px"};

function CompanyPart({data}) {
    const elemsToShow = [];

    if (data.highestSeries) {
        elemsToShow.push(<div className="containerRowCell">
            <img
                src={TrendSVG}
                style={IconStyle}
                className="RowIcon"
                alt="Series"
            />
            {data.highestSeries}
        </div>)
    }

    if (data.totalFunding) {
        elemsToShow.push(<div className="containerRowCell">
            <img
                src={UsdSVG}
                style={IconStyle}
                className="RowIcon"
                alt="Funding"
            />
            {amountToText(data.totalFunding)}
        </div>)
    }

    if (data.employeeCount) {
        elemsToShow.push(<div className="containerRowCell">
            <img
                src={TeamSVG}
                style={IconStyle}
                className="RowIcon"
                alt="EmployeeCount"
            />
            {data.employeeCount}
        </div>)
    }

    if (data.categories.length > 0) {
        elemsToShow.push(<div className="containerRowCell">
            <img
            src={CategoriesSVG}
            style={IconStyle}
            className="RowIcon"
            alt="Categories"
        />
            {data.categories.join(", ")}
        </div>)
    }

    return (
        <div>
            <p className="font-bold" style={{fontSize: "18px"}}>{data.name}</p>
            {data.description && <p style={{marginBottom: "12px"}}>{data.description}</p>}
            <div className="containerRow">
                {elemsToShow[0]}
                {elemsToShow[1]}
            </div>
            <div className="containerRow">
                {elemsToShow[2]}
                {elemsToShow[3]}
            </div>
        </div>
    )
}
function PositionElem({data}) {
    return (
        <div style={{marginTop: "10px"}}>
            <p style={{color: "#0496FF", fontSize: "16px"}}>{data.title}</p>
            <p className="text-gray-800" style={{fontWeight: 400, fontStyle: "italic"}}>{data.startDate} - {data.endDate || "Present"} · {data.duration}</p>
        </div>
    )
}

function Timeline({dateJoined, dateLeft, fundingRounds, companyName}) {
    const arr = fundingRounds.map(elem => ({type: "round", date: elem.date, name: elem.name, amount: elem.amount}));

    arr.push({type: "dateJoined", date: dateJoined, name: `Joined ${companyName}`});
    if (dateLeft) {
        arr.push({type: "dateLeft", date: dateLeft, name: `Left ${companyName}`});
    }

    const sorted = arr.sort(compare);

    return (
        <div className="timelineHolder">
            <div className="line"/>
            <div className="timeline">
                {sorted.map((elem, index) => <TimelineElem name={elem.name} type={elem.type} date={elem.date}
                                                           amount={elem.amount} key={index}/>)}
            </div>
        </div>
    )
}

function TimelineElem({type, name, date, amount}) {
    const isRound = type === "round";
    const workColor = "#0496FF";
    const thisColor = isRound ? getRoundColor(name) : workColor;
    const [month, year] = date.split(" ");
    const amountText = amountToText(amount);

    return (
        <div className={`container`} style={{flexDirection: isRound ? "column" : "column-reverse"}}>
            <div className={`title ${isRound ? "round" : "event"}`} style={{color: thisColor}}>{name}</div>
            <div className="big" style={{background: thisColor}}>
                {isRound ? amountText : ""}
                {type === "dateJoined" && <img
                    src={JoinSVG}
                    style={{width: "32px", marginBottom: "2px"}}
                    alt="Joined"
                />}
                {type === "dateLeft" && <img
                    src={ExitSVG}
                    style={{width: "28px", marginLeft: "3px", marginTop: "1px"}}
                    alt="Left"
                />}
            </div>
            <div className="thin" style={{background: thisColor}}/>
            <div className="dot" style={{background: thisColor}}/>
            <div className="date"><div>{month}</div><div>{year}</div></div>
            {!isRound && <div style={{height: "54.5px"}}/>}
        </div>
    )
}

function dateConverted(date) {
    // Safari doesn't like "July 2023"
    return moment(date, "MMM YYYY").toDate();
}

function compare(a, b) {
    if (dateConverted(a.date) < dateConverted(b.date)) {
        return -1;
    }
    if (dateConverted(a.date) > dateConverted(b.date)) {
        return 1;
    }
    return 0;
}

function amountToText(amount) {
    if (!amount) {
        return undefined;
    }

    const amountInBillions = amount / Math.pow(10, 9);
    const amountInMillions = amount / Math.pow(10, 6);
    const amountUnit = amountInBillions >= 1 ? "b" : amountInMillions >= 1 ? "m" : "k";
    const amountStandard = Number.parseFloat(amount / (amountUnit === "b" ? Math.pow(10, 9) : amountUnit === "m" ? Math.pow(10, 6) : Math.pow(10, 3))).toFixed(0);
    const amountText = !!amount ? `${amountStandard}${amountUnit}` : "";

    return amountText;
}

function getRoundColor(round) {
    const roundMap = {
        "Pre-Seed": "#F2BB13",
        "Seed": "#FC9E4F",
        "Series A": "#E54587",
        "Series B": "#3AB795",
        "Series C": "#FF5E5B",
        "Series D": "#FFC642",
        "Series E": "#7B59DA",
        "Series F": "#489FB5",
        "Series G": "#E079A4",
        "Raise": "#777A80"
    };

    const ret = roundMap[round];

    if (!ret) {
        return roundMap["Series G"];
    }

    return ret;
}
