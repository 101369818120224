import React, { useState, useRef, useEffect } from "react";
import "../../../Login/index.css";
import "./CompIndex.css";
import OctupusImg from "../../images/OctopusVentures.svg";
import DummyVcLogo from "../../images/gvc.svg";
import HumanCapital from "../../images/human.svg";
import {ReactComponent as M13Img} from "../../images/m13.svg";
import Oak from "../../../AddMember/images/Oaknew.svg";
import BVT from "../../../Dashboard/images/BTV.svg";
import LeftLane from "../../../Dashboard/images/LeftLane.svg"; 
import Burnt from "../../../Dashboard/images/BurntIslandLogo.svg"; 
import DemoVC from "../../../Dashboard/images/DemoVC.svg"; 
import UpfrontVentures from "../../../Dashboard/images/UpfrontLogo.svg";
import { ReactComponent as ProfileIcon } from "../../../Dashboard/images/imageProfile.svg";

const PageHeaders = ({title, VentureData, CompanyName, userRole, profileIcon, setProfileIcon, menuRefs, handleCampanyTeamMember, LogoutUser, handleCandidateDetails, handleRegisteredComp, handlemanageTeamMember, handleCompaniesDetails, storedCompanyName, handleCollection, editCandidate, handleList, vcLogo}) => {
    return(
        <>
            <div className="w-full fixed inset-x-0 z-20 bg-deep-purple-10 top-0 border-b border-gray-300]" style={editCandidate == true ? {zIndex: "auto"} : null}>
            <div className="w-full mx-auto flex flex-wrap items-center justify-between py-2 px-4">
            <h1 className="mr-auto">
            {VentureData == undefined && vcLogo == undefined ? (
              null
            ) : VentureData && vcLogo == undefined  ? (
              <a
                className="text-deep-purple-500 no-underline hover:no-underline font-bold text-2xl lg:text-4xl"
                href="/dashboard/candidates"
              >
                 <img
                    style={{maxWidth: '230.25px', maxHeight: '29.34px'}}
                    src={VentureData?.form_logo_header}
                    alt="Venture_logo"
                  />
                  <span className="sr-only">Venture_logo</span>
              </a>
            ) : (
              <a
                className="text-deep-purple-500 no-underline hover:no-underline font-bold text-2xl lg:text-4xl"
                href="/dashboard/candidates"
              >
                 <img
                    style={{maxWidth: '230.25px', maxHeight: '29.34px'}}
                    src={vcLogo}
                    alt="Venture_logo"
                  />
                  <span className="sr-only">Venture_logo</span>
              </a>
            )}
            </h1>
                <h1 className="mr-auto font-bold">
                {storedCompanyName == undefined ? "" : title}
                </h1>
                {userRole == "anonymous" || userRole == "companyportfolio" ? (
            <div className= {userRole == "venturecapital" || userRole == "admin" || userRole == "globalvc" ? "relative order-2 sm:order-3 ml-8" : "sm:w-auto relative ml-8 order-1 sm:order-2"}
            
             ref={menuRefs}>
             <ProfileIcon className="cursor-pointer" onClick={() => setProfileIcon((profileIcon) => !profileIcon)}/>
              {profileIcon && (
                <ul
                  className="
                w-48
                min-w-max
                absolute
                -right-4
                bg-white
                text-base
                z-50
                float-left
                py-2
                list-none
                text-left
                rounded-lg
                shadow-lg
                mt-1 
                m-0
                bg-clip-padding
                border-none
              "
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <a
                      className=" 
                    text-sm
                    py-2
                    px-4
                    font-normal
                    block
                    w-full
                    whitespace-nowrap
                    bg-transparent
                    text-gray-700
                    hover:bg-gray-100
                  "
                      href="javascript:void(0);"
                      onClick={handleCampanyTeamMember}
                    >
                      Manage Team Members
                    </a>
                  </li>
                  <li>
                    <a
                      className=" 
                    text-sm
                    py-2
                    px-4
                    font-normal
                    block
                    w-full
                    whitespace-nowrap
                    bg-transparent
                    text-gray-700
                    hover:bg-gray-100
                  "
                      href="javascript:void(0);"
                      onClick={LogoutUser}
                    >
                      {" "}
                      Logout
                    </a>
                  </li>
                </ul>
              )}
            </div>
          ) : (
            <div className="relative order-2 sm:order-3 ml-8" ref={menuRefs}>
            <ProfileIcon className="cursor-pointer" onClick={() => setProfileIcon((profileIcon) => !profileIcon)} />
              {profileIcon && (
                <ul
                  className="
                w-48
                min-w-max
                absolute
                -right-4
                bg-white
                text-base
                z-50
                float-left
                py-2
                list-none
                text-left
                rounded-lg
                shadow-lg
                mt-1 
                m-0
                bg-clip-padding
                border-none
              "
                  aria-labelledby="dropdownMenuButton1"
                >
                {userRole == "admin" || userRole == "globalvc" || userRole == "venturecapital" && storedCompanyName != "Global VC Talent Network" ? (
                  <li>
                    <a
                      className="flex 
                      items-center
                      justify-between
                      text-sm
                      py-2
                      px-4
                      font-normal 
                      w-full
                      whitespace-nowrap
                      bg-transparent
                      text-gray-700
                      hover:bg-gray-100
                  "
                      onClick={handleCollection}
                      href={"javascript:void(0)"}
                    >
                      View Connections
                    </a>
                  </li>
                ) : null}
                {userRole == "admin" || userRole == "globalvc" || userRole == "venturecapital" && storedCompanyName != "Global VC Talent Network" ? (
                  <li>
                      <a
                        className="flex 
                      items-center
                      justify-between
                      text-sm
                      py-2
                      px-4
                      font-normal 
                      w-full
                      whitespace-nowrap
                      bg-transparent
                      text-gray-700
                      hover:bg-gray-100
                  "
                        onClick={handleList}
                        href={"javascript:void(0)"}
                      >
                        Manage Lists
                      </a>
                    </li>
                ) : null}
                  <li>
                    <a
                      className="flex 
                      items-center
                      justify-between
                      text-sm
                      py-2
                      px-4
                      font-normal 
                      w-full
                      whitespace-nowrap
                      bg-transparent
                      text-gray-700
                      hover:bg-gray-100
                  "
                      onClick={handleCandidateDetails}
                      href={"javascript:void(0)"}
                    >
                      Manage Candidates
                    </a>
                  </li>
                  {userRole == "admin" || userRole == "globalvc" || userRole == "venturecapital" && storedCompanyName != "Global VC Talent Network" ? (
                    <>
                    <li>
                      <a
                        className="flex 
                      items-center
                      justify-between
                      text-sm
                      py-2
                      px-4
                      font-normal 
                      w-full
                      whitespace-nowrap
                      bg-transparent
                      text-gray-700
                      hover:bg-gray-100
                  "
                        onClick={handleRegisteredComp}
                        href={"javascript:void(0)"}
                      >
                        Manage Portfolio Users
                      </a>
                    </li>
                    <li>
                    <a
                      className="
                    flex 
                    items-center
                    justify-between
                    text-sm
                    py-2
                    px-4
                    font-normal 
                    w-full
                    whitespace-nowrap
                    bg-transparent
                    text-gray-700
                    hover:bg-gray-100
                  "
                      onClick={handlemanageTeamMember}
                      href={"javascript:void(0)"}
                    >
                      Manage Team Members
                    </a>
                  </li>
                    </>
                  ) : null}
                  {userRole == "admin" && (
                    <li>
                      <a
                        className="flex 
                      items-center
                      justify-between
                      text-sm
                      py-2
                      px-4
                      font-normal 
                      w-full
                      whitespace-nowrap
                      bg-transparent
                      text-gray-700
                      hover:bg-gray-100
                  "
                        onClick={handleCompaniesDetails}
                        href={"javascript:void(0)"}
                      >
                        Companies Details
                      </a>
                    </li>
                  )}
                  <li>
                    <a
                      className=" 
                    text-sm
                    py-2
                    px-4
                    font-normal
                    block
                    w-full
                    whitespace-nowrap
                    bg-transparent
                    text-gray-700
                    hover:bg-gray-100
                  "
                      href="javascript:void(0);"
                      onClick={LogoutUser}
                    >
                      {" "}
                      Logout
                    </a>
                  </li>
                </ul>
              )}
            </div>
          )}
            </div>
            </div>
        </>
    )
}

export default PageHeaders;