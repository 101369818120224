import React, { useRef, useState, useEffect } from "react";
import "./index.css";
import axios from "axios";
import Image1 from "./images/modal-list-img.png"
import LoaderPopUp from "./LoaderPopup";
import TablePopUp from "./TablePopUp";

const AddListPopup = ({candidateDetails, handleListModel, userDetails, ContactType}) => {
    const [tabledata, setTableData] = useState([]);
    const [Loading, setLoading] = useState(true);
    const ListRef = useRef();
    useEffect(() => {
        const storedCompanyId = localStorage.getItem("companyId");
        axios({
            method: "get",
            url: `/api/lists/lists/getall/${storedCompanyId}`,
            headers: {
              "Content-Type": "application/json",
            },
          }).then((res) => {
            setTableData(res.data.data)
          }).finally(() => {
            setLoading(false);
          });
      }, [])

      useEffect(() => {
        let handler = (event) => {
          if (!ListRef?.current?.contains(event.target)) {
            handleListModel();
          }
        };
        document.addEventListener("mousedown", handler);
    
        return () => {
          document.removeEventListener("mousedown", handler);
        };
      }, []);
  return (
    <>
      <div className="popup-premium modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden show">
        <div className="modal-backdrop fade show " style={{minHeight: '1200px', maxHeight: '2400px'}}></div>
        <div
          className="modal-dialog"
          style={{minWidth: '660px', paddingBottom: '20px', top: '2%'}}
          ref={ListRef}
        >
        {Loading == true ? ( <LoaderPopUp /> ) : (<TablePopUp ContactType={ContactType} handleListModel={handleListModel} userDetails={userDetails} tabledata={tabledata} candidateDetails={candidateDetails} />)}
        {/* <LoaderPopUp /> */}
        </div>
      </div>
    </>
  );
};

export default AddListPopup;
