import React, { useState, useRef, useEffect } from "react";
import "../../../Login/index.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import octopus_ventures from "../../images/ventures_logo.svg";
import OAK_ventures from "../../images/Oaknew.svg";
import LeftLane from "../../images/LeftLane.svg";
import UpfrontVentures from "../../images/UpfrontLogo.svg";
import M13_ventures from "../../images/m13.svg";
import DummyVCLogo from "../../images/gvc.svg";
import HumanCapital_ventures from "../../images/human.svg";
import Select, { NonceProvider } from "react-select";
import Vector from "../../../ForgotPass/images/error.svg";
import { Helmet } from "react-helmet";
import { GooglePlacesSelect } from "../../../../shared/components/googlePlaces";

const Form = ({ authentication_state }) => {
  const [screen, setScreen] = useState(1);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [location, setLocation] = useState(null);
  const [expertise, setExpertise] = useState(null);
  const [role, setRole] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [email, setEmail] = useState("");
  const [career, setCareer] = useState(null);
  const [stage, setStage] = useState([]);
  const [workFor, setWorkFor] = useState("");
  const [typeOfwork, setTypeOfWork] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [marketPlaces, setMarketPlaces] = useState([]);
  const [likeToWork, setLikeToWork] = useState([]);
  const [textArea, setTestArea] = useState("");
  const [gender, setGender] = useState(null);
  const [Ethnicity, setEthnicity] = useState(null);
  const [terms, setTerm] = useState("");
  const [boardDirector, setBoardDirector] = useState("");
  const [Loading, setLoading] = useState(false);
  const [experience, setExperience] = useState("");
  const [file, setFiles] = useState();
  const [CharCount, setCharCount] = useState(0);

  //Errors States are here ----->
  const [firstenameError, setFirstNameError] = useState("");
  const [lastnameError, setLastNameError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [expertiseError, setExpertiseError] = useState("");
  const [roleError, setRoleError] = useState("");
  const [workForError, setWorkForError] = useState("");
  const [boardDirectorError, setBoardDirectorError] = useState("");
  const [linkedInError, setLinkedInError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [careerError, setCareerError] = useState("");
  const [typeOfworkError, setTypeOfworkError] = useState("");
  const [experienceError, setExperienceError] = useState("");
  const [stageError, setStageError] = useState("");
  const [industriesError, setIndustriesError] = useState("");
  const [marketPlacesError, setMarketPlacesError] = useState("");
  const [likeToWorkError, setLikeToWorkError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [EthnicityError, setEthnicityError] = useState("");
  const [TNCError, setTNCError] = useState("");
  // const Email_Regex = /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|life|in|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;

  const [PrimeName, setPrimeName] = useState()
  const headers = {};
  const params = useParams();
  const OrgName = params.name;
  const authentication_ref = useRef(authentication_state);
  authentication_ref.current = authentication_state;
  let likeToWorkArray = [];
  const likeToWorkArrayMap = likeToWork?.map((item) => {
    likeToWorkArray.push(item.currItem);
  });
  let Stages = [];
  const StagesArray = stage?.map((item) => {
    Stages.push(item.currItem)
  })
  // Desired Location Array
  let UniDesiredLocStringresult = [
    { currItem: "London" },
    { currItem: "Cambridge" },
    { currItem: "Other Location (UK)" },
    { currItem: "San Fran / Bay Area" },
    { currItem: "New York" },
    { currItem: "Los Angeles" },
    { currItem: "Boston" },
    { currItem: "Other Location (US)" },
    { currItem: "Paris" },
    { currItem: "Berlin" },
    { currItem: "Other Location (Europe)" },
    { currItem: "Other Location (Asia)" },
  ];
  // Level Array
  let uniqueLevelresult = [
    { currItem: "Entry Level" },
    { currItem: "Junior / Mid-Level" },
    { currItem: "Senior / Principal" },
    { currItem: "Lead / Mid-Manager" },
    { currItem: "Head of / Director" },
    { currItem: "VP / GM" },
    { currItem: "Executive / C-suite" },
    { currItem: "CEO" },
    { currItem: "Board of Directors / Advisory" },
  ];
  // Expertise Array
  let ExpertiseMapStringresult = [
    { currItem: "Biz Ops & Strategy" },
    { currItem: "Product" },
    { currItem: "Commercial" },
    { currItem: "Growth & Marketing" },
    { currItem: "People & Talent" },
    { currItem: "Customer Success" },
    { currItem: "Finance" },
    { currItem: "Legal" },
    { currItem: "Risk & Compliance" },
    { currItem: "Software Engineering" },
    { currItem: "Data Science / Machine Learning" },
    { currItem: "BI / Data Analytics" },
    { currItem: "Procurement & Supply Chain" },
    { currItem: "Administrative" },
  ];
  // Location Array
  let uniqueLocationresult = [
    { currItem: "London" },
    { currItem: "Cambridge" },
    { currItem: "Other Location (UK)" },
    { currItem: "San Fran / Bay Area" },
    { currItem: "New York" },
    { currItem: "Los Angeles" },
    { currItem: "Boston" },
    { currItem: "Other Location (US)" },
    { currItem: "Paris" },
    { currItem: "Berlin" },
    { currItem: "Other Location (Europe)" },
    { currItem: "Other Location (Asia)" },
  ];

  // Stages Array
  let stagesArray = [
    { currItem: "Pre-Seed" },
    { currItem: "Seed" },
    { currItem: "Series-A" },
    { currItem: "Series-B" },
    { currItem: "Series-C" },
    { currItem: "5+ Rounds-of-Funding" },
    { currItem: "Private" },
  ];

  // Gender Array
  let GenderArray = [
    { currItem: "Female" },
    { currItem: "Male" },
    { currItem: "Transgender" },
    { currItem: "Non-binary" },
    { currItem: "Prefer not to say" },
  ];

  // Stages Array
  let EthencityArray = [
    { currItem: "Black or African American" },
    { currItem: "Hispanic or Latino" },
    { currItem: "Middle Eastern" },
    { currItem: "South Asian" },
    { currItem: "East Asian" },
    { currItem: "White" },
    { currItem: "Mixed" },
    { currItem: "Prefer not to say" },
  ];

  useEffect(() => {
    const headerData = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios.get(`/api/user/get/${params.id}/`, headerData).then((res) => {
      setPrimeName(res.data.data.access)
    })
  },[])

  useEffect(() => {
    if(PrimeName == "noaccess"){
      window.location.href = "/";
    }
  }, [PrimeName])

  const handleClickPrev = (e) => {
    e.preventDefault();
    setScreen((currPage) => currPage - 1);
  };

  const handlePrivacyPolicy = () => {
    if(OrgName == "OctopusVentures"){
      window.open("https://octopusventures.com/privacy-policy/")
    }else{
      window.open("https://www.startuplane.io/legal/privacy") 
    }   
  }

  const handleClickNext = (e) => {
    e.preventDefault();
    if (screen == 1 && firstName == "") {
      setFirstNameError("Please add your First Name");
      window.scrollTo(0, 0);
    } else if (screen == 1 && lastName == "") {
      setLastNameError("Please add your Last Name");
      window.scrollTo(0, 0);
    } else if (screen == 1 && location == null) {
      setLocationError("Please add your Current Location");
    } else if (screen == 1 && expertise == null) {
      setExpertiseError("Please add your main Professional Expertise");
    } else if (screen == 1 && role == "") {
      setRoleError("Please add your most recent Role");
    }else if (screen == 1 && marketPlaces.length == 0) {
      setMarketPlacesError("Please select a business model");
    } else if (screen == 1 && workFor == "") {
      setWorkForError("Please add your most recent Employer");
    } else if (screen == 1 && boardDirector == "") {
      setBoardDirectorError("Please let us know if you have served on a Board");
    } else if (screen == 1 && linkedIn == "") {
      setLinkedInError("Please add your LinkedIn Profile");
    } else if (screen == 1 && linkedIn.includes("gmail" || "yahoo")) {
      toast.error("Please add your valid LinkedIn Profile", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (screen == 1 && email == "") {
      setEmailError("Please add your Email Address");
    }else if(email != "" &&  !email.includes("@")){
      setEmailError("Please add valid Email Address");
    } else if (screen == 2 && career == null) {
      setCareerError("Please let us know which Career Level you are targeting");
      window.scrollTo(0, 0);
    } else if (screen == 2 && typeOfwork.length == 0) {
      setTypeOfworkError("Please add your Work Schedule preferences");
      window.scrollTo(0, 0);
    } else if (screen == 2 && experience == "") {
      setExperienceError("Please add your Remote Work preferences");
      window.scrollTo(0, 0);
    } else if (screen == 2 && stage.length == 0) {
      setStageError("Please add your Company Stage preferences");
      window.scrollTo(0, 0);
    } else if (screen == 2 && industries.length == 0) {
      setIndustriesError("Please add your preferred Industries");
    } else if (screen == 2 && likeToWorkArray.length == 0) {
      setLikeToWorkError("Please choose your preferred Location(s)");
    } else {
      setScreen((currPage) => currPage + 1);
      window.scrollTo(0, 0);
    }
  };

  const handleStagesArray = (e) => {
    const { value, checked } = e?.target;
    if (checked) {
      setStage([...stage, value]);
    } else {
      setStage(stage.filter((e) => e !== value));
    }
  }

  const handleMarketPlace = (e) => {
    const { value, checked } = e?.target;
    if (checked) {
      setMarketPlaces([...marketPlaces, value]);
    } else {
      setMarketPlaces(marketPlaces.filter((e) => e !== value));
    }
  }

  const handleIndustries = (e) => {
    const { value, checked } = e?.target;
    if (checked) {
      setIndustries([...industries, value]);
    } else {
      setIndustries(industries.filter((e) => e !== value));
    }
  };

  const handleTerm = (e) => {
    const { checked } = e?.target;
    if (checked) {
      setTerm(checked);
    } else {
      setTerm(false);
    }
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e?.target;
    if (checked) {
      setTypeOfWork([...typeOfwork, value]);
    } else {
      setTypeOfWork(typeOfwork.filter((e) => e !== value));
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };

  const handleFileInputChange = (e) => {
    let file = e.target.files[0];
    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        setFiles({
          base64URL: result,
          file,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    setFiles({
      file: e.target.files[0],
    });
  };

  const isValid = () => {
    if (screen == 3 && gender == null) {
      setGenderError("Please add your Gender Identity");
      return false;
    } else if (screen == 3 && Ethnicity == null) {
      setEthnicityError("Please add your Ethnicity");
      return false;
    } else if (screen == 3 && terms == false) {
      setTNCError("Please Check Terms and Conditions");
      return false;
    } else {
      return true;
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (isValid()) {
      setLoading(true);
      const data = {
        firstname: firstName,
        lastname: lastName,
        created_by: params.id,
        access: OrgName == "StartupLane" ? "premium" : "basic" ,
        email: email,
        profilepic: null,
        about: {
          candidate_description: textArea,
          // nativeplace: "London",
          currentLocation: location,
          functionalexpertise: expertise?.currItem,
          gender: gender.currItem,
          ethnicity: Ethnicity.currItem,
          linkedin: linkedIn,
          worktype: typeOfwork,
        },
        careerplan: {
          kind_of_business: marketPlaces.toString(),
          work: role,
          employer: workFor,
          careerlevel: career?.currItem,
          capital: OrgName == "OctopusVentures" ? "OctopusVentures" : OrgName == "AnzuPartners" ? "Anzu Partners" : OrgName == "M13" ? "M13" : OrgName == "HumanCapital" ? "Human Capital" : OrgName == "GVCTN" ? "Global VC Talent Network" : OrgName == "LLC" ? "Left Lane Capital" : OrgName == "UpfrontVentures" ? "Upfront Ventures" : "admin",
          employer: workFor,
          industry: industries.toString(),
          stage: stage.toString(),
          workplace: likeToWorkArray.join(","),
          remote: experience,
          experience: boardDirector,
          work_preference: workFor,
        },
        cv:
          file?.file?.base64.replace("data:application/pdf;base64,", "") ||
          null,
        ext: file?.file?.type.replace("application/", ".") || null,
        finalbits: {
          document: null,
          extrainformation: textArea,
          termsandconditions: terms,
        },
      };
      const headerData = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios
        .post(`/api/candidate/candidate/`, data, headerData)
        .then((res) => {
          if (res.status == 201) {
            setScreen((currPage) => currPage + 1);
          }
        })
        .catch((error) => {
          toast.error(error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        });
    }
  };

  const handleCharCount = (e) => {
    const inputName = e.target.value;
    const resetCount = 0 + inputName?.length;
    setTestArea(inputName);
    setCharCount(resetCount > 0 ? resetCount : 0);
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      border: "1px solid red",
      // This line disable the blue border
      boxShadow: "red",
    }),
  };

  return (
    <>
      <Helmet>
        <title>
          {OrgName == "StartupLane" ? "SL Candidate Form" : OrgName == "OakHCFT" ? "Anzu Partners Candidate Form" : OrgName == "M13" ? "M13 Candidate Form" : OrgName == "HumanCapital" ? "Human Capital Candidate Form" : OrgName == "GVCTN" ? "GVCTN Candidate Form" : OrgName == "LLC" ? "Left Lane Capital" : OrgName == "UpfrontVentures" ? "Upfront Ventures" : "OV Candidate Form"}
        </title>
      </Helmet>
      <div className="leading-normal font-normal text-base text-gray-1000 bg-deep-purple-5000 font-sans">
        <header className="w-full relative border-b border-gray-300">
          <div className="w-full mx-auto flex flex-wrap items-center justify-between p-4">
            <h1 className="md:mx-auto" style={{ minHeight: "30px" }}>
              {OrgName == "OctopusVentures" && (
                <a
                  href="https://octopusventures.com/"
                  className="block no-underline hover:no-underline"
                  target="_blank"
                >
                  <img
                    className="w-36 md:w-56"
                    src={octopus_ventures}
                    alt="Octopus Ventures"
                  />
                  <span className="sr-only">Octopus Ventures</span>
                </a>
              )}
              {OrgName == "GVCTN" && (
                <a
                  href="https://www.startuplane.io/legal/privacy"
                  className="block no-underline hover:no-underline"
                  target="_blank"
                >
                  <img
                    className="w-20 md:w-26"
                    src={DummyVCLogo}
                    alt="GVCTN"
                  />
                  <span className="sr-only">GVCTN</span>
                </a>
              )}
              {OrgName == "AnzuPartners" && (
                <a
                  href="https://www.anzupartners.com/"
                  className="block no-underline hover:no-underline"
                  target="_blank"
                >
                  <img
                    className="w-32 md:w-46"
                    src={OAK_ventures}
                    alt="Anzu Partners"
                  />
                  <span className="sr-only">Anzu Partners</span>
                </a>
              )}
              {OrgName == "LLC" && (
                <a
                  href="https://www.leftlane.com/"
                  className="block no-underline hover:no-underline"
                  target="_blank"
                >
                  <img
                    className="w-16 md:w-18"
                    src={LeftLane}
                    alt="LeftLane"
                  />
                  <span className="sr-only">LeftLane</span>
                </a>
              )}
              {OrgName == "UpfrontVentures" && (
                <a
                  href="https://www.UpfrontVentures.com/"
                  className="block no-underline hover:no-underline"
                  target="_blank"
                >
                  <img
                    className="w-16 md:w-30"
                    src={UpfrontVentures}
                    alt="UpfrontVentures"
                  />
                  <span className="sr-only">UpfrontVentures</span>
                </a>
              )}
              {OrgName == "M13" && (
                <a
                  href="https://m13.co/"
                  className="block no-underline hover:no-underline"
                  target="_blank"
                >
                  <img
                    className="w-16 md:w-18"
                    src={M13_ventures}
                    alt="M13"
                  />
                  <span className="sr-only">M13</span>
                </a>
              )}
              {OrgName == "HumanCapital" && (
                <a
                  href="https://human.capital/"
                  className="block no-underline hover:no-underline"
                  target="_blank"
                >
                  <img
                    className="w-16 md:w-18"
                    src={HumanCapital_ventures}
                    alt="Human Capital"
                  />
                  <span className="sr-only">Human Capital</span>
                </a>
              )}
              {/* OAK_ventures */}
            </h1>
            <div className="absolute right-4 group flex items-center bg-gray-50 rounded-lg py-2 px-3 md:px-4">
              <span className="text-xs sm:text-base font-medium mr-4">
                Powered by
              </span>
              <a className="block no-underline hover:no-underline" href="/">
                <svg
                  width="98"
                  height="42"
                  className="w-12 h-auto"
                  viewBox="0 0 98 42"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M25.4552 41.8057C24.7351 41.8057 24.0611 41.6949 23.4332 41.4733C22.8238 41.2332 22.2976 40.9101 21.8544 40.5038C21.4112 40.0791 21.0603 39.5898 20.8018 39.0358C20.5433 38.4634 20.414 37.8447 20.414 37.18C20.414 36.4598 20.5618 35.8043 20.8572 35.2133C21.1711 34.6224 21.6051 34.1146 22.1591 33.6899C22.713 33.2652 23.3686 32.9328 24.1257 32.6927C24.8828 32.4527 25.723 32.3327 26.6463 32.3327C27.2557 32.3327 27.8466 32.3881 28.419 32.4989C28.9915 32.5912 29.49 32.7389 29.9148 32.942V32.2773C29.9148 30.5969 28.9453 29.7567 27.0064 29.7567C26.2123 29.7567 25.4552 29.8952 24.7351 30.1721C24.0149 30.4307 23.2578 30.8277 22.4637 31.3632L21.1619 28.5933C22.1221 27.9655 23.1193 27.4946 24.1534 27.1807C25.1875 26.8667 26.2862 26.7098 27.4496 26.7098C29.647 26.7098 31.3459 27.2268 32.5462 28.2609C33.7464 29.295 34.3466 30.8092 34.3466 32.8035V36.5429C34.3466 36.9861 34.4112 37.3 34.5405 37.4847C34.6882 37.6509 34.9375 37.7524 35.2884 37.7893V41.5287C34.9006 41.621 34.5497 41.6764 34.2358 41.6949C33.9219 41.7318 33.6357 41.7503 33.3771 41.7503C32.5462 41.7503 31.9183 41.5933 31.4936 41.2794C31.0689 40.947 30.8011 40.4854 30.6903 39.8945L30.6072 39.2297C29.9609 40.0607 29.1854 40.6977 28.2805 41.1409C27.3942 41.5841 26.4524 41.8057 25.4552 41.8057ZM26.7571 38.6203C27.2372 38.6203 27.6988 38.5372 28.142 38.371C28.6037 38.2048 28.9822 37.9832 29.2777 37.7063C29.7024 37.3739 29.9148 37.023 29.9148 36.6537V35.2687C29.527 35.121 29.1023 35.0102 28.6406 34.9364C28.1974 34.844 27.7819 34.7979 27.3942 34.7979C26.5817 34.7979 25.9076 34.9825 25.3721 35.3518C24.8551 35.7212 24.5966 36.2013 24.5966 36.7922C24.5966 37.3277 24.7997 37.7709 25.2059 38.1217C25.6122 38.4541 26.1292 38.6203 26.7571 38.6203Z"
                    fill="#182135"
                  />
                  <path
                    d="M51.5736 41.5287H47.1418V33.3575C47.1418 32.3973 46.9571 31.7048 46.5878 31.2801C46.2369 30.8369 45.7661 30.6153 45.1751 30.6153C44.8612 30.6153 44.5473 30.68 44.2334 30.8092C43.9195 30.9385 43.6148 31.1231 43.3193 31.3632C43.0239 31.5848 42.7561 31.8525 42.516 32.1665C42.2945 32.4804 42.119 32.822 41.9898 33.1913V41.5287H37.5579V26.9868H41.5466V29.4243C42.0821 28.5564 42.8392 27.8916 43.8179 27.4299C44.7966 26.9498 45.923 26.7098 47.1972 26.7098C48.1389 26.7098 48.896 26.8852 49.4685 27.2361C50.0409 27.5684 50.4841 28.0116 50.798 28.5656C51.1119 29.1011 51.3151 29.692 51.4074 30.3383C51.5182 30.9846 51.5736 31.6125 51.5736 32.2219V41.5287Z"
                    fill="#182135"
                  />
                  <path
                    d="M61.4706 41.8057C60.2518 41.8057 59.1531 41.6118 58.1744 41.224C57.2142 40.8178 56.3924 40.273 55.7092 39.5898C55.0444 38.9065 54.5366 38.1217 54.1857 37.2354C53.8349 36.3305 53.6595 35.3888 53.6595 34.4101C53.6595 33.3575 53.8349 32.3696 54.1857 31.4463C54.5366 30.5045 55.0444 29.6828 55.7092 28.9811C56.374 28.2794 57.1865 27.7254 58.1467 27.3192C59.1254 26.9129 60.2333 26.7098 61.4706 26.7098C62.7078 26.7098 63.8065 26.9129 64.7667 27.3192C65.727 27.7254 66.5395 28.2794 67.2042 28.9811C67.869 29.6643 68.3676 30.4584 68.7 31.3632C69.0508 32.268 69.2263 33.219 69.2263 34.2162C69.2263 34.4747 69.217 34.7332 69.1986 34.9918C69.1801 35.2318 69.1524 35.4442 69.1155 35.6288H58.3683C58.4421 36.6075 58.793 37.3554 59.4208 37.8724C60.0671 38.371 60.7966 38.6203 61.6091 38.6203C62.2554 38.6203 62.874 38.4634 63.4649 38.1494C64.0743 37.8355 64.4805 37.4108 64.6836 36.8753L68.4507 37.9278C67.8782 39.0727 66.9919 40.0053 65.7916 40.7254C64.5913 41.4456 63.151 41.8057 61.4706 41.8057ZM64.5451 32.8866C64.4528 31.9633 64.1204 31.2339 63.548 30.6984C62.9755 30.1629 62.2646 29.8952 61.4152 29.8952C60.5473 29.8952 59.8271 30.1721 59.2546 30.7261C58.7007 31.2616 58.3775 31.9818 58.2852 32.8866H64.5451Z"
                    fill="#182135"
                  />
                  <path
                    d="M11.9946 24.9039H16.4265V36.349C16.4265 37.457 16.8973 38.0109 17.8391 38.0109H19.6024V41.778H18.04H15.9556C14.6814 41.778 13.7027 41.4456 13.0195 40.7808C12.3362 40.1161 11.9946 39.1558 11.9946 37.9001V24.9039Z"
                    fill="#182135"
                  />
                  <path
                    d="M14.1072 19.077L18.5957 25.1809H9.61869L14.1072 19.077Z"
                    fill="#182135"
                  />
                  <path
                    d="M7.07766 20.2667C5.84044 20.2667 4.62168 20.0728 3.42139 19.685C2.22111 19.2787 1.20548 18.6971 0.374512 17.94L1.95335 15.2255C2.83972 15.8348 3.69838 16.3057 4.52935 16.6381C5.37879 16.9705 6.19129 17.1367 6.96686 17.1367C7.5393 17.1367 7.98249 17.0351 8.29641 16.832C8.61033 16.6289 8.76729 16.3334 8.76729 15.9456C8.76729 15.5579 8.56416 15.2532 8.15791 15.0316C7.77013 14.7915 7.07765 14.5422 6.08049 14.2837C5.1018 14.0067 4.27083 13.739 3.58759 13.4804C2.92281 13.2219 2.37807 12.9357 1.95335 12.6218C1.52863 12.3078 1.22395 11.9478 1.03929 11.5415C0.854627 11.1353 0.762297 10.6551 0.762297 10.1012C0.762297 9.36253 0.910025 8.68852 1.20548 8.07914C1.50094 7.46977 1.90719 6.95272 2.42423 6.528C2.94128 6.10329 3.55066 5.7709 4.25236 5.53084C4.97254 5.29078 5.74811 5.17075 6.57907 5.17075C7.66857 5.17075 8.68419 5.31848 9.62596 5.61394C10.5862 5.90939 11.5095 6.44491 12.3959 7.22048L10.6785 9.87957C9.84755 9.30713 9.10891 8.89165 8.4626 8.63312C7.81629 8.3746 7.18845 8.24534 6.57907 8.24534C6.09896 8.24534 5.69271 8.3469 5.36032 8.55003C5.02793 8.73468 4.86174 9.03937 4.86174 9.46409C4.86174 9.87034 5.03717 10.175 5.38802 10.3782C5.75734 10.5813 6.43135 10.8029 7.41004 11.0429C8.44414 11.3199 9.31204 11.5969 10.0137 11.8739C10.7339 12.1509 11.3156 12.4648 11.7588 12.8157C12.202 13.148 12.5251 13.5358 12.7282 13.979C12.9314 14.4222 13.0329 14.9485 13.0329 15.5579C13.0329 16.9982 12.4974 18.1431 11.4264 18.9925C10.3554 19.842 8.90579 20.2667 7.07766 20.2667Z"
                    fill="#6A35FF"
                  />
                  <path
                    d="M24.584 19.2141C23.9747 19.4726 23.273 19.7127 22.4789 19.9343C21.7033 20.1559 20.9185 20.2667 20.1245 20.2667C19.5705 20.2667 19.0442 20.1928 18.5457 20.0451C18.0656 19.9158 17.6408 19.7035 17.2715 19.408C16.9022 19.0941 16.6067 18.6878 16.3851 18.1893C16.182 17.6907 16.0805 17.0905 16.0805 16.3888V8.79932H14.2246V5.44774H16.0805V0.822021H20.5123V5.44774H23.4761V8.79932H20.5123V14.8654C20.5123 15.3455 20.6323 15.6964 20.8724 15.9179C21.1124 16.1211 21.4171 16.2226 21.7864 16.2226C22.1188 16.2226 22.4604 16.1672 22.8113 16.0564C23.1622 15.9456 23.4668 15.8256 23.7254 15.6964L24.584 19.2141Z"
                    fill="#6A35FF"
                  />
                  <path
                    d="M30.438 20.2667C29.7179 20.2667 29.0439 20.1559 28.416 19.9343C27.8066 19.6942 27.2804 19.3711 26.8372 18.9648C26.394 18.5401 26.0431 18.0508 25.7846 17.4968C25.5261 16.9243 25.3968 16.3057 25.3968 15.641C25.3968 14.9208 25.5446 14.2652 25.84 13.6743C26.1539 13.0834 26.5879 12.5756 27.1419 12.1509C27.6958 11.7262 28.3514 11.3938 29.1085 11.1537C29.8656 10.9137 30.7058 10.7936 31.6291 10.7936C32.2385 10.7936 32.8294 10.849 33.4018 10.9598C33.9743 11.0522 34.4728 11.1999 34.8976 11.403V10.7382C34.8976 9.05784 33.9281 8.21764 31.9892 8.21764C31.1951 8.21764 30.438 8.35613 29.7179 8.63312C28.9977 8.89165 28.2406 9.28866 27.4465 9.82418L26.1447 7.05428C27.1049 6.42644 28.1021 5.95556 29.1362 5.64164C30.1703 5.32772 31.269 5.17075 32.4324 5.17075C34.6298 5.17075 36.3287 5.6878 37.529 6.7219C38.7292 7.75599 39.3294 9.2702 39.3294 11.2645V15.0039C39.3294 15.4471 39.394 15.761 39.5233 15.9456C39.671 16.1118 39.9203 16.2134 40.2712 16.2503V19.9897C39.8834 20.082 39.5325 20.1374 39.2186 20.1559C38.9047 20.1928 38.6184 20.2113 38.3599 20.2113C37.529 20.2113 36.9011 20.0543 36.4764 19.7404C36.0517 19.408 35.7839 18.9464 35.6731 18.3554L35.59 17.6907C34.9437 18.5216 34.1682 19.1587 33.2633 19.6019C32.377 20.0451 31.4352 20.2667 30.438 20.2667ZM31.7399 17.0813C32.22 17.0813 32.6816 16.9982 33.1248 16.832C33.5865 16.6658 33.965 16.4442 34.2605 16.1672C34.6852 15.8348 34.8976 15.484 34.8976 15.1147V13.7297C34.5098 13.582 34.0851 13.4712 33.6234 13.3973C33.1802 13.305 32.7647 13.2588 32.377 13.2588C31.5645 13.2588 30.8904 13.4435 30.3549 13.8128C29.8379 14.1821 29.5794 14.6623 29.5794 15.2532C29.5794 15.7887 29.7825 16.2319 30.1887 16.5827C30.595 16.9151 31.112 17.0813 31.7399 17.0813Z"
                    fill="#6A35FF"
                  />
                  <path
                    d="M51.7645 9.2425C50.6935 9.26096 49.724 9.43639 48.8561 9.76878C47.9882 10.1012 47.3604 10.5997 46.9726 11.2645V19.9897H42.5407V5.44774H46.6125V8.38383C47.1111 7.40514 47.7481 6.6388 48.5237 6.08482C49.3177 5.53084 50.1579 5.24462 51.0443 5.22615C51.2474 5.22615 51.3952 5.22615 51.4875 5.22615C51.5798 5.22615 51.6722 5.23539 51.7645 5.25385V9.2425Z"
                    fill="#6A35FF"
                  />
                  <path
                    d="M63.0488 19.2141C62.4394 19.4726 61.7377 19.7127 60.9437 19.9343C60.1681 20.1559 59.3833 20.2667 58.5892 20.2667C58.0353 20.2667 57.509 20.1928 57.0104 20.0451C56.5303 19.9158 56.1056 19.7035 55.7362 19.408C55.3669 19.0941 55.0715 18.6878 54.8499 18.1893C54.6468 17.6907 54.5452 17.0905 54.5452 16.3888V8.79932H52.6894V5.44774H54.5452V0.822021H58.977V5.44774H61.9408V8.79932H58.977V14.8654C58.977 15.3455 59.0971 15.6964 59.3371 15.9179C59.5772 16.1211 59.8819 16.2226 60.2512 16.2226C60.5836 16.2226 60.9252 16.1672 61.276 16.0564C61.6269 15.9456 61.9316 15.8256 62.1901 15.6964L63.0488 19.2141Z"
                    fill="#6A35FF"
                  />
                  <path
                    d="M69.1798 20.2667C67.684 20.2667 66.5484 19.805 65.7728 18.8817C65.0157 17.94 64.6371 16.5643 64.6371 14.7546V5.44774H69.069V13.7851C69.069 14.6715 69.2444 15.3547 69.5952 15.8348C69.9461 16.2965 70.4447 16.5273 71.091 16.5273C71.6819 16.5273 72.2543 16.3704 72.8083 16.0564C73.3623 15.7425 73.8516 15.1978 74.2764 14.4222V5.44774H78.7082V15.0039C78.7082 15.4471 78.7728 15.761 78.9021 15.9456C79.0498 16.1118 79.2991 16.2134 79.65 16.2503V19.9897C79.2252 20.082 78.8652 20.1374 78.5697 20.1559C78.2742 20.1928 78.0065 20.2113 77.7664 20.2113C76.1784 20.2113 75.2735 19.5927 75.0519 18.3554L74.9688 17.4691C74.3041 18.4293 73.4731 19.1403 72.4759 19.6019C71.4972 20.0451 70.3985 20.2667 69.1798 20.2667Z"
                    fill="#6A35FF"
                  />
                  <path
                    d="M91.3376 20.2667C90.2297 20.2667 89.2694 20.0359 88.4569 19.5742C87.6444 19.1126 87.0166 18.4662 86.5734 17.6353V28.6931H82.1416V5.44774H85.9917V7.80215C86.5272 6.97119 87.192 6.32488 87.986 5.86323C88.7985 5.40158 89.7588 5.17075 90.8667 5.17075C91.8454 5.17075 92.741 5.36465 93.5535 5.75243C94.3845 6.14022 95.0954 6.67573 95.6863 7.35897C96.2957 8.02375 96.7666 8.81778 97.099 9.74108C97.4498 10.6459 97.6253 11.6246 97.6253 12.6772C97.6253 13.7667 97.4683 14.7731 97.1544 15.6964C96.8405 16.6196 96.4065 17.4229 95.8525 18.1062C95.2986 18.7709 94.6338 19.2972 93.8582 19.685C93.0826 20.0728 92.2424 20.2667 91.3376 20.2667ZM89.8419 16.5273C90.322 16.5273 90.7559 16.4258 91.1437 16.2226C91.55 16.001 91.9008 15.7148 92.1963 15.364C92.4917 15.0131 92.7133 14.6069 92.8611 14.1452C93.0273 13.6651 93.1103 13.1665 93.1103 12.6495C93.1103 12.114 93.018 11.6246 92.8334 11.1814C92.6672 10.7198 92.4271 10.3228 92.1132 9.99037C91.8177 9.65798 91.4484 9.39946 91.0052 9.2148C90.5805 9.03014 90.1189 8.93781 89.6203 8.93781C89.0109 8.93781 88.42 9.12247 87.8475 9.49179C87.2936 9.84264 86.8689 10.3228 86.5734 10.9321V13.9236C86.8689 14.6623 87.312 15.2809 87.9029 15.7794C88.5123 16.278 89.1586 16.5273 89.8419 16.5273Z"
                    fill="#6A35FF"
                  />
                  <path
                    d="M84.5398 34.5106L80.0512 28.4068L89.0283 28.4068L84.5398 34.5106Z"
                    fill="#6A35FF"
                  />
                </svg>
                <span className="sr-only">Startup Lane</span>
              </a>
            </div>
          </div>
        </header>
        <main className="mt-5 sm:mt-10 px-4">
          <section className="max-w-4xl	mx-auto md:flex items-start md:space-x-5">
            <aside className="w-full md:w-1/4 bg-white flex flex-wrap items-center justify-between md:block sticky top-5">
              <nav className="w-full rounded-lg bg-white border border-gray-300 mb-2 md:mb-5 h-12 md:h-auto hover:h-auto overflow-hidden hover:overflow-auto">
                <h3 className="flex group justify-between items-center text-lg font-bold border-b border-gray-300 px-4 py-2.5 md:py-3">
                  Your Details
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    className="w-4 h-4 md:hidden"
                  >
                    <path
                      fill="none"
                      stroke="#6936FF"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M2 5l6 6 6-6"
                    />
                  </svg>
                </h3>
                <ul>
                  <li className="border-b border-gray-300">
                    <a
                      href="#about"
                      className="block py-2.5 pl-9 pr-4 text-sm whitespace-nowrap relative"
                    >
                      <span
                        className={
                          screen == 1
                            ? "inline-block w-2.5 h-2.5 bg-deep-purple-500 border-2 border-deep-purple-500 rounded-full absolute left-4 top-3.5"
                            : screen == 2 || 3
                            ? "inline-block w-2.5 h-2.5 bg-deep-purple-50 border-2 border-deep-purple-500 rounded-full absolute left-4 top-3.5"
                            : "inline-block w-2.5 h-2.5 bg-deep-purple-50 border-2 border-deep-purple-50 rounded-full absolute left-4 top-3.5"
                        }
                      ></span>{" "}
                      Background
                    </a>
                  </li>
                  <li className="border-b border-gray-300">
                    <a
                      href="#career"
                      className="block py-2.5 pl-9 pr-4 text-sm whitespace-nowrap relative"
                    >
                      <span
                        className={
                          screen == 2
                            ? "inline-block w-2.5 h-2.5 bg-deep-purple-500 border-2 border-deep-purple-500 rounded-full absolute left-4 top-3.5"
                            : screen == 3
                            ? "inline-block w-2.5 h-2.5 bg-deep-purple-50 border-2 border-deep-purple-500 rounded-full absolute left-4 top-3.5"
                            : "inline-block w-2.5 h-2.5 bg-deep-purple-50 border-2 border-deep-purple-50 rounded-full absolute left-4 top-3.5"
                        }
                      ></span>{" "}
                      Career Preferences
                    </a>
                  </li>
                  <li className="border-b border-gray-300">
                    <a
                      href="#finalBits"
                      className="block py-2.5 pl-9 pr-4 text-sm whitespace-nowrap relative"
                    >
                      <span
                        className={
                          screen == 3
                            ? "inline-block w-2.5 h-2.5 bg-deep-purple-500 border-2 border-deep-purple-500 rounded-full absolute left-4 top-3.5"
                            : "inline-block w-2.5 h-2.5 bg-deep-purple-50 border-2 border-deep-purple-50 rounded-full absolute left-4 top-3.5"
                        }
                      ></span>{" "}
                      Final Bits
                    </a>
                  </li>
                </ul>
              </nav>
              {screen != 4 && (
                <span className="flex items-center group bg-deep-purple-50 text-sm text-deep-purple-500 whitespace-nowrap rounded px-3 py-2 mb-3">
                  <svg
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    className="mr-2"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.63696 0.387939C3.32316 0.387939 0.636963 3.07414 0.636963 6.38794C0.636963 9.70174 3.32316 12.3879 6.63696 12.3879C9.95076 12.3879 12.637 9.70174 12.637 6.38794C12.637 3.07414 9.95076 0.387939 6.63696 0.387939ZM8.61276 9.21214L6.03696 6.63634V2.78794H7.23696V6.13954L9.46116 8.36374L8.61276 9.21214Z"
                      fill="#6936FF"
                    />
                  </svg>
                  {screen == 1
                    ? "Estimated: 3 mins left"
                    : screen == 2
                    ? "Estimated: 2 mins left"
                    : screen == 3
                    ? "Estimated: 1 mins left"
                    : null}
                </span>
              )}

              {screen == 4 ? (
                <span className="flex items-center group bg-deep-purple-50 text-sm text-deep-purple-500 whitespace-nowrap rounded px-3 py-2 mb-3">
                  <svg
                    width="17"
                    height="13"
                    viewBox="0 0 17 13"
                    className="mr-2"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.4888 2.40445L7.03875 11.8545C6.7755 12.1177 6.4299 12.25 6.0843 12.25C5.7387 12.25 5.3931 12.1177 5.12985 11.8545L1.07985 7.80445C0.552002 7.2766 0.552002 6.4234 1.07985 5.89555C1.6077 5.3677 2.4609 5.3677 2.98875 5.89555L6.0843 8.9911L14.5798 0.49555C15.1077 -0.0323 15.9609 -0.0323 16.4888 0.49555C17.0166 1.0234 17.0166 1.8766 16.4888 2.40445Z"
                      fill="#6936FF"
                    />
                  </svg>
                  Completed
                </span>
              ) : null}
              {screen == 3 && (
                <span className="text-sm text-gray-600 mb-3">*required</span>
              )}
            </aside>
            {/* //   <!-- Right Panel --> */}
            <div className="w-full md:w-2/4 sl-candidate">
              <section className="rounded-xl border border-gray-300 p-5">
                <form id="about">
                  {screen == 1 && (
                    <>
                      <h2 className="text-2xl md:text-3xl font-bold mb-2">
                        Your Background
                      </h2>
                      <hr className="my-4 -mx-5" />
                      <p className="text-base md:text-lg">
                        Please tell us a few things about yourself. Our Startup
                        Founders would love to know you.
                      </p>
                      <hr className="w-full my-5" />
                      <div className="flex space-x-3">
                        <div className="w-full md:w-1/2 mb-4">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 mb-1"
                            htmlFor="email"
                          >
                            First Name
                          </label>
                          <input
                            type="text"
                            id="firstName"
                            value={firstName}
                            onChange={(e) => {
                              const result =
                                e.target.value.charAt(0).toUpperCase() +
                                e.target.value.slice(1);
                              setFirstName(result);
                            }}
                            // "appearance-none w-full text-lg leading-6 bg-white font-normal text-gray-800 placeholder:text-gray-400 rounded-lg py-3 px-4 border border-red-300 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition duration-500"
                            placeholder="Jane"
                            className={
                              firstenameError != "" && firstName == ""
                                ? "appearance-none w-full text-lg leading-6 bg-white font-normal text-gray-800 placeholder:text-gray-400 rounded-lg py-3 px-4 border border-red-500 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition duration-500"
                                : "appearance-none block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 placeholder:text-gray-400 bg-white bg-clip-padding border border-gray-300 rounded-lg m-0 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition ease-in-out"
                            }
                          />
                          {firstenameError != "" && firstName == "" && (
                            <div className="inline-flex items-baseline mt-2">
                              <img className="self-center" src={Vector} />
                              <h5 className="errorClass">{firstenameError}</h5>
                            </div>
                          )}
                        </div>
                        <div className="w-full md:w-1/2 mb-4">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 mb-1"
                            htmlFor="password"
                          >
                            Last Name
                          </label>
                          <input
                            type="text"
                            id="lastName"
                            value={lastName}
                            onChange={(e) => {
                              setLastName(e.target.value);
                            }}
                            placeholder="Doe"
                            className={
                              lastnameError != "" && lastName == ""
                                ? "appearance-none w-full text-lg leading-6 bg-white font-normal text-gray-800 placeholder:text-gray-400 rounded-lg py-3 px-4 border border-red-500 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition duration-500"
                                : "appearance-none block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 placeholder:text-gray-400 bg-white bg-clip-padding border border-gray-300 rounded-lg m-0 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition ease-in-out"
                            }
                          />
                          {lastnameError != "" && lastName == "" && (
                            <div className="inline-flex items-baseline mt-2">
                              <img className="self-center" src={Vector} />
                              <h5 className="errorClass">{lastnameError}</h5>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="w-full mb-4">
                        <label
                          className="block text-base md:text-lg font-normal text-gray-1000 mb-1"
                          htmlFor="password"
                        >
                          Where do you <strong>live</strong>?
                        </label>
                        <GooglePlacesSelect value={location} onChange={location => setLocation(location)} placeholder={"Current Location"}/>
                        {locationError != "" && location == null && (
                          <div className="inline-flex items-baseline mt-2">
                            <img className="self-center" src={Vector} />
                            <h5 className="errorClass">{locationError}</h5>
                          </div>
                        )}
                      </div>
                      <div className="w-full mb-4">
                        <label
                          className="block text-base md:text-lg font-normal text-gray-1000 mb-1"
                          htmlFor="password"
                        >
                          What are you <strong>best at</strong>?
                        </label>
                        <Select
                          placeholder="Choose Expertise"
                          name="Expertise"
                          isClearable={true}
                          getOptionLabel={(item) => item?.currItem}
                          getOptionValue={(item) => item?.currItem}
                          value={expertise}
                          styles={
                            expertiseError != "" &&
                            expertise == null &&
                            customStyles
                          }
                          onChange={(item) => setExpertise(item)}
                          // onChange={(item) => {
                          //   handleExpertiseMap(item);
                          // }}
                          // value={ExpertiseMapStringresult.currItem}
                          options={ExpertiseMapStringresult}
                        />
                        {expertiseError != "" && expertise == null && (
                          <div className="inline-flex items-baseline mt-2">
                            <img className="self-center" src={Vector} />
                            <h5 className="errorClass">{expertiseError}</h5>
                          </div>
                        )}
                      </div>
                      <div className="w-full mb-4">
                        <label
                          className="block text-base md:text-lg font-normal text-gray-1000 mb-1"
                          htmlFor="password"
                        >
                          What was your most recent <b>job title?</b>
                        </label>
                        <input
                          type="text"
                          id="role"
                          value={role}
                          onChange={(e) => {
                            setRole(e.target.value);
                          }}
                          placeholder="Chief Product Officer"
                          className={
                            roleError != "" && role == ""
                              ? "appearance-none w-full text-lg leading-6 bg-white font-normal text-gray-800 placeholder:text-gray-400 rounded-lg py-3 px-4 border border-red-500 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition duration-500"
                              : "appearance-none block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 placeholder:text-gray-400 bg-white bg-clip-padding border border-gray-300 rounded-lg m-0 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition ease-in-out"
                          }
                        />
                        {roleError != "" && role == "" && (
                          <div className="inline-flex items-baseline mt-2">
                            <img className="self-center" src={Vector} />
                            <h5 className="errorClass">{roleError}</h5>
                          </div>
                        )}
                      </div>
                      <div className="w-full mb-4">
                        <label
                          className="block text-base md:text-lg font-normal text-gray-1000 mb-1"
                          htmlFor="password"
                        >
                          Who did you last <strong>work for</strong>?
                        </label>
                        <input
                          type="text"
                          id="employer"
                          placeholder="Acme LTD"
                          value={workFor}
                          onChange={(e) => {
                            setWorkFor(e.target.value);
                          }}
                          className={
                            workForError != "" && workFor == ""
                              ? "appearance-none w-full text-lg leading-6 bg-white font-normal text-gray-800 placeholder:text-gray-400 rounded-lg py-3 px-4 border border-red-500 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition duration-500"
                              : "appearance-none block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 placeholder:text-gray-400 bg-white bg-clip-padding border border-gray-300 rounded-lg m-0 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition ease-in-out"
                          }
                        />
                        {workForError != "" && workFor == "" && (
                          <div className="inline-flex items-baseline mt-2">
                            <img className="self-center" src={Vector} />
                            <h5 className="errorClass">{workForError}</h5>
                          </div>
                        )}
                      </div>
                      <div className="w-full mb-4 ">
                      <label
                          className="block text-base md:text-lg font-normal text-gray-1000 mb-3"
                          htmlFor="password"
                        >
                          What <strong>kinds of businesses </strong>have you worked for ?
                        </label>
                        <ul className="w-full flex flex-wrap items-start o-preferred-candidate">
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="b2b"
                              type="checkbox"
                              value={"B2B"}
                              checked={marketPlaces.includes(
                                "B2B"
                              )}
                              onChange={(e) => handleMarketPlace(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="b2b"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              B2B
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="b2g"
                              type="checkbox"
                              value={"B2G"}
                              checked={marketPlaces.includes("B2G")}
                              onChange={(e) => handleMarketPlace(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="b2g"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              B2G
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="b2c"
                              type="checkbox"
                              value={"B2C"}
                              checked={marketPlaces.includes("B2C")}
                              onChange={(e) => handleMarketPlace(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="b2c"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              B2C
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="Marketplace"
                              type="checkbox"
                              value={"Marketplace"}
                              checked={marketPlaces.includes("Marketplace")}
                              onChange={(e) => handleMarketPlace(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="Marketplace"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Marketplace
                            </label>
                          </li>
                          </ul>
                          {marketPlacesError != "" && marketPlaces.length == 0 && (
                          <div className="inline-flex items-baseline mt-2">
                            <img className="self-center" src={Vector} />
                            <h5 className="errorClass">{marketPlacesError}</h5>
                          </div>
                        )}
                      </div>
                      <div className="w-full mb-4">
                        <label
                          className="block text-base md:text-lg font-normal text-gray-1000 mb-1"
                          htmlFor="password"
                        >
                          Have you ever been on a{" "}
                          <strong>board of directors</strong>?
                        </label>
                        <div className="flex space-x-3">
                          <div className="w-1/2 relative">
                            <input
                              type="radio"
                              id="radio-1"
                              checked={boardDirector.includes("Yes")}
                              value="Yes"
                              onClick={(e) => {
                                setBoardDirector(e.target.value);
                              }}
                              name="startupExperience"
                              className="form-radio-input block w-full h-full opacity-0 absolute inset-0 cursor-pointer"
                            />
                            <label
                              htmlFor="radio-1"
                              className="block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 text-center bg-white bg-clip-padding border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-deep-purple-500 transition ease-in-out"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="w-1/2 relative">
                            <input
                              type="radio"
                              id="radio-2"
                              checked={boardDirector.includes("No")}
                              value="No"
                              onClick={(e) => {
                                setBoardDirector(e.target.value);
                              }}
                              name="startupExperience"
                              className="form-radio-input block w-full h-full opacity-0 absolute inset-0 cursor-pointer"
                            />
                            <label
                              htmlFor="radio-2"
                              className="block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 text-center bg-white bg-clip-padding border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-deep-purple-500 transition ease-in-out"
                            >
                              No
                            </label>
                          </div>
                        </div>
                        {boardDirectorError != "" && boardDirector == "" && (
                          <div className="inline-flex items-baseline mt-2">
                            <img className="self-center" src={Vector} />
                            <h5 className="errorClass">{boardDirectorError}</h5>
                          </div>
                        )}
                      </div>
                      <div className="w-full mb-4">
                        <label
                          className="block text-base md:text-lg font-normal text-gray-1000 mb-1"
                          htmlFor="url"
                        >
                          What’s your LinkedIn <strong>profile</strong>?
                        </label>
                        <input
                          type="url"
                          id="linkedin"
                          value={linkedIn}
                          autoComplete="off"
                          onChange={(e) => {
                            setLinkedIn(e.target.value);
                          }}
                          placeholder="www.linkedin.com/in/..."
                          className={
                            linkedInError != "" && linkedIn == ""
                              ? "appearance-none w-full text-lg leading-6 bg-white font-normal text-gray-800 placeholder:text-gray-400 rounded-lg py-3 px-4 border border-red-500 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition duration-500"
                              : "appearance-none block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 placeholder:text-gray-400 bg-white bg-clip-padding border border-gray-300 rounded-lg m-0 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition ease-in-out"
                          }
                        />
                        {linkedInError != "" && linkedIn == "" && (
                          <div className="inline-flex items-baseline mt-2">
                            <img className="self-center" src={Vector} />
                            <h5 className="errorClass">{linkedInError}</h5>
                          </div>
                        )}
                      </div>
                      <div className="w-full mb-4">
                        <label
                          className="block text-base md:text-lg font-normal text-gray-1000 mb-1"
                          htmlFor="password"
                        >
                          and your <strong>email address</strong>?
                        </label>
                        <input
                          type="text"
                          id="linkedin"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                          placeholder="me@email.com"
                          className={
                            email != "" &&  !email.includes("@") || emailError != "" && email == ""
                              ? "appearance-none w-full text-lg leading-6 bg-white font-normal text-gray-800 placeholder:text-gray-400 rounded-lg py-3 px-4 border border-red-500 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition duration-500"
                              : "appearance-none block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 placeholder:text-gray-400 bg-white bg-clip-padding border border-gray-300 rounded-lg m-0 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition ease-in-out"
                          }
                        />
                        {email != "" && emailError != "" &&  !email.includes("@") || emailError != "" && email == "" ? (
                          <div className="inline-flex items-baseline mt-2">
                            <img className="self-center" src={Vector} />
                            <h5 className="errorClass">{emailError}</h5>
                          </div>
                        ) : null}
                      </div>
                    </>
                  )}
                  {screen == 2 && (
                    <>
                      <h2 className="text-2xl md:text-3xl font-bold">
                        Career Preferences
                      </h2>
                      <hr className="my-4 -mx-5" />
                      <div className="w-full mb-4">
                        <label
                          className="block text-base md:text-lg font-normal text-gray-1000 mb-1"
                          htmlFor="password"
                        >
                          What’s your <strong>target career level</strong>?
                        </label>
                        <Select
                          placeholder="Choose a Seniority Level"
                          name="Seniority"
                          isClearable={true}
                          getOptionLabel={(item) => item?.currItem}
                          getOptionValue={(item) => item?.currItem}
                          value={career}
                          onChange={(item) => setCareer(item)}
                          styles={
                            careerError != "" && career == null && customStyles
                          }
                          // onChange={(item) => {
                          //   handleLevelMap(item);
                          // }}
                          // value={uniqueLevelresult.currItem}
                          options={uniqueLevelresult}
                        />
                        {careerError != "" && career == null && (
                          <div className="inline-flex items-baseline mt-2">
                            <img className="self-center" src={Vector} />
                            <h5 className="errorClass">{careerError}</h5>
                          </div>
                        )}
                      </div>
                      <div className="w-full mb-4">
                        <label
                          className="block text-base md:text-lg font-normal text-gray-1000 mb-3"
                          htmlFor="password"
                        >
                          What <strong>type of work</strong> would you like to
                          do?
                        </label>
                        <ul className="">
                          <li className="flex mb-3">
                            <input
                              id="full_time"
                              type="checkbox"
                              value={"Full Time"}
                              checked={typeOfwork.includes("Full Time")}
                              onChange={(e) => handleCheckboxChange(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="full_time"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Full Time
                            </label>
                          </li>
                          <li className="flex mb-3">
                            <input
                              id="part_time"
                              type="checkbox"
                              checked={typeOfwork.includes("Part Time")}
                              value={"Part Time"}
                              onChange={(e) => handleCheckboxChange(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="part_time"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Part Time
                            </label>
                          </li>
                          <li className="flex mb-3">
                            <input
                              id="project_based_consultancy"
                              type="checkbox"
                              value={"Project Based / Consultancy"}
                              checked={typeOfwork.includes(
                                "Project Based / Consultancy"
                              )}
                              onChange={(e) => handleCheckboxChange(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="project_based_consultancy"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Project Based / Consultancy
                            </label>
                          </li>
                          {typeOfworkError != "" && typeOfwork.length == 0 && (
                            <div className="inline-flex items-baseline mt-2">
                              <img className="self-center" src={Vector} />
                              <h5 className="errorClass">{typeOfworkError}</h5>
                            </div>
                          )}
                        </ul>
                      </div>
                      <div className="w-full mb-4">
                        <label
                          className="block text-base md:text-lg font-normal text-gray-1000 mb-3"
                          htmlFor="password"
                        >
                          Are you open to <strong>remote work</strong>?
                        </label>
                        <div className="flex space-x-3">
                          <div className="w-1/2 relative">
                            <input
                              checked={experience.includes("True")}
                              type="radio"
                              id="radio-1"
                              name="startupExperience"
                              value="True"
                              onClick={(e) => {
                                setExperience(e.target.value);
                              }}
                              className="form-radio-input block w-full h-full opacity-0 absolute inset-0 cursor-pointer"
                            />
                            <label
                              htmlFor="radio-1"
                              className="block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 text-center bg-white bg-clip-padding border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-deep-purple-500 transition ease-in-out"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="w-1/2 relative">
                            <input
                              type="radio"
                              checked={experience.includes("False")}
                              id="radio-2"
                              name="startupExperience"
                              value="False"
                              onClick={(e) => {
                                setExperience(e.target.value);
                              }}
                              className="form-radio-input block w-full h-full opacity-0 absolute inset-0 cursor-pointer"
                            />
                            <label
                              htmlFor="radio-2"
                              className="block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 text-center bg-white bg-clip-padding border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-deep-purple-500 transition ease-in-out"
                            >
                              No
                            </label>
                          </div>
                        </div>
                        {experienceError != "" && experience == "" && (
                          <div className="inline-flex items-baseline mt-2">
                            <img className="self-center" src={Vector} />
                            <h5 className="errorClass">{experienceError}</h5>
                          </div>
                        )}
                      </div>
                      <div className="w-full mb-4">
                        <label
                          className="block text-base md:text-lg font-normal text-gray-1000 mb-3"
                          htmlFor="password"
                        >
                          What <strong>stage</strong> should the business be at?
                        </label>
                        <ul className="w-full flex flex-wrap items-start o-preferred-candidate">
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="pre-Seed"
                              type="checkbox"
                              value={"Pre-Seed"}
                              checked={stage.includes(
                                "Pre-Seed"
                              )}
                              onChange={(e) => handleStagesArray(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="pre-Seed"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Pre-Seed
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="series C"
                              type="checkbox"
                              value={"Series C"}
                              checked={stage.includes(
                                "Series C"
                              )}
                              onChange={(e) => handleStagesArray(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="series C"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Series C
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="seed"
                              type="checkbox"
                              value={"Seed"}
                              checked={stage.includes(
                                "Seed"
                              )}
                              onChange={(e) => handleStagesArray(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="seed"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Seed
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="series D"
                              type="checkbox"
                              value={"Series D"}
                              checked={stage.includes(
                                "Series D"
                              )}
                              onChange={(e) => handleStagesArray(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="series D"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Series D
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="series A"
                              type="checkbox"
                              value={"Series A"}
                              checked={stage.includes(
                                "Series A"
                              )}
                              onChange={(e) => handleStagesArray(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="series A"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Series A
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="series E"
                              type="checkbox"
                              value={"Series E"}
                              checked={stage.includes(
                                "Series E"
                              )}
                              onChange={(e) => handleStagesArray(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="series E"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Series E
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="series B"
                              type="checkbox"
                              value={"Series B"}
                              checked={stage.includes(
                                "Series B"
                              )}
                              onChange={(e) => handleStagesArray(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="series B"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Series B
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="pre-IPO"
                              type="checkbox"
                              value={"Pre-IPO"}
                              checked={stage.includes(
                                "Pre-IPO"
                              )}
                              onChange={(e) => handleStagesArray(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="pre-IPO"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Pre-IPO
                            </label>
                          </li>
                          </ul>
                        {/* <Select
                          placeholder="Choose Stage(s)"
                          name="Stage"
                          isMulti
                          isClearable={true}
                          getOptionLabel={(item) => item?.currItem}
                          getOptionValue={(item) => item?.currItem}
                          styles={
                            stageError != "" &&
                            stage.length == 0 &&
                            customStyles
                          }
                          onChange={(item) => {
                            setStage(item);
                          }}
                          value={stage}
                          options={stagesArray}
                        /> */}
                        {stageError != "" && stage.length == 0 && (
                          <div className="inline-flex items-baseline mt-2">
                            <img className="self-center" src={Vector} />
                            <h5 className="errorClass">{stageError}</h5>
                          </div>
                        )}
                      </div>
                      <div className="w-full mb-4 ">
                        <label
                          className="block text-base md:text-lg font-normal text-gray-1000 mb-3"
                          htmlFor="password"
                        >
                          Any <strong>preferred industries</strong>?
                        </label>
                        <div className="flex mb-5">
                          <input
                            id="noIndustryPreference"
                            type="checkbox"
                            value={"No Industry Preference"}
                            checked={industries.includes(
                              "No Industry Preference"
                            )}
                            onChange={(e) => handleIndustries(e)}
                            className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                          />
                          <label
                            htmlFor="noIndustryPreference"
                            className="text-base md:text-lg font-normal text-gray-1000 place-self-center block w-full border-b border-gray-300 pb-5"
                          >
                            No Industry Preference
                          </label>
                        </div>
                        <ul className="w-full flex flex-wrap items-start o-preferred-candidate">
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="artificialIntelligence"
                              type="checkbox"
                              value={"Artificial Intelligence"}
                              checked={industries.includes(
                                "Artificial Intelligence"
                              )}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="artificialIntelligence"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Artificial Intelligence
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="automotive"
                              type="checkbox"
                              value={"Automotive"}
                              checked={industries.includes(
                                "Automotive"
                              )}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="Automotive"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Automotive
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="blockchain"
                              type="checkbox"
                              value={"Blockchain"}
                              checked={industries.includes("Blockchain")}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="blockchain"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Blockchain
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="b2bServices"
                              type="checkbox"
                              value={"B2B Services"}
                              checked={industries.includes("B2B Services")}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="b2bServices"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              B2B Services
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="crypto"
                              type="checkbox"
                              value={"Crypto"}
                              checked={industries.includes("Crypto")}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="crypto"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Crypto
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="consumer"
                              type="checkbox"
                              value={"Consumer"}
                              checked={industries.includes("Consumer")}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="consumer"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Consumer
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="deepTech"
                              type="checkbox"
                              value={"Deep Tech"}
                              checked={industries.includes("Deep Tech")}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="deepTech"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Deep Tech
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="dataAnalytics"
                              type="checkbox"
                              value={"Data Analytics"}
                              checked={industries.includes("Data Analytics")}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="dataAnalytics"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Data Analytics
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="eCommerce"
                              type="checkbox"
                              value={"eCommerce"}
                              checked={industries.includes("eCommerce")}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="eCommerce"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              eCommerce
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="ClimateTech"
                              type="checkbox"
                              value={"Sustainability"}
                              checked={industries.includes("Sustainability")}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="ClimateTech"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Sustainability
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="edTech"
                              type="checkbox"
                              value={"EdTech"}
                              checked={industries.includes("EdTech")}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="edTech"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              EdTech
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="enterpriseSoftware"
                              type="checkbox"
                              value={"Enterprise Software"}
                              checked={industries.includes(
                                "Enterprise Software"
                              )}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="enterpriseSoftware"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Enterprise Software
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="femTech"
                              type="checkbox"
                              value={"FemTech"}
                              checked={industries.includes("FemTech")}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="femTech"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              FemTech
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="finTech"
                              type="checkbox"
                              value={"FinTech"}
                              checked={industries.includes("FinTech")}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="finTech"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              FinTech
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="healthcare"
                              type="checkbox"
                              value={"Healthcare"}
                              checked={industries.includes("Healthcare")}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="healthcare"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Healthcare
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="insurTech"
                              type="checkbox"
                              value={"InsurTech"}
                              checked={industries.includes("InsurTech")}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="insurTech"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              InsurTech
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="marketplace"
                              type="checkbox"
                              value={"Marketplace"}
                              checked={industries.includes("Marketplace")}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="marketplace"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Marketplace
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="payments"
                              type="checkbox"
                              value={"Payments"}
                              checked={industries.includes("Payments")}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="payments"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Payments
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="robotics"
                              type="checkbox"
                              value={"Robotics"}
                              checked={industries.includes("Robotics")}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="robotics"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Robotics
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="retail"
                              type="checkbox"
                              value={"Retail"}
                              checked={industries.includes("Retail")}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="retail"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Retail
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="saas"
                              type="checkbox"
                              value={"SaaS"}
                              checked={industries.includes("SaaS")}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="saas"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              SaaS
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="telecom"
                              type="checkbox"
                              value={"Telecom"}
                              checked={industries.includes("Telecom")}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="telecom"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Telecom
                            </label>
                          </li>
                        </ul>
                        {industriesError != "" && industries.length == 0 && (
                          <div className="inline-flex items-baseline mt-2">
                            <img className="self-center" src={Vector} />
                            <h5 className="errorClass">{industriesError}</h5>
                          </div>
                        )}
                      </div>
                      <div className="w-full mb-4">
                        <label
                          className="block text-base md:text-lg font-normal text-gray-1000 mb-1"
                          htmlFor="password"
                        >
                          Where would you like to <strong>work</strong>?
                        </label>
                        <Select
                          placeholder="Choose Location(s)"
                          name="Location"
                          isClearable={true}
                          isMulti
                          getOptionLabel={(item) => item?.currItem}
                          getOptionValue={(item) => item?.currItem}
                          onChange={(item) => {
                            setLikeToWork(item);
                          }}
                          styles={
                            likeToWorkError != "" &&
                            likeToWork == "" &&
                            customStyles
                          }
                          value={likeToWork}
                          options={UniDesiredLocStringresult}
                        />
                        {likeToWorkError != "" && likeToWork == "" && (
                          <div className="inline-flex items-baseline mt-2">
                            <img className="self-center" src={Vector} />
                            <h5 className="errorClass">{likeToWorkError}</h5>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  {screen == 3 && (
                    <>
                      <h2 className="text-2xl md:text-3xl font-bold">
                        Final Bits
                      </h2>
                      <hr className="my-4 -mx-5" />
                      <div className="w-full mb-4">
                        <label
                          className="block text-base md:text-lg font-normal text-gray-1000 mb-1"
                          for="password"
                        >
                          Anything else you’d like{" "}
                          <strong>employers to know?</strong>
                        </label>
                        <p className="text-sm md:text-base text-gray-800 mb-2">
                          You can use this section to give us more details about
                          your strengths, experience, and/or the types of roles
                          you’re interested in. Employers will see this
                          alongside your career preferences.
                        </p>
                        <textarea
                          id="textarea"
                          placeholder={`I have extensive experience scaling B2B global businesses. I have exited one business and scaled another to $100m in annual revenue.
                                                                           My background includes building and leading high performing teams and accelerating business performance.
                                                                           I’m looking for US based COO roles at growth companies. Must have dogs. 🐶`}
                          rows="11"
                          onChange={(e) => handleCharCount(e)}
                          maxlength="600"
                          className="appearance-none block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 placeholder:text-gray-400 bg-white bg-clip-padding border border-gray-300 rounded-lg m-0 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition ease-in-out"
                        >
                          {textArea}
                        </textarea>
                        <div className="mainCharDiv">
                          {CharCount >= 600 && (
                            <p className="char_text">
                              You have reached the character limit.
                            </p>
                          )}
                          <h5 className="char_count">{CharCount}/600</h5>
                        </div>
                      </div>
                      <div className="w-full mb-4">
                        <div className="flex space-x-3">
                          <div className="w-full md:w-1/2 mb-4">
                            <label
                              className="block text-base md:text-lg font-normal text-gray-1000 mb-1"
                              for="email"
                            >
                              Gender Identity*
                            </label>
                            <Select
                              placeholder="Choose"
                              name="Gender"
                              isClearable={true}
                              getOptionLabel={(item) => item?.currItem}
                              getOptionValue={(item) => item?.currItem}
                              onChange={(item) => {
                                setGender(item);
                              }}
                              styles={
                                genderError != "" &&
                                gender == null &&
                                customStyles
                              }
                              value={gender}
                              options={GenderArray}
                            />
                          </div>
                          <div className="w-full md:w-1/2 mb-4">
                            <label
                              className="block text-base md:text-lg font-normal text-gray-1000 mb-1"
                              for="password"
                            >
                              Ethnicity*
                            </label>
                            <Select
                              placeholder="Choose"
                              name="Ethnicity"
                              isClearable={true}
                              getOptionLabel={(item) => item?.currItem}
                              getOptionValue={(item) => item?.currItem}
                              styles={
                                EthnicityError != "" &&
                                Ethnicity == null &&
                                customStyles
                              }
                              onChange={(item) => {
                                setEthnicity(item);
                              }}
                              value={Ethnicity}
                              options={EthencityArray}
                            />
                          </div>
                        </div>
                        {genderError != "" && gender == null && (
                          <div className="inline-flex items-baseline mt-2">
                            <img className="self-center" src={Vector} />
                            <h5 className="errorClass">{genderError}</h5>
                          </div>
                        )}
                        {EthnicityError != "" && Ethnicity == null && (
                          <div className="inline-flex items-baseline mt-2">
                            <img className="self-center" src={Vector} />
                            <h5 className="errorClass">{EthnicityError}</h5>
                          </div>
                        )}
                      </div>
                      <div className="w-full mb-4">
                        <label
                          className="block text-sm md:text-base font-bold text-gray-800 mb-1"
                          for="password"
                        >
                          Why is this important?
                        </label>
                        <p className="text-sm md:text-base text-gray-800 mb-2">
                          We want to build more inclusive teams, starting at the
                          top. To reach our goal, we need to understand a bit
                          more about who you are so we know how we’re doing.This
                          data is just for us and won’t be shared with employers
                          (or anyone else). Thank you for supporting us in this
                          goal. 🙂🙏
                        </p>
                      </div>
                      <div className="w-full mb-4">
                        <label
                          className="block text-base md:text-lg font-normal text-gray-1000 mb-1"
                          for="password"
                        >
                          We’d love a copy of <strong>your CV</strong> if you
                          have one
                        </label>
                        <div className="block relative flex justify-center items-center group w-full bg-deep-purple-50 hover:bg-deep-purple-100 text-deep-purple-500 text-lg font-medium rounded-lg cursor-pointer transition duration-200 py-3 px-4">
                          <input
                            type="file"
                            id="file"
                            accept=".pdf"
                            size="10"
                            placeholder="Other information"
                            onChange={(e) => handleFileInputChange(e)}
                            className="appearance-none absolute inset-0 opacity-0 z-10 cursor-pointer	w-full"
                          />
                          <svg
                            width="16"
                            height="18"
                            viewBox="0 0 16 18"
                            className="mr-2"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.3727 0.280762C10.3032 0.280762 9.23467 0.704491 8.43348 1.54975L2.62024 7.68202C0.446656 9.97575 0.446656 13.6629 2.62024 15.9567C2.68724 16.0273 2.76777 16.0837 2.85702 16.1226L2.86127 16.124C5.05119 18.1992 8.46713 18.176 10.5702 15.9567L14.6593 11.6421C14.7286 11.5737 14.7833 11.492 14.8203 11.402C14.8574 11.312 14.8759 11.2154 14.8749 11.1181C14.8739 11.0208 14.8533 10.9246 14.8143 10.8354C14.7754 10.7462 14.7189 10.6657 14.6483 10.5988C14.5776 10.5318 14.4942 10.4798 14.403 10.4457C14.3118 10.4116 14.2147 10.3962 14.1175 10.4005C14.0202 10.4047 13.9248 10.4284 13.8369 10.4702C13.749 10.5121 13.6705 10.5711 13.6059 10.6439L9.51673 14.9585C7.88206 16.6835 5.3098 16.6835 3.67513 14.9585C2.04039 13.2334 2.04039 10.4053 3.67513 8.68019L9.48696 2.54793C10.5503 1.42613 12.1966 1.42613 13.2599 2.54793C14.3231 3.66962 14.3231 5.52104 13.2599 6.64272L7.96984 12.2249C7.477 12.7445 6.75758 12.7441 6.26557 12.2249C5.77355 11.7056 5.77355 10.8314 6.26557 10.3122L10.5206 5.82178C10.5898 5.75335 10.6446 5.67169 10.6816 5.58167C10.7186 5.49165 10.7372 5.3951 10.7361 5.29777C10.7351 5.20043 10.7145 5.1043 10.6756 5.01509C10.6367 4.92587 10.5802 4.8454 10.5095 4.77845C10.4389 4.7115 10.3555 4.65944 10.2643 4.62537C10.1731 4.5913 10.076 4.57591 9.97874 4.58013C9.8815 4.58434 9.78609 4.60806 9.69819 4.64989C9.6103 4.69172 9.53171 4.75079 9.46711 4.8236L5.21068 9.31398C4.17966 10.402 4.17966 12.135 5.21068 13.223C5.27949 13.2955 5.36252 13.3529 5.45455 13.3918C6.50038 14.2654 8.06267 14.2358 9.02332 13.223L14.3134 7.6409C15.8497 6.02002 15.8718 3.48337 14.4622 1.78512C14.4283 1.69779 14.3777 1.61786 14.3134 1.54975C13.5122 0.704491 12.4423 0.280762 11.3727 0.280762Z"
                              fill="#6936FF"
                            />
                          </svg>
                          {file?.file?.name
                            ? file?.file?.name
                            : "Choose PDF file"}
                        </div>
                        {file?.file?.type != "application/pdf" && file && (
                          <p className="text-sm md:text-base text-red-800 mb-2">
                            *Please select PDF format of your CV only
                          </p>
                        )}
                      </div>
                      <div className="w-full mb-4">
                        <label
                          className="block text-base md:text-lg font-normal text-gray-1000 mb-2"
                          for="password"
                        >
                          Terms and conditions*
                        </label>
                        <div className="flex mb-3">
                          <input
                            id="checked-checkbox-term"
                            type="checkbox"
                            checked={terms}
                            onChange={(e) => handleTerm(e)}
                            className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                          />
                          <label
                            for="checked-checkbox-term"
                            className="checked-unstyled text-sm md:text-base font-normal text-gray-800 leading-tight place-self-start"
                          >
                            I hereby grant{" "}
                            {OrgName == "OctopusVentures"
                              ? "Octopus Investments Limited"
                              : "Startup Lane Ltd"}{" "}
                            consent to collect data for my candidate profile and
                            use it for this purpose only. For further
                            information on how we collect, manage and store your
                            personal data please read our{" "}
                            <a
                              className="text-deep-purple-500"
                              href={"javascript:void(0)"}
                              onClick={handlePrivacyPolicy}
                              // href={
                              //   OrgName == "OctopusVentures"
                              //     ? "https://octopusventures.com/privacy-policy/"
                              //     : "https://www.startuplane.io/legal/privacy"
                              // }
                            >
                              Privacy Policy
                            </a>
                          </label>
                        </div>
                        {TNCError != "" && terms == false && (
                          <div className="inline-flex items-baseline mt-2">
                            <img className="self-center" src={Vector} />
                            <h5 className="errorClass">{TNCError}</h5>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  {screen == 4 && (
                    <>
                      {/* Thank you Start */}
                      <h2 className="text-2xl md:text-3xl font-bold mb-2">
                        Nicely Done 🎉
                      </h2>
                      <hr className="my-4 -mx-5" />
                      <p className="text-base md:text-lg mb-4">
                        Thank you for submitting the form!
                      </p>
                      <p className="text-base md:text-lg mb-4">
                        Your profile has been shared with our companies. They
                        will reach out to you directly with suitable
                        opportunities based on your experience and preferences.
                      </p>
                      <p className="text-base md:text-lg mb-4">Good luck!</p>
                      {OrgName == "OctopusVentures" ? (
                        <img
                          className="w-60 h-60 rounded-full mx-auto"
                          src="https://i.giphy.com/media/WwMHQqMG0yirepFPP8/giphy.webp"
                          alt="Octopus Ventures"
                          width="48"
                          height="48"
                        />
                      ) : (
                        <img
                          className="w-full"
                          src="https://i.giphy.com/media/l41YmQjOz9qg2Ecow/giphy.webp"
                          alt="Octopus Ventures"
                          width="48"
                          height="48"
                        />
                      )}

                      {/* Thank End */}
                    </>
                  )}
                  {/* {Pagedisplay()} */}
                  {screen != 3 && screen != 4 ? (
                    <>
                      <button
                        className="w-full bg-deep-purple-500 hover:bg-deep-purple-400 text-white text-lg font-medium rounded-lg hover:shadow-md transition duration-200 py-3 px-4 mb-4"
                        onClick={(e) => handleClickNext(e)}
                      >
                        Next
                      </button>
                      <ToastContainer />
                    </>
                  ) : null}
                  {screen == 3 && !Loading ? (
                    <>
                      <button
                        className="w-full bg-deep-purple-500 hover:bg-deep-purple-400 text-white text-lg font-medium rounded-lg  hover:shadow-md transition duration-200 py-3 px-4 mb-4"
                        type="submit"
                        onClick={(e) => handleFormSubmit(e)}
                        // disabled={file?.file?.type != "application/pdf"}
                      >
                        Submit
                      </button>
                      <ToastContainer />
                    </>
                  ) : screen == 3 && Loading ? (
                    <button
                      type="button"
                      className="w-full flex items-center justify-center bg-deep-purple-500 hover:bg-deep-purple-400 text-white text-lg font-medium rounded-lg hover:shadow-md transition duration-200 py-3 px-4 mb-4"
                      disabled
                    >
                      <span
                        className="animate-spin inline-block w-8 h-8 mr-3 border-2 border-white border-l-transparent rounded-full"
                        role="status"
                      ></span>
                      Loading...
                    </button>
                  ) : null}
                  {/* {screen != 3 ? (
                  <>
                  <button
                    className="w-full bg-deep-purple-500 hover:bg-deep-purple-400 text-white text-lg font-medium rounded-lg hover:shadow-md transition duration-200 py-3 px-4 mb-4"
                    onClick={(e) => handleClickNext(e)}
                  >
                    Next
                  </button>
                  <ToastContainer />
                  </>
                ) :
                Loading ? (
                  <button
              type="button"
              className="w-full flex items-center justify-center bg-deep-purple-500 hover:bg-deep-purple-400 text-white text-lg font-medium rounded-lg hover:shadow-md transition duration-200 py-3 px-4 mb-4"
              disabled
            >
              <span
                className="animate-spin inline-block w-8 h-8 mr-3 border-2 border-white border-l-transparent rounded-full"
                role="status"
              ></span>
              Loading...
            </button>
                ) : 
                 (
                  <>
                  <button
                    className="w-full bg-deep-purple-500 hover:bg-deep-purple-400 text-white text-lg font-medium rounded-lg  hover:shadow-md transition duration-200 py-3 px-4 mb-4"
                    type="submit"
                    onClick={(e) => handleFormSubmit(e)}
                  >
                    Submit
                  </button>
                  <ToastContainer />
                  </>
               
                )} */}

                  {screen > 1 && screen != 4 && (
                    <button
                      onClick={(e) => handleClickPrev(e)}
                      className="w-full bg-deep-purple-50 hover:bg-deep-purple-100 text-deep-purple-500 text-lg font-medium rounded-lg transition duration-200 py-3 px-4 "
                      type="submit"
                    >
                      Back
                    </button>
                  )}
                </form>
              </section>
            </div>
          </section>
        </main>
      </div>
    </>
  );
};

const mapStateToProps = ({ authentication }) => ({
  authentication_state: authentication,
});

export default connect(mapStateToProps)(Form);
