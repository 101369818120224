import axios from "axios";
import {useEffect, useState} from "react";

const fullProfileCache = {};

export function getFullProfile(mongoId) {
    if (fullProfileCache[mongoId]) {
        return fullProfileCache[mongoId];
    }

    const container = {
        isLoading: true,
        isError: false,
        promise: null,
        data: null,
    }

    container.promise = axios
        .get(`https://startuplaneqa.onrender.com/profile/${mongoId}`, {
            timeout: 5000,
        })
        .then((response) => {
            const data = response.data;

            container.isLoading = false;
            container.data = data;

            return data;
        })
        .catch(() => {
            container.isLoading = false;
            container.isError = true;
        });

    fullProfileCache[mongoId] = container;

    return container;
}

export function useGetFullProfile(mongoId) {
    const [state, setState] = useState(getFullProfile(mongoId));

    useEffect(() => {
        if (!state.isLoading) {
            return;
        }

        const intervalId = setInterval(() => {
            const data = getFullProfile(mongoId);

            if (!data.isLoading) {
                setState({...data});

                clearInterval(intervalId);
            }
        }, 1500);

        return () => clearInterval(intervalId);
    }, []);

    return state;
}
