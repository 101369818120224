import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import "../../../Login/index.css";
import { CSVLink } from "react-csv";
import DataTable from "react-data-table-component";
import { getCookie, setCookie } from "../../../../shared/utils/cookies";
import { PropTypes } from "prop-types";
import { getCompanyData } from "../../../authentication";
import {
  getLogOut,
} from "../../../authentication";
import { Helmet } from "react-helmet";
import moment from "moment";
import axios from "axios";
import PageHeaders from "./PageHeaders";

const TeamMemberTable = ({ authentication_state, UserLogout, }) => {
  const authentication_ref = useRef(authentication_state);
  authentication_ref.current = authentication_state;
  const menuRefs = useRef();
  const RoleName =
  authentication_ref?.current?.Roles_data?.response?.data?.data?.rolename;
  const CompanyName = authentication_ref?.current?.port_data?.response?.data?.data[0] || []
  const [search, setSearch] = useState("");
  const Name = localStorage.getItem('companyName');
  const storedCompanyName = localStorage.getItem('companyName')
  const UserID = localStorage.getItem("usersIdentity");
  const [cardData, setCardData] = useState([]);
  const [profileIcon, setProfileIcon] = useState(false);
  const [addTeamMemberData, setAddTeamMemberData] = useState([])
  const [FullData, setFullData] = useState([])
  const [VentureData, setVentureData] = useState()

  useEffect(() => {
    const headerData = {
      headers: {
        "Content-Type": "application/json",
      },
    };
      axios
      .get(
        `/api/venturecapitalforms/venturecapitalforms/get/${UserID}/`,
        headerData
      ).then((res) => {
        setVentureData(res?.data?.data)
      })
  }, [])

  useEffect(() => {
    const result = FullData.filter((candidate) => {
      return(
        candidate.firstname.toLocaleLowerCase().match(search.toLocaleLowerCase()) || 
        candidate.lastname.toLocaleLowerCase().match(search.toLocaleLowerCase())
      )
    });
    setAddTeamMemberData(result);
  }, [search]);

  useEffect(() => {
    let handler = (event) => {
      if (!menuRefs?.current?.contains(event.target)) {
        setProfileIcon(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);



  useEffect(() => {
    // const CompanyName_Venture = authentication_ref?.current?.port_data?.response?.data?.data[0] || [];
    const Name = localStorage.getItem('companyName');
    if(Name){
        axios({
            method: "get",
            url: `/api/venture/venture/getall/${Name}`,
            headers: {
              "Content-Type":
                "multipart/form-data; boundary=<calculated when request is sent>",
            },
          }).then((res) => {
            if(res.status == 200){
                setAddTeamMemberData(res.data.data)
                setFullData(res.data.data)
            }
          })
    }
  },[])

  const headers = [
    {
      label: "First Name",
      key: "firstname",
    },
    {
      label: "Last Name",
      key: "lastname",
    },
    {
      label: "Role",
      key: "current_role",
    },
    {
      label: "Email",
      key: "email",
    },
    {
      label: "Date Joined",
      key: "created_at",
    },
  ];

  const csvLink = {
    filename: "TeamMembers.csv",
    headers: headers,
    data: cardData,
  };

  const handleRowData = (state) => {
    setCardData(state.selectedRows);
  };

  const CustomTitleName = ({ row }) => (
    <div>
      {}
      <div>{`${row.firstname} ${row.lastname}`}</div>
      <div>
        <div
          data-tag="allowRowEvents"
          style={{
            color: "grey",
            overflow: "hidden",
            whiteSpace: "wrap",
            textOverflow: "ellipses",
          }}
        >
          {}
          {row.plot}
        </div>
      </div>
    </div>
  )

  const CustomTitleLinkIn = ({ row }) => (
    <div>
      {}
      <div>{row.current_role}</div>
      <div>
        <div
          data-tag="allowRowEvents"
          style={{
            color: "grey",
            overflow: "hidden",
            whiteSpace: "wrap",
            textOverflow: "ellipses",
          }}
        >
          {}
          {row.plot}
        </div>
      </div>
    </div>
  );

  const CustomTitleEmail = ({ row }) => (
    <div>
      {}
      <div>{row.email}</div>
      <div>
        <div
          data-tag="allowRowEvents"
          style={{
            color: "grey",
            overflow: "hidden",
            whiteSpace: "wrap",
            textOverflow: "ellipses",
          }}
        >
          {}
          {row.plot}
        </div>
      </div>
    </div>
  );

  const CustomTitleCreated = ({row}) => (
    <>
        {}
        
      <div>{moment(row?.created_at).format('DD-MM-YYYY')}</div>
      <div>
        <div
          data-tag="allowRowEvents"
          style={{
            color: "grey",
            overflow: "hidden",
            whiteSpace: "wrap",
            textOverflow: "ellipses",
          }}
        >
          {}
          {row.plot}
        </div>
      </div>
    </>
  );

  const columns = [
    {
      name: "Full Name",
      selector: (row) => `${row.firstname}  ${row.lastname}`,
      sortable: true,
      cell: (row) => <CustomTitleName row={row} />,
      maxWidth: "650px",
      minWidth: "150px",
    },
    {
      name: "Role",
      selector: (row) => row.current_role,
      cell: (row) => <CustomTitleLinkIn row={row} />,
      maxWidth: "650px",
      minWidth: "200px",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      cell: (row) => <CustomTitleEmail row={row} />,
      maxWidth: "650px",
    },
    {
      name: "Date Joined",
      selector: (row) =>moment(row?.created_at).format('DD-MM-YYYY'),
      cell: (row) => <CustomTitleCreated row={row} />,
      maxWidth: "650px",
      minWidth: "150px",
    },
  ];

  const handleCampanyTeamMember = () => {
    window.open(`${window.location.origin}/manage-company/team-member`);
  };

  const handleCandidateDetails = () => {
    window.open(`${window.location.origin}/Candidates/details`);
};

const handleRegisteredComp = () => {
  window.open(`${window.location.origin}/Company/details`);
};

const handleList = () => {
  window.open(`${window.location.origin}/lists`);
};

const handlemanageTeamMember = () => {
  window.open(`${window.location.origin}/manage/team-member`);
};

const handleCompaniesDetails = () => {
  window.open(`${window.location.origin}/companies/details`);
};

    //LoggOut user
    const LogoutUser = () => {
      UserLogout();
      setCookie("access_token", "");
      window.localStorage.removeItem("Token");
      window.location.href = "/";
    };


  const handleTeamMemebrAdding = () => {
    const Name = localStorage.getItem('companyName');
    if(Name == "Startup Lane"){
        window.open(`${window.location.origin}/StartupLane/add-member`);
      }else{
        const modifiedString = VentureData?.url_shortname;
        // userRole == "admin" ? "StartupLane" : "OctopusVentures";
        window.open(`${window.location.origin}/${modifiedString}/add-member`);
      }
  }

  const handleCollection = () => {
    window.open(`${window.location.origin}/candidates/connections`);
  }


  return (
    <>
      <Helmet>
        <title>Team Members</title>
      </Helmet>
      <div
        className="leading-normal text-gray-1000 bg-deep-purple-10 font-sans min-h-screen	h-full"
        style={{ fontFamily: "DM Sans, sans-serif" }}
      >
       <PageHeaders
        title={"Team Members"}
        VentureData={VentureData}
        CompanyName={CompanyName}
        userRole={RoleName}
        profileIcon={profileIcon}
        handleCollection={handleCollection}
        menuRefs={menuRefs}
        storedCompanyName={storedCompanyName}
        setProfileIcon={setProfileIcon}
        LogoutUser={LogoutUser}
        handleCampanyTeamMember={handleCampanyTeamMember}
        handleCandidateDetails={handleCandidateDetails}
        handleRegisteredComp={handleRegisteredComp}
        handleList={handleList}
        handlemanageTeamMember={handlemanageTeamMember}
        handleCompaniesDetails={handleCompaniesDetails}
        />
        <div className="pt-[9.5rem] sm:pt-[9.75rem] lg:pt-[9rem]">
          <section className="fixed z-10 inset-x-0 top-[100px] sm:top-[4rem] lg:top-[3.25rem] bg-deep-purple-10 w-full px-7 pt-2 lg:pt-4 border-b border-gray-300">
            <form className="flex relative TableHeads" method="POST" action="#">
              <div className="inline-flex items-center group lg:w-48 lg:pr-1.5 mb-2 lg:mb-4">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  className="absolute z-[1] left-4 mb-0.5"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.50441 0.554688C3.19958 0.554688 0.504395 3.24987 0.504395 6.55471C0.504395 9.85954 3.19958 12.5547 6.50441 12.5547C8.02237 12.5547 9.4082 11.9825 10.4668 11.0474L11.0044 11.585V12.5547L13.6939 15.2442C14.1079 15.6582 14.7799 15.6582 15.1939 15.2442C15.6079 14.8302 15.6079 14.1582 15.1939 13.7442L12.5044 11.0547H11.5347L10.9971 10.5171C11.9322 9.45849 12.5044 8.07266 12.5044 6.55471C12.5044 3.24987 9.80925 0.554688 6.50441 0.554688ZM6.50441 2.05469C8.99859 2.05469 11.0044 4.06053 11.0044 6.55471C11.0044 9.04888 8.99859 11.0547 6.50441 11.0547C4.01024 11.0547 2.0044 9.04888 2.0044 6.55471C2.0044 4.06053 4.01024 2.05469 6.50441 2.05469Z"
                    fill="#434343"
                  />
                </svg>
                <input
                  type="search"
                  id="search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyDown={(e) => {if (e.key === 'Enter') {
                  e.preventDefault()
                  setSearch(e.target.value)
                }}}
                  placeholder="Search"
                  className="w-[42px] absolute sm:relative hover:w-full focus:w-full sm:w-48 sm:hover:w-48 sm:focus:w-48 sm:active:w-48 appearance-none text-ellipsis text-base leading-3 sm:leading-6 bg-white font-normal text-gray-800  placeholder:text-gray-400 rounded-lg py-2 hover:pr-4 focus:pr-4 sm:pr-4 pl-9 border border-gray-300 focus:outline-none focus:ring-1 focus:ring-deepe-purplee-500 focus:border-deepe-purplee-500 transition duration-500"
                />
              </div>
              <div className="flex items-center ml-auto justify-between mb-2 lg:mb-4">
                {cardData.length === 0 ? (  <button
                  type="button"
                  className={"add-candidate-btnnn mr-2 inline-flex items-center text-base font-medium whitespace-nowrap rounded-lg transition duration-200 py-3 px-4"} 
                >
                  <svg
                      width="16px"
                      className="mr-2 mb-0.5"
                      height="19px"
                      viewBox="0 0 22 22"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                    >
                      <g
                        id="Page-1"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          id="noun-download-5485540"
                          fill="#B8B7BD"
                          fill-rule="nonzero"
                        >
                          <path
                            d="M11,0 C4.92486775,0 0,4.92486775 0,11 C0,17.0751322 4.92486775,22 11,22 C17.0751322,22 22,17.0751322 22,11 C22,8.08261861 20.8410748,5.28472557 18.7781746,3.22182541 C16.7152744,1.15892524 13.9173814,0 11,0 Z M14.71,13.71 L11.71,16.71 C11.5212812,16.8971865 11.265803,17.0015368 11,17.0000167 C10.735233,16.9963165 10.4816338,16.8927337 10.29,16.71 L7.29,13.71 C6.89787783,13.3178778 6.89787783,12.6821222 7.29,12.29 C7.68212217,11.8978778 8.31787783,11.8978778 8.71,12.29 L10,13.59 L10,6 C10,5.44771525 10.4477153,5 11,5 C11.5522847,5 12,5.44771525 12,6 L12,13.59 L13.29,12.29 C13.6821222,11.8978778 14.3178778,11.8978778 14.71,12.29 C15.1021222,12.6821222 15.1021222,13.3178778 14.71,13.71 Z"
                            id="Shape"
                          ></path>
                        </g>
                      </g>
                      {/* <path d="M11,0 C4.92486775,0 0,4.92486775 0,11 C0,17.0751322 4.92486775,22 11,22 C17.0751322,22 22,17.0751322 22,11 C22,8.08261861 20.8410748,5.28472557 18.7781746,3.22182541 C16.7152744,1.15892524 13.9173814,0 11,0 Z M14.71,13.71 L11.71,16.71 C11.5212812,16.8971865 11.265803,17.0015368 11,17.0000167 C10.735233,16.9963165 10.4816338,16.8927337 10.29,16.71 L7.29,13.71 C6.89787783,13.3178778 6.89787783,12.6821222 7.29,12.29 C7.68212217,11.8978778 8.31787783,11.8978778 8.71,12.29 L10,13.59 L10,6 C10,5.44771525 10.4477153,5 11,5 C11.5522847,5 12,5.44771525 12,6 L12,13.59 L13.29,12.29 C13.6821222,11.8978778 14.3178778,11.8978778 14.71,12.29 C15.1021222,12.6821222 15.1021222,13.3178778 14.71,13.71 Z" id="Shape"></path> */}
                    </svg>
                  Download CSV
                </button>) : (
                  <CSVLink {...csvLink}>
                  <button
                    type="button"
                    className="csvButton mr-2 inline-flex items-center text-base font-medium whitespace-nowrap rounded-lg hover:shadow-md transition duration-200 py-3 px-4"
                  >
                      <svg
                      width="16px"
                      className="mr-2 mb-0.5"
                      height="19px"
                      viewBox="0 0 22 22"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                    >
                      <g
                        id="Page-1"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          id="noun-download-5485540"
                          fill="#434343"
                          fill-rule="nonzero"
                        >
                          <path
                            d="M11,0 C4.92486775,0 0,4.92486775 0,11 C0,17.0751322 4.92486775,22 11,22 C17.0751322,22 22,17.0751322 22,11 C22,8.08261861 20.8410748,5.28472557 18.7781746,3.22182541 C16.7152744,1.15892524 13.9173814,0 11,0 Z M14.71,13.71 L11.71,16.71 C11.5212812,16.8971865 11.265803,17.0015368 11,17.0000167 C10.735233,16.9963165 10.4816338,16.8927337 10.29,16.71 L7.29,13.71 C6.89787783,13.3178778 6.89787783,12.6821222 7.29,12.29 C7.68212217,11.8978778 8.31787783,11.8978778 8.71,12.29 L10,13.59 L10,6 C10,5.44771525 10.4477153,5 11,5 C11.5522847,5 12,5.44771525 12,6 L12,13.59 L13.29,12.29 C13.6821222,11.8978778 14.3178778,11.8978778 14.71,12.29 C15.1021222,12.6821222 15.1021222,13.3178778 14.71,13.71 Z"
                            id="Shape"
                          ></path>
                        </g>
                      </g>
                      {/* <path d="M11,0 C4.92486775,0 0,4.92486775 0,11 C0,17.0751322 4.92486775,22 11,22 C17.0751322,22 22,17.0751322 22,11 C22,8.08261861 20.8410748,5.28472557 18.7781746,3.22182541 C16.7152744,1.15892524 13.9173814,0 11,0 Z M14.71,13.71 L11.71,16.71 C11.5212812,16.8971865 11.265803,17.0015368 11,17.0000167 C10.735233,16.9963165 10.4816338,16.8927337 10.29,16.71 L7.29,13.71 C6.89787783,13.3178778 6.89787783,12.6821222 7.29,12.29 C7.68212217,11.8978778 8.31787783,11.8978778 8.71,12.29 L10,13.59 L10,6 C10,5.44771525 10.4477153,5 11,5 C11.5522847,5 12,5.44771525 12,6 L12,13.59 L13.29,12.29 C13.6821222,11.8978778 14.3178778,11.8978778 14.71,12.29 C15.1021222,12.6821222 15.1021222,13.3178778 14.71,13.71 Z" id="Shape"></path> */}
                    </svg>
                    Download CSV
                  </button>
                </CSVLink>
                )}
                <button
                  type="button"
                  onClick={handleTeamMemebrAdding}
                  className="add-Port-Button ml-3 inline-flex items-center text-white text-base font-medium whitespace-nowrap rounded-lg hover:shadow-md transition duration-200 py-3 px-4"
                >
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    className="mr-1"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.09814 0.0386963C3.67974 0.0386963 0.0981445 3.6203 0.0981445 8.0387C0.0981445 12.4571 3.67974 16.0387 8.09814 16.0387C12.5165 16.0387 16.0981 12.4571 16.0981 8.0387C16.0981 3.6203 12.5165 0.0386963 8.09814 0.0386963ZM10.4981 8.8387H8.89814V10.4387C8.89814 10.8803 8.53974 11.2387 8.09814 11.2387C7.65654 11.2387 7.29814 10.8803 7.29814 10.4387V8.8387H5.69814C5.25654 8.8387 4.89814 8.4803 4.89814 8.0387C4.89814 7.5971 5.25654 7.2387 5.69814 7.2387H7.29814V5.6387C7.29814 5.1971 7.65654 4.8387 8.09814 4.8387C8.53974 4.8387 8.89814 5.1971 8.89814 5.6387V7.2387H10.4981C10.9397 7.2387 11.2981 7.5971 11.2981 8.0387C11.2981 8.4803 10.9397 8.8387 10.4981 8.8387Z"
                      fill="white"
                    />
                  </svg>
                  Add Team Member
                </button>
              </div>
            </form>
          </section>
          <section className="flex flex-wrap">
            <DataTable
              // title
              fixedHeader
              fixedHeaderScrollHeight="100%"
              columns={columns}
              data={addTeamMemberData}
              paginationRowsPerPageOptions={[100, 200, 300, 500]}
              pagination
              paginationPerPage={[100]}
              selectableRows
              onSelectedRowsChange={handleRowData}
              selectableRowsHighlight
              highlightOnHover
              // subHeader
              subHeaderAlign="left"
            />
          </section>
        </div>
      </div>
    </>
  );
};

TeamMemberTable.propTypes = {
  UserLogout: PropTypes.func,
};

const mapStateToProps = ({ authentication }) => ({
  authentication_state: authentication,
});

export default connect(mapStateToProps, {
  UserLogout: getLogOut,
})(TeamMemberTable);
