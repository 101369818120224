import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import "../../../Login/index.css";
import "./CompIndex.css";
import OctupusImg from "../../images/OctopusVentures.svg";
import DummyVcLogo from "../../images/gvc.svg";
import HumanCapital from "../../images/human.svg";
import { ReactComponent as M13Img } from "../../images/m13.svg";
import Oak from "../../../AddMember/images/Oaknew.svg";
import { CSVLink } from "react-csv";
import { getCookie, setCookie } from "../../../../shared/utils/cookies";
import { ReactComponent as ProfileIcon } from "../../../Dashboard/images/imageProfile.svg";
import Icon from "../../../Dashboard/images/imageProfile.svg";
import { PropTypes } from "prop-types";
import HeiredImage from "../../../Dashboard/images/hiredimage.svg";
import HeiredTic from "../../../Dashboard/images/hiredTic.svg";
import HeiredCross from "../../../Dashboard/images/hiredCross.svg";
import RedCross from "../../../Dashboard/images/crossCicle.svg";
import $ from "jquery";
import {
  getCompanyData,
  getRoles,
  getPortfolioComp,
  getLogOut,
} from "../../../authentication";
import { Helmet } from "react-helmet";
import moment from "moment";
import axios from "axios";
import PageHeaders from "./PageHeaders";

const ConnectDetails = ({
  authentication_state,
  fetchRegisteredCandidate,
  fetchRoles,
  fetchVentureData,
  UserLogout,
}) => {
  const authentication_ref = useRef(authentication_state);
  authentication_ref.current = authentication_state;
  // const RoleName = authentication_ref?.current?.Roles_data?.response?.data?.data?.rolename;
  const uniqueRoleId =
    authentication_ref?.current?.retailer_auth?.response?.data?.data?.role_id ||
    "";
  // const UserCase = authentication_ref.current?.User?.response?.data?.data?.access || "";
  const uniqueId =
    authentication_ref?.current?.retailer_auth?.response?.data?.data?.id || "";
  const UserID = localStorage.getItem("usersIdentity");
  const CompanysID = localStorage.getItem("companyId");
  const storedCompanyName = localStorage.getItem("companyName");
  const CompanyName =
    authentication_ref?.current?.port_data?.response?.data?.data[0] || [];
  // const Token = getCookie("access_token");
  // const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [cardData, setCardData] = useState([]);
  const [profileIcon, setProfileIcon] = useState(false);
  const [isRowHovered, setRowHovered] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [loadingIndex, setLoadingIndex] = useState(null);
  const [loadingIndexs, setLoadingIndexs] = useState(null);
  const [clickProcessing, setClickProcessing] = useState(false);
  const [userRole, setUserRole] = useState();
  const [userRoleData, setUSerRoleData] = useState();
  const [userCompany, setUserCompony] = useState();
  const [fullData, setFullData] = useState([]);
  const [companyCount, setCompanyCount] = useState(0);
  const [VentureData, setVentureData] = useState()
  const [totalUserCount, setTotalUserCount] = useState(0);
  // const [PortfolioData, setPortfolioData] = useState([])
  // const [PortfolioDataFiltered, setPortfolioDataFiltered] = useState([])
  const iconRef = useRef();
  const menuRefs = useRef();

  // useEffect(() => {
  //   if (filteredData) {
  //     let companies = filteredData.length;
  //     let users = filteredData.reduce(
  //       (total, company) => total + company.users.length,
  //       0
  //     );

  //     setCompanyCount(companies);
  //     setTotalUserCount(users);
  //   }
  // }, [filteredData]);
  useEffect(() => {
    const headerData = {
      headers: {
        "Content-Type": "application/json",
      },
    };
      axios
      .get(
        `/api/venturecapitalforms/venturecapitalforms/get/${UserID}/`,
        headerData
      ).then((res) => {
        setVentureData(res?.data?.data)
      })
  }, [])

  const handleSearch = (searchTerm) => {
    const result = fullData.filter((candidate) => {
      return (
        candidate.candidate_name
          .toLocaleLowerCase()
          .match(searchTerm.toLocaleLowerCase()) ||
        candidate.candidate_lastname
          .toLocaleLowerCase()
          .match(searchTerm.toLocaleLowerCase()) ||
        candidate.portco_user_company_name
          .toLocaleLowerCase()
          .match(searchTerm.toLocaleLowerCase())
      );
    });
    setFilteredData(result);
  };

  // Image preloading
  useEffect(() => {
    const preloadedImages = [
      RedCross,
      HeiredTic,
      HeiredImage,
      // Add other image paths here
    ].map((src) => {
      const img = new Image();
      img.src = src;
      return img;
    });
  }, []); // Empty dependency array ensures it runs only once when the component mounts

  const handleClearSearch = () => {
    setFilteredData(fullData);
    setSearchTerm("");
    const data = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios.get(`/api/introduction/getall/${CompanysID}`, data).then((responce) => {
      setFullData(responce?.data?.data);
      setFilteredData(responce?.data?.data);
    });
  };

  const handleSearchInputChange = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    if (newSearchTerm) {
      handleSearch(newSearchTerm);
    } else {
      handleClearSearch();
    }
  };

  useEffect(() => {
    let handler = (event) => {
      if (!menuRefs?.current?.contains(event.target)) {
        setProfileIcon(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  useEffect(() => {
    const data = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios.get(`/api/user/get/${UserID}/`, data).then((responce) => {
      setUSerRoleData(responce.data.data.access);
    });
  }, []);

  //Getting the user roles
  useEffect(() => {
    fetchRoles(uniqueRoleId).then(() => {
      if (authentication_ref?.current?.Roles_data?.response?.status == 200) {
        setUserRole(
          authentication_ref?.current?.Roles_data?.response?.data?.data
            ?.rolename
        );
      }
    });
  }, []);

  useEffect(() => {
    fetchVentureData(uniqueId).then(() => {
      if (authentication_ref?.current?.port_data?.response?.status == 200) {
        setUserCompony(
          authentication_ref?.current?.port_data?.response?.data?.data[0]
        );
      }
    });
  }, []);

  const handleCandidateDetails = () => {
    window.open(`${window.location.origin}/Candidates/details`);
  };

  //Handling add new team member
  const handleTeammember = () => {
    if (userCompany == undefined) {
      window.open(`${window.location.origin}/StartupLane/add-member`);
    } else {
      const modifiedString = VentureData?.url_shortname;
      // userRole == "admin" ? "StartupLane" : "OctopusVentures";
      window.open(`${window.location.origin}/${modifiedString}/add-member`);
    }
  };

  //Handling company member
  const handleCampanyMember = () => {
    const modifiedString = VentureData?.url_shortname;
    // const RolebasedName = VentureData?.url_shortname
    window.open(`${window.location.origin}/${modifiedString}/register`);
  };

  //LoggOut user
  const LogoutUser = () => {
    UserLogout();
    setCookie("access_token", "");
    window.localStorage.removeItem("Token");
    window.location.href = "/";
  };

  const handleCompaniesDetails = () => {
    window.open(`${window.location.origin}/companies/details`);
  };

  const handleRegisteredComp = () => {
    window.open(`${window.location.origin}/Company/details`);
  };

  const handleList = () => {
    window.open(`${window.location.origin}/lists`);
  };

  //FETCHING REGISTERED CANDIDTE DETAILS
  // useEffect(() => {
  //   const storedCompanyId = localStorage.getItem('companyId')
  //   fetchRegisteredCandidate(storedCompanyId).then(() => {
  //     if (
  //       authentication_ref.current?.Company_data?.response?.data?.status_code ==
  //       200
  //     ) {
  //       setFullData(
  //         authentication_ref.current?.Company_data?.response?.data?.data
  //       );
  //       setFilteredData(
  //         authentication_ref.current?.Company_data?.response?.data?.data
  //       );
  //     }
  //   });
  // }, []);

  useEffect(() => {
    const data = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios.get(`/api/introduction/getall/${CompanysID}`, data).then((responce) => {
      setFullData(responce?.data?.data);
      setFilteredData(responce?.data?.data);
    });
  }, []);

  const handleRowData = (state) => {
    setCardData(state.selectedRows);
  };

  //   const data = cardDatas

  const headers = [
    {
      label: "First Name",
      key: "candidate_name",
    },
    {
      label: "Last Name",
      key: "candidate_lastname",
    },
    {
      label: "Title",
      key: "candidate_title",
    },
    {
      label: "Introduced to",
      key: "portco_user_email",
    },
    {
      label: "Portco",
      key: "portco_user_company_name",
    },
    {
      label: "Hired",
      key: "hired",
    },
    {
      label: "Hired Date",
      key: "hired_date",
    },
    {
      label: "Intro Date",
      key: "date_of_introduction",
    },
  ];

  // const NewData = fullData?.filter((item) => {
  //   const fullName = `${item.users[0]?.firstname} ${item.users[0]?.lastname}`;
  //   return (
  //     item.company_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     fullName.toLowerCase().includes(searchTerm.toLowerCase())
  //   );
  // });

  const csvLink = {
    filename: "Connections.csv",
    headers: headers,
    data: filteredData,
  };

  const handlePortFolioAdding = (e) => {
    const modifiedString = VentureData?.url_shortname;
    // const RolebasedName = VentureData?.url_shortname
    window.open(`${window.location.origin}/${modifiedString}/register`);
  };

  const handleInitials = (company) => {
    const words = company.company_name.split(" ");

    if (words.length > 1) {
      return (
        <span>
          {words[0][0]}
          {words[1][0].toUpperCase()}
        </span>
      );
    } else if (company.company_name.length > 1) {
      return <span>{company.company_name.substring(0, 2).toUpperCase()}</span>;
    } else {
      return <span>{company.company_name.toUpperCase()}</span>;
    }
  };

  const handleCompUrl = (company, e) => {
    if (e) {
      window.open(company?.website);
    }
  };

  const handleCollection = () => {
    window.open(`${window.location.origin}/candidates/connections`);
  };

  const handleCampanyTeamMember = () => {
    window.open(`${window.location.origin}/manage-company/team-member`);
  };

  const handlemanageTeamMember = () => {
    window.open(`${window.location.origin}/manage/team-member`);
  };

  useEffect(() => {
    function handleScroll(event) {
      const tempEl = $(event.currentTarget).parent().children(".data_row");
      const scrollLeft = event.currentTarget.scrollLeft;

      for (let i = 0; i < tempEl.length; i++) {
        $(tempEl[i]).scrollLeft(scrollLeft);
      }
    }

    $(".custom_card .data_row").on("scroll", handleScroll);

    return () => {
      $(".custom_card .data_row").off("scroll", handleScroll);
    };
  }, []);

  const handleNameClick = (e, company) => {
    if (e) {
      if(company?.candidate_type == "advisor"){
        e.preventDefault(); // Prevent the default behavior (opening a new tab)
        const FirstName = company?.candidate_name?.trim();
        const LastName = company?.candidate_lastname?.trim();
        var new_url = `/advisors/${company?.candidate_id}-${FirstName?.charAt(
          0
        )?.toLowerCase()}${FirstName?.slice(1)
          ?.toLowerCase()
          .replaceAll(" ", "-")}-${LastName?.charAt(
          0
        )?.toLowerCase()}${LastName?.slice(1)
          ?.toLowerCase()
          .replaceAll(" ", "-")}`;
  
        // Open the new URL in a new tab with _blank target
        window.open(`${window.location.origin}${new_url}`, "_blank");
      }else{
        e.preventDefault(); // Prevent the default behavior (opening a new tab)
        const FirstName = company?.candidate_name?.trim();
        const LastName = company?.candidate_lastname?.trim();
        var new_url = `/candidate/${company?.candidate_id}-${FirstName?.charAt(
          0
        )?.toLowerCase()}${FirstName?.slice(1)
          ?.toLowerCase()
          .replaceAll(" ", "-")}-${LastName?.charAt(
          0
        )?.toLowerCase()}${LastName?.slice(1)
          ?.toLowerCase()
          .replaceAll(" ", "-")}`;
  
        // Open the new URL in a new tab with _blank target
        window.open(`${window.location.origin}${new_url}`, "_blank");
      }
    }
  };

  const handleRowHover = (index, hovered) => {
    setHoveredIndex(hovered ? index : null);
  };

  // Helper function to check if an element matches the search term
  const searchTermMatches = (element) => {
    return (
      element.candidate_name
        .toLocaleLowerCase()
        .includes(searchTerm.toLocaleLowerCase()) ||
      element.candidate_lastname
        .toLocaleLowerCase()
        .includes(searchTerm.toLocaleLowerCase()) ||
      element.portco_user_company_name
        .toLocaleLowerCase()
        .includes(searchTerm.toLocaleLowerCase())
    );
  };

  const handleAfterHeiredClick = (e, company, index) => {
    e.preventDefault();
    setLoadingIndex(index);

    setTimeout(() => {
      setLoadingIndex(null);
    }, 500);

    const updatedData = filteredData.map((c) =>
    c.id === company.id ? { ...c, hired: "no" } : c
    );

    setFilteredData(updatedData);

    const headerData = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const data = {
      hired: "no",
    };
    axios.put(`/api/introduction/update/${company.id}/`, data, headerData);
  };

  const handleHeiredClick = (e, company, index) => {
    e.preventDefault();
    setLoadingIndexs(index);

    setTimeout(() => {
      setLoadingIndexs(null);
    }, 1000);

    setTimeout(() => {
      const updatedDatasss = filteredData.map((c) =>
      c.id === company.id ? { ...c, hired: "yes" } : c
      );
  
      setFilteredData(updatedDatasss);
    }, 500);


    const headerData = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const data = {
      hired: "yes",
    };
    axios.put(`/api/introduction/update/${company.id}/`, data, headerData);
  };

  return (
    <>
      <Helmet>
        <title>Connections</title>
      </Helmet>
      <div
        className="leading-normal text-gray-1000 bg-deep-purple-10 font-sans min-h-screen	h-full"
        style={{ fontFamily: "DM Sans, sans-serif" }}
      >
        <PageHeaders
          title={"Connections"}
          VentureData={VentureData}
          CompanyName={CompanyName}
          userRole={userRole}
          profileIcon={profileIcon}
          storedCompanyName={storedCompanyName}
          setProfileIcon={setProfileIcon}
          handleCollection={handleCollection}
          menuRefs={menuRefs}
          handleCampanyTeamMember={handleCampanyTeamMember}
          LogoutUser={LogoutUser}
          handleCandidateDetails={handleCandidateDetails}
          handleRegisteredComp={handleRegisteredComp}
          handleList={handleList}
          handlemanageTeamMember={handlemanageTeamMember}
          handleCompaniesDetails={handleCompaniesDetails}
        />
        <div className="pt-[9.5rem] sm:pt-[9.75rem] lg:pt-[9rem]">
          <section className="fixed z-10 inset-x-0 top-[100px] sm:top-[4rem] lg:top-[3.25rem] bg-deep-purple-10 w-full px-7 pt-2 lg:pt-4 border-b border-gray-300">
            <form className="flex relative TableHeads" method="POST" action="#">
              <div className="inline-flex items-center group lg:w-48 lg:pr-1.5 mb-2 lg:mb-4">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  className="absolute z-[1] left-4 mb-0.5"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.50441 0.554688C3.19958 0.554688 0.504395 3.24987 0.504395 6.55471C0.504395 9.85954 3.19958 12.5547 6.50441 12.5547C8.02237 12.5547 9.4082 11.9825 10.4668 11.0474L11.0044 11.585V12.5547L13.6939 15.2442C14.1079 15.6582 14.7799 15.6582 15.1939 15.2442C15.6079 14.8302 15.6079 14.1582 15.1939 13.7442L12.5044 11.0547H11.5347L10.9971 10.5171C11.9322 9.45849 12.5044 8.07266 12.5044 6.55471C12.5044 3.24987 9.80925 0.554688 6.50441 0.554688ZM6.50441 2.05469C8.99859 2.05469 11.0044 4.06053 11.0044 6.55471C11.0044 9.04888 8.99859 11.0547 6.50441 11.0547C4.01024 11.0547 2.0044 9.04888 2.0044 6.55471C2.0044 4.06053 4.01024 2.05469 6.50441 2.05469Z"
                    fill="#434343"
                  />
                </svg>
                <input
                  type="search"
                  id="search"
                  value={searchTerm}
                  onChange={handleSearchInputChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      setSearchTerm(e.target.value);
                    }
                  }}
                  placeholder="Search"
                  className="w-[42px] absolute sm:relative hover:w-full focus:w-full sm:w-48 sm:hover:w-48 sm:focus:w-48 sm:active:w-48 appearance-none text-ellipsis text-base leading-3 sm:leading-6 bg-white font-normal text-gray-800  placeholder:text-gray-400 rounded-lg py-2 hover:pr-4 focus:pr-4 sm:pr-4 pl-9 border border-gray-300 focus:outline-none focus:ring-1 focus:ring-deepe-purplee-500 focus:border-deepe-purplee-500 transition duration-500"
                />
              </div>
              <div className="flex items-center ml-auto justify-between mb-2 lg:mb-4">
                <CSVLink {...csvLink}>
                  <button
                    type="button"
                    className="csvButton ml-3 mr-3 inline-flex items-center text-base font-medium whitespace-nowrap rounded-lg hover:shadow-md transition duration-200 py-3 px-4"
                  >
                    <svg
                      width="16px"
                      className="mr-2"
                      height="19px"
                      viewBox="0 0 22 22"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                    >
                      <g
                        id="Page-1"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          id="noun-download-5485540"
                          fill="#434343"
                          fill-rule="nonzero"
                        >
                          <path
                            d="M11,0 C4.92486775,0 0,4.92486775 0,11 C0,17.0751322 4.92486775,22 11,22 C17.0751322,22 22,17.0751322 22,11 C22,8.08261861 20.8410748,5.28472557 18.7781746,3.22182541 C16.7152744,1.15892524 13.9173814,0 11,0 Z M14.71,13.71 L11.71,16.71 C11.5212812,16.8971865 11.265803,17.0015368 11,17.0000167 C10.735233,16.9963165 10.4816338,16.8927337 10.29,16.71 L7.29,13.71 C6.89787783,13.3178778 6.89787783,12.6821222 7.29,12.29 C7.68212217,11.8978778 8.31787783,11.8978778 8.71,12.29 L10,13.59 L10,6 C10,5.44771525 10.4477153,5 11,5 C11.5522847,5 12,5.44771525 12,6 L12,13.59 L13.29,12.29 C13.6821222,11.8978778 14.3178778,11.8978778 14.71,12.29 C15.1021222,12.6821222 15.1021222,13.3178778 14.71,13.71 Z"
                            id="Shape"
                          ></path>
                        </g>
                      </g>
                      {/* <path d="M11,0 C4.92486775,0 0,4.92486775 0,11 C0,17.0751322 4.92486775,22 11,22 C17.0751322,22 22,17.0751322 22,11 C22,8.08261861 20.8410748,5.28472557 18.7781746,3.22182541 C16.7152744,1.15892524 13.9173814,0 11,0 Z M14.71,13.71 L11.71,16.71 C11.5212812,16.8971865 11.265803,17.0015368 11,17.0000167 C10.735233,16.9963165 10.4816338,16.8927337 10.29,16.71 L7.29,13.71 C6.89787783,13.3178778 6.89787783,12.6821222 7.29,12.29 C7.68212217,11.8978778 8.31787783,11.8978778 8.71,12.29 L10,13.59 L10,6 C10,5.44771525 10.4477153,5 11,5 C11.5522847,5 12,5.44771525 12,6 L12,13.59 L13.29,12.29 C13.6821222,11.8978778 14.3178778,11.8978778 14.71,12.29 C15.1021222,12.6821222 15.1021222,13.3178778 14.71,13.71 Z" id="Shape"></path> */}
                    </svg>
                    Download CSV
                  </button>
                </CSVLink>
              </div>
            </form>
          </section>
          <body>
            <div
              className="custom_card"
              style={{ boxShadow: "1px 1px 14px 0px #0000000D" }}
            >
              <div
                className="row_c data_row data_head"
                style={{ paddingBottom: "0px" }}
              >
                <div
                  className="col_v"
                  style={{ width: "7%", paddingLeft: "0px" }}
                >
                  <label></label>
                </div>
                <div
                  className="col_v"
                  style={{ width: "15%", paddingLeft: "6px" }}
                >
                  <label>Candidate</label>
                </div>
                <div className="col_d" style={{ paddingLeft: "6px", width: '20%' }}>
                  <label>Title</label>
                </div>
                <div className="col_d" style={{width: '20%'}}>
                  <label>Introduced to</label>
                </div>
                <div className="col_d">
                  <label>Company</label>
                </div>
                <div className="col_d">
                  <label>Intro Date</label>
                </div>
                <div className="col_d" style={{width: '5%'}}>
                  <label>Hired</label>
                </div>
              </div>
              {filteredData?.map((company, index) => {
                return (
                  <div
                    className="row_c data_row data_content"
                    onMouseEnter={() => handleRowHover(index, true)}
                    onMouseLeave={() => handleRowHover(index, false)}
                    key={index}
                    style={{
                      paddingBottom: "10px",
                      height: "60px",
                      backgroundColor:
                        hoveredIndex === index ? "#E6EAF5" : "transparent",
                    }}
                  >
                    <div
                      className="col_d"
                      style={
                        company?.candidate_profilepic
                          ? {
                              width: "7%",
                              paddingLeft: "0px",
                              paddingTop: "6px",
                            }
                          : {
                              width: "7%",
                              paddingLeft: "0px",
                              paddingTop: "-2px",
                            }
                      }
                    >
                      <figure
                        className={
                          "shrink-0 bg-deep-purple-50 rounded-full w-12 h-12 inline-flex items-center justify-center"
                        }
                        style={{ width: "34px", height: "34px" }}
                      >
                        {company?.candidate_profilepic ? (
                          <img
                            className="max-w-full h-auto min-h-full rounded-full"
                            // src={`https://cnp1656-dev-image.s3.amazonaws.com/profilepic/${company?.candidate_profilepic}`}
                            src={`https://cnp1656-prod.s3.amazonaws.com/profilepic/${company?.candidate_profilepic}`}
                            alt={
                              company?.candidate_name +
                              " " +
                              company?.candidate_lastname
                            }
                          />
                        ) : (
                          <span className="text-md text-deep-purple-500 font-medium">
                            {company?.candidate_name[0]}
                            {company?.candidate_lastname[0]?.toUpperCase()}
                          </span>
                        )}
                      </figure>
                    </div>
                    <div
                      className="col_d underline"
                      style={{ fontWeight: "700", fontSize: "14px" }}
                    >
                      <p
                        className="cursor-pointer"
                        onClick={(e) => {
                          handleNameClick(e, company);
                        }}
                        // onClick={handleNameClick(company)}
                      >
                        {company?.candidate_name} {company?.candidate_lastname}
                      </p>
                    </div>
                    <div
                      className="col_d"
                      style={{ fontWeight: "500", fontSize: "14px", width: '20%' }}
                    >
                      <p>{company?.candidate_type == "advisor" ? company?.candidate_main_expertise : company?.candidate_title}</p>
                    </div>
                    <div
                      className="col_d"
                      style={{ wordBreak: "break-all", fontWeight: "500", width: '20%' }}
                    >
                      <p>{company?.portco_user_email}</p>
                    </div>
                    <div
                      className="col_d"
                      style={{ fontWeight: "500", fontSize: "14px" }}
                    >
                      <p>{company?.portco_user_company_name}</p>
                    </div>
                    <div
                      className="col_d"
                      style={{ fontWeight: "500", fontSize: "14px" }}
                    >
                      <p>
                        {moment(company?.date_of_introduction).format(
                          "DD-MM-YYYY"
                        )}
                      </p>
                    </div>
                    <div
                      className="col_d"
                      style={{ fontWeight: "500", fontSize: "14px", width: '5%' }}
                    >
                      {loadingIndex === index ? (
                        <img
                          src={RedCross}
                          className="rotate-once-per-second"
                        />
                      ) : company?.hired === "yes" ? (
                        hoveredIndex === index ? (
                          <>
                            <svg
                              width="17"
                              height="17"
                              viewBox="0 0 17 17"
                              fill="none"
                              style={{ cursor: "pointer" }}
                              onClick={(e) =>
                                handleAfterHeiredClick(e, company, index)
                              }
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                style={{ cursor: "pointer" }}
                                className="custom-fills cursor-pointer"
                                d="M8.5332 0C3.86451 0 0 3.79811 0 8.4668C0 13.1355 3.86451 17 8.5332 17C13.2019 17 17 13.1355 17 8.4668C17 3.79811 13.2019 0 8.5332 0ZM12.7241 11.2489C13.1126 11.6374 13.1126 12.2692 12.7241 12.658C12.3386 13.0432 11.7068 13.0495 11.315 12.658L8.5332 9.87527L5.68471 12.6584C5.29623 13.0468 4.66438 13.0468 4.27557 12.6584C3.88709 12.2699 3.88709 11.638 4.27557 11.2492L7.05799 8.4668L4.27557 5.68437C3.88709 5.29557 3.88709 4.66371 4.27557 4.27523C4.66438 3.88676 5.29623 3.88676 5.68471 4.27523L8.5332 7.05832L11.315 4.27523C11.7028 3.88742 12.3346 3.88609 12.7241 4.27523C13.1126 4.66371 13.1126 5.29557 12.7241 5.68437L9.94168 8.4668L12.7241 11.2489Z"
                              />
                            </svg>
                          </>
                        ) : (
                          <img src={HeiredTic} className="cursor-pointer" />
                        )
                      ) : loadingIndexs === index ? (
                        <img
                          src={HeiredImage}
                          className="cursor-pointer rotate-once-per-second"
                        />
                      ) : company?.hired === "no" ? (
                        hoveredIndex === index ? (
                          <>
                            <svg
                              width="17"
                              height="17"
                              viewBox="0 0 17 17"
                              fill="none"
                              style={{ cursor: "pointer" }}
                              onClick={(e) =>
                                handleHeiredClick(e, company, index)
                              }
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                style={{ cursor: "pointer" }}
                                className="custom-fill cursor-pointer"
                                d="M8.5332 0C3.86451 0 0 3.79811 0 8.4668C0 13.1355 3.86451 17 8.5332 17C13.2019 17 17 13.1355 17 8.4668C17 3.79811 13.2019 0 8.5332 0ZM13.7199 5.97656L8.18092 11.551C7.79078 11.9412 7.09418 11.9395 6.70537 11.551L3.53613 8.38113C3.14666 7.99166 3.14666 7.36213 3.53613 6.97266C3.90535 6.60377 4.57639 6.60377 4.94527 6.97266L7.47668 9.43766L12.3114 4.56809C12.6799 4.1982 13.3493 4.19721 13.7202 4.56809C14.1024 4.95025 14.115 5.58145 13.7199 5.97656Z"
                              />
                            </svg>
                          </>
                        ) : null
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </div>
          </body>
        </div>
      </div>
    </>
  );
};

ConnectDetails.propTypes = {
  fetchRegisteredCandidate: PropTypes.func,
  fetchRoles: PropTypes.func,
  fetchVentureData: PropTypes.func,
  UserLogout: PropTypes.func,
};

const mapStateToProps = ({ authentication }) => ({
  authentication_state: authentication,
});

export default connect(mapStateToProps, {
  fetchRegisteredCandidate: getCompanyData,
  fetchRoles: getRoles,
  UserLogout: getLogOut,
  fetchVentureData: getPortfolioComp,
})(ConnectDetails);
