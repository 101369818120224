import React, { useState, useEffect, useRef } from "react";
import "../../../Login/index.css";
import { withRouter } from "react-router-dom";
import { setCookie, getCookie } from "../../../../shared/utils/cookies";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import {
  getDash,
  getUniqueLink,
  getPortfolioComp,
  getUserDetails,
  getLogOut,
  getRoles,
  getGlobalSearch,
} from "../../../authentication";
import ModuleCandidatePage from "../NewCAndidatePage/ModuleCandidatePage";
import PageHeaders from "../Component/PageHeaders";

const ModelRoute = ({
  fetchRoles,
  authentication_state,
  UserLogout,
  fetchUniqueLink,
  fetchUSerDetails,
  fetchVentureData,
}) => {
  const authentication_ref = useRef(authentication_state);
  authentication_ref.current = authentication_state;
  const uniqueRoleId =
    authentication_ref?.current?.retailer_auth?.response?.data?.data?.role_id ||
    "";
  const UserCase = authentication_ref.current?.User?.response?.data?.data?.access || "";
  const uniqueId =
    authentication_ref?.current?.retailer_auth?.response?.data?.data?.id || "";
  const UserID = localStorage.getItem('usersIdentity')
  const [canData, setData] = useState();
  const [vClogo, setVClogo] = useState();
  const [filterData, setFilterData] = useState([]);
  const checkPage = 1
  const [showModel, setShowModel] = useState(true);
  const [userRoleData, setUSerRoleData] = useState()
  const [profileIcon, setProfileIcon] = useState(false);
  const [connectScollModel, setconnectScoll] = useState(false);
  const [addCandidatePopUp, setAddCAndidatePopUp] = useState(false);
  const [editCandidate, setEditCandidate] = useState(false);
  const [userCompany, setUserCompony] = useState();
  const [userDetails, setUserDetails] = useState("");
  const [userRole, setUserRole] = useState();
  const [VC, setVC] = useState();
  const Access_Token = getCookie("access_token");
  const params = useParams();
  const menuRef = useRef();
  const buttonRef = useRef()
  const iconRef = useRef();
  const history = useHistory();
  const [VentureData, setVentureData] = useState()
  const id= localStorage.getItem('MainCompanyId')
  const companyId= localStorage.getItem('companyId')

  useEffect(() => {
    const headerData = {
      headers: {
        "Content-Type": "application/json",
      },
    };
      axios
      .get(
        `/api/venturecapitalforms/venturecapitalforms/get/${UserID}/`,
        headerData
      ).then((res) => {
        setVentureData(res?.data?.data)
      })
  }, [])


  // useEffect(() => {
  //   console.log("testing")
  //   const data = {
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   };
  //   axios
  //     .get(`/api/user/get/${UserID}/`, data)
  //     .then((responce) => {
  //       setUSerRoleData(responce.data.data.access)
  //     });
  // }, [])

  useEffect(() => {
    GetCandidate();
  }, []);

  useEffect(() => {
    fetchVentureData(uniqueId).then(() => {
      if(authentication_ref?.current?.port_data?.response?.status == 200){
        setUserCompony(authentication_ref?.current?.port_data?.response?.data?.data[0])
        const storedCompanyId = localStorage.getItem('companyId') || null
        if(storedCompanyId == null){
          const initialCompanyId = authentication_ref?.current?.port_data?.response?.data?.data[0].id;
          localStorage.setItem('companyId', initialCompanyId); // Store in local storage
        }
      }
    })
  }, [])

  useEffect(() => {
    fetchUSerDetails(uniqueId).then(() => {
      if (authentication_ref?.current?.User?.response.status == 200) {
        setUserDetails(
          authentication_ref?.current?.User?.response?.data?.data
        );
        setUSerRoleData(authentication_ref?.current?.User?.response?.data?.data?.access)
      }
    });
  }, []);

  //LoggOut user
  const LogoutUser = () => {
    UserLogout();
    setCookie("access_token", "");
    window.localStorage.removeItem("Token");
    window.localStorage.removeItem("companyId");
    window.localStorage.removeItem("companyName");
    window.localStorage.removeItem("AdvisoryDetails");
    window.localStorage.removeItem("MultiUserComp");
    window.localStorage.removeItem("MainCompanyId");
    window.location.href = "/";
  };

  //Clicking outside close icon window
  useEffect(() => {
    let handler = (event) => {
      if (!iconRef?.current?.contains(event.target)) {
        setProfileIcon(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  //Getting the user roles
  useEffect(() => {
    fetchRoles(uniqueRoleId).then(() => {
      if (authentication_ref?.current?.Roles_data?.response?.status == 200) {
        setUserRole(
          authentication_ref?.current?.Roles_data?.response?.data?.data
            ?.rolename
        );
      }
    });
  }, []);

  // useEffect(() => {
  //   fetchUniqueLink(uniqueId, uniqueRoleId).then(() => {
  //     if (
  //       authentication_ref?.current?.UniqueLink_data?.response?.status == 200
  //     ) {
  //       setVC(
  //         authentication_ref?.current?.UniqueLink_data?.response?.data?.data?.vc
  //       );
  //     }
  //   });
  // }, []);

  useEffect(() => {
    let eventhandler = (event) => {
      if (!buttonRef?.current?.contains(event.target)) {
        setAddCAndidatePopUp(false);
      }
    };
    document.addEventListener("mousedown", eventhandler);

    return () => {
      document.removeEventListener("mousedown", eventhandler);
    };
  }, []);

  // get enriched data
  // useEffect(() => {
  //   getAllEnrichedData().then(data => setEnrichedData(data));
  // }, []);

  //Handling company register link
  const handleRegisteredComp = () => {
    window.open(`${window.location.origin}/Company/details`);
  };

  const handleList = () => {
    window.open(`${window.location.origin}/lists`);
  };

  const handleCampanyTeamMember = () => {
    window.open(`${window.location.origin}/manage-company/team-member`);
  };

  const handleCandidateDetails = () => {
    window.open(`${window.location.origin}/Candidates/details`);
  };

  const handleModelClosed = () => {
    setShowModel(!showModel);
    window.location.href = `/dashboard/candidates`;
  };

  const GetCandidate = () => {
    const data = {
      headers: {
        "Content-Type":
          "multipart/form-data; boundary=<calculated when request is sent>",
      },
    };
    axios
      .get(`/api/candidate/candidate/get/${params.id}/`, data)
      .then((responce) => {
        setData(responce.data.data.candidate);
        setVClogo(responce.data.data.Logo);
      });
  };

  const handleCompaniesDetails = () => {
    window.open(`${window.location.origin}/companies/details`);
  };

  const handlemanageTeamMember = () => {
    window.open(`${window.location.origin}/manage/team-member`);
  };

  const handleCollection = () => {
    window.open(`${window.location.origin}/candidates/connections`);
  }

  return (
    <>
      <Helmet>
        <title>Startup Lane Candidate</title>
      </Helmet>
      <div
        className="leading-normal text-gray-1000 bg-deep-purple-10 font-sans min-h-screen	h-full"
        style={connectScollModel == true ? {overflow: 'hidden', fontFamily: "DM Sans, sans-serif"} : {overflow: 'hidden', fontFamily: "DM Sans, sans-serif"}}
      >
      <PageHeaders
      title={"Talent Network"} 
      VentureData={VentureData}
      CompanyName={userCompany}
       userRole={userRole}
       vcLogo={vClogo}
       editCandidate={editCandidate}
       profileIcon={profileIcon}
       setProfileIcon={setProfileIcon}
       handleCollection={handleCollection}
       menuRefs={iconRef}
       storedCompanyName={canData?.careerplan?.capital}
       LogoutUser={LogoutUser}
       handleCampanyTeamMember={handleCampanyTeamMember}
       handleCandidateDetails={handleCandidateDetails}
       handleRegisteredComp={handleRegisteredComp}
       handleList={handleList}
       handlemanageTeamMember={handlemanageTeamMember}
       handleCompaniesDetails={handleCompaniesDetails}
       />
       {editCandidate == true ? (
        <div className="CandidatePgeScroll" style={{transform: 'translateY()'}}>
        {canData != undefined && showModel == true && (
          <div className="CandidatePageDetails">
            <ModuleCandidatePage
              cardData={canData}
              setconnectScoll={setconnectScoll} 
              VentureData={VentureData}
              connectScollModel={connectScollModel}
              CompanyName={userCompany}
              authentication_ref={authentication_ref}
              setEditCandidate={setEditCandidate}
              editCandidate={editCandidate}
              storedCompanyName={canData?.careerplan?.capital}
              userCompany={userCompany}
              candidateId={canData.id}
              userRole={userRole}
              checkPage={checkPage}
              uniqueId={uniqueId}
              Token={Access_Token}
              userDetails={userDetails}
              UserCase={UserCase == "" ? userRoleData : UserCase}
              handleModelClose={handleModelClosed}
            />
          </div>
        )}
        </div>
       ) : (
        <div>
        {canData != undefined && showModel == true && (
          <div className="CandidatePageDetails">
            <ModuleCandidatePage
              cardData={canData}
              setconnectScoll={setconnectScoll} 
              connectScollModel={connectScollModel}
              CompanyName={userCompany}
              authentication_ref={authentication_ref}
              setEditCandidate={setEditCandidate}
              editCandidate={editCandidate}
              storedCompanyName={canData?.careerplan?.capital}
              userCompany={userCompany}
              candidateId={canData.id}
              userRole={userRole}
              checkPage={checkPage}
              uniqueId={uniqueId}
              Token={Access_Token}
              userDetails={userDetails}
              UserCase={UserCase == "" ? userRoleData : UserCase}
              handleModelClose={handleModelClosed}
            />
          </div>
        )}
        </div>
       )}
      {/* <div
          style={
             editCandidate == true
              ? { overflow: 'hidden', transform: 'translateY()', zIndex: '10' } 
              : null
          }
        >
         {canData != undefined && showModel == true && (
          <div className="CandidatePageDetails">
            <ModuleCandidatePage
              cardData={canData}
              setconnectScoll={setconnectScoll} 
              connectScollModel={connectScollModel}
              CompanyName={userCompany}
              authentication_ref={authentication_ref}
              setEditCandidate={setEditCandidate}
              editCandidate={editCandidate}
              storedCompanyName={canData?.careerplan?.capital}
              userCompany={userCompany}
              candidateId={canData.id}
              userRole={userRole}
              checkPage={checkPage}
              uniqueId={uniqueId}
              Token={Access_Token}
              userDetails={userDetails}
              UserCase={UserCase == "" ? userRoleData : UserCase}
              handleModelClose={handleModelClosed}
            />
          </div>
        )}
        </div> */}
      </div>
    </>
  );
};

ModelRoute.propTypes = {
  fetchDashboard: PropTypes.func,
  fetchUniqueLink: PropTypes.func,
  fetchRoles: PropTypes.func,
  fetchGlobalSearch: PropTypes.func,
  fetchVentureData: PropTypes.func,
  fetchUSerDetails: PropTypes.func,
  UserLogout: PropTypes.func,
};

const mapStateToProps = ({ authentication }) => ({
  authentication_state: authentication,
});

export default withRouter(
  connect(mapStateToProps, {
    fetchDashboard: getDash,
    fetchRoles: getRoles,
    fetchUniqueLink: getUniqueLink,
    fetchUSerDetails: getUserDetails,
    fetchVentureData: getPortfolioComp,
    UserLogout: getLogOut,
    fetchGlobalSearch: getGlobalSearch,
  })(ModelRoute)
);
