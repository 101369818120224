import { createAction, handleActions } from "redux-actions";
import ActionTypes from "../../shared/constant/ActionType";
import {
  _getRetailerAuthToken,
  _addminLog,
  _AuthToken,
  _getDash,
  _getNewDash,
  _getAdvisoryDash,
  _getPortfolioComp,
  _getCompData,
  _getCompanyData,
  _getUniqueLink,
  _getUserDetails,
  _getLevel,
  _getList,
  _getLocation,
  _getRoles,
  _getfetchVentureLog,
  _getDesire,
  _getExperience,
  _getGlobalSearch,
  _getLogOut,
} from "../../shared/httpService/apis";

const initialState = {
  retailer_auth: { loading: false, response: {}, hasError: false, error: {} },
  User: { loading: false, response: {}, hasError: false, error: {} },
  logIn_auth: { loading: false, response: {}, hasError: false, error: {} },
  dash_data: { loading: false, response: {}, hasError: false, error: {} },
  advisory_data: { loading: false, response: {}, hasError: false, error: {} },
  dash_new_data: { loading: false, response: {}, hasError: false, error: {} },
  port_data: { loading: false, response: {}, hasError: false, error: {} },
  list_data: { loading: false, response: {}, hasError: false, error: {} },
  comp_data: { loading: false, response: {}, hasError: false, error: {} },
  Company_data: { loading: false, response: {}, hasError: false, error: {} },
  level_data: { loading: false, response: {}, hasError: false, error: {} },
  location: { loading: false, response: {}, hasError: false, error: {} },
  desire_data: { loading: false, response: {}, hasError: false, error: {} },
  experience_data: { loading: false, response: {}, hasError: false, error: {} },
  global_data: { loading: false, response: {}, hasError: false, error: {} },
  UniqueLink_data: { loading: false, response: {}, hasError: false, error: {} },
  venture_log: { loading: false, response: {}, hasError: false, error: {} },
  Roles_data: { loading: false, response: {}, hasError: false, error: {} },
};

//DASHBOARD DATA
export const getDash = () => (dispatch) => {
  dispatch(dashDataStart());
  return _getDash()
    .then((response) => {
      dispatch(dashDataSuccess(response));
    })
    .catch((error) => {
      dispatch(dashDataError(error));
    });
};

// NEW DASHBOARD DATA
export const getNewDash = (userCompanyId) => (dispatch) => {
  dispatch(dashNewDataStart());
  return _getNewDash(userCompanyId)
    .then((response) => {
      dispatch(dashNewDataSuccess(response));
    })
    .catch((error) => {
      dispatch(dashNewDataError(error));
    });
};

// NEW DASHBOARD DATA FOR ADVISORY
export const getAdvisoryDash = (userCompanyId) => (dispatch) => {
  dispatch(AdvisoryDashDataStart());
  return _getAdvisoryDash(userCompanyId)
    .then((response) => {
      dispatch(AdvisoryDataSuccess(response));
    })
    .catch((error) => {
      dispatch(AdvisoryDataError(error));
    });
};

//DASHBOARD DATA
export const getPortfolioComp = (uniqueId) => (dispatch) => {
  dispatch(PortfolioCompStart());
  return _getPortfolioComp(uniqueId)
    .then((response) => {
      dispatch(PortfolioCompSuccess(response));
    })
    .catch((error) => {
      dispatch(PortfolioCompError(error));
    });
};

//LIST DATA
export const getList = (uniqueId) => (dispatch) => {
  dispatch(ListStart());
  return _getList(uniqueId)
    .then((response) => {
      dispatch(ListSuccess(response));
    })
    .catch((error) => {
      dispatch(ListError(error));
    });
};

//Company Data
export const getCompanyDash = () => (dispatch) => {
  dispatch(compDataStart());
  return _getCompData()
    .then((response) => {
      dispatch(compDataSuccess(response));
    })
    .catch((error) => {
      dispatch(compDataError(error));
    });
}
//GET COMPANY DATA
export const getCompanyData = (uniqueId) => (dispatch) => {
  dispatch(companyDataStart());
  return _getCompanyData(uniqueId)
  .then((response) => {
    dispatch(companyDataSuccess(response));
  })
  .catch((error) => {
    dispatch(companyDataError(error));
  });
}
// GET ROLE OF THE USER
export const getRoles = (uniqueRoleId) => (dispatch) => {
  dispatch(getRolesStart());
  return _getRoles(uniqueRoleId)
    .then((response) => {
      dispatch(getRolesSuccess(response));
    })
    .catch((error) => {
      dispatch(getRolesError(error));
    });
}

//GET USER DETAILS
export const getUserDetails = (uniqueId) => (dispatch) => {
  dispatch(UserDetailsStart());
  return _getUserDetails(uniqueId)
    .then((response) => {
      dispatch(UserDetailsSuccess(response));
    })
    .catch((error) => {
      dispatch(UserDetailsError(error));
    });
}


// GET UNIQUE LINK
export const getUniqueLink = (uniqueId, uniqueRoleId) => (dispatch) => {
  dispatch(UniqueLinkStart());
  return _getUniqueLink(uniqueId, uniqueRoleId)
    .then((response) => {
      dispatch(UniqueLinkSuccess(response));
    })
    .catch((error) => {
      dispatch(UniqueLinkError(error));
    });
}

//GLOBAL SEARCH
export const getGlobalSearch = (globalSearch) => (dispatch) => {
  dispatch(GlobalSearchStart());
  return _getGlobalSearch(globalSearch)
    .then((response) => {
      dispatch(GlobalSearchSuccess(response));
    })
    .catch((error) => {
      dispatch(GlobalSearchError(error));
    });
}

//EXPERINCE DATA
export const getExperience = (Experience, test) => (dispatch) => {
  dispatch(experienceStart());
  return _getExperience(Experience, test)
    .then((response) => {
      dispatch(experienceSuccess(response));
    })
    .catch((error) => {
      dispatch(experienceError(error));
    });
};

//LOCATION DATA
export const getDesire = (Desire, test) => (dispatch) => {
  dispatch(desireStart());
  return _getDesire(Desire, test)
    .then((response) => {
      dispatch(desireSuccess(response));
    })
    .catch((error) => {
      dispatch(desireError(error));
    });
};

//LOCATION DATA
export const getLocation = (Location, test) => (dispatch) => {
  dispatch(locationStart());
  return _getLocation(Location, test)
    .then((response) => {
      dispatch(locationSuccess(response));
    })
    .catch((error) => {
      dispatch(locationError(error));
    });
};

//LEVEL DATA
export const getLevel = (Level, test) => (dispatch) => {
  dispatch(levelStart());
  return _getLevel(Level, test)
    .then((response) => {
      dispatch(levelSuccess(response));
    })
    .catch((error) => {
      dispatch(levelError(error));
    });
};

// FOR LOGGING_OUT
export const getLogOut = () => {
  return _getLogOut;
};

// WILL BE USED IN THE FUTURE
export const addminLog = (data) => (dispatch) => {
  dispatch(addminLogStart());
  return _addminLog(data)
    .then((response) => {
      dispatch(addminLogSuccess(response));
    })
    .catch((error) => {
      dispatch(addminLogError(error));
    });
};

//VENTURE LOGGED IN DETAILS
export const getfetchVentureLog = (data) => (dispatch) => {
  dispatch(getfetchVentureLogStart());
  return _getfetchVentureLog(data)
  .then((response) => {
    dispatch(getfetchVentureLogSuccess(response));
  }).catch((error) => {
    dispatch(getfetchVentureLogError(error));
  })
}

// SERVER SIDE CONNECTION
export const getRetailerAuthToken = (data) => (dispatch) => {
  dispatch(getRetailerAuthTokenStart());
  return _getRetailerAuthToken(data)
    .then((response) => {
      dispatch(getRetailerAuthTokenSuccess(response));
    })
    .catch((error) => {
      dispatch(getRetailerAuthTokenError(error));
    });
};

// FOR LOGGING_IN THE USER
export const AuthToken = (data) => {
  return _AuthToken(data);
};

//VENTURE ACTION CREATED
export const getfetchVentureLogStart = createAction(ActionTypes.getfetchVentureLog)
export const getfetchVentureLogSuccess = createAction(
  ActionTypes.getfetchVentureLog_SUCCESS,
  (response) => response
);
export const getfetchVentureLogError = createAction(
  ActionTypes.getfetchVentureLog_ERROR,
  (error) => error
);

// ACTION CREATION
export const getRetailerAuthTokenStart = createAction(
  ActionTypes.GET_RETAILER_AUTHENTICATION_TOKEN
);
export const getRetailerAuthTokenSuccess = createAction(
  ActionTypes.GET_RETAILER_AUTHENTICATION_TOKEN_SUCCESS,
  (response) => response
);
export const getRetailerAuthTokenError = createAction(
  ActionTypes.GET_RETAILER_AUTHENTICATION_TOKEN_ERROR,
  (error) => error
);

//GLOBAL SEARCH
export const GlobalSearchStart = createAction(ActionTypes.GLOBALSEARCH)
export const GlobalSearchSuccess = createAction(
  ActionTypes.GLOBALSEARCH_SUCCESS,
  (response) => response
);
export const GlobalSearchError = createAction(
  ActionTypes.GLOBALSEARCH_ERROR,
  (error) => error
);

// LEVEL EXPERIENCE
export const experienceStart = createAction(ActionTypes.EXPERIENCE);
export const experienceSuccess = createAction(
  ActionTypes.EXPERIENCE_SUCCESS,
  (response) => response
);
export const experienceError = createAction(
  ActionTypes.EXPERIENCE_ERROR,
  (error) => error
);

// LEVEL DESIRE
export const desireStart = createAction(ActionTypes.DESIRE);
export const desireSuccess = createAction(
  ActionTypes.DESIRE_SUCCESS,
  (response) => response
);
export const desireError = createAction(
  ActionTypes.DESIRE_ERROR,
  (error) => error
);

// LEVEL FILTER
export const locationStart = createAction(ActionTypes.LOCATION);
export const locationSuccess = createAction(
  ActionTypes.LOCATION_SUCCESS,
  (response) => response
);
export const locationError = createAction(
  ActionTypes.LOCATION_ERROR,
  (error) => error
);

// LEVEL FILTER
export const levelStart = createAction(ActionTypes.LEVEL);
export const levelSuccess = createAction(
  ActionTypes.LEVEL_SUCCESS,
  (response) => response
);
export const levelError = createAction(
  ActionTypes.LEVEL_ERROR,
  (error) => error
);

// Creating User Details Action Type
export const UserDetailsStart = createAction(ActionTypes.UserDetail);
export const UserDetailsSuccess = createAction(
  ActionTypes.UserDetail_SUCCESS,
  (response) => response
);
export const UserDetailsError = createAction(
  ActionTypes.UserDetail_ERROR,
  (error) => error
);

// Creating Unique Link data Action Type
export const UniqueLinkStart = createAction(ActionTypes.UniqueLink);
export const UniqueLinkSuccess = createAction(
  ActionTypes.UniqueLink_SUCCESS,
  (response) => response
);
export const UniqueLinkError = createAction(
  ActionTypes.UniqueLink_ERROR,
  (error) => error
);

//CREAATING FOR ROLE ACTION TYPE
export const getRolesStart = createAction(ActionTypes.Roles);
export const getRolesSuccess = createAction(
  ActionTypes.Roles_SUCCESS,
  (response) => response
);
export const getRolesError = createAction(
  ActionTypes.Roles_ERROR,
  (error) => error
);

// Creating Dashboard data Action Type
export const dashDataStart = createAction(ActionTypes.DASH_DATA);
export const dashDataSuccess = createAction(
  ActionTypes.DASH_DATA_SUCCESS,
  (response) => response
);
export const dashDataError = createAction(
  ActionTypes.DASH_DATA_ERROR,
  (error) => error
);

// Creating NEW Dashboard data Action Type
export const AdvisoryDashDataStart = createAction(ActionTypes.ADVISORY_DATA);
export const AdvisoryDataSuccess = createAction(
  ActionTypes.ADVISORY_DATA_SUCCESS,
  (response) => response
);
export const AdvisoryDataError = createAction(
  ActionTypes.ADVISORY_DATA_ERROR,
  (error) => error
);

// Creating NEW Dashboard data Action Type
export const dashNewDataStart = createAction(ActionTypes.NEWDASH_DATA);
export const dashNewDataSuccess = createAction(
  ActionTypes.NEWDASH_DATA_SUCCESS,
  (response) => response
);
export const dashNewDataError = createAction(
  ActionTypes.NEWDASH_DATA_ERROR,
  (error) => error
);

// Creating Dashboard data Action Type
export const PortfolioCompStart = createAction(ActionTypes.PORT_DATA);
export const PortfolioCompSuccess = createAction(
  ActionTypes.PORT_DATA_SUCCESS,
  (response) => response
);
export const PortfolioCompError = createAction(
  ActionTypes.PORT_DATA_ERROR,
  (error) => error
);

// Creating LIST data Action Type
export const ListStart = createAction(ActionTypes.LIST_DATA);
export const ListSuccess = createAction(
  ActionTypes.LIST_DATA_SUCCESS,
  (response) => response
);
export const ListError = createAction(
  ActionTypes.LIST_DATA_ERROR,
  (error) => error
);

// Creating Company data Action Type
export const compDataStart = createAction(ActionTypes.COMP_DATA);
export const compDataSuccess = createAction(
  ActionTypes.COMP_DATA_SUCCESS,
  (response) => response
);
export const compDataError = createAction(
  ActionTypes.COMP_DATA_ERROR,
  (error) => error
);

//CREATING COMPANY DATA ACTION TYPE
export const companyDataStart = createAction(ActionTypes.COMPANY_DATA);
export const companyDataSuccess = createAction(
  ActionTypes.COMPANY_DATA_SUCCESS,
  (response) => response
);
export const companyDataError = createAction(
  ActionTypes.COMPANY_DATA_ERROR,
  (error) => error
);

export const addminLogStart = createAction(ActionTypes.POST_LOGIN);
export const addminLogSuccess = createAction(
  ActionTypes.POST_LOGIN_SUCCESS,
  (response) => response
);
export const addminLogError = createAction(
  ActionTypes.POST_LOGIN_ERROR,
  (error) => error
);

// HANDALING THE TYPE OF THE ACTION
const reducer = handleActions(
  {
               // USER DATA
               [ActionTypes.UserDetail]: (state) => ({
                ...state,
                User: {
                  ...state.User,
                  loading: true,
                  hasError: false,
                  error: {},
                },
              }),
              [ActionTypes.UserDetail_SUCCESS]: (state, action) => ({
                ...state,
                User: {
                  ...state.User,
                  response: action.payload,
                  loading: false,
                  hasError: false,
                  error: {},
                },
              }),
              [ActionTypes.UserDetail_ERROR]: (state, action) => ({
                ...state,
                User: {
                  ...state.User,
                  error: action.payload,
                  loading: false,
                  hasError: true,
                  response: {},
                },
              }),
                // ROLE DATA
                [ActionTypes.Roles]: (state) => ({
                  ...state,
                  Roles_data: {
                    ...state.Roles_data,
                    loading: true,
                    hasError: false,
                    error: {},
                  },
                }),
                [ActionTypes.Roles_SUCCESS]: (state, action) => ({
                  ...state,
                  Roles_data: {
                    ...state.Roles_data,
                    response: action.payload,
                    loading: false,
                    hasError: false,
                    error: {},
                  },
                }),
                [ActionTypes.Roles_ERROR]: (state, action) => ({
                  ...state,
                  Roles_data: {
                    ...state.Roles_data,
                    error: action.payload,
                    loading: false,
                    hasError: true,
                    response: {},
                  },
                }),
            // VENTURE DATA
            [ActionTypes.getfetchVentureLog]: (state) => ({
              ...state,
              venture_log_data: {
                ...state.venture_log_data,
                loading: true,
                hasError: false,
                error: {},
              },
            }),
            [ActionTypes.getfetchVentureLog_SUCCESS]: (state, action) => ({
              ...state,
              venture_log_data: {
                ...state.venture_log_data,
                response: action.payload,
                loading: false,
                hasError: false,
                error: {},
              },
            }),
            [ActionTypes.getfetchVentureLog_ERROR]: (state, action) => ({
              ...state,
              venture_log_data: {
                ...state.venture_log_data,
                error: action.payload,
                loading: false,
                hasError: true,
                response: {},
              },
            }),
        //UNIQUE LINK DATA
        [ActionTypes.UniqueLink]: (state) => ({
          ...state,
          UniqueLink_data: {
            ...state.UniqueLink_data,
            loading: true,
            hasError: false,
            error: {},
          },
        }),
        [ActionTypes.UniqueLink_SUCCESS]: (state, action) => ({
          ...state,
          UniqueLink_data: {
            ...state.UniqueLink_data,
            response: action.payload,
            loading: false,
            hasError: false,
            error: {},
          },
        }),
        [ActionTypes.UniqueLink_ERROR]: (state, action) => ({
          ...state,
          UniqueLink_data: {
            ...state.UniqueLink_data,
            error: action.payload,
            loading: false,
            hasError: true,
            response: {},
          },
        }),
    //GLOBAL SEARCH DATA
    [ActionTypes.GLOBALSEARCH]: (state) => ({
      ...state,
      global_data: {
        ...state.global_data,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GLOBALSEARCH_SUCCESS]: (state, action) => ({
      ...state,
      global_data: {
        ...state.global_data,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GLOBALSEARCH_ERROR]: (state, action) => ({
      ...state,
      global_data: {
        ...state.global_data,
        error: action.payload,
        loading: false,
        hasError: true,
        response: {},
      },
    }),
    //EXPERIENCE_DATA
    [ActionTypes.EXPERIENCE]: (state) => ({
      ...state,
      experience_data: {
        ...state.experience_data,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.EXPERIENCE_SUCCESS]: (state, action) => ({
      ...state,
      experience_data: {
        ...state.experience_data,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.EXPERIENCE_ERROR]: (state, action) => ({
      ...state,
      experience_data: {
        ...state.experience_data,
        error: action.payload,
        loading: false,
        hasError: true,
        response: {},
      },
    }),
    //DESIRE_DATA
    [ActionTypes.DESIRE]: (state) => ({
      ...state,
      desire_data: {
        ...state.desire_data,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.DESIRE_SUCCESS]: (state, action) => ({
      ...state,
      desire_data: {
        ...state.desire_data,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.DESIRE_ERROR]: (state, action) => ({
      ...state,
      desire_data: {
        ...state.desire_data,
        error: action.payload,
        loading: false,
        hasError: true,
        response: {},
      },
    }),
    //LOCATION_DATA
    [ActionTypes.LOCATION]: (state) => ({
      ...state,
      location: {
        ...state.location,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.LOCATION_SUCCESS]: (state, action) => ({
      ...state,
      location: {
        ...state.location,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.LOCATION_ERROR]: (state, action) => ({
      ...state,
      location: {
        ...state.location,
        error: action.payload,
        loading: false,
        hasError: true,
        response: {},
      },
    }),
    //LEVEL_DATA
    [ActionTypes.LEVEL]: (state) => ({
      ...state,
      level_data: {
        ...state.level_data,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.LEVEL_SUCCESS]: (state, action) => ({
      ...state,
      level_data: {
        ...state.level_data,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.LEVEL_ERROR]: (state, action) => ({
      ...state,
      level_data: {
        ...state.level_data,
        error: action.payload,
        loading: false,
        hasError: true,
        response: {},
      },
    }),
    //GET_COMPONY_DATA
    [ActionTypes.COMPANY_DATA]: (state) => ({
      ...state,
      Company_data: {
        ...state.Company_data,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.COMPANY_DATA_SUCCESS]: (state, action) => ({
      ...state,
      Company_data: {
        ...state.Company_data,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.COMPANY_DATA_ERROR]: (state, action) => ({
      ...state,
      Company_data: {
        ...state.Company_data,
        error: action.payload,
        loading: false,
        hasError: true,
        response: {},
      },
    }),

        //GET_PORT_DATA
        [ActionTypes.PORT_DATA]: (state) => ({
          ...state,
          port_data: {
            ...state.port_data,
            loading: true,
            hasError: false,
            error: {},
          },
        }),
        [ActionTypes.PORT_DATA_SUCCESS]: (state, action) => ({
          ...state,
          port_data: {
            ...state.port_data,
            response: action.payload,
            loading: false,
            hasError: false,
            error: {},
          },
        }),
        [ActionTypes.PORT_DATA_ERROR]: (state, action) => ({
          ...state,
          port_data: {
            ...state.port_data,
            error: action.payload,
            loading: false,
            hasError: true,
            response: {},
          },
        }),


                //GET_LIST_DATA
                [ActionTypes.LIST_DATA]: (state) => ({
                  ...state,
                  list_data: {
                    ...state.list_data,
                    loading: true,
                    hasError: false,
                    error: {},
                  },
                }),
                [ActionTypes.LIST_DATA_SUCCESS]: (state, action) => ({
                  ...state,
                  list_data: {
                    ...state.list_data,
                    response: action.payload,
                    loading: false,
                    hasError: false,
                    error: {},
                  },
                }),
                [ActionTypes.LIST_DATA_ERROR]: (state, action) => ({
                  ...state,
                  list_data: {
                    ...state.list_data,
                    error: action.payload,
                    loading: false,
                    hasError: true,
                    response: {},
                  },
                }),



     //GET_DASH_DATA FOR ADVISORY
     [ActionTypes.ADVISORY_DATA]: (state) => ({
      ...state,
      advisory_data: {
        ...state.advisory_data,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.ADVISORY_DATA_SUCCESS]: (state, action) => ({
      ...state,
      advisory_data: {
        ...state.advisory_data,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.ADVISORY_DATA_ERROR]: (state, action) => ({
      ...state,
      advisory_data: {
        ...state.advisory_data,
        error: action.payload,
        loading: false,
        hasError: true,
        response: {},
      },
    }),       

    //GET_DASH_DATA
    [ActionTypes.DASH_DATA]: (state) => ({
      ...state,
      dash_data: {
        ...state.dash_data,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.DASH_DATA_SUCCESS]: (state, action) => ({
      ...state,
      dash_data: {
        ...state.dash_data,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.DASH_DATA_ERROR]: (state, action) => ({
      ...state,
      dash_data: {
        ...state.dash_data,
        error: action.payload,
        loading: false,
        hasError: true,
        response: {},
      },
    }),

        //GET_NEW_DASH_DATA
        [ActionTypes.NEWDASH_DATA]: (state) => ({
          ...state,
          dash_new_data: {
            ...state.dash_new_data,
            loading: true,
            hasError: false,
            error: {},
          },
        }),
        [ActionTypes.NEWDASH_DATA_SUCCESS]: (state, action) => ({
          ...state,
          dash_new_data: {
            ...state.dash_new_data,
            response: action.payload,
            loading: false,
            hasError: false,
            error: {},
          },
        }),
        [ActionTypes.NEWDASH_DATA_ERROR]: (state, action) => ({
          ...state,
          dash_new_data: {
            ...state.dash_new_data,
            error: action.payload,
            loading: false,
            hasError: true,
            response: {},
          },
        }),

        //GET_COMP_DATA
        [ActionTypes.COMP_DATA]: (state) => ({
          ...state,
          comp_data: {
            ...state.comp_data,
            loading: true,
            hasError: false,
            error: {},
          },
        }),
        [ActionTypes.COMP_DATA_SUCCESS]: (state, action) => ({
          ...state,
          comp_data: {
            ...state.comp_data,
            response: action.payload,
            loading: false,
            hasError: false,
            error: {},
          },
        }),
        [ActionTypes.COMP_DATA_ERROR]: (state, action) => ({
          ...state,
          comp_data: {
            ...state.comp_data,
            error: action.payload,
            loading: false,
            hasError: true,
            response: {},
          },
        }),

    //GET_RETAILER_AUTHENTICATION_TOKEN
    [ActionTypes.GET_RETAILER_AUTHENTICATION_TOKEN]: (state) => ({
      ...state,
      retailer_auth: {
        ...state.retailer_auth,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GET_RETAILER_AUTHENTICATION_TOKEN_SUCCESS]: (
      state,
      action
    ) => ({
      ...state,
      retailer_auth: {
        ...state.retailer_auth,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GET_RETAILER_AUTHENTICATION_TOKEN_ERROR]: (state, action) => ({
      ...state,
      retailer_auth: {
        ...state.retailer_auth,
        error: action.payload,
        loading: false,
        hasError: true,
        response: {},
      },
    }),
    //POST_LOGIN
    [ActionTypes.POST_LOGIN]: (state) => ({
      ...state,
      logIn_auth: {
        ...state.logIn_auth,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.POST_LOGIN_SUCCESS]: (state, action) => ({
      ...state,
      logIn_auth: {
        ...state.logIn_auth,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.POST_LOGIN_ERROR]: (state, action) => ({
      ...state,
      logIn_auth: {
        ...state.logIn_auth,
        error: action.payload,
        loading: false,
        hasError: true,
        response: {},
      },
    }),
  },
  initialState
);

export default reducer;
