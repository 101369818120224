import React, { useRef, useState, useEffect } from "react";
import "./index.css";
import axios from "axios";
import {ReactComponent as Image1} from "./img/mail.svg";
import { ReactComponent as Image27 } from "./img/Load.svg";
import Image2 from "./img/Close.svg";
import Image3 from "./img/Tick-1.svg";
import Image4 from "./img/Tick-2.svg";
import Image5 from "./img/Tick-3.svg";

const Connect = ({ handleConnectModel, ConnectData, candidateDetails, Token, ContactType }) => {
  const [showSuccessModel, setShowSuccessModel] = useState(true);
  const [showEror, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const PrimiumRef = useRef();
  useEffect(() => {
    let handler = (event) => {
      if (!PrimiumRef?.current?.contains(event.target)) {
        handleConnectModel();
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  const handleEmailConnect = () => {
    setIsLoading(true);
      const data = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      };
      const newData = {
        candidate_name: ConnectData?.candidate_name,
        connecttype: ContactType,
        candidate_lastname: ConnectData?.candidate_lastname,
        candidate_profilepic: ConnectData?.candidate_profilepic,
        candidate_title: ConnectData?.candidate_title,
        cc: ConnectData?.cc,
        contact_address_name: ConnectData?.contact_address_name,
        portco_user_firstname: ConnectData?.portco_user_firstname,
        portco_user_lastname: ConnectData?.portco_user_lastname,
        portco_name: ConnectData?.portco_name,
        candidate_main_expertise: ConnectData?.candidate_main_expertise,
        portco_name_website: ConnectData?.portco_name_website,
        portco_user_name: ConnectData?.portco_user_name,
        portco_user_title: ConnectData?.portco_user_title,
        recipients: ConnectData?.recipients,
        reply_to: ConnectData?.reply_to,
        subject: ConnectData?.subject,
        venture_logo: ConnectData?.venture_logo,
        candidate_id: ConnectData?.candidate_id,
        portco_user_company_id: ConnectData?.portco_user_company_id,
        portco_user_id: ConnectData?.portco_user_id,
        vc_id:ConnectData?.vc_id,
        vc_name:ConnectData?.vc_name,
      }
      axios
        .post(`/api/companies/connect/mail`, newData, data)
        .then((responce) => {
          if(responce.status == 200){
            setShowSuccessModel(false)
          }else{
            setShowError(true)
          }
        })
        .catch((error) => {
          // Handle error if needed
          console.error('API request failed:', error);
          setShowError(true);
        })
        .finally(() => {
          // Set loading state to false when the API call is completed, regardless of success or failure
          setIsLoading(false);
        });
  }

  const handleClose = (e) => {
    e.preventDefault();
    handleConnectModel();
    setShowError(false);
  }

  const Namedata = ConnectData?.candidate_name;
  console.log(ConnectData, "ConnectData")
  const CCData = `${ConnectData?.portco_user_firstname} (${ConnectData?.portco_name})`;
  const handleTextAreaFunction = () => {
    return (
      <div className="textareacontent">
        Hey {candidateDetails?.firstname?.trim()},<br />
        <br />
        {ConnectData?.portco_user_firstname} {ConnectData?.portco_user_lastname} ({ConnectData?.portco_user_title} @{" "}
        {ConnectData?.portco_name}) has seen your profile on our {ContactType == "advisor" ? "Advisor Network" : "Talent Network"}{" "}
        and would like to connect.
        <br />
        <br />
        Reply to this email and get in touch with{" "}
        {ConnectData?.portco_user_firstname} directly to schedule a meeting or
        request more information.
        <br />
        <br />
        In the meantime you can check out {ConnectData?.portco_name}'s{" "}
        <a
          href={
            ConnectData?.portco_name_website?.includes("http")
              ? ConnectData?.portco_name_website
              : `https://${ConnectData?.portco_name_website}`
          }
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#3D424D" }}
          className="underline"
        >
          website
        </a>
        <br />
        <br /> Have a great day!
        <br />
        {ConnectData?.contact_address_name}
      </div>
    );
  };

  return (
    <>
    {showSuccessModel == true ? (
      <div className="popup-premium modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden show">
        <div className="modal-backdrop fade show"></div>
        <div
          className="modal-dialog  modal-dialog-centered relative"
          style={{ backgroundColor: 'transparent'}}
        >
          <div className="popupbox" ref={PrimiumRef}>
            <div className="popupbox-innerr">
              <div className="popupbox-top">
                <h2>Connect</h2>
              </div>
              <div className="popupbox-contentbox">
                <div className="popupbox-body">
                  <p>
                    Click <b>connect</b> to be introduced to{" "}
                    <b>{candidateDetails?.firstname}</b> over email
                  </p>
                  <p
                    className="titlehead"
                    style={{ backgroundColor: "#C8C8C8" }}
                  >
                    New Introduction
                  </p>
                  <div className="popupbox-list">
                    <div class="container">
                      <form action="/action_page.php">
                        <div class="row">
                          <div class="col-25">
                            <label for="fname">To:</label>
                          </div>
                          <div class="col-75">
                            <input
                              type="text"
                              id="fname"
                              name="firstname"
                              value={Namedata}
                              readOnly={true}
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-25">
                            <label for="lname">From:</label>
                          </div>
                          <div class="col-75">
                            <input
                              type="text"
                              id="lname"
                              name="lastname"
                              readOnly={true}
                              value={ConnectData?.contact_address_name}
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-25">
                            <label for="lname">CC:</label>
                          </div>
                          <div class="col-75">
                            <input
                              type="text"
                              id="lname"
                              name="lastname"
                              readOnly={true}
                              value={CCData}
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-25">
                            <label for="lname"></label>
                          </div>
                          <div class="col-75">
                            <div style={{ height: "362px" }} id="textarea">
                              {handleTextAreaFunction()}
                            </div>
                            {/* <textarea
                              style={{ height: "362px" }}
                              type="text"
                              id="lname"
                              name="lastname"
                              value={TextArea}
                            /> */}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  {showEror == true ? (
                    <p style={{paddingTop: '20px', fontSize: '14px', fontWeight: '700', color: '#FF0000'}}>
                  Unfortunately, something went wrong. Please try again later.
                  </p>
                  ) : null}
                  <div class="row float-right" style={{paddingTop: '35px'}}>
                  {isLoading == true ? (
                    <a
                      href="javascript:void(0);"
                      className={"btn dark flex"}
                      // onClick={handleEmailConnect}
                    >
                      <Image27 style={{marginTop: '0.25rem', marginRight: '6px'}} width={'16px'}/>{" "}Loading
                    </a>
                  ) : (
                    <>
                    {showEror == true ? (
                    <>
                    <a
                      href="javascript:void(0);"
                      className={"btn mr-2"}
                      onClick={(e) => handleClose(e)}
                    >
                      Close
                    </a>
                    </>
                  ) : (
                    <>
                    <a
                      href="javascript:void(0);"
                      className={"btn mr-2"}
                      onClick={handleConnectModel}
                    >
                      Cancel
                    </a>
                    <a
                      href="javascript:void(0);"
                      className={"btn dark"}
                      onClick={handleEmailConnect}
                    >
                      Connect
                    </a>
                    </>
                  )}
                    </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="popup-premium modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden show">
        <div className="modal-backdrop fade show"></div>
        <div
          ref={PrimiumRef}
          className="modal-dialog  modal-dialog-centered relative"
          style={{ backgroundColor: 'transparent'}}
        >
          <div className="popupbox">
            <div className="popupbox-innerr">
              <div className="popupbox-top">
                <h2>Connected</h2>
              </div>
              <div className="popupbox-contentbox">
                <div className="popupbox-body">
                  <p>
                  The introduction email has <b>been sent</b>, please check your inbox.
                  </p>
                  <div>
                  <p style={{fontWeight: '400', marginTop: '15px', fontStyle: 'italic', fontSize: '16px', paddingLeft: '16px', paddingRight: '16px', color: '#3D424D'}}>
                  To maximize candidate engagement we recommend you reply directly to them with more details
                  </p>
                  </div>
                  <div className="flex justify-content-center" style={{marginTop: '15px', paddingLeft: '16px', paddingRight: '16px',}}>
                   <Image1 />
                  </div>
                  <div class="row float-right" style={{paddingTop: '35px'}}>
                    <a
                      href="javascript:void(0);"
                      className={"btn mr-2"}
                      onClick={handleConnectModel}
                    >
                      Close
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
    </>
  );
};

export default Connect;
