import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import "../../../Login/index.css";
import { CSVLink } from "react-csv";
import DataTable from "react-data-table-component";
import { getCookie } from "../../../../shared/utils/cookies";
import { PropTypes } from "prop-types";
import { getCompanyData } from "../../../authentication";
import { Helmet } from "react-helmet";
import moment from "moment";
import axios from "axios";


const CompanyTeamMamber = ({ authentication_state }) => {
    const authentication_ref = useRef(authentication_state);
    authentication_ref.current = authentication_state;
    const [search, setSearch] = useState("");
    const [cardData, setCardData] = useState([]);
    const [FullData, setFullData] = useState([])
    const [AddCompanyTeamMemberData, setAddCompanyTeamMemberData] = useState([]);
    const CompanyName = authentication_ref?.current?.port_data?.response?.data?.data[0] || []
    const CompanyID = authentication_ref?.current?.User?.response?.data?.data?.id || [];

    useEffect(() => {
        const result = FullData.filter((candidate) => {
          return(
            candidate.firstname.toLocaleLowerCase().match(search.toLocaleLowerCase()) || 
            candidate.lastname.toLocaleLowerCase().match(search.toLocaleLowerCase())
          )
        });
        setAddCompanyTeamMemberData(result);
      }, [search]);


    useEffect(() => {
        const CompanyID = authentication_ref?.current?.User?.response?.data?.data?.id || [];
        axios({
            method: "get",
            url: `/api/companies/companies/getteam/${CompanyID}`,
            headers: {
              "Content-Type":
                "multipart/form-data; boundary=<calculated when request is sent>",
            },
          }).then((res) => {
            if(res.status == 200){
                setAddCompanyTeamMemberData(res?.data?.data[0]?.users)
                setFullData(res?.data?.data[0]?.users)
            }
          })
      },[CompanyID])

      const headers = [
        {
          label: "First Name",
          key: "firstname",
        },
        {
          label: "Last Name",
          key: "lastname",
        },
        {
          label: "Role",
          key: "current_role",
        },
        {
          label: "Email",
          key: "email",
        },
        {
          label: "Date Joined",
          key: "created_at",
        },
      ];
      
      const csvLink = {
        filename: "TeamMembers.csv",
        headers: headers,
        data: cardData,
      };

      const handleRowData = (state) => {
        setCardData(state.selectedRows);
      };

      const CustomTitleName = ({ row }) => (
        <div>
          {}
          <div>{`${row.firstname} ${row.lastname}`}</div>
          <div>
            <div
              data-tag="allowRowEvents"
              style={{
                color: "grey",
                overflow: "hidden",
                whiteSpace: "wrap",
                textOverflow: "ellipses",
              }}
            >
              {}
              {row.plot}
            </div>
          </div>
        </div>
      )

      const CustomTitleLinkIn = ({ row }) => (
        <div>
          {}
          <div>{row.current_role}</div>
          <div>
            <div
              data-tag="allowRowEvents"
              style={{
                color: "grey",
                overflow: "hidden",
                whiteSpace: "wrap",
                textOverflow: "ellipses",
              }}
            >
              {}
              {row.plot}
            </div>
          </div>
        </div>
      );

      const CustomTitleEmail = ({ row }) => (
        <div>
          {}
          <div>{row.email}</div>
          <div>
            <div
              data-tag="allowRowEvents"
              style={{
                color: "grey",
                overflow: "hidden",
                whiteSpace: "wrap",
                textOverflow: "ellipses",
              }}
            >
              {}
              {row.plot}
            </div>
          </div>
        </div>
      );

      
      const CustomTitleCreated = ({row}) => (
        <>
            {}
            
          <div>{moment(row?.created_at).format('DD-MM-YYYY')}</div>
          <div>
            <div
              data-tag="allowRowEvents"
              style={{
                color: "grey",
                overflow: "hidden",
                whiteSpace: "wrap",
                textOverflow: "ellipses",
              }}
            >
              {}
              {row.plot}
            </div>
          </div>
        </>
      );

      const columns = [
        {
          name: "Full Name",
          selector: (row) => `${row.firstname}  ${row.lastname}`,
          sortable: true,
          cell: (row) => <CustomTitleName row={row} />,
          maxWidth: "650px",
          minWidth: "150px",
        },
        {
          name: "Role",
          selector: (row) => row.current_role,
          cell: (row) => <CustomTitleLinkIn row={row} />,
          maxWidth: "650px",
          minWidth: "200px",
        },
        {
          name: "Email",
          selector: (row) => row.email,
          cell: (row) => <CustomTitleEmail row={row} />,
          maxWidth: "650px",
        },
        {
          name: "Date Joined",
          selector: (row) =>moment(row?.created_at).format('DD-MM-YYYY'),
          cell: (row) => <CustomTitleCreated row={row} />,
          maxWidth: "650px",
          minWidth: "150px",
        },
      ];

      const handleTeamMemebrAdding = () => {
        if (CompanyName.company_name == "Octopus Ventures") {
            window.open(`${window.location.origin}/OctopusVentures/register`);
          } else if (CompanyName.company_name == "Anzu Partners") {
            window.open(`${window.location.origin}/AnzuPartners/register`);
          } else if (CompanyName.company_name == "Burnt Island Ventures") {
            window.open(`${window.location.origin}/BurntIslandVentures/register`);
          } else if (CompanyName.company_name == "Left Lane Capital") {
            window.open(`${window.location.origin}/LLC/register`);
          } else if (CompanyName.company_name == "Demo VC") {
            window.open(`${window.location.origin}/Demo-VC/register`);
          } else if (CompanyName.company_name == "Upfront Ventures") {
            window.open(`${window.location.origin}/UpfrontVentures/register`);
          } else if (CompanyName.company_name == "M13") {
            window.open(`${window.location.origin}/M13/register`);
          } else if (CompanyName.company_name == "Human Capital") {
            window.open(`${window.location.origin}/HumanCapital/register`);
          } else if (
            CompanyName.company_name == "Global VC Talent Network"
          ) {
            window.open(`${window.location.origin}/GVCTN/register`);
          } else if (CompanyName?.length == 0) {
            window.open(`${window.location.origin}/startupLane/register`);
          }
      }



    return( <>
        <Helmet>
          <title>Team Members</title>
        </Helmet>
        <div
          className="leading-normal text-gray-1000 bg-deep-purple-10 font-sans min-h-screen	h-full"
          style={{ fontFamily: "DM Sans, sans-serif" }}
        >
          <div className="w-full fixed inset-x-0 z-20 bg-deep-purple-10 top-0 border-b border-gray-300]">
            <div className="w-full mx-auto flex flex-wrap items-center justify-between py-4 px-4">
              <h1 className="mr-auto">
                <a
                  className="text-deep-purple-500 no-underline hover:no-underline font-bold text-2xl lg:text-4xl w-20 sm:w-24 block"
                  href="/dashboard/candidates"
                >
                  <svg
                    viewBox="0 0 98 42"
                    className="w-full"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M25.4552 41.8057C24.7351 41.8057 24.0611 41.6949 23.4332 41.4733C22.8238 41.2332 22.2976 40.9101 21.8544 40.5038C21.4112 40.0791 21.0603 39.5898 20.8018 39.0358C20.5433 38.4634 20.414 37.8447 20.414 37.18C20.414 36.4598 20.5618 35.8043 20.8572 35.2133C21.1711 34.6224 21.6051 34.1146 22.1591 33.6899C22.713 33.2652 23.3686 32.9328 24.1257 32.6927C24.8828 32.4527 25.723 32.3327 26.6463 32.3327C27.2557 32.3327 27.8466 32.3881 28.419 32.4989C28.9915 32.5912 29.49 32.7389 29.9148 32.942V32.2773C29.9148 30.5969 28.9453 29.7567 27.0064 29.7567C26.2123 29.7567 25.4552 29.8952 24.7351 30.1721C24.0149 30.4307 23.2578 30.8277 22.4637 31.3632L21.1619 28.5933C22.1221 27.9655 23.1193 27.4946 24.1534 27.1807C25.1875 26.8667 26.2862 26.7098 27.4496 26.7098C29.647 26.7098 31.3459 27.2268 32.5462 28.2609C33.7464 29.295 34.3466 30.8092 34.3466 32.8035V36.5429C34.3466 36.9861 34.4112 37.3 34.5405 37.4847C34.6882 37.6509 34.9375 37.7524 35.2884 37.7893V41.5287C34.9006 41.621 34.5497 41.6764 34.2358 41.6949C33.9219 41.7318 33.6357 41.7503 33.3771 41.7503C32.5462 41.7503 31.9183 41.5933 31.4936 41.2794C31.0689 40.947 30.8011 40.4854 30.6903 39.8945L30.6072 39.2297C29.9609 40.0607 29.1854 40.6977 28.2805 41.1409C27.3942 41.5841 26.4524 41.8057 25.4552 41.8057ZM26.7571 38.6203C27.2372 38.6203 27.6988 38.5372 28.142 38.371C28.6037 38.2048 28.9822 37.9832 29.2777 37.7063C29.7024 37.3739 29.9148 37.023 29.9148 36.6537V35.2687C29.527 35.121 29.1023 35.0102 28.6406 34.9364C28.1974 34.844 27.7819 34.7979 27.3942 34.7979C26.5817 34.7979 25.9076 34.9825 25.3721 35.3518C24.8551 35.7212 24.5966 36.2013 24.5966 36.7922C24.5966 37.3277 24.7997 37.7709 25.2059 38.1217C25.6122 38.4541 26.1292 38.6203 26.7571 38.6203Z"
                      fill="#182135"
                    />
                    <path
                      d="M51.5736 41.5287H47.1418V33.3575C47.1418 32.3973 46.9571 31.7048 46.5878 31.2801C46.2369 30.8369 45.7661 30.6153 45.1751 30.6153C44.8612 30.6153 44.5473 30.68 44.2334 30.8092C43.9195 30.9385 43.6148 31.1231 43.3193 31.3632C43.0239 31.5848 42.7561 31.8525 42.516 32.1665C42.2945 32.4804 42.119 32.822 41.9898 33.1913V41.5287H37.5579V26.9868H41.5466V29.4243C42.0821 28.5564 42.8392 27.8916 43.8179 27.4299C44.7966 26.9498 45.923 26.7098 47.1972 26.7098C48.1389 26.7098 48.896 26.8852 49.4685 27.2361C50.0409 27.5684 50.4841 28.0116 50.798 28.5656C51.1119 29.1011 51.3151 29.692 51.4074 30.3383C51.5182 30.9846 51.5736 31.6125 51.5736 32.2219V41.5287Z"
                      fill="#182135"
                    />
                    <path
                      d="M61.4706 41.8057C60.2518 41.8057 59.1531 41.6118 58.1744 41.224C57.2142 40.8178 56.3924 40.273 55.7092 39.5898C55.0444 38.9065 54.5366 38.1217 54.1857 37.2354C53.8349 36.3305 53.6595 35.3888 53.6595 34.4101C53.6595 33.3575 53.8349 32.3696 54.1857 31.4463C54.5366 30.5045 55.0444 29.6828 55.7092 28.9811C56.374 28.2794 57.1865 27.7254 58.1467 27.3192C59.1254 26.9129 60.2333 26.7098 61.4706 26.7098C62.7078 26.7098 63.8065 26.9129 64.7667 27.3192C65.727 27.7254 66.5395 28.2794 67.2042 28.9811C67.869 29.6643 68.3676 30.4584 68.7 31.3632C69.0508 32.268 69.2263 33.219 69.2263 34.2162C69.2263 34.4747 69.217 34.7332 69.1986 34.9918C69.1801 35.2318 69.1524 35.4442 69.1155 35.6288H58.3683C58.4421 36.6075 58.793 37.3554 59.4208 37.8724C60.0671 38.371 60.7966 38.6203 61.6091 38.6203C62.2554 38.6203 62.874 38.4634 63.4649 38.1494C64.0743 37.8355 64.4805 37.4108 64.6836 36.8753L68.4507 37.9278C67.8782 39.0727 66.9919 40.0053 65.7916 40.7254C64.5913 41.4456 63.151 41.8057 61.4706 41.8057ZM64.5451 32.8866C64.4528 31.9633 64.1204 31.2339 63.548 30.6984C62.9755 30.1629 62.2646 29.8952 61.4152 29.8952C60.5473 29.8952 59.8271 30.1721 59.2546 30.7261C58.7007 31.2616 58.3775 31.9818 58.2852 32.8866H64.5451Z"
                      fill="#182135"
                    />
                    <path
                      d="M11.9946 24.9039H16.4265V36.349C16.4265 37.457 16.8973 38.0109 17.8391 38.0109H19.6024V41.778H18.04H15.9556C14.6814 41.778 13.7027 41.4456 13.0195 40.7808C12.3362 40.1161 11.9946 39.1558 11.9946 37.9001V24.9039Z"
                      fill="#182135"
                    />
                    <path
                      d="M14.1072 19.077L18.5957 25.1809H9.61869L14.1072 19.077Z"
                      fill="#182135"
                    />
                    <path
                      d="M7.07766 20.2667C5.84044 20.2667 4.62168 20.0728 3.42139 19.685C2.22111 19.2787 1.20548 18.6971 0.374512 17.94L1.95335 15.2255C2.83972 15.8348 3.69838 16.3057 4.52935 16.6381C5.37879 16.9705 6.19129 17.1367 6.96686 17.1367C7.5393 17.1367 7.98249 17.0351 8.29641 16.832C8.61033 16.6289 8.76729 16.3334 8.76729 15.9456C8.76729 15.5579 8.56416 15.2532 8.15791 15.0316C7.77013 14.7915 7.07765 14.5422 6.08049 14.2837C5.1018 14.0067 4.27083 13.739 3.58759 13.4804C2.92281 13.2219 2.37807 12.9357 1.95335 12.6218C1.52863 12.3078 1.22395 11.9478 1.03929 11.5415C0.854627 11.1353 0.762297 10.6551 0.762297 10.1012C0.762297 9.36253 0.910025 8.68852 1.20548 8.07914C1.50094 7.46977 1.90719 6.95272 2.42423 6.528C2.94128 6.10329 3.55066 5.7709 4.25236 5.53084C4.97254 5.29078 5.74811 5.17075 6.57907 5.17075C7.66857 5.17075 8.68419 5.31848 9.62596 5.61394C10.5862 5.90939 11.5095 6.44491 12.3959 7.22048L10.6785 9.87957C9.84755 9.30713 9.10891 8.89165 8.4626 8.63312C7.81629 8.3746 7.18845 8.24534 6.57907 8.24534C6.09896 8.24534 5.69271 8.3469 5.36032 8.55003C5.02793 8.73468 4.86174 9.03937 4.86174 9.46409C4.86174 9.87034 5.03717 10.175 5.38802 10.3782C5.75734 10.5813 6.43135 10.8029 7.41004 11.0429C8.44414 11.3199 9.31204 11.5969 10.0137 11.8739C10.7339 12.1509 11.3156 12.4648 11.7588 12.8157C12.202 13.148 12.5251 13.5358 12.7282 13.979C12.9314 14.4222 13.0329 14.9485 13.0329 15.5579C13.0329 16.9982 12.4974 18.1431 11.4264 18.9925C10.3554 19.842 8.90579 20.2667 7.07766 20.2667Z"
                      fill="#6A35FF"
                    />
                    <path
                      d="M24.584 19.2141C23.9747 19.4726 23.273 19.7127 22.4789 19.9343C21.7033 20.1559 20.9185 20.2667 20.1245 20.2667C19.5705 20.2667 19.0442 20.1928 18.5457 20.0451C18.0656 19.9158 17.6408 19.7035 17.2715 19.408C16.9022 19.0941 16.6067 18.6878 16.3851 18.1893C16.182 17.6907 16.0805 17.0905 16.0805 16.3888V8.79932H14.2246V5.44774H16.0805V0.822021H20.5123V5.44774H23.4761V8.79932H20.5123V14.8654C20.5123 15.3455 20.6323 15.6964 20.8724 15.9179C21.1124 16.1211 21.4171 16.2226 21.7864 16.2226C22.1188 16.2226 22.4604 16.1672 22.8113 16.0564C23.1622 15.9456 23.4668 15.8256 23.7254 15.6964L24.584 19.2141Z"
                      fill="#6A35FF"
                    />
                    <path
                      d="M30.438 20.2667C29.7179 20.2667 29.0439 20.1559 28.416 19.9343C27.8066 19.6942 27.2804 19.3711 26.8372 18.9648C26.394 18.5401 26.0431 18.0508 25.7846 17.4968C25.5261 16.9243 25.3968 16.3057 25.3968 15.641C25.3968 14.9208 25.5446 14.2652 25.84 13.6743C26.1539 13.0834 26.5879 12.5756 27.1419 12.1509C27.6958 11.7262 28.3514 11.3938 29.1085 11.1537C29.8656 10.9137 30.7058 10.7936 31.6291 10.7936C32.2385 10.7936 32.8294 10.849 33.4018 10.9598C33.9743 11.0522 34.4728 11.1999 34.8976 11.403V10.7382C34.8976 9.05784 33.9281 8.21764 31.9892 8.21764C31.1951 8.21764 30.438 8.35613 29.7179 8.63312C28.9977 8.89165 28.2406 9.28866 27.4465 9.82418L26.1447 7.05428C27.1049 6.42644 28.1021 5.95556 29.1362 5.64164C30.1703 5.32772 31.269 5.17075 32.4324 5.17075C34.6298 5.17075 36.3287 5.6878 37.529 6.7219C38.7292 7.75599 39.3294 9.2702 39.3294 11.2645V15.0039C39.3294 15.4471 39.394 15.761 39.5233 15.9456C39.671 16.1118 39.9203 16.2134 40.2712 16.2503V19.9897C39.8834 20.082 39.5325 20.1374 39.2186 20.1559C38.9047 20.1928 38.6184 20.2113 38.3599 20.2113C37.529 20.2113 36.9011 20.0543 36.4764 19.7404C36.0517 19.408 35.7839 18.9464 35.6731 18.3554L35.59 17.6907C34.9437 18.5216 34.1682 19.1587 33.2633 19.6019C32.377 20.0451 31.4352 20.2667 30.438 20.2667ZM31.7399 17.0813C32.22 17.0813 32.6816 16.9982 33.1248 16.832C33.5865 16.6658 33.965 16.4442 34.2605 16.1672C34.6852 15.8348 34.8976 15.484 34.8976 15.1147V13.7297C34.5098 13.582 34.0851 13.4712 33.6234 13.3973C33.1802 13.305 32.7647 13.2588 32.377 13.2588C31.5645 13.2588 30.8904 13.4435 30.3549 13.8128C29.8379 14.1821 29.5794 14.6623 29.5794 15.2532C29.5794 15.7887 29.7825 16.2319 30.1887 16.5827C30.595 16.9151 31.112 17.0813 31.7399 17.0813Z"
                      fill="#6A35FF"
                    />
                    <path
                      d="M51.7645 9.2425C50.6935 9.26096 49.724 9.43639 48.8561 9.76878C47.9882 10.1012 47.3604 10.5997 46.9726 11.2645V19.9897H42.5407V5.44774H46.6125V8.38383C47.1111 7.40514 47.7481 6.6388 48.5237 6.08482C49.3177 5.53084 50.1579 5.24462 51.0443 5.22615C51.2474 5.22615 51.3952 5.22615 51.4875 5.22615C51.5798 5.22615 51.6722 5.23539 51.7645 5.25385V9.2425Z"
                      fill="#6A35FF"
                    />
                    <path
                      d="M63.0488 19.2141C62.4394 19.4726 61.7377 19.7127 60.9437 19.9343C60.1681 20.1559 59.3833 20.2667 58.5892 20.2667C58.0353 20.2667 57.509 20.1928 57.0104 20.0451C56.5303 19.9158 56.1056 19.7035 55.7362 19.408C55.3669 19.0941 55.0715 18.6878 54.8499 18.1893C54.6468 17.6907 54.5452 17.0905 54.5452 16.3888V8.79932H52.6894V5.44774H54.5452V0.822021H58.977V5.44774H61.9408V8.79932H58.977V14.8654C58.977 15.3455 59.0971 15.6964 59.3371 15.9179C59.5772 16.1211 59.8819 16.2226 60.2512 16.2226C60.5836 16.2226 60.9252 16.1672 61.276 16.0564C61.6269 15.9456 61.9316 15.8256 62.1901 15.6964L63.0488 19.2141Z"
                      fill="#6A35FF"
                    />
                    <path
                      d="M69.1798 20.2667C67.684 20.2667 66.5484 19.805 65.7728 18.8817C65.0157 17.94 64.6371 16.5643 64.6371 14.7546V5.44774H69.069V13.7851C69.069 14.6715 69.2444 15.3547 69.5952 15.8348C69.9461 16.2965 70.4447 16.5273 71.091 16.5273C71.6819 16.5273 72.2543 16.3704 72.8083 16.0564C73.3623 15.7425 73.8516 15.1978 74.2764 14.4222V5.44774H78.7082V15.0039C78.7082 15.4471 78.7728 15.761 78.9021 15.9456C79.0498 16.1118 79.2991 16.2134 79.65 16.2503V19.9897C79.2252 20.082 78.8652 20.1374 78.5697 20.1559C78.2742 20.1928 78.0065 20.2113 77.7664 20.2113C76.1784 20.2113 75.2735 19.5927 75.0519 18.3554L74.9688 17.4691C74.3041 18.4293 73.4731 19.1403 72.4759 19.6019C71.4972 20.0451 70.3985 20.2667 69.1798 20.2667Z"
                      fill="#6A35FF"
                    />
                    <path
                      d="M91.3376 20.2667C90.2297 20.2667 89.2694 20.0359 88.4569 19.5742C87.6444 19.1126 87.0166 18.4662 86.5734 17.6353V28.6931H82.1416V5.44774H85.9917V7.80215C86.5272 6.97119 87.192 6.32488 87.986 5.86323C88.7985 5.40158 89.7588 5.17075 90.8667 5.17075C91.8454 5.17075 92.741 5.36465 93.5535 5.75243C94.3845 6.14022 95.0954 6.67573 95.6863 7.35897C96.2957 8.02375 96.7666 8.81778 97.099 9.74108C97.4498 10.6459 97.6253 11.6246 97.6253 12.6772C97.6253 13.7667 97.4683 14.7731 97.1544 15.6964C96.8405 16.6196 96.4065 17.4229 95.8525 18.1062C95.2986 18.7709 94.6338 19.2972 93.8582 19.685C93.0826 20.0728 92.2424 20.2667 91.3376 20.2667ZM89.8419 16.5273C90.322 16.5273 90.7559 16.4258 91.1437 16.2226C91.55 16.001 91.9008 15.7148 92.1963 15.364C92.4917 15.0131 92.7133 14.6069 92.8611 14.1452C93.0273 13.6651 93.1103 13.1665 93.1103 12.6495C93.1103 12.114 93.018 11.6246 92.8334 11.1814C92.6672 10.7198 92.4271 10.3228 92.1132 9.99037C91.8177 9.65798 91.4484 9.39946 91.0052 9.2148C90.5805 9.03014 90.1189 8.93781 89.6203 8.93781C89.0109 8.93781 88.42 9.12247 87.8475 9.49179C87.2936 9.84264 86.8689 10.3228 86.5734 10.9321V13.9236C86.8689 14.6623 87.312 15.2809 87.9029 15.7794C88.5123 16.278 89.1586 16.5273 89.8419 16.5273Z"
                      fill="#6A35FF"
                    />
                    <path
                      d="M84.5398 34.5106L80.0512 28.4068L89.0283 28.4068L84.5398 34.5106Z"
                      fill="#6A35FF"
                    />
                  </svg>
                  <span className="sr-only">Startup Lane</span>
                </a>
              </h1>
            </div>
          </div>
          <div className="pt-[9.5rem] sm:pt-[9.75rem] lg:pt-[11rem]">
          <section className="fixed z-10 inset-x-0 top-[104px] sm:top-[4rem] lg:top-[4.25rem] bg-deep-purple-10 w-full px-4 pt-2 lg:pt-4 border-b border-gray-300">
              <form className="flex relative TableHead" method="POST" action="#">
                <div className="inline-flex items-center group lg:w-48 lg:pr-1.5 mb-2 lg:mb-4">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    className="absolute z-[1] left-3"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.50441 0.554688C3.19958 0.554688 0.504395 3.24987 0.504395 6.55471C0.504395 9.85954 3.19958 12.5547 6.50441 12.5547C8.02237 12.5547 9.4082 11.9825 10.4668 11.0474L11.0044 11.585V12.5547L13.6939 15.2442C14.1079 15.6582 14.7799 15.6582 15.1939 15.2442C15.6079 14.8302 15.6079 14.1582 15.1939 13.7442L12.5044 11.0547H11.5347L10.9971 10.5171C11.9322 9.45849 12.5044 8.07266 12.5044 6.55471C12.5044 3.24987 9.80925 0.554688 6.50441 0.554688ZM6.50441 2.05469C8.99859 2.05469 11.0044 4.06053 11.0044 6.55471C11.0044 9.04888 8.99859 11.0547 6.50441 11.0547C4.01024 11.0547 2.0044 9.04888 2.0044 6.55471C2.0044 4.06053 4.01024 2.05469 6.50441 2.05469Z"
                      fill="#6936FF"
                    />
                  </svg>
                  <input
                    type="search"
                    id="search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyDown={(e) => {if (e.key === 'Enter') {
                  e.preventDefault()
                  setSearch(e.target.value)
                }}}
                    placeholder="Search"
                    className="w-[42px] absolute sm:relative hover:w-full focus:w-full sm:w-48 sm:hover:w-48 sm:focus:w-48 sm:active:w-48 appearance-none text-ellipsis text-base leading-3 sm:leading-6 bg-white font-normal text-gray-800  placeholder:text-gray-400 rounded-lg py-2 hover:pr-4 focus:pr-4 sm:pr-4 pl-9 border border-gray-300 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:border-deep-purple-500 transition duration-500"
                  />
                </div>
                <div className="flex items-center ml-auto justify-between mb-2 lg:mb-4">
                <button
                    type="button"
                    onClick={handleTeamMemebrAdding}
                    className="add-candidate-btn ml-3 inline-flex items-center bg-deep-purple-500 hover:bg-deep-purple-400 text-white text-base font-medium whitespace-nowrap rounded-lg hover:shadow-md transition duration-200 py-3 px-4"
                  >
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      className="mr-1"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.09814 0.0386963C3.67974 0.0386963 0.0981445 3.6203 0.0981445 8.0387C0.0981445 12.4571 3.67974 16.0387 8.09814 16.0387C12.5165 16.0387 16.0981 12.4571 16.0981 8.0387C16.0981 3.6203 12.5165 0.0386963 8.09814 0.0386963ZM10.4981 8.8387H8.89814V10.4387C8.89814 10.8803 8.53974 11.2387 8.09814 11.2387C7.65654 11.2387 7.29814 10.8803 7.29814 10.4387V8.8387H5.69814C5.25654 8.8387 4.89814 8.4803 4.89814 8.0387C4.89814 7.5971 5.25654 7.2387 5.69814 7.2387H7.29814V5.6387C7.29814 5.1971 7.65654 4.8387 8.09814 4.8387C8.53974 4.8387 8.89814 5.1971 8.89814 5.6387V7.2387H10.4981C10.9397 7.2387 11.2981 7.5971 11.2981 8.0387C11.2981 8.4803 10.9397 8.8387 10.4981 8.8387Z"
                        fill="white"
                      />
                    </svg>
                    Add Team Member
                  </button>
                  {cardData.length === 0 ? (  <button
                    type="button"
                    className={cardData.length === 0 ? "add-candidate-btnnn ml-3 inline-flex items-center text-white text-base font-medium whitespace-nowrap rounded-lg transition duration-200 py-3 px-4" : "add-candidate-btn ml-3 inline-flex items-center bg-deep-purple-500 hover:bg-deep-purple-400 text-white text-base font-medium whitespace-nowrap rounded-lg hover:shadow-md transition duration-200 py-3 px-4"} 
                  >
                    <svg
                      width="16px" className="mr-2" height="19px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"
                    >
                     <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                     <g id="noun-download-5485540" fill="white" fill-rule="nonzero">
                     <path d="M11,0 C4.92486775,0 0,4.92486775 0,11 C0,17.0751322 4.92486775,22 11,22 C17.0751322,22 22,17.0751322 22,11 C22,8.08261861 20.8410748,5.28472557 18.7781746,3.22182541 C16.7152744,1.15892524 13.9173814,0 11,0 Z M14.71,13.71 L11.71,16.71 C11.5212812,16.8971865 11.265803,17.0015368 11,17.0000167 C10.735233,16.9963165 10.4816338,16.8927337 10.29,16.71 L7.29,13.71 C6.89787783,13.3178778 6.89787783,12.6821222 7.29,12.29 C7.68212217,11.8978778 8.31787783,11.8978778 8.71,12.29 L10,13.59 L10,6 C10,5.44771525 10.4477153,5 11,5 C11.5522847,5 12,5.44771525 12,6 L12,13.59 L13.29,12.29 C13.6821222,11.8978778 14.3178778,11.8978778 14.71,12.29 C15.1021222,12.6821222 15.1021222,13.3178778 14.71,13.71 Z" id="Shape"></path>
                     </g>
                     </g>
                         {/* <path d="M11,0 C4.92486775,0 0,4.92486775 0,11 C0,17.0751322 4.92486775,22 11,22 C17.0751322,22 22,17.0751322 22,11 C22,8.08261861 20.8410748,5.28472557 18.7781746,3.22182541 C16.7152744,1.15892524 13.9173814,0 11,0 Z M14.71,13.71 L11.71,16.71 C11.5212812,16.8971865 11.265803,17.0015368 11,17.0000167 C10.735233,16.9963165 10.4816338,16.8927337 10.29,16.71 L7.29,13.71 C6.89787783,13.3178778 6.89787783,12.6821222 7.29,12.29 C7.68212217,11.8978778 8.31787783,11.8978778 8.71,12.29 L10,13.59 L10,6 C10,5.44771525 10.4477153,5 11,5 C11.5522847,5 12,5.44771525 12,6 L12,13.59 L13.29,12.29 C13.6821222,11.8978778 14.3178778,11.8978778 14.71,12.29 C15.1021222,12.6821222 15.1021222,13.3178778 14.71,13.71 Z" id="Shape"></path> */}
                    </svg>
                    Download CSV
                  </button>) : (
                    <CSVLink {...csvLink}>
                    <button
                    type="button"
                    className={cardData.length === 0 ? "add-candidate-btnnn ml-3 inline-flex items-center text-white text-base font-medium whitespace-nowrap rounded-lg transition duration-200 py-3 px-4" : "add-candidate-btn ml-3 inline-flex items-center bg-deep-purple-500 hover:bg-deep-purple-400 text-white text-base font-medium whitespace-nowrap rounded-lg hover:shadow-md transition duration-200 py-3 px-4"} 
                  >
                    <svg
                      width="16px" className="mr-2" height="19px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"
                    >
                     <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                     <g id="noun-download-5485540" fill="white" fill-rule="nonzero">
                     <path d="M11,0 C4.92486775,0 0,4.92486775 0,11 C0,17.0751322 4.92486775,22 11,22 C17.0751322,22 22,17.0751322 22,11 C22,8.08261861 20.8410748,5.28472557 18.7781746,3.22182541 C16.7152744,1.15892524 13.9173814,0 11,0 Z M14.71,13.71 L11.71,16.71 C11.5212812,16.8971865 11.265803,17.0015368 11,17.0000167 C10.735233,16.9963165 10.4816338,16.8927337 10.29,16.71 L7.29,13.71 C6.89787783,13.3178778 6.89787783,12.6821222 7.29,12.29 C7.68212217,11.8978778 8.31787783,11.8978778 8.71,12.29 L10,13.59 L10,6 C10,5.44771525 10.4477153,5 11,5 C11.5522847,5 12,5.44771525 12,6 L12,13.59 L13.29,12.29 C13.6821222,11.8978778 14.3178778,11.8978778 14.71,12.29 C15.1021222,12.6821222 15.1021222,13.3178778 14.71,13.71 Z" id="Shape"></path>
                     </g>
                     </g>
                         {/* <path d="M11,0 C4.92486775,0 0,4.92486775 0,11 C0,17.0751322 4.92486775,22 11,22 C17.0751322,22 22,17.0751322 22,11 C22,8.08261861 20.8410748,5.28472557 18.7781746,3.22182541 C16.7152744,1.15892524 13.9173814,0 11,0 Z M14.71,13.71 L11.71,16.71 C11.5212812,16.8971865 11.265803,17.0015368 11,17.0000167 C10.735233,16.9963165 10.4816338,16.8927337 10.29,16.71 L7.29,13.71 C6.89787783,13.3178778 6.89787783,12.6821222 7.29,12.29 C7.68212217,11.8978778 8.31787783,11.8978778 8.71,12.29 L10,13.59 L10,6 C10,5.44771525 10.4477153,5 11,5 C11.5522847,5 12,5.44771525 12,6 L12,13.59 L13.29,12.29 C13.6821222,11.8978778 14.3178778,11.8978778 14.71,12.29 C15.1021222,12.6821222 15.1021222,13.3178778 14.71,13.71 Z" id="Shape"></path> */}
                    </svg>
                    Download CSV
                  </button>
                  </CSVLink>
                  )}
                
                </div>
              </form>
            </section>
            <section className="flex flex-wrap px-2">
              <DataTable
                // title
                fixedHeader
                fixedHeaderScrollHeight="100%"
                columns={columns}
                data={AddCompanyTeamMemberData}
                paginationRowsPerPageOptions={[100, 200, 300, 500]}
                pagination
                paginationPerPage={[100]}
                selectableRows
                onSelectedRowsChange={handleRowData}
                selectableRowsHighlight
                highlightOnHover
                subHeader
                subHeaderAlign="left"
              />
            </section>
          </div>
        </div>
      </>)
}

const mapStateToProps = ({ authentication }) => ({
    authentication_state: authentication,
  });
  
  export default connect(mapStateToProps)(CompanyTeamMamber);