import React from "react";
import "./index.css"


const SkeletonTable = () => {
    return(
        <>
             <body>
		<div class="inside-table-cont">
			<div class="inside-table-main">
				<div class="inside-table-data">
					<table>
						{/* <!-- start loader structure --> */}
						<tr class="in-loader">
							<td class="in-list1">
								<div class="in-data-col in-author-display">
									<div class="in-circle"></div>
									<div class="in-loader-line"></div>
							</div>
							</td>
							<td>
								<div class="in-loader-line"></div>
							</td>
							<td>
								<div class="in-loader-line"></div>
							</td>
							<td>
								<div class="in-loader-line"></div>
							</td>
							<td class="in-list2">
								<div class="in-loader-line"></div>
							</td>
						</tr>
						{/* <!-- end loader structure --> */}
						{/* <!-- start loader structure --> */}
						<tr class="in-loader">
							<td class="in-list1">
								<div class="in-data-col in-author-display">
									<div class="in-circle"></div>
									<div class="in-loader-line"></div>
							</div>
							</td>
							<td>
								<div class="in-loader-line"></div>
							</td>
							<td>
								<div class="in-loader-line"></div>
							</td>
							<td>
								<div class="in-loader-line"></div>
							</td>
							<td class="in-list2">
								<div class="in-loader-line"></div>
							</td>
						</tr>
						{/* <!-- end loader structure --> */}
						{/* <!-- start loader structure --> */}
						<tr class="in-loader">
							<td class="in-list1">
								<div class="in-data-col in-author-display">
									<div class="in-circle"></div>
									<div class="in-loader-line"></div>
							</div>
							</td>
							<td>
								<div class="in-loader-line"></div>
							</td>
							<td>
								<div class="in-loader-line"></div>
							</td>
							<td>
								<div class="in-loader-line"></div>
							</td>
							<td class="in-list2">
								<div class="in-loader-line"></div>
							</td>
						</tr>
						{/* <!-- end loader structure --> */}
						{/* <!-- start loader structure --> */}
						<tr class="in-loader">
							<td class="in-list1">
								<div class="in-data-col in-author-display">
									<div class="in-circle"></div>
									<div class="in-loader-line"></div>
							</div>
							</td>
							<td>
								<div class="in-loader-line"></div>
							</td>
							<td>
								<div class="in-loader-line"></div>
							</td>
							<td>
								<div class="in-loader-line"></div>
							</td>
							<td class="in-list2">
								<div class="in-loader-line"></div>
							</td>
						</tr>
						{/* <!-- end loader structure --> */}
						{/* <!-- start loader structure --> */}
						<tr class="in-loader">
							<td class="in-list1">
								<div class="in-data-col in-author-display">
									<div class="in-circle"></div>
									<div class="in-loader-line"></div>
							</div>
							</td>
							<td>
								<div class="in-loader-line"></div>
							</td>
							<td>
								<div class="in-loader-line"></div>
							</td>
							<td>
								<div class="in-loader-line"></div>
							</td>
							<td class="in-list2">
								<div class="in-loader-line"></div>
							</td>
						</tr>
						{/* <!-- end loader structure --> */}
					</table>
				</div>
			</div>
		</div>
   
  </body>
        </>
    )
}

export default SkeletonTable