import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import "../../../Login/index.css";
import { CSVLink } from "react-csv";
import DataTable from "react-data-table-component";
import { getCookie } from "../../../../shared/utils/cookies";
import Vector from "../../../ForgotPass/images/error.svg";
import { getCompanyDash } from "../../../authentication";
import { Helmet } from "react-helmet";
import Select, { NonceProvider } from "react-select";
import { PropTypes } from "prop-types";
import axios from "axios";

const CompaniesDetailTable = ({ authentication_state, fetchCompData }) => {
  const authentication_ref = useRef(authentication_state);
  authentication_ref.current = authentication_state;
  const RoleName =
    authentication_ref?.current?.Roles_data?.response?.data?.data?.rolename;
  const FullData =
    authentication_ref?.current.comp_data?.response?.data?.data || [];
  const [fullData, setFullData] = useState(
    authentication_ref?.current?.comp_data?.response?.data?.data || []
  );
  const menuRef = useRef();
  const Token = getCookie("access_token");
  const [show, setShow] = useState(false);
  const [candata, setCanData] = useState();
  const [showModel, setShowModel] = useState(false);
  const [file, setFileName] = useState();
  const [files, setFiles] = useState();
  const [deleteModel, setDeleteModel] = useState(false)
  const [loading, setLoading] = useState(false);

  // Edit state for the company
  const [search, setSearch] = useState("");
  const [name, setName] = useState("");
  const [industry, setIndustry] = useState();
  const [stage, setStage] = useState();
  const [headQat, setHeadQat] = useState();
  const [discrip, setDiscrip] = useState();
  const [website, setWesite] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [newErrorMsg, setNewErrorMsg] = useState("");

  //Adding New Company
  const [model, setModel] = useState(false);
  const [newName, setNewName] = useState("")
  const [newIndustry, setNewIndustry] = useState("")
  const [newLocation, setNewLocatiom] = useState("")
  const [newDiscription, setNewDiscrip] = useState("")
  const [newWesite, setNewWesite] = useState("")
  const [newStage, setNewStage] = useState()
  const [CharCount, setCharCount] = useState(0);
  const [CharCounts, setCharCounts] = useState();

  //Global search filters
  useEffect(() => {
    const result = FullData.filter((company) => {
      return company.Name
        .toLocaleLowerCase()
        .match(search.toLocaleLowerCase());
    });
    setFullData(result);
  }, [search]);

  // Stages Array
  let stagesArray = [
    { currItem: "Pre-Seed" },
    { currItem: "Seed" },
    { currItem: "Series A" },
    { currItem: "Series B" },
    { currItem: "Series C" },
    { currItem: "5+ Rounds-of-Funding" },
    { currItem: "Private" },
  ];

  // Industries Array
  let idustriesArray = [
    { currItem: "Artificial Intelligence" },
    { currItem: "Automotive" },
    { currItem: "Blockchain" },
    { currItem: "B2B Services" },
    { currItem: "Deep Tech" },
    { currItem: "Crypto" },
    { currItem: "Consumer" },
    { currItem: "Data Analytics" },
    { currItem: "eCommerce" },
    { currItem: "Sustainability" },
    { currItem: "EdTech" },
    { currItem: "Enterprise Software" },
    { currItem: "FemTech" },
    { currItem: "FinTech" },
    { currItem: "Healthcare" },
    { currItem: "InsurTech" },
    { currItem: "Marketplace" },
    { currItem: "Payments" },
    { currItem: "Robotics" },
    { currItem: "Retail" },
    { currItem: "SaaS" },
    { currItem: "Telecom" },
  ];

  let UniDesiredLocStringresult = [
    { currItem: "London" },
    { currItem: "Cambridge" },
    { currItem: "Other Location (UK)" },
    { currItem: "San Fran / Bay Area" },
    { currItem: "New York" },
    { currItem: "Los Angeles" },
    { currItem: "Boston" },
    { currItem: "Other Location (US)" },
    { currItem: "Paris" },
    { currItem: "Berlin" },
    { currItem: "Other Location (Europe)" },
    { currItem: "Other Location (Asia)" },
  ];

  //setting up the early states with values
  useEffect(() => {
    if (candata != undefined) {
      setName(candata.Name);
      setIndustry({currItem : candata?.Industry});
      setStage({ currItem: candata?.Funding_Stage });
      setHeadQat(candata?.Headquarters);
      setDiscrip(candata?.Short_Description);
      setCharCounts(candata?.Short_Description?.length);
      setWesite(candata?.Website);
    }
  }, [candata]);

  // Clicking outside to close model
  useEffect(() => {
    if(showModel == true){
      let handler = (event) => {
        if(!menuRef?.current?.contains(event.target)){
          handleClose()
        }
      }
      document.addEventListener("mousedown", handler);
  
      return () => {
        document.removeEventListener("mousedown", handler)
      }
    }
  }, [showModel])

  // const ArrayThing = headQat?.map((item) => ({ currItem: item }));

  // const handleSelectionChanges = (item) => {
  //   let likeToWorkArray = [];
  //   const likeToWorkArrayMap = item?.map((items) => {
  //     likeToWorkArray.push(items.currItem);
  //   });
  //   setHeadQat(likeToWorkArray);
  // };

  const GetAllData = () => {
    fetchCompData().then(() => {
      setFullData(authentication_ref?.current?.comp_data?.response?.data?.data);
    });
  };

  useEffect(() => {
    GetAllData()
  }, [])

  const CustomTitleName = ({ row }) => (
    <div>
      {}
      <div>{`${row.Name}`}</div>
      <div>
        <div
          data-tag="allowRowEvents"
          style={{
            color: "grey",
            overflow: "hidden",
            whiteSpace: "wrap",
            textOverflow: "ellipses",
          }}
        >
          {}
          {row.plot}
        </div>
      </div>
    </div>
  );

  const CustomTitleEmail = ({ row }) => (
    <div>
      {}
      <div>{row.Funding_Stage}</div>
      <div>
        <div
          data-tag="allowRowEvents"
          style={{
            color: "grey",
            overflow: "hidden",
            whiteSpace: "wrap",
            textOverflow: "ellipses",
          }}
        >
          {}
          {row.plot}
        </div>
      </div>
    </div>
  );

  const CustomTitleJob = ({ row }) => (
    <div>
      {}
      <div>{row.Industry}</div>
      <div>
        <div
          data-tag="allowRowEvents"
          style={{
            color: "grey",
            overflow: "hidden",
            whiteSpace: "wrap",
            textOverflow: "ellipses",
          }}
        >
          {}
          {row.plot}
        </div>
      </div>
    </div>
  );

  const CustomTitleLinkIn = ({ row }) => (
    <div>
      {}
      <div>{row.Website}</div>
      <div>
        <div
          data-tag="allowRowEvents"
          style={{
            color: "grey",
            overflow: "hidden",
            whiteSpace: "wrap",
            textOverflow: "ellipses",
          }}
        >
          {}
          {row.plot}
        </div>
      </div>
    </div>
  );

  const handleImageChange = (row) => {
    if (RoleName == "admin") {
      setShow(true);
      setCanData(row);
    }
  };

  const handleNewModelClose = () => {
    setModel(false);
    setNewErrorMsg("");
    setNewName("");
    setNewIndustry("");
    setNewLocatiom("");
    setNewDiscrip("");
    setNewWesite("")
    setNewStage();
  }

  const handleClose = () => {
    setShow(false);
    setShowModel(false);  
    setFileName(null);
    setFiles(undefined);
    setDeleteModel(false)
    setErrorMsg("")
  };

  const handleFileInputChange = (e) => {
    let files = e.target.files[0];
    setFileName(files);
  };

  const handleKeyDown = (e) => {
    e.preventDefault()
    setSearch(e.target.value)
  }

  const AddCompany = () => {
    if(!newName || !newStage || !newLocation || !newIndustry || !newDiscription || !newWesite){
      setNewErrorMsg("Field can not be empty")
    }else{
      setLoading(true);
      const data = {
        Name: newName,
        Funding_Stage: newStage?.currItem,
        Headquarters: newLocation,
        Industry: newIndustry?.currItem,
        Short_Description: newDiscription,
        Website: newWesite,
      };
      const headerData = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      };
      axios
      .post(`/api/company/company/`, data, headerData)
      .then((res) => {
        if (res.status == 201) {
          setLoading(false);
          setModel(false);
          GetAllData();
          setNewErrorMsg("");
          setNewName("");
          setNewIndustry("");
          setNewLocatiom("");
          setNewDiscrip("");
          setNewWesite("")
          setNewStage();
        }
      })
      .catch((err) => {
        if (err) {
          setLoading(false);
        }
      });
    }
  }

  const DeleteCompany = () => {
    setDeleteModel(true)
    setShowModel(false)
  }

  const EditCandidate = () => {
    if(!name || !stage || !headQat || !industry || !discrip || !website){
      setErrorMsg("Field can not be empty")
    }else{
      setLoading(true);
      const data = {
        Name: name,
        Funding_Stage: stage?.currItem,
        Headquarters: headQat,
        Industry: industry?.currItem,
        Short_Description: discrip,
        Website: website,
      };
      const headerData = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      };
      axios
        .put(`/api/company/company/update/${candata.id}/`, data, headerData)
        .then((res) => {
          if (res.status == 206) {
            setLoading(false);
            setShowModel(false);
            setFiles(undefined);
            setErrorMsg("")
            GetAllData();
          }
        })
        .catch((err) => {
          if (err) {
            setLoading(false);
          }
        });
    }
  };

  const SubmitImage = () => {
    if (file != undefined) {
      setLoading(true);
      var filey = new FormData();
      filey.append("file", file);
      axios({
        method: "put",
        url: `/api/company/company/update/logo/${candata.id}`,
        data: filey,
        headers: {
          "Content-Type":
            "multipart/form-data; boundary=<calculated when request is sent>",
          Authorization: `Bearer ${Token}`,
        },
      }).then((res) => {
        if (res.status == 206) {
          setLoading(false);
          setShow(false);
          setFileName(null);
          GetAllData();
        }
      });
    }
  };

  const DeleteCompanyApi = () => {
    if(candata.id){
      setLoading(true);
      axios({
        method: "delete",
        url: `/api/company/company/delete/${candata.id}/`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(candata.id)
      })
      .then((res) => {
        if (res.status == 200 || res.status == 206) {
          setDeleteModel(false)
          setErrorMsg("")
          setLoading(false);
          GetAllData();
        }
      })
      .catch((err) => {
        if (err) {
          setLoading(false);
        }
      });
    }
  }

  const DeleteImage = () => {
    setLoading(true);
    axios({
      method: "delete",
      url: `/api/company/company/delete/logo/${candata.id}/`,
      headers: {
        "Content-Type":
          "multipart/form-data; boundary=<calculated when request is sent>",
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((res) => {
        if (res.status == 206) {
          setLoading(false);
          setShow(false);
          setFileName(null);
          GetAllData();
        }
      })
      .catch((err) => {
        if (err) {
          setLoading(false);
        }
      });
  };

  const handleModelButtonClick = (row) => {
    setShowModel(true);
    setCanData(row);
  };

  const columns = [
    {
      name: "Logo",
      cell: (row) => (
        <>
          <div>
            {row.Logo ? (
              <figure
                className="mt-3 mb-3 shrink-0 bg-deep-purple-50 rounded-full w-12 md:w-[60px]  h-12 md:h-[60px] inline-flex items-start justify-center overflow-hidden cursor-pointer"
                onClick={() => handleImageChange(row)}
                style={{ width: "30px", height: "30px" }}
              >
                <img
                  className="max-w-full h-auto min-h-full rounded-full"
                  src={`https://cnp1656-dev-image.s3.amazonaws.com/logo/${row.Logo}`}
                  // src={`https://cnp1656-prod.s3.amazonaws.com/profilepic/${row.profilepic}`}
                  // src={`${window.location.origin}/api/candidate/profilepic/${row.profilepic}`}
                  alt={row?.Name}
                />
              </figure>
            ) : (
              <figure
                className="mt-3 mb-3 shrink-0 bg-deep-purple-50 rounded-full w-12 h-12 inline-flex items-center justify-center cursor-pointer"
                onClick={() => handleImageChange(row)}
                style={{ width: "30px", height: "30px" }}
              >
                <span className="text-md text-deep-purple-500 font-medium">
                  {row?.Name[0]?.toUpperCase()}
                </span>
              </figure>
            )}
          </div>
        </>
      ),
      maxWidth: "100px",
      minWidth: "50px",
    },
    {
      name: "Full Name",
      selector: (row) => `${row.Name}`,
      sortable: true,
      cell: (row) => (
        <div>
          <CustomTitleName row={row} />
        </div>
      ),
      //   maxWidth: "300px",
      //   minWidth: "100px",
    },
    {
      name: "Funding Stage",
      selector: (row) => row.Funding_Stage,
      cell: (row) => <CustomTitleEmail row={row} />,
      //   maxWidth: "650px",
    },
    {
      name: "Industries",
      selector: (row) => row.Industry,
      cell: (row) => <CustomTitleJob row={row} />,
      //   maxWidth: "150px",
      //   minWidth: "10px",
    },
    {
      name: "Website",
      selector: (row) => row.Website,
      button: true,
      cell: (row) => (
        <div>
          {RoleName == "admin" && (
            <a
              href={
                row?.Website?.includes("http")
                  ? row?.Website
                  : `https://${row?.Website}`
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <CustomTitleLinkIn row={row} />
            </a>
          )}
        </div>
      ),
      maxWidth: "300px",
      minWidth: "200px",
      style: {
        textDecoration: "underline",
        color: "blue",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      name: "Edit",
      cell: (row) => (
        <div>
          <button
            className="bg-deep-purple-500 hover:bg-deep-purple-400 text-white text-base font-medium whitespace-nowrap rounded-lg hover:shadow-md transition p-2"
            onClick={() => {
              handleModelButtonClick(row);
            }}
          >
            Edit
          </button>
        </div>
      ),
      //   maxWidth: "100px",
      //   minWidth: "50px",
    },
  ];

  const VcColumns = [
    {
      name: "Logo",
      cell: (row) => (
        <>
          <div>
            {row.Logo ? (
              <figure
                className="mt-3 mb-3 shrink-0 bg-deep-purple-50 rounded-full w-12 md:w-[60px]  h-12 md:h-[60px] inline-flex items-start justify-center overflow-hidden cursor-pointer"
                // onClick={() => handleImageChange(row)}
                style={{ width: "30px", height: "30px" }}
              >
                <img
                  className="max-w-full h-auto min-h-full rounded-full"
                  src={`https://cnp1656-dev-image.s3.amazonaws.com/logo/${row.Logo}`}
                  // src={`https://cnp1656-prod.s3.amazonaws.com/profilepic/${row.profilepic}`}
                  // src={`${window.location.origin}/api/candidate/profilepic/${row.profilepic}`}
                  alt={row?.Name}
                />
              </figure>
            ) : (
              <figure
                className="mt-3 mb-3 shrink-0 bg-deep-purple-50 rounded-full w-12 h-12 inline-flex items-center justify-center cursor-pointer"
                // onClick={() => handleImageChange(row)}
                style={{ width: "30px", height: "30px" }}
              >
                <span className="text-md text-deep-purple-500 font-medium">
                  {row?.Name[0]?.toUpperCase()}
                </span>
              </figure>
            )}
          </div>
        </>
      ),
      maxWidth: "100px",
      minWidth: "50px",
    },
    {
      name: "Full Name",
      selector: (row) => `${row.Name}`,
      sortable: true,
      cell: (row) => (
        <div>
          <CustomTitleName row={row} />
          {/* <a
                href={`${Funtionstesting(row)}`}
                target="_blank"
                rel="noopener noreferrer"
              >        
              </a> */}
        </div>
      ),
      // maxWidth: "300px",
      // minWidth: "100px",
    },
    {
      name: "Funding Stage",
      selector: (row) => row.Funding_Stage,
      cell: (row) => <CustomTitleEmail row={row} />,
      //   maxWidth: "650px",
    },
    {
      name: "Industries",
      selector: (row) => row.Industry,
      cell: (row) => <CustomTitleJob row={row} />,
      // maxWidth: "650px",
      // minWidth: "200px",
    },
    {
      name: "Website",
      selector: (row) => row.Website,
      button: true,
      cell: (row) => (
        <div>
            <a
              href={
                row?.Website?.includes("http")
                  ? row?.Website
                  : `https://${row?.Website}`
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <CustomTitleLinkIn row={row} />
            </a>
        </div>
      ),
      maxWidth: "300px",
      minWidth: "200px",
      style: {
        textDecoration: "underline",
        color: "blue",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];

  const handleNewCompModel = () => {
    setModel(true)
    setCharCount(0)
  }

  const handleCharCount = (e) => {
    const inputName = e.target.value;
    const resetCount = 0 + inputName?.length;
    setNewDiscrip(inputName);
    setCharCount(resetCount > 0 ? resetCount : 0);
  };

  //for edit company discription area
  const handleCharCounts = (e) => {
    const inputName = e.target.value;
    const resetCount = 0 + inputName?.length;
    setDiscrip(inputName);
    setCharCounts(resetCount > 0 ? resetCount : 0);
  }


  return (
    <>
      <div
        className="leading-normal text-gray-1000 bg-deep-purple-10 font-sans min-h-screen	h-full"
        style={{ fontFamily: "DM Sans, sans-serif" }}
      >
        <div className="w-full fixed inset-x-0 z-20 bg-deep-purple-10 top-0 border-b border-gray-300]">
          <div className="w-full mx-auto flex flex-wrap items-center justify-between py-2 px-4">
            <h1 className="mr-auto">
              <a
                className="logoClass pt-1 text-deep-purple-500 no-underline hover:no-underline font-bold text-2xl lg:text-4xl w-20 sm:w-24 block"
                href="/dashboard/candidates"
              >
                <svg
                  viewBox="0 0 98 42"
                  className="w-full"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M25.4552 41.8057C24.7351 41.8057 24.0611 41.6949 23.4332 41.4733C22.8238 41.2332 22.2976 40.9101 21.8544 40.5038C21.4112 40.0791 21.0603 39.5898 20.8018 39.0358C20.5433 38.4634 20.414 37.8447 20.414 37.18C20.414 36.4598 20.5618 35.8043 20.8572 35.2133C21.1711 34.6224 21.6051 34.1146 22.1591 33.6899C22.713 33.2652 23.3686 32.9328 24.1257 32.6927C24.8828 32.4527 25.723 32.3327 26.6463 32.3327C27.2557 32.3327 27.8466 32.3881 28.419 32.4989C28.9915 32.5912 29.49 32.7389 29.9148 32.942V32.2773C29.9148 30.5969 28.9453 29.7567 27.0064 29.7567C26.2123 29.7567 25.4552 29.8952 24.7351 30.1721C24.0149 30.4307 23.2578 30.8277 22.4637 31.3632L21.1619 28.5933C22.1221 27.9655 23.1193 27.4946 24.1534 27.1807C25.1875 26.8667 26.2862 26.7098 27.4496 26.7098C29.647 26.7098 31.3459 27.2268 32.5462 28.2609C33.7464 29.295 34.3466 30.8092 34.3466 32.8035V36.5429C34.3466 36.9861 34.4112 37.3 34.5405 37.4847C34.6882 37.6509 34.9375 37.7524 35.2884 37.7893V41.5287C34.9006 41.621 34.5497 41.6764 34.2358 41.6949C33.9219 41.7318 33.6357 41.7503 33.3771 41.7503C32.5462 41.7503 31.9183 41.5933 31.4936 41.2794C31.0689 40.947 30.8011 40.4854 30.6903 39.8945L30.6072 39.2297C29.9609 40.0607 29.1854 40.6977 28.2805 41.1409C27.3942 41.5841 26.4524 41.8057 25.4552 41.8057ZM26.7571 38.6203C27.2372 38.6203 27.6988 38.5372 28.142 38.371C28.6037 38.2048 28.9822 37.9832 29.2777 37.7063C29.7024 37.3739 29.9148 37.023 29.9148 36.6537V35.2687C29.527 35.121 29.1023 35.0102 28.6406 34.9364C28.1974 34.844 27.7819 34.7979 27.3942 34.7979C26.5817 34.7979 25.9076 34.9825 25.3721 35.3518C24.8551 35.7212 24.5966 36.2013 24.5966 36.7922C24.5966 37.3277 24.7997 37.7709 25.2059 38.1217C25.6122 38.4541 26.1292 38.6203 26.7571 38.6203Z"
                    fill="#182135"
                  />
                  <path
                    d="M51.5736 41.5287H47.1418V33.3575C47.1418 32.3973 46.9571 31.7048 46.5878 31.2801C46.2369 30.8369 45.7661 30.6153 45.1751 30.6153C44.8612 30.6153 44.5473 30.68 44.2334 30.8092C43.9195 30.9385 43.6148 31.1231 43.3193 31.3632C43.0239 31.5848 42.7561 31.8525 42.516 32.1665C42.2945 32.4804 42.119 32.822 41.9898 33.1913V41.5287H37.5579V26.9868H41.5466V29.4243C42.0821 28.5564 42.8392 27.8916 43.8179 27.4299C44.7966 26.9498 45.923 26.7098 47.1972 26.7098C48.1389 26.7098 48.896 26.8852 49.4685 27.2361C50.0409 27.5684 50.4841 28.0116 50.798 28.5656C51.1119 29.1011 51.3151 29.692 51.4074 30.3383C51.5182 30.9846 51.5736 31.6125 51.5736 32.2219V41.5287Z"
                    fill="#182135"
                  />
                  <path
                    d="M61.4706 41.8057C60.2518 41.8057 59.1531 41.6118 58.1744 41.224C57.2142 40.8178 56.3924 40.273 55.7092 39.5898C55.0444 38.9065 54.5366 38.1217 54.1857 37.2354C53.8349 36.3305 53.6595 35.3888 53.6595 34.4101C53.6595 33.3575 53.8349 32.3696 54.1857 31.4463C54.5366 30.5045 55.0444 29.6828 55.7092 28.9811C56.374 28.2794 57.1865 27.7254 58.1467 27.3192C59.1254 26.9129 60.2333 26.7098 61.4706 26.7098C62.7078 26.7098 63.8065 26.9129 64.7667 27.3192C65.727 27.7254 66.5395 28.2794 67.2042 28.9811C67.869 29.6643 68.3676 30.4584 68.7 31.3632C69.0508 32.268 69.2263 33.219 69.2263 34.2162C69.2263 34.4747 69.217 34.7332 69.1986 34.9918C69.1801 35.2318 69.1524 35.4442 69.1155 35.6288H58.3683C58.4421 36.6075 58.793 37.3554 59.4208 37.8724C60.0671 38.371 60.7966 38.6203 61.6091 38.6203C62.2554 38.6203 62.874 38.4634 63.4649 38.1494C64.0743 37.8355 64.4805 37.4108 64.6836 36.8753L68.4507 37.9278C67.8782 39.0727 66.9919 40.0053 65.7916 40.7254C64.5913 41.4456 63.151 41.8057 61.4706 41.8057ZM64.5451 32.8866C64.4528 31.9633 64.1204 31.2339 63.548 30.6984C62.9755 30.1629 62.2646 29.8952 61.4152 29.8952C60.5473 29.8952 59.8271 30.1721 59.2546 30.7261C58.7007 31.2616 58.3775 31.9818 58.2852 32.8866H64.5451Z"
                    fill="#182135"
                  />
                  <path
                    d="M11.9946 24.9039H16.4265V36.349C16.4265 37.457 16.8973 38.0109 17.8391 38.0109H19.6024V41.778H18.04H15.9556C14.6814 41.778 13.7027 41.4456 13.0195 40.7808C12.3362 40.1161 11.9946 39.1558 11.9946 37.9001V24.9039Z"
                    fill="#182135"
                  />
                  <path
                    d="M14.1072 19.077L18.5957 25.1809H9.61869L14.1072 19.077Z"
                    fill="#182135"
                  />
                  <path
                    d="M7.07766 20.2667C5.84044 20.2667 4.62168 20.0728 3.42139 19.685C2.22111 19.2787 1.20548 18.6971 0.374512 17.94L1.95335 15.2255C2.83972 15.8348 3.69838 16.3057 4.52935 16.6381C5.37879 16.9705 6.19129 17.1367 6.96686 17.1367C7.5393 17.1367 7.98249 17.0351 8.29641 16.832C8.61033 16.6289 8.76729 16.3334 8.76729 15.9456C8.76729 15.5579 8.56416 15.2532 8.15791 15.0316C7.77013 14.7915 7.07765 14.5422 6.08049 14.2837C5.1018 14.0067 4.27083 13.739 3.58759 13.4804C2.92281 13.2219 2.37807 12.9357 1.95335 12.6218C1.52863 12.3078 1.22395 11.9478 1.03929 11.5415C0.854627 11.1353 0.762297 10.6551 0.762297 10.1012C0.762297 9.36253 0.910025 8.68852 1.20548 8.07914C1.50094 7.46977 1.90719 6.95272 2.42423 6.528C2.94128 6.10329 3.55066 5.7709 4.25236 5.53084C4.97254 5.29078 5.74811 5.17075 6.57907 5.17075C7.66857 5.17075 8.68419 5.31848 9.62596 5.61394C10.5862 5.90939 11.5095 6.44491 12.3959 7.22048L10.6785 9.87957C9.84755 9.30713 9.10891 8.89165 8.4626 8.63312C7.81629 8.3746 7.18845 8.24534 6.57907 8.24534C6.09896 8.24534 5.69271 8.3469 5.36032 8.55003C5.02793 8.73468 4.86174 9.03937 4.86174 9.46409C4.86174 9.87034 5.03717 10.175 5.38802 10.3782C5.75734 10.5813 6.43135 10.8029 7.41004 11.0429C8.44414 11.3199 9.31204 11.5969 10.0137 11.8739C10.7339 12.1509 11.3156 12.4648 11.7588 12.8157C12.202 13.148 12.5251 13.5358 12.7282 13.979C12.9314 14.4222 13.0329 14.9485 13.0329 15.5579C13.0329 16.9982 12.4974 18.1431 11.4264 18.9925C10.3554 19.842 8.90579 20.2667 7.07766 20.2667Z"
                    fill="#6A35FF"
                  />
                  <path
                    d="M24.584 19.2141C23.9747 19.4726 23.273 19.7127 22.4789 19.9343C21.7033 20.1559 20.9185 20.2667 20.1245 20.2667C19.5705 20.2667 19.0442 20.1928 18.5457 20.0451C18.0656 19.9158 17.6408 19.7035 17.2715 19.408C16.9022 19.0941 16.6067 18.6878 16.3851 18.1893C16.182 17.6907 16.0805 17.0905 16.0805 16.3888V8.79932H14.2246V5.44774H16.0805V0.822021H20.5123V5.44774H23.4761V8.79932H20.5123V14.8654C20.5123 15.3455 20.6323 15.6964 20.8724 15.9179C21.1124 16.1211 21.4171 16.2226 21.7864 16.2226C22.1188 16.2226 22.4604 16.1672 22.8113 16.0564C23.1622 15.9456 23.4668 15.8256 23.7254 15.6964L24.584 19.2141Z"
                    fill="#6A35FF"
                  />
                  <path
                    d="M30.438 20.2667C29.7179 20.2667 29.0439 20.1559 28.416 19.9343C27.8066 19.6942 27.2804 19.3711 26.8372 18.9648C26.394 18.5401 26.0431 18.0508 25.7846 17.4968C25.5261 16.9243 25.3968 16.3057 25.3968 15.641C25.3968 14.9208 25.5446 14.2652 25.84 13.6743C26.1539 13.0834 26.5879 12.5756 27.1419 12.1509C27.6958 11.7262 28.3514 11.3938 29.1085 11.1537C29.8656 10.9137 30.7058 10.7936 31.6291 10.7936C32.2385 10.7936 32.8294 10.849 33.4018 10.9598C33.9743 11.0522 34.4728 11.1999 34.8976 11.403V10.7382C34.8976 9.05784 33.9281 8.21764 31.9892 8.21764C31.1951 8.21764 30.438 8.35613 29.7179 8.63312C28.9977 8.89165 28.2406 9.28866 27.4465 9.82418L26.1447 7.05428C27.1049 6.42644 28.1021 5.95556 29.1362 5.64164C30.1703 5.32772 31.269 5.17075 32.4324 5.17075C34.6298 5.17075 36.3287 5.6878 37.529 6.7219C38.7292 7.75599 39.3294 9.2702 39.3294 11.2645V15.0039C39.3294 15.4471 39.394 15.761 39.5233 15.9456C39.671 16.1118 39.9203 16.2134 40.2712 16.2503V19.9897C39.8834 20.082 39.5325 20.1374 39.2186 20.1559C38.9047 20.1928 38.6184 20.2113 38.3599 20.2113C37.529 20.2113 36.9011 20.0543 36.4764 19.7404C36.0517 19.408 35.7839 18.9464 35.6731 18.3554L35.59 17.6907C34.9437 18.5216 34.1682 19.1587 33.2633 19.6019C32.377 20.0451 31.4352 20.2667 30.438 20.2667ZM31.7399 17.0813C32.22 17.0813 32.6816 16.9982 33.1248 16.832C33.5865 16.6658 33.965 16.4442 34.2605 16.1672C34.6852 15.8348 34.8976 15.484 34.8976 15.1147V13.7297C34.5098 13.582 34.0851 13.4712 33.6234 13.3973C33.1802 13.305 32.7647 13.2588 32.377 13.2588C31.5645 13.2588 30.8904 13.4435 30.3549 13.8128C29.8379 14.1821 29.5794 14.6623 29.5794 15.2532C29.5794 15.7887 29.7825 16.2319 30.1887 16.5827C30.595 16.9151 31.112 17.0813 31.7399 17.0813Z"
                    fill="#6A35FF"
                  />
                  <path
                    d="M51.7645 9.2425C50.6935 9.26096 49.724 9.43639 48.8561 9.76878C47.9882 10.1012 47.3604 10.5997 46.9726 11.2645V19.9897H42.5407V5.44774H46.6125V8.38383C47.1111 7.40514 47.7481 6.6388 48.5237 6.08482C49.3177 5.53084 50.1579 5.24462 51.0443 5.22615C51.2474 5.22615 51.3952 5.22615 51.4875 5.22615C51.5798 5.22615 51.6722 5.23539 51.7645 5.25385V9.2425Z"
                    fill="#6A35FF"
                  />
                  <path
                    d="M63.0488 19.2141C62.4394 19.4726 61.7377 19.7127 60.9437 19.9343C60.1681 20.1559 59.3833 20.2667 58.5892 20.2667C58.0353 20.2667 57.509 20.1928 57.0104 20.0451C56.5303 19.9158 56.1056 19.7035 55.7362 19.408C55.3669 19.0941 55.0715 18.6878 54.8499 18.1893C54.6468 17.6907 54.5452 17.0905 54.5452 16.3888V8.79932H52.6894V5.44774H54.5452V0.822021H58.977V5.44774H61.9408V8.79932H58.977V14.8654C58.977 15.3455 59.0971 15.6964 59.3371 15.9179C59.5772 16.1211 59.8819 16.2226 60.2512 16.2226C60.5836 16.2226 60.9252 16.1672 61.276 16.0564C61.6269 15.9456 61.9316 15.8256 62.1901 15.6964L63.0488 19.2141Z"
                    fill="#6A35FF"
                  />
                  <path
                    d="M69.1798 20.2667C67.684 20.2667 66.5484 19.805 65.7728 18.8817C65.0157 17.94 64.6371 16.5643 64.6371 14.7546V5.44774H69.069V13.7851C69.069 14.6715 69.2444 15.3547 69.5952 15.8348C69.9461 16.2965 70.4447 16.5273 71.091 16.5273C71.6819 16.5273 72.2543 16.3704 72.8083 16.0564C73.3623 15.7425 73.8516 15.1978 74.2764 14.4222V5.44774H78.7082V15.0039C78.7082 15.4471 78.7728 15.761 78.9021 15.9456C79.0498 16.1118 79.2991 16.2134 79.65 16.2503V19.9897C79.2252 20.082 78.8652 20.1374 78.5697 20.1559C78.2742 20.1928 78.0065 20.2113 77.7664 20.2113C76.1784 20.2113 75.2735 19.5927 75.0519 18.3554L74.9688 17.4691C74.3041 18.4293 73.4731 19.1403 72.4759 19.6019C71.4972 20.0451 70.3985 20.2667 69.1798 20.2667Z"
                    fill="#6A35FF"
                  />
                  <path
                    d="M91.3376 20.2667C90.2297 20.2667 89.2694 20.0359 88.4569 19.5742C87.6444 19.1126 87.0166 18.4662 86.5734 17.6353V28.6931H82.1416V5.44774H85.9917V7.80215C86.5272 6.97119 87.192 6.32488 87.986 5.86323C88.7985 5.40158 89.7588 5.17075 90.8667 5.17075C91.8454 5.17075 92.741 5.36465 93.5535 5.75243C94.3845 6.14022 95.0954 6.67573 95.6863 7.35897C96.2957 8.02375 96.7666 8.81778 97.099 9.74108C97.4498 10.6459 97.6253 11.6246 97.6253 12.6772C97.6253 13.7667 97.4683 14.7731 97.1544 15.6964C96.8405 16.6196 96.4065 17.4229 95.8525 18.1062C95.2986 18.7709 94.6338 19.2972 93.8582 19.685C93.0826 20.0728 92.2424 20.2667 91.3376 20.2667ZM89.8419 16.5273C90.322 16.5273 90.7559 16.4258 91.1437 16.2226C91.55 16.001 91.9008 15.7148 92.1963 15.364C92.4917 15.0131 92.7133 14.6069 92.8611 14.1452C93.0273 13.6651 93.1103 13.1665 93.1103 12.6495C93.1103 12.114 93.018 11.6246 92.8334 11.1814C92.6672 10.7198 92.4271 10.3228 92.1132 9.99037C91.8177 9.65798 91.4484 9.39946 91.0052 9.2148C90.5805 9.03014 90.1189 8.93781 89.6203 8.93781C89.0109 8.93781 88.42 9.12247 87.8475 9.49179C87.2936 9.84264 86.8689 10.3228 86.5734 10.9321V13.9236C86.8689 14.6623 87.312 15.2809 87.9029 15.7794C88.5123 16.278 89.1586 16.5273 89.8419 16.5273Z"
                    fill="#6A35FF"
                  />
                  <path
                    d="M84.5398 34.5106L80.0512 28.4068L89.0283 28.4068L84.5398 34.5106Z"
                    fill="#6A35FF"
                  />
                </svg>
                <span className="sr-only">Startup Lane</span>
              </a>
            </h1>
            {/* <div className="w-full sm:w-auto relative top-2 sm:top-2.5 order-3 sm:order-2">
              <ul className="flex flex-wrap -mb-px text-sm font-medium text-center lg:text-base">
                <li className="mr-4 sm:mr-6">
                  <a
                    href="/dashboard/candidates"
                    className="inline-flex items-center py-2 sm:py-3 text-deep-purple-500 border-b-4 border-deep-purple-500 active group"
                    aria-current="page"
                  >
                    Companies
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
        <div className="pt-[9.5rem] sm:pt-[9.75rem] lg:pt-[9rem]">
          <section className="fixed z-10 inset-x-0 top-[104px] sm:top-[4rem] lg:top-[4.25rem] bg-deep-purple-10 w-full px-4 pt-2 lg:pt-4 border-b border-gray-300">
            <form className="flex relative" method="POST" action="#">
              <div className="inline-flex items-center group lg:w-48 lg:pr-1.5 mb-2 lg:mb-4">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  className="absolute z-[1] left-3"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.50441 0.554688C3.19958 0.554688 0.504395 3.24987 0.504395 6.55471C0.504395 9.85954 3.19958 12.5547 6.50441 12.5547C8.02237 12.5547 9.4082 11.9825 10.4668 11.0474L11.0044 11.585V12.5547L13.6939 15.2442C14.1079 15.6582 14.7799 15.6582 15.1939 15.2442C15.6079 14.8302 15.6079 14.1582 15.1939 13.7442L12.5044 11.0547H11.5347L10.9971 10.5171C11.9322 9.45849 12.5044 8.07266 12.5044 6.55471C12.5044 3.24987 9.80925 0.554688 6.50441 0.554688ZM6.50441 2.05469C8.99859 2.05469 11.0044 4.06053 11.0044 6.55471C11.0044 9.04888 8.99859 11.0547 6.50441 11.0547C4.01024 11.0547 2.0044 9.04888 2.0044 6.55471C2.0044 4.06053 4.01024 2.05469 6.50441 2.05469Z"
                    fill="#434343"
                  />
                </svg>
                <input
                  type="search"
                  id="search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      setSearch(e.target.value);
                    }
                  }}
                  placeholder="Search name"
                  className="w-[42px] absolute sm:relative hover:w-full focus:w-full sm:w-48 sm:hover:w-48 sm:focus:w-48 sm:active:w-48 appearance-none text-ellipsis text-base leading-3 sm:leading-6 bg-white font-normal text-gray-800  placeholder:text-gray-400 rounded-lg py-2 hover:pr-4 focus:pr-4 sm:pr-4 pl-9 border border-gray-300 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:border-deep-purple-500 transition duration-500"
                />
              </div>
              {RoleName == "admin" && (
                <div className="flex items-center ml-auto justify-between mb-2 lg:mb-4">
                  <button
                    type="button"
                    className="add-Port-Button ml-3 inline-flex items-center text-white text-base font-medium whitespace-nowrap rounded-lg hover:shadow-md transition duration-200 py-3 px-4"
                    onClick={handleNewCompModel}
                  >
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      className="mr-1"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.09814 0.0386963C3.67974 0.0386963 0.0981445 3.6203 0.0981445 8.0387C0.0981445 12.4571 3.67974 16.0387 8.09814 16.0387C12.5165 16.0387 16.0981 12.4571 16.0981 8.0387C16.0981 3.6203 12.5165 0.0386963 8.09814 0.0386963ZM10.4981 8.8387H8.89814V10.4387C8.89814 10.8803 8.53974 11.2387 8.09814 11.2387C7.65654 11.2387 7.29814 10.8803 7.29814 10.4387V8.8387H5.69814C5.25654 8.8387 4.89814 8.4803 4.89814 8.0387C4.89814 7.5971 5.25654 7.2387 5.69814 7.2387H7.29814V5.6387C7.29814 5.1971 7.65654 4.8387 8.09814 4.8387C8.53974 4.8387 8.89814 5.1971 8.89814 5.6387V7.2387H10.4981C10.9397 7.2387 11.2981 7.5971 11.2981 8.0387C11.2981 8.4803 10.9397 8.8387 10.4981 8.8387Z"
                        fill="white"
                      />
                    </svg>
                    Add Company
                  </button>
                </div>
              )}
            </form>
          </section>
          <section className="flex flex-wrap px-2">
            <DataTable
              fixedHeader
              fixedHeaderScrollHeight="100%"
              columns={RoleName == "admin" ? columns : VcColumns}
              data={fullData}
              paginationRowsPerPageOptions={[20, 35, 50, 100]}
              pagination
              paginationPerPage={[20]}
              selectableRowsHighlight
              highlightOnHover
              // subHeader
              subHeaderAlign="left"
            />
          </section>
          {deleteModel == true && (
            <div className="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto show">
              <div className="modal-backdrop fade show"></div>
              <div className="modal-dialog modal-dialog-centered relative w-auto pointer-events-none pt-16">
                <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                  <h2 className="text-2xl font-bold text-center">
                    Are you sure you want to delete this company ?
                  </h2>
                  <div className="modal-header flex flex-shrink-0 items-start justify-between p-4 md:px-6 border-gray-300 rounded-t-lg">
                    <button
                      type="button"
                      className="btn-close box-content w-4 h-4 p-2.5 md:p-3 absolute right-4 -top-14 bg-deep-purple-50 hover:bg-deep-purple-100 text-deep-purple-500 border-none rounded-xl focus:shadow-none focus:outline-none hover:text-deep-purple-500 hover:no-underline"
                      onClick={handleClose}
                      aria-label="Close"
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.00291 0.000434093C0.804333 0.000486257 0.610286 0.0597734 0.445575 0.170716C0.280863 0.281658 0.152972 0.439215 0.0782501 0.623243C0.00352837 0.807271 -0.014628 1.00941 0.0261023 1.20381C0.0668325 1.39821 0.164598 1.57605 0.306901 1.71459L6.58849 7.99786L0.306901 14.2811C0.211101 14.3731 0.134617 14.4833 0.0819275 14.6053C0.029238 14.7272 0.00140279 14.8584 5.16679e-05 14.9913C-0.00129945 15.1241 0.0238609 15.2559 0.0740591 15.3789C0.124257 15.5019 0.198484 15.6136 0.292393 15.7075C0.386302 15.8015 0.498004 15.8757 0.620959 15.9259C0.743913 15.9761 0.875648 16.0013 1.00845 15.9999C1.14125 15.9986 1.27244 15.9707 1.39435 15.918C1.51626 15.8653 1.62643 15.7888 1.71841 15.693L8 9.40974L14.2816 15.693C14.3736 15.7888 14.4837 15.8653 14.6056 15.918C14.7276 15.9708 14.8587 15.9986 14.9915 15.9999C15.1243 16.0013 15.2561 15.9761 15.379 15.9259C15.502 15.8757 15.6137 15.8015 15.7076 15.7075C15.8015 15.6136 15.8757 15.5019 15.9259 15.3789C15.9761 15.2559 16.0013 15.1241 15.9999 14.9913C15.9986 14.8584 15.9708 14.7272 15.9181 14.6053C15.8654 14.4833 15.7889 14.3731 15.6931 14.2811L9.4115 7.99786L15.6931 1.71459C15.8373 1.57434 15.9359 1.39378 15.9757 1.19656C16.0156 0.999346 15.995 0.794678 15.9166 0.609379C15.8382 0.424079 15.7057 0.266792 15.5364 0.158131C15.3671 0.0494693 15.1689 -0.00549711 14.9678 0.000434093C14.7085 0.0081633 14.4624 0.116575 14.2816 0.302702L8 6.58597L1.71841 0.302702C1.62539 0.207055 1.51414 0.131028 1.39125 0.079111C1.26836 0.0271943 1.13631 0.000441872 1.00291 0.000434093Z"
                          fill="#6936FF"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="inline-flex justify-between pb-3" role="group">
                    <button
                      type="button"
                      onClick={DeleteCompanyApi}
                      style={{
                        marginLeft: "130px",
                      }}
                      className="inline-flex items-center bg-deep-purple-500 hover:bg-deep-purple-400 text-white text-base font-medium whitespace-nowrap rounded-lg hover:shadow-md transition duration-200 py-3 px-10"
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      style={{
                        marginRight: "130px",
                      }}
                      onClick={handleClose}
                      className="inline-flex items-center bg-deep-purple-500 hover:bg-deep-purple-400 text-white text-base font-medium whitespace-nowrap rounded-lg hover:shadow-md transition duration-200 py-3 px-10"
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {showModel == true && (
            <div className="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto show">
              <div className="modal-backdrop fade show"></div>
              <div className="modal-dialog modal-dialog-centered relative w-auto pointer-events-none pt-16">
                <div
                  className="modal-content border-none shadow-lg relative flex flex-col w-full w-lg pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current"
                  ref={menuRef}
                >
                  <div className="modal-header flex flex-shrink-0 items-start justify-between p-4 md:px-6 border-b border-gray-300 rounded-t-lg">
                    <button
                      type="button"
                      className="btn-close box-content w-4 h-4 p-2.5 md:p-3 absolute right-4 -top-14 bg-deep-purple-50 hover:bg-deep-purple-100 text-deep-purple-500 border-none rounded-xl focus:shadow-none focus:outline-none hover:text-deep-purple-500 hover:no-underline"
                      onClick={handleClose}
                      aria-label="Close"
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.00291 0.000434093C0.804333 0.000486257 0.610286 0.0597734 0.445575 0.170716C0.280863 0.281658 0.152972 0.439215 0.0782501 0.623243C0.00352837 0.807271 -0.014628 1.00941 0.0261023 1.20381C0.0668325 1.39821 0.164598 1.57605 0.306901 1.71459L6.58849 7.99786L0.306901 14.2811C0.211101 14.3731 0.134617 14.4833 0.0819275 14.6053C0.029238 14.7272 0.00140279 14.8584 5.16679e-05 14.9913C-0.00129945 15.1241 0.0238609 15.2559 0.0740591 15.3789C0.124257 15.5019 0.198484 15.6136 0.292393 15.7075C0.386302 15.8015 0.498004 15.8757 0.620959 15.9259C0.743913 15.9761 0.875648 16.0013 1.00845 15.9999C1.14125 15.9986 1.27244 15.9707 1.39435 15.918C1.51626 15.8653 1.62643 15.7888 1.71841 15.693L8 9.40974L14.2816 15.693C14.3736 15.7888 14.4837 15.8653 14.6056 15.918C14.7276 15.9708 14.8587 15.9986 14.9915 15.9999C15.1243 16.0013 15.2561 15.9761 15.379 15.9259C15.502 15.8757 15.6137 15.8015 15.7076 15.7075C15.8015 15.6136 15.8757 15.5019 15.9259 15.3789C15.9761 15.2559 16.0013 15.1241 15.9999 14.9913C15.9986 14.8584 15.9708 14.7272 15.9181 14.6053C15.8654 14.4833 15.7889 14.3731 15.6931 14.2811L9.4115 7.99786L15.6931 1.71459C15.8373 1.57434 15.9359 1.39378 15.9757 1.19656C16.0156 0.999346 15.995 0.794678 15.9166 0.609379C15.8382 0.424079 15.7057 0.266792 15.5364 0.158131C15.3671 0.0494693 15.1689 -0.00549711 14.9678 0.000434093C14.7085 0.0081633 14.4624 0.116575 14.2816 0.302702L8 6.58597L1.71841 0.302702C1.62539 0.207055 1.51414 0.131028 1.39125 0.079111C1.26836 0.0271943 1.13631 0.000441872 1.00291 0.000434093Z"
                          fill="#6936FF"
                        />
                      </svg>
                    </button>
                    <section className="w-full bg-white rounded-xl shadow max-w-[26.25rem] mx-auto p-4 md:p-8">
                      <form className="flex flex-col" method="POST" action="#">
                        <div className="w-full mb-4">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 mb-1"
                            htmlFor="password"
                          >
                            Company <strong>Name</strong>
                          </label>
                          <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                            placeholder="Company Name"
                            className="appearance-none block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 placeholder:text-gray-400 bg-white bg-clip-padding border border-gray-300 rounded-lg m-0 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition ease-in-out"
                          />
                        </div>
                        <div className="w-full mb-4">
                          <label
                            className="block text-bDeleteCompanyase md:text-lg font-normal text-gray-1000 mb-1"
                            htmlFor="password"
                          >
                            Main <strong>Industry</strong>
                          </label>
                          <Select
                            placeholder="Choose Industry"
                            name="Industry"
                            // isClearable={true}
                            getOptionLabel={(item) => item?.currItem}
                            getOptionValue={(item) => item?.currItem}
                            onChange={(item) => setIndustry(item)}
                            value={industry}
                            options={idustriesArray}
                          />
                        </div>
                        <div className="w-full mb-4">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 mb-1"
                            htmlFor="password"
                          >
                            Company <strong>Stage</strong>
                          </label>
                          <Select
                            placeholder="Choose Stage"
                            name="stage"
                            // isClearable={true}
                            getOptionLabel={(item) => item?.currItem}
                            getOptionValue={(item) => item?.currItem}
                            onChange={(item) => setStage(item)}
                            value={stage}
                            options={stagesArray}
                          />
                        </div>
                        <div className="w-full mb-4">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 mb-1"
                            htmlFor="password"
                          >
                            Main <strong>Headquarters</strong>
                          </label>
                          <input
                            type="text"
                            id="Location"
                            value={headQat}
                            onChange={(e) => {
                              setHeadQat(e.target.value);
                            }}
                            placeholder="Main Headquarters"
                            className="appearance-none block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 placeholder:text-gray-400 bg-white bg-clip-padding border border-gray-300 rounded-lg m-0 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition ease-in-out"
                          />
                          {/* <Select
                            placeholder="Choose Location(s)"
                            name="Location"
                            isClearable={true}
                            isMulti
                            getOptionLabel={(item) => item?.currItem}
                            getOptionValue={(item) => item?.currItem}
                            onChange={(item) => {
                              handleSelectionChanges(item);
                            }}
                            value={ArrayThing || []}
                            options={UniDesiredLocStringresult}
                          /> */}
                        </div>
                        <div className="w-full mb-4">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 mb-1"
                            for="password"
                          >
                            Company short <strong>Description</strong>
                          </label>
                          <textarea
                            id="textarea"
                            placeholder={`No information to show`}
                            rows="6"
                            // onChange={(e) => setDiscrip(e.target.value)}
                            onChange={(e) => handleCharCounts(e)}
                            maxlength="122"
                            value={discrip}
                            className="appearance-none block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 placeholder:text-gray-400 bg-white bg-clip-padding border border-gray-300 rounded-lg m-0 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition ease-in-out"
                          />
                          <div className="mainCharDiv">
                            {CharCounts >= 122 && (
                              <p className="char_text">
                                You have reached the character limit.
                              </p>
                            )}
                            <h5 className="char_count">{CharCounts}/122</h5>
                          </div>
                        </div>
                        <div className="w-full mb-4">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 mb-1"
                            htmlFor="password"
                          >
                            Company <strong>Website</strong>
                          </label>
                          <input
                            type="text"
                            id="Website"
                            value={website}
                            onChange={(e) => {
                              setWesite(e.target.value);
                            }}
                            placeholder="Website"
                            className="appearance-none block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 placeholder:text-gray-400 bg-white bg-clip-padding border border-gray-300 rounded-lg m-0 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition ease-in-out"
                          />
                        </div>
                        {errorMsg && (
                          <div className="inline-flex items-baseline mt-2 mb-2">
                            <img className="self-center" src={Vector} />
                            <h5 className="errorClass">{errorMsg}</h5>
                          </div>
                        )}
                        {loading == true ? (
                          <button
                            type="button"
                            className="w-full flex items-center justify-center bg-deep-purple-500 hover:bg-deep-purple-400 text-white text-lg font-medium rounded-lg hover:shadow-md transition duration-200 py-3 px-4 mb-4"
                            disabled
                          >
                            <span
                              className="animate-spin inline-block w-8 h-8 mr-3 border-2 border-white border-l-transparent rounded-full"
                              role="status"
                            ></span>
                            Loading...
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={EditCandidate}
                            className="mb-3 inline-flex items-center bg-deep-purple-500 hover:bg-deep-purple-400 text-white text-base font-medium whitespace-nowrap rounded-lg hover:shadow-md transition duration-200 py-3 px-4"
                          >
                            <svg
                              width="17"
                              height="17"
                              viewBox="0 0 17 17"
                              className="mr-1"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.09814 0.0386963C3.67974 0.0386963 0.0981445 3.6203 0.0981445 8.0387C0.0981445 12.4571 3.67974 16.0387 8.09814 16.0387C12.5165 16.0387 16.0981 12.4571 16.0981 8.0387C16.0981 3.6203 12.5165 0.0386963 8.09814 0.0386963ZM10.4981 8.8387H8.89814V10.4387C8.89814 10.8803 8.53974 11.2387 8.09814 11.2387C7.65654 11.2387 7.29814 10.8803 7.29814 10.4387V8.8387H5.69814C5.25654 8.8387 4.89814 8.4803 4.89814 8.0387C4.89814 7.5971 5.25654 7.2387 5.69814 7.2387H7.29814V5.6387C7.29814 5.1971 7.65654 4.8387 8.09814 4.8387C8.53974 4.8387 8.89814 5.1971 8.89814 5.6387V7.2387H10.4981C10.9397 7.2387 11.2981 7.5971 11.2981 8.0387C11.2981 8.4803 10.9397 8.8387 10.4981 8.8387Z"
                                fill="white"
                              />
                            </svg>
                            Save Changes
                          </button>
                        )}
                        {loading == true ? (
                          <button
                            type="button"
                            className="w-full flex items-center justify-center bg-deep-purple-500 hover:bg-deep-purple-400 text-white text-lg font-medium rounded-lg hover:shadow-md transition duration-200 py-3 px-4 mb-4"
                            disabled
                          >
                            <span
                              className="animate-spin inline-block w-8 h-8 mr-3 border-2 border-white border-l-transparent rounded-full"
                              role="status"
                            ></span>
                            Loading...
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={DeleteCompany}
                            className="inline-flex items-center bg-deep-purple-500 hover:bg-deep-purple-400 text-white text-base font-medium whitespace-nowrap rounded-lg hover:shadow-md transition duration-200 py-3 px-4"
                          >
                            <svg
                              width="17"
                              height="17"
                              viewBox="0 0 17 17"
                              className="mr-1"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.09814 0.0386963C3.67974 0.0386963 0.0981445 3.6203 0.0981445 8.0387C0.0981445 12.4571 3.67974 16.0387 8.09814 16.0387C12.5165 16.0387 16.0981 12.4571 16.0981 8.0387C16.0981 3.6203 12.5165 0.0386963 8.09814 0.0386963ZM10.4981 8.8387H8.89814V10.4387C8.89814 10.8803 8.53974 11.2387 8.09814 11.2387C7.65654 11.2387 7.29814 10.8803 7.29814 10.4387V8.8387H5.69814C5.25654 8.8387 4.89814 8.4803 4.89814 8.0387C4.89814 7.5971 5.25654 7.2387 5.69814 7.2387H7.29814V5.6387C7.29814 5.1971 7.65654 4.8387 8.09814 4.8387C8.53974 4.8387 8.89814 5.1971 8.89814 5.6387V7.2387H10.4981C10.9397 7.2387 11.2981 7.5971 11.2981 8.0387C11.2981 8.4803 10.9397 8.8387 10.4981 8.8387Z"
                                fill="white"
                              />
                            </svg>
                            Delete Company
                          </button>
                        )}
                      </form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          )}
          {show == true && (
            <div className="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto show">
              <div className="modal-backdrop fade show"></div>
              <div className="modal-dialog modal-dialog-centered relative w-auto pointer-events-none pt-16">
                <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                  <h2 className="text-2xl font-bold text-center mb-2">
                    Choose Profile Pic For {`${candata.Name}`}
                  </h2>
                  <div className="modal-header flex flex-shrink-0 items-start justify-between p-4 md:px-6 border-b border-gray-300 rounded-t-lg">
                    <button
                      type="button"
                      className="btn-close box-content w-4 h-4 p-2.5 md:p-3 absolute right-4 -top-14 bg-deep-purple-50 hover:bg-deep-purple-100 text-deep-purple-500 border-none rounded-xl focus:shadow-none focus:outline-none hover:text-deep-purple-500 hover:no-underline"
                      onClick={handleClose}
                      aria-label="Close"
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.00291 0.000434093C0.804333 0.000486257 0.610286 0.0597734 0.445575 0.170716C0.280863 0.281658 0.152972 0.439215 0.0782501 0.623243C0.00352837 0.807271 -0.014628 1.00941 0.0261023 1.20381C0.0668325 1.39821 0.164598 1.57605 0.306901 1.71459L6.58849 7.99786L0.306901 14.2811C0.211101 14.3731 0.134617 14.4833 0.0819275 14.6053C0.029238 14.7272 0.00140279 14.8584 5.16679e-05 14.9913C-0.00129945 15.1241 0.0238609 15.2559 0.0740591 15.3789C0.124257 15.5019 0.198484 15.6136 0.292393 15.7075C0.386302 15.8015 0.498004 15.8757 0.620959 15.9259C0.743913 15.9761 0.875648 16.0013 1.00845 15.9999C1.14125 15.9986 1.27244 15.9707 1.39435 15.918C1.51626 15.8653 1.62643 15.7888 1.71841 15.693L8 9.40974L14.2816 15.693C14.3736 15.7888 14.4837 15.8653 14.6056 15.918C14.7276 15.9708 14.8587 15.9986 14.9915 15.9999C15.1243 16.0013 15.2561 15.9761 15.379 15.9259C15.502 15.8757 15.6137 15.8015 15.7076 15.7075C15.8015 15.6136 15.8757 15.5019 15.9259 15.3789C15.9761 15.2559 16.0013 15.1241 15.9999 14.9913C15.9986 14.8584 15.9708 14.7272 15.9181 14.6053C15.8654 14.4833 15.7889 14.3731 15.6931 14.2811L9.4115 7.99786L15.6931 1.71459C15.8373 1.57434 15.9359 1.39378 15.9757 1.19656C16.0156 0.999346 15.995 0.794678 15.9166 0.609379C15.8382 0.424079 15.7057 0.266792 15.5364 0.158131C15.3671 0.0494693 15.1689 -0.00549711 14.9678 0.000434093C14.7085 0.0081633 14.4624 0.116575 14.2816 0.302702L8 6.58597L1.71841 0.302702C1.62539 0.207055 1.51414 0.131028 1.39125 0.079111C1.26836 0.0271943 1.13631 0.000441872 1.00291 0.000434093Z"
                          fill="#6936FF"
                        />
                      </svg>
                    </button>
                    <div className="block relative flex justify-center items-center group w-full bg-deep-purple-50 hover:bg-deep-purple-100 text-deep-purple-500 text-lg font-medium rounded-lg cursor-pointer transition duration-200 py-3 px-4">
                      <input
                        type="file"
                        id="file"
                        accept="jpg, jpeg, png"
                        size="10"
                        placeholder="Other information"
                        onChange={handleFileInputChange}
                        className="appearance-none absolute inset-0 opacity-0 z-10 cursor-pointer	w-full"
                      />
                      <svg
                        width="16"
                        height="18"
                        viewBox="0 0 16 18"
                        className="mr-2"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.3727 0.280762C10.3032 0.280762 9.23467 0.704491 8.43348 1.54975L2.62024 7.68202C0.446656 9.97575 0.446656 13.6629 2.62024 15.9567C2.68724 16.0273 2.76777 16.0837 2.85702 16.1226L2.86127 16.124C5.05119 18.1992 8.46713 18.176 10.5702 15.9567L14.6593 11.6421C14.7286 11.5737 14.7833 11.492 14.8203 11.402C14.8574 11.312 14.8759 11.2154 14.8749 11.1181C14.8739 11.0208 14.8533 10.9246 14.8143 10.8354C14.7754 10.7462 14.7189 10.6657 14.6483 10.5988C14.5776 10.5318 14.4942 10.4798 14.403 10.4457C14.3118 10.4116 14.2147 10.3962 14.1175 10.4005C14.0202 10.4047 13.9248 10.4284 13.8369 10.4702C13.749 10.5121 13.6705 10.5711 13.6059 10.6439L9.51673 14.9585C7.88206 16.6835 5.3098 16.6835 3.67513 14.9585C2.04039 13.2334 2.04039 10.4053 3.67513 8.68019L9.48696 2.54793C10.5503 1.42613 12.1966 1.42613 13.2599 2.54793C14.3231 3.66962 14.3231 5.52104 13.2599 6.64272L7.96984 12.2249C7.477 12.7445 6.75758 12.7441 6.26557 12.2249C5.77355 11.7056 5.77355 10.8314 6.26557 10.3122L10.5206 5.82178C10.5898 5.75335 10.6446 5.67169 10.6816 5.58167C10.7186 5.49165 10.7372 5.3951 10.7361 5.29777C10.7351 5.20043 10.7145 5.1043 10.6756 5.01509C10.6367 4.92587 10.5802 4.8454 10.5095 4.77845C10.4389 4.7115 10.3555 4.65944 10.2643 4.62537C10.1731 4.5913 10.076 4.57591 9.97874 4.58013C9.8815 4.58434 9.78609 4.60806 9.69819 4.64989C9.6103 4.69172 9.53171 4.75079 9.46711 4.8236L5.21068 9.31398C4.17966 10.402 4.17966 12.135 5.21068 13.223C5.27949 13.2955 5.36252 13.3529 5.45455 13.3918C6.50038 14.2654 8.06267 14.2358 9.02332 13.223L14.3134 7.6409C15.8497 6.02002 15.8718 3.48337 14.4622 1.78512C14.4283 1.69779 14.3777 1.61786 14.3134 1.54975C13.5122 0.704491 12.4423 0.280762 11.3727 0.280762Z"
                          fill="#6936FF"
                        />
                      </svg>
                      {file?.name ? file?.name : "Chose File"}
                    </div>
                    <br />
                    {loading == true ? (
                      <button
                        type="button"
                        className="w-full flex items-center justify-center bg-deep-purple-500 hover:bg-deep-purple-400 text-white text-lg font-medium rounded-lg hover:shadow-md transition duration-200 py-3 px-4 mb-4"
                        disabled
                      >
                        <span
                          className="animate-spin inline-block w-8 h-8 mr-3 border-2 border-white border-l-transparent rounded-full"
                          role="status"
                        ></span>
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={SubmitImage}
                        className="inline-flex items-center bg-deep-purple-500 hover:bg-deep-purple-400 text-white text-base font-medium whitespace-nowrap rounded-lg hover:shadow-md transition duration-200 py-3 px-4"
                      >
                        <svg
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          className="mr-1"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.09814 0.0386963C3.67974 0.0386963 0.0981445 3.6203 0.0981445 8.0387C0.0981445 12.4571 3.67974 16.0387 8.09814 16.0387C12.5165 16.0387 16.0981 12.4571 16.0981 8.0387C16.0981 3.6203 12.5165 0.0386963 8.09814 0.0386963ZM10.4981 8.8387H8.89814V10.4387C8.89814 10.8803 8.53974 11.2387 8.09814 11.2387C7.65654 11.2387 7.29814 10.8803 7.29814 10.4387V8.8387H5.69814C5.25654 8.8387 4.89814 8.4803 4.89814 8.0387C4.89814 7.5971 5.25654 7.2387 5.69814 7.2387H7.29814V5.6387C7.29814 5.1971 7.65654 4.8387 8.09814 4.8387C8.53974 4.8387 8.89814 5.1971 8.89814 5.6387V7.2387H10.4981C10.9397 7.2387 11.2981 7.5971 11.2981 8.0387C11.2981 8.4803 10.9397 8.8387 10.4981 8.8387Z"
                            fill="white"
                          />
                        </svg>
                        Upload
                      </button>
                    )}
                  </div>
                  {candata.Logo && (
                    <div className="items-center justify-center buttonLeft">
                      {loading == true ? (
                        <button
                          type="button"
                          className="w-full flex items-center justify-center bg-deep-purple-500 hover:bg-deep-purple-400 text-white text-lg font-medium rounded-lg hover:shadow-md transition duration-200 py-3 px-4 mb-4"
                          disabled
                        >
                          <span
                            className="animate-spin inline-block w-8 h-8 mr-3 border-2 border-white border-l-transparent rounded-full"
                            role="status"
                          ></span>
                          Loading...
                        </button>
                      ) : (
                        <button
                          className="bg-deep-purple-500 items-center hover:bg-deep-purple-400 text-white text-lg font-medium rounded-lg  hover:shadow-md transition duration-200 py-3 px-4 mb-4"
                          type="submit"
                          onClick={DeleteImage}
                        >
                          Delete profilepic
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {model == true && (
            <div className="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto show">
              <div className="modal-backdrop fade show"></div>
              <div className="modal-dialog modal-dialog-centered relative w-auto pointer-events-none pt-16">
                <div className="modal-content border-none shadow-lg relative flex flex-col w-full w-lg pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                  <div className="modal-header flex flex-shrink-0 items-start justify-between p-4 md:px-6 border-b border-gray-300 rounded-t-lg">
                    <button
                      type="button"
                      className="btn-close box-content w-4 h-4 p-2.5 md:p-3 absolute right-4 -top-14 bg-deep-purple-50 hover:bg-deep-purple-100 text-deep-purple-500 border-none rounded-xl focus:shadow-none focus:outline-none hover:text-deep-purple-500 hover:no-underline"
                      onClick={handleNewModelClose}
                      aria-label="Close"
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.00291 0.000434093C0.804333 0.000486257 0.610286 0.0597734 0.445575 0.170716C0.280863 0.281658 0.152972 0.439215 0.0782501 0.623243C0.00352837 0.807271 -0.014628 1.00941 0.0261023 1.20381C0.0668325 1.39821 0.164598 1.57605 0.306901 1.71459L6.58849 7.99786L0.306901 14.2811C0.211101 14.3731 0.134617 14.4833 0.0819275 14.6053C0.029238 14.7272 0.00140279 14.8584 5.16679e-05 14.9913C-0.00129945 15.1241 0.0238609 15.2559 0.0740591 15.3789C0.124257 15.5019 0.198484 15.6136 0.292393 15.7075C0.386302 15.8015 0.498004 15.8757 0.620959 15.9259C0.743913 15.9761 0.875648 16.0013 1.00845 15.9999C1.14125 15.9986 1.27244 15.9707 1.39435 15.918C1.51626 15.8653 1.62643 15.7888 1.71841 15.693L8 9.40974L14.2816 15.693C14.3736 15.7888 14.4837 15.8653 14.6056 15.918C14.7276 15.9708 14.8587 15.9986 14.9915 15.9999C15.1243 16.0013 15.2561 15.9761 15.379 15.9259C15.502 15.8757 15.6137 15.8015 15.7076 15.7075C15.8015 15.6136 15.8757 15.5019 15.9259 15.3789C15.9761 15.2559 16.0013 15.1241 15.9999 14.9913C15.9986 14.8584 15.9708 14.7272 15.9181 14.6053C15.8654 14.4833 15.7889 14.3731 15.6931 14.2811L9.4115 7.99786L15.6931 1.71459C15.8373 1.57434 15.9359 1.39378 15.9757 1.19656C16.0156 0.999346 15.995 0.794678 15.9166 0.609379C15.8382 0.424079 15.7057 0.266792 15.5364 0.158131C15.3671 0.0494693 15.1689 -0.00549711 14.9678 0.000434093C14.7085 0.0081633 14.4624 0.116575 14.2816 0.302702L8 6.58597L1.71841 0.302702C1.62539 0.207055 1.51414 0.131028 1.39125 0.079111C1.26836 0.0271943 1.13631 0.000441872 1.00291 0.000434093Z"
                          fill="#6936FF"
                        />
                      </svg>
                    </button>
                    <section className="w-full bg-white rounded-xl shadow max-w-[26.25rem] mx-auto p-4 md:p-8">
                      <form className="flex flex-col" method="POST" action="#">
                        <div className="w-full mb-4">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 mb-1"
                            htmlFor="password"
                          >
                            Company <strong>Name</strong>
                          </label>
                          <input
                            type="text"
                            id="name"
                            value={newName}
                            onChange={(e) => {
                              setNewName(e.target.value);
                            }}
                            placeholder="Company Name"
                            className="appearance-none block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 placeholder:text-gray-400 bg-white bg-clip-padding border border-gray-300 rounded-lg m-0 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition ease-in-out"
                          />
                        </div>
                        <div className="w-full mb-4">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 mb-1"
                            htmlFor="password"
                          >
                            Main <strong>Industry</strong>
                          </label>
                          <Select
                            placeholder="Choose Industry"
                            name="stage"
                            isClearable={true}
                            getOptionLabel={(item) => item?.currItem}
                            getOptionValue={(item) => item?.currItem}
                            onChange={(item) => setNewIndustry(item)}
                            value={newIndustry}
                            options={idustriesArray}
                          />
                        </div>
                        <div className="w-full mb-4">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 mb-1"
                            htmlFor="password"
                          >
                            Company <strong>Stage</strong>
                          </label>
                          <Select
                            placeholder="Choose Stage"
                            name="stage"
                            isClearable={true}
                            getOptionLabel={(item) => item?.currItem}
                            getOptionValue={(item) => item?.currItem}
                            onChange={(item) => setNewStage(item)}
                            value={newStage}
                            options={stagesArray}
                          />
                        </div>
                        <div className="w-full mb-4">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 mb-1"
                            htmlFor="password"
                          >
                            Main <strong>Headquarters</strong>
                          </label>
                          <input
                            type="text"
                            id="Location"
                            value={newLocation}
                            onChange={(e) => {
                              setNewLocatiom(e.target.value);
                            }}
                            placeholder="Main Headquarters"
                            className="appearance-none block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 placeholder:text-gray-400 bg-white bg-clip-padding border border-gray-300 rounded-lg m-0 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition ease-in-out"
                          />
                        </div>
                        <div className="w-full mb-4">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 mb-1"
                            for="password"
                          >
                            Company short <strong>Description</strong>
                          </label>
                          <textarea
                            id="textarea"
                            placeholder={`No information to show`}
                            rows="6"
                            onChange={(e) => handleCharCount(e)}
                            // onChange={(e) => setNewDiscrip(e.target.value)}
                            maxlength="122"
                            value={newDiscription}
                            className="appearance-none block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 placeholder:text-gray-400 bg-white bg-clip-padding border border-gray-300 rounded-lg m-0 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition ease-in-out"
                          />
                          <div className="mainCharDiv">
                            {CharCount >= 122 && (
                              <p className="char_text">
                                You have reached the character limit.
                              </p>
                            )}
                            <h5 className="char_count">{CharCount}/122</h5>
                          </div>
                        </div>
                        <div className="w-full mb-4">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 mb-1"
                            htmlFor="password"
                          >
                            Company <strong>Website</strong>
                          </label>
                          <input
                            type="text"
                            id="Website"
                            value={newWesite}
                            onChange={(e) => {
                              setNewWesite(e.target.value);
                            }}
                            placeholder="Website"
                            className="appearance-none block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 placeholder:text-gray-400 bg-white bg-clip-padding border border-gray-300 rounded-lg m-0 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition ease-in-out"
                          />
                        </div>
                        {newErrorMsg && (
                          <div className="inline-flex items-baseline mt-2 mb-2">
                            <img className="self-center" src={Vector} />
                            <h5 className="errorClass">{newErrorMsg}</h5>
                          </div>
                        )}
                        {loading == true ? (
                          <button
                            type="button"
                            className="w-full flex items-center justify-center bg-deep-purple-500 hover:bg-deep-purple-400 text-white text-lg font-medium rounded-lg hover:shadow-md transition duration-200 py-3 px-4 mb-4"
                            disabled
                          >
                            <span
                              className="animate-spin inline-block w-8 h-8 mr-3 border-2 border-white border-l-transparent rounded-full"
                              role="status"
                            ></span>
                            Loading...
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={AddCompany}
                            className="inline-flex items-center bg-deep-purple-500 hover:bg-deep-purple-400 text-white text-base font-medium whitespace-nowrap rounded-lg hover:shadow-md transition duration-200 py-3 px-4"
                          >
                            <svg
                              width="17"
                              height="17"
                              viewBox="0 0 17 17"
                              className="mr-1"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.09814 0.0386963C3.67974 0.0386963 0.0981445 3.6203 0.0981445 8.0387C0.0981445 12.4571 3.67974 16.0387 8.09814 16.0387C12.5165 16.0387 16.0981 12.4571 16.0981 8.0387C16.0981 3.6203 12.5165 0.0386963 8.09814 0.0386963ZM10.4981 8.8387H8.89814V10.4387C8.89814 10.8803 8.53974 11.2387 8.09814 11.2387C7.65654 11.2387 7.29814 10.8803 7.29814 10.4387V8.8387H5.69814C5.25654 8.8387 4.89814 8.4803 4.89814 8.0387C4.89814 7.5971 5.25654 7.2387 5.69814 7.2387H7.29814V5.6387C7.29814 5.1971 7.65654 4.8387 8.09814 4.8387C8.53974 4.8387 8.89814 5.1971 8.89814 5.6387V7.2387H10.4981C10.9397 7.2387 11.2981 7.5971 11.2981 8.0387C11.2981 8.4803 10.9397 8.8387 10.4981 8.8387Z"
                                fill="white"
                              />
                            </svg>
                            Save Changes
                          </button>
                        )}
                      </form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

CompaniesDetailTable.propTypes = {
  fetchCompData: PropTypes.func,
};

const mapStateToProps = ({ authentication }) => ({
  authentication_state: authentication,
});

export default connect(mapStateToProps, {
  fetchCompData: getCompanyDash,
})(CompaniesDetailTable);
