export const idustriesArray = [
    { currItem: "Artificial Intelligence" },
    { currItem: "Blockchain" },
    { currItem: "B2B Services" },
    { currItem: "Crypto" },
    { currItem: "Consumer" },
    { currItem: "Data Analytics" },
    { currItem: "eCommerce" },
    { currItem: "Sustainability" },
    { currItem: "EdTech" },
    { currItem: "Enterprise Software" },
    { currItem: "FemTech" },
    { currItem: "FinTech" },
    { currItem: "Healthcare" },
    { currItem: "InsurTech" },
    { currItem: "Marketplace" },
    { currItem: "Payments" },
    { currItem: "Robotics" },
    { currItem: "Retail" },
    { currItem: "SaaS" },
  ];

export const ExpertiseMapStringresult = [
    { currItem: "Biz Ops & Strategy" },
    { currItem: "Product" },
    { currItem: "Commercial" },
    { currItem: "Growth & Marketing" },
    { currItem: "People & Talent" },
    { currItem: "Customer Success" },
    { currItem: "Finance" },
    { currItem: "Legal" },
    { currItem: "Risk & Compliance" },
    { currItem: "Software Engineering" },
    { currItem: "Data Science / Machine Learning" },
    { currItem: "BI / Data Analytics" },
    { currItem: "Procurement & Supply Chain" },
    { currItem: "Administrative" },
  ];  

  export const Unqiiidesire = [
    { currItem: "London" },
    { currItem: "Cambridge" },
    { currItem: "Other Location (UK)" },
    { currItem: "San Fran / Bay Area" },
    { currItem: "New York" },
    { currItem: "Los Angeles" },
    { currItem: "Boston" },
    { currItem: "Other Location (US)" },
    { currItem: "Paris" },
    { currItem: "Berlin" },
    { currItem: "Other Location (Europe)" },
    { currItem: "Other Location (Asia)" },
  ];  
 
 export const uniqueLevelresult = [
   { currItem: "Mid" },
   { currItem: "Senior" },
   { currItem: "Lead/Principal" },
   { currItem: "Manager" },
   { currItem: "Head Of" },
   { currItem: "Director" },
   { currItem: "VP/GM" },
   { currItem: "Exec" },
   { currItem: "NED" },
   { currItem: "Founder" },
 ]; 

 export const uniqueBizzModel = [
  { currItem: "B2B" },
  { currItem: "B2C" },
  { currItem: "B2G" },
  { currItem: "Marketplace" },
]; 

export const uniqueNewStage = [
  { currItem: "Pre-Seed" },
  { currItem: "Seed" },
  { currItem: "Series A" },
  { currItem: "Series B" },
  { currItem: "Series C" },
  { currItem: "Series D" },
  { currItem: "Series E" },
  { currItem: "Pre-IPO" },
]; 

 export const uniqueLocationresult = [
    { currItem: "London" },
    { currItem: "Cambridge" },
    { currItem: "Other Location (UK)" },
    { currItem: "San Fran / Bay Area" },
    { currItem: "New York" },
    { currItem: "Los Angeles" },
    { currItem: "Boston" },
    { currItem: "Other Location (US)" },
    { currItem: "Paris" },
    { currItem: "Berlin" },
    { currItem: "Other Location (Europe)" },
    { currItem: "Other Location (Asia)" },
  ];