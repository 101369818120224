import React, { useRef } from "react";
import { BrowserRouter, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { getCookie } from "../shared/utils/cookies";
import { createBrowserHistory  } from 'history'
import Register from "../modules/Register";
import Login from "../modules/Login";
import PortfolioLogin from "../modules/PortfolioLogin";
import CandidatePopUpLogin from "../modules/ModuleLogin/index";
import Dashboard from "../modules/Dashboard";
import ModelRoute from "../modules/Dashboard/singleton/CandidateCards/ModelRoute";
import { Route, withRouter } from "react-router";
import Form from "../modules/Dashboard/singleton/Component/Form"
import LoadingComp from "../modules/Dashboard/singleton/Component/Loading"
import PropTypes from "prop-types";
import CandidateDetailTable from "../modules/Dashboard/singleton/Component/CandidateDetailTable";
import PdfFiles from "../modules/Dashboard/singleton/Component/PdfFiles";
import CompanyLogin from "../modules/Login/CompanyLogin.js";
import RedirectingToTheForm from "../modules/Dashboard/singleton/Component/RedirectingToTheForm";
import RedirectingComponent from "../modules/Dashboard/singleton/Component/RedirectingComponent";
import Forget from "../modules/ForgotPass/Forget.js";
import NewPass from "../modules/ForgotPass/NewPass.js";
import LoginForm from "../modules/ForgotPass/LoginForm.js";
import CompanyDetails from "../modules/Dashboard/singleton/Component/CompanyDetails";
import ReactGA from "react-ga"
import AddMember from "../modules/AddMember";
import CompaniesDetailTable from "../modules/Dashboard/singleton/Component/CompaniesDetailTable";
import CompanyDashboard from "../modules/Dashboard/CompanyDashboard";
import TagManager from 'react-gtm-module'
import TeamMemberTable from "../modules/Dashboard/singleton/Component/TeamMemberTable";
// import Forms from "../modules/Dashboard/singleton/FormNew";
import Forms from "../modules/Dashboard/singleton/Forms";
import CompanyTeamMamber from "../modules/Dashboard/singleton/Component/CompanyTeamMamber";
import AdvisoryDashboard from "../modules/Dashboard/AdvisoryDash";
import AdvisoryForm from "../modules/Dashboard/singleton/AdvisoryForm";
import ModuleAdvisorRoute from "../modules/Dashboard/singleton/CandidateCards/ModuleAdvisorRoute";
import ConnectDetails from "../modules/Dashboard/singleton/Component/ConnectDetails.js";
import ListUser from "../modules/Dashboard/singleton/ListUser"
import ModuleLists from "../modules/Dashboard/singleton/CandidateCards/ModuleLists.js";
export const history = createBrowserHistory();

// history.listen((location) => {
//   ReactGA.pageview(location)
// })

const Router = ({authentication_state}) => {
  const authentication_ref = useRef(authentication_state);
  authentication_ref.current = authentication_state;
  const LoggedIn = getCookie("access_token");
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route exact path="/:name/register" component={Register} />
          {/* <Route exact path="/OctopusVentures/register" component={Register} />
          <Route exact path="/AnzuPartners/register" component={Register} />
          <Route exact path="/UpfrontVentures/register" component={Register} />
          <Route exact path="/LLC/register" component={Register} />
          <Route exact path="/Demo-VC/register" component={Register} />
          <Route exact path="/M13/register" component={Register} />
          <Route exact path="/HumanCapital/register" component={Register} />
          <Route exact path="/GVCTN/register" component={Register} />
          <Route exact path="/BTV/register" component={Register} />
          <Route exact path="/BurntIslandVentures/register" component={Register} /> */}
          <Route exact path="/octupusVenture/register" component={RedirectingComponent} />
          <Route exact path="/" component={Login} />
          <Route path="/:token/:uniqueId/add_candidate" component={RedirectingToTheForm} />
          <Route exact path="/login" component={Login} />
          {/* <Route exact path="/Userlogin"> <CandidatePopUpLogin params={params} /></Route> */}
          <Route exact path="/loginForm" component={LoginForm} />
          <Route exact path="/user/password_confirm/:id" component={NewPass} />
          <Route exact path="/forgotPassword" component={Forget} />
          <Route exact path="/dashboard/candidates">
             {LoggedIn ? <Dashboard/> : <Redirect to="/" /> }
          </Route>
          <Route exact path="/dashboard/companies">
             {LoggedIn ? <CompanyDashboard/> : <Redirect to="/" /> }
          </Route>
          <Route exact path="/dashboard/advisors">
             {LoggedIn ? <AdvisoryDashboard/> : <Redirect to="/" /> }
          </Route>
          {/* <Route path="/dashboard/:id" component={ModelRoute} /> */}
          <Route path="/candidate/:id-:firstname-:lastname">
            {LoggedIn ? <ModelRoute/> : <CandidatePopUpLogin />}
          </Route>
          <Route path="/lists/:id-:name">
            {LoggedIn ? <ModuleLists/> : <CandidatePopUpLogin />}
          </Route>
          <Route path="/advisors/:id-:firstname-:lastname">
            {LoggedIn ? <ModuleAdvisorRoute/> : <CandidatePopUpLogin />}
          </Route>
          {/* Old URL Will delete this in some point of time */}
          <Route path="/dashboard/:id/:uniqueId/:role">
            {LoggedIn ? <ModelRoute/> : <Redirect to="/OctopusVentures/register" />}
          </Route>
          <Route path="/dashboard">
           <Redirect to="/dashboard/candidates" />
          </Route>
          {/* <Route path="/:name/applyid=:id" component={Form} /> */}
          <Route path="/:name/applyid=:id" component={Forms} />
          <Route path="/:name/advisorsid=:id" component={AdvisoryForm} />
          <Route path="/:name/add-member" component={AddMember} />
          <Route path="/user/user/emailconfirm/:id" component={LoadingComp} />
          <Route path="/Candidates/details"> <CandidateDetailTable/> </Route>
          <Route path="/manage/team-member"> <TeamMemberTable/> </Route>
          <Route path="/manage-company/team-member"> <CompanyTeamMamber/> </Route>
          <Route path="/candidates/connections"> <ConnectDetails/> </Route>
          <Route path="/lists"> <ListUser/> </Route>
          <Route path="/companies/details"> <CompaniesDetailTable/> </Route>
          <Route path="/Company/details"> <CompanyDetails/> </Route>
          <Route path="/pdfDetails/:id"> <PdfFiles/> </Route>
          <Route path="/:id/:role">
            {LoggedIn ? <Dashboard/> : <Redirect to="/OctopusVentures/register" /> }
          </Route>
        </Switch>
        {/* <Route path="/testing"> <CandidateDetailTable/> </Route> */}
      </BrowserRouter>
    </>
  );
};

Router.propTypes = {
  authentication_state: PropTypes.object,
};
const mapStateToProps = ({ authentication }) => ({
  authentication_state: authentication,
});

export default withRouter(
  connect(mapStateToProps)(Router)
);