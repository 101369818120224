import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import "../../../Login/index.css";
import "./CompIndex.css";
import OctupusImg from "../../images/OctopusVentures.svg";
import DummyVcLogo from "../../images/gvc.svg";
import HumanCapital from "../../images/human.svg";
import { ReactComponent as M13Img } from "../../images/m13.svg";
import Oak from "../../../AddMember/images/Oaknew.svg";
import { CSVLink } from "react-csv";
import { getCookie, setCookie } from "../../../../shared/utils/cookies";
import { ReactComponent as ProfileIcon } from "../../../Dashboard/images/imageProfile.svg";
import Icon from "../../../Dashboard/images/imageProfile.svg";
import { PropTypes } from "prop-types";
import $ from "jquery";
import {
  getCompanyData,
  getRoles,
  getPortfolioComp,
  getLogOut,
} from "../../../authentication";
import { Helmet } from "react-helmet";
import moment from "moment";
import axios from "axios";
import PageHeaders from "./PageHeaders";

const CompanyDetails = ({
  authentication_state,
  fetchRegisteredCandidate,
  fetchRoles,
  fetchVentureData,
  UserLogout,
}) => {
  const authentication_ref = useRef(authentication_state);
  authentication_ref.current = authentication_state;
  // const RoleName = authentication_ref?.current?.Roles_data?.response?.data?.data?.rolename;
  const uniqueRoleId =
    authentication_ref?.current?.retailer_auth?.response?.data?.data?.role_id ||
    "";
  // const UserCase = authentication_ref.current?.User?.response?.data?.data?.access || "";
  const uniqueId =
    authentication_ref?.current?.retailer_auth?.response?.data?.data?.id || "";
  const UserID = localStorage.getItem("usersIdentity");
  const storedCompanyName = localStorage.getItem('companyName')
  const CompanyName =
    authentication_ref?.current?.port_data?.response?.data?.data[0] || [];
  // const Token = getCookie("access_token");
  // const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [cardData, setCardData] = useState([]);
  const [profileIcon, setProfileIcon] = useState(false);
  const [userRole, setUserRole] = useState();
  const [userRoleData, setUSerRoleData] = useState();
  const [userCompany, setUserCompony] = useState();
  const [fullData, setFullData] = useState([]);
  const [companyCount, setCompanyCount] = useState(0);
  const [totalUserCount, setTotalUserCount] = useState(0);
  const [VentureData, setVentureData] = useState()
  // const [PortfolioData, setPortfolioData] = useState([])
  // const [PortfolioDataFiltered, setPortfolioDataFiltered] = useState([])
  const iconRef = useRef();
  const menuRefs = useRef();

  useEffect(() => {
    const headerData = {
      headers: {
        "Content-Type": "application/json",
      },
    };
      axios
      .get(
        `/api/venturecapitalforms/venturecapitalforms/get/${UserID}/`,
        headerData
      ).then((res) => {
        setVentureData(res?.data?.data)
      })
  }, [])

  useEffect(() => {
    if (filteredData) {
      // Calculate the company and user counts
      let companies = filteredData.length;
      let users = filteredData.reduce(
        (total, company) => total + company.users.length,
        0
      );

      setCompanyCount(companies);
      setTotalUserCount(users);
    }
  }, [filteredData]);

  const handleSearch = (searchTerm) => {
    const updatedFilteredData = fullData.filter((company) => {
      const companyMatch = company.company_name
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const userMatch = company.users.some(
        (user) =>
          user.firstname.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.lastname.toLowerCase().includes(searchTerm.toLowerCase())
      );
      return companyMatch || userMatch;
    });
    setFilteredData(updatedFilteredData);
  };

  const handleClearSearch = () => {
    setFilteredData(fullData);
    setSearchTerm("");
  };

  const handleSearchInputChange = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    if (newSearchTerm) {
      handleSearch(newSearchTerm);
    } else {
      handleClearSearch();
    }
  };

  useEffect(() => {
    let handler = (event) => {
      if (!menuRefs?.current?.contains(event.target)) {
        setProfileIcon(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  useEffect(() => {
    const data = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios.get(`/api/user/get/${UserID}/`, data).then((responce) => {
      setUSerRoleData(responce.data.data.access);
    });
  }, []);

  //Getting the user roles
  useEffect(() => {
    fetchRoles(uniqueRoleId).then(() => {
      if (authentication_ref?.current?.Roles_data?.response?.status == 200) {
        setUserRole(
          authentication_ref?.current?.Roles_data?.response?.data?.data
            ?.rolename
        );
      }
    });
  }, []);

  useEffect(() => {
    fetchVentureData(uniqueId).then(() => {
      if (authentication_ref?.current?.port_data?.response?.status == 200) {
        setUserCompony(
          authentication_ref?.current?.port_data?.response?.data?.data[0]
        );
      }
    });
  }, []);

  const handleCandidateDetails = () => {
    window.open(`${window.location.origin}/Candidates/details`);
  };

  //Handling add new team member
  const handleTeammember = () => {
    if (userCompany == undefined) {
      window.open(`${window.location.origin}/StartupLane/add-member`);
    } else {
      const modifiedString = VentureData?.url_shortname; 
      // userRole == "admin" ? "StartupLane" : "OctopusVentures";
      window.open(`${window.location.origin}/${modifiedString}/add-member`);
    }
  };

  //Handling company member
  const handleCampanyMember = () => {
    const modifiedString = VentureData?.url_shortname;
    // const RolebasedName = VentureData?.url_shortname
    window.open(`${window.location.origin}/${modifiedString}/register`);
  };

  //LoggOut user
  const LogoutUser = () => {
    UserLogout();
    setCookie("access_token", "");
    window.localStorage.removeItem("Token");
    window.location.href = "/";
  };

  const handleCompaniesDetails = () => {
    window.open(`${window.location.origin}/companies/details`);
  };

  const handleRegisteredComp = () => {
    window.open(`${window.location.origin}/Company/details`);
  };

  const handleList = () => {
    window.open(`${window.location.origin}/lists`);
  };

  //FETCHING REGISTERED CANDIDTE DETAILS
  useEffect(() => {
    const storedCompanyId = localStorage.getItem('companyId')
    fetchRegisteredCandidate(storedCompanyId).then(() => {
      if (
        authentication_ref.current?.Company_data?.response?.data?.status_code ==
        200
      ) {
        setFullData(
          authentication_ref.current?.Company_data?.response?.data?.data
        );
        setFilteredData(
          authentication_ref.current?.Company_data?.response?.data?.data
        );
      }
    });
  }, []);

  // useEffect(() => {
  //   const result = FullData.filter((candidate) => {
  //     return candidate.firstname
  //       .toLocaleLowerCase()
  //       .match(search.toLocaleLowerCase());
  //   });
  //   setFullData(result);
  // }, [search]);

  const handleRowData = (state) => {
    setCardData(state.selectedRows);
  };

  //   const data = cardDatas

  const headers = [
    {
      label: "First Name",
      key: "firstname",
    },
    {
      label: "Last Name",
      key: "lastname",
    },
    {
      label: "Role",
      key: "current_role",
    },
    {
      label: "Company Name",
      key: "company_name",
    },
    {
      label: "Email",
      key: "email",
    },
    {
      label: "Company Website",
      key: "website",
    },
    {
      label: "Date Joined",
      key: "created_at",
    },
    {
      label: "VC",
      key: "vc",
    },
  ];

  const NewData = fullData?.filter((item) => {
    const fullName = `${item.users[0]?.firstname} ${item.users[0]?.lastname}`;
    return (
      item.company_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      fullName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const csvData = filteredData
    ?.map((company) => {
      const users = company?.users?.map((user) => ({
        Company: company?.company_name,
        Website: company?.website,
        CreatedAt: company?.created_at,
        // ... Other company fields
        FirstName: user?.firstname,
        LastName: user?.lastname,
        Email: user?.email,
        Role: user?.current_role,
        // ... Other user fields
      }));
      return users;
    })
    .flat();

  const csvLink = {
    filename: "PortcoUsers.csv",
    // headers: headers,
    data: csvData,
  };

  const handlePortFolioAdding = (e) => {
    const modifiedString = VentureData?.url_shortname; // Remove spaces
    // const RolebasedName = VentureData?.url_shortname
    window.open(`${window.location.origin}/${modifiedString}/register`);
  };

  const handleInitials = (company) => {
    const words = company.company_name.split(" ");

    if (words.length > 1) {
      return (
        <span>
          {words[0][0]}
          {words[1][0]?.toUpperCase()}
        </span>
      );
    } else if (company.company_name.length > 1) {
      return <span>{company.company_name?.substring(0, 2)?.toUpperCase()}</span>;
    } else {
      return <span>{company?.company_name?.toUpperCase()}</span>;
    }
  };

  const handleCompUrl = (company, e) => {
    if(e){
      window.open(company?.website);
    }
  };

  const handleCampanyTeamMember = () => {
    window.open(`${window.location.origin}/manage-company/team-member`);
  };

  const handlemanageTeamMember = () => {
    window.open(`${window.location.origin}/manage/team-member`);
  };

  useEffect(() => {
    function handleScroll(event) {
      const tempEl = $(event.currentTarget).parent().children(".data_row");
      const scrollLeft = event.currentTarget.scrollLeft;

      for (let i = 0; i < tempEl.length; i++) {
        $(tempEl[i]).scrollLeft(scrollLeft);
      }
    }

    $(".custom_card .data_row").on("scroll", handleScroll);

    return () => {
      $(".custom_card .data_row").off("scroll", handleScroll);
    };
  }, []);

  const handleCollection = () => {
    window.open(`${window.location.origin}/candidates/connections`);
  }

  return (
    <>
      <Helmet>
        <title>Portfolio Users</title>
      </Helmet>
      <div
        className="leading-normal text-gray-1000 bg-deep-purple-10 font-sans min-h-screen	h-full"
        style={{ fontFamily: "DM Sans, sans-serif" }}
      >
        <PageHeaders
          title={"Portfolio Users"}
          CompanyName={CompanyName}
          VentureData={VentureData}
          userRole={userRole}
          profileIcon={profileIcon}
          handleCollection={handleCollection}
          storedCompanyName={storedCompanyName}
          setProfileIcon={setProfileIcon}
          menuRefs={menuRefs}
          handleCampanyTeamMember={handleCampanyTeamMember}
          LogoutUser={LogoutUser}
          handleCandidateDetails={handleCandidateDetails}
          handleRegisteredComp={handleRegisteredComp}
          handleList={handleList}
          handlemanageTeamMember={handlemanageTeamMember}
          handleCompaniesDetails={handleCompaniesDetails}
        />
        <div className="pt-[9.5rem] sm:pt-[9.75rem] lg:pt-[9rem]">
          <section className="fixed z-10 inset-x-0 top-[100px] sm:top-[4rem] lg:top-[3.25rem] bg-deep-purple-10 w-full px-7 pt-2 lg:pt-4 border-b border-gray-300">
            <form className="flex relative TableHeads" method="POST" action="#">
              <div className="inline-flex items-center group lg:w-48 lg:pr-1.5 mb-2 lg:mb-4">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  className="absolute z-[1] left-4 mb-0.5"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.50441 0.554688C3.19958 0.554688 0.504395 3.24987 0.504395 6.55471C0.504395 9.85954 3.19958 12.5547 6.50441 12.5547C8.02237 12.5547 9.4082 11.9825 10.4668 11.0474L11.0044 11.585V12.5547L13.6939 15.2442C14.1079 15.6582 14.7799 15.6582 15.1939 15.2442C15.6079 14.8302 15.6079 14.1582 15.1939 13.7442L12.5044 11.0547H11.5347L10.9971 10.5171C11.9322 9.45849 12.5044 8.07266 12.5044 6.55471C12.5044 3.24987 9.80925 0.554688 6.50441 0.554688ZM6.50441 2.05469C8.99859 2.05469 11.0044 4.06053 11.0044 6.55471C11.0044 9.04888 8.99859 11.0547 6.50441 11.0547C4.01024 11.0547 2.0044 9.04888 2.0044 6.55471C2.0044 4.06053 4.01024 2.05469 6.50441 2.05469Z"
                    fill="#434343"
                  />
                </svg>
                <input
                  type="search"
                  id="search"
                  value={searchTerm}
                  onChange={handleSearchInputChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      setSearchTerm(e.target.value);
                    }
                  }}
                  placeholder="Search"
                  className="w-[42px] absolute sm:relative hover:w-full focus:w-full sm:w-48 sm:hover:w-48 sm:focus:w-48 sm:active:w-48 appearance-none text-ellipsis text-base leading-3 sm:leading-6 bg-white font-normal text-gray-800  placeholder:text-gray-400 rounded-lg py-2 hover:pr-4 focus:pr-4 sm:pr-4 pl-9 border border-gray-300 focus:outline-none focus:ring-1 focus:ring-deepe-purplee-500 focus:border-deepe-purplee-500 transition duration-500"
                />
              </div>
              <div className="flex items-center ml-auto justify-between mb-2 lg:mb-4">
                <CSVLink {...csvLink}>
                  <button
                    type="button"
                    className="csvButton ml-3 mr-3 inline-flex items-center text-base font-medium whitespace-nowrap rounded-lg hover:shadow-md transition duration-200 py-3 px-4"
                  >
                    <svg
                      width="16px"
                      className="mr-2"
                      height="19px"
                      viewBox="0 0 22 22"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                    >
                      <g
                        id="Page-1"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          id="noun-download-5485540"
                          fill="#434343"
                          fill-rule="nonzero"
                        >
                          <path
                            d="M11,0 C4.92486775,0 0,4.92486775 0,11 C0,17.0751322 4.92486775,22 11,22 C17.0751322,22 22,17.0751322 22,11 C22,8.08261861 20.8410748,5.28472557 18.7781746,3.22182541 C16.7152744,1.15892524 13.9173814,0 11,0 Z M14.71,13.71 L11.71,16.71 C11.5212812,16.8971865 11.265803,17.0015368 11,17.0000167 C10.735233,16.9963165 10.4816338,16.8927337 10.29,16.71 L7.29,13.71 C6.89787783,13.3178778 6.89787783,12.6821222 7.29,12.29 C7.68212217,11.8978778 8.31787783,11.8978778 8.71,12.29 L10,13.59 L10,6 C10,5.44771525 10.4477153,5 11,5 C11.5522847,5 12,5.44771525 12,6 L12,13.59 L13.29,12.29 C13.6821222,11.8978778 14.3178778,11.8978778 14.71,12.29 C15.1021222,12.6821222 15.1021222,13.3178778 14.71,13.71 Z"
                            id="Shape"
                          ></path>
                        </g>
                      </g>
                      {/* <path d="M11,0 C4.92486775,0 0,4.92486775 0,11 C0,17.0751322 4.92486775,22 11,22 C17.0751322,22 22,17.0751322 22,11 C22,8.08261861 20.8410748,5.28472557 18.7781746,3.22182541 C16.7152744,1.15892524 13.9173814,0 11,0 Z M14.71,13.71 L11.71,16.71 C11.5212812,16.8971865 11.265803,17.0015368 11,17.0000167 C10.735233,16.9963165 10.4816338,16.8927337 10.29,16.71 L7.29,13.71 C6.89787783,13.3178778 6.89787783,12.6821222 7.29,12.29 C7.68212217,11.8978778 8.31787783,11.8978778 8.71,12.29 L10,13.59 L10,6 C10,5.44771525 10.4477153,5 11,5 C11.5522847,5 12,5.44771525 12,6 L12,13.59 L13.29,12.29 C13.6821222,11.8978778 14.3178778,11.8978778 14.71,12.29 C15.1021222,12.6821222 15.1021222,13.3178778 14.71,13.71 Z" id="Shape"></path> */}
                    </svg>
                    Download CSV
                  </button>
                </CSVLink>
                <button
                  type="button"
                  onClick={handlePortFolioAdding}
                  className="add-Port-Button ml-3 inline-flex items-center text-white text-base font-medium whitespace-nowrap rounded-lg hover:shadow-md transition duration-200 py-3 px-4"
                >
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    className="mr-1"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.09814 0.0386963C3.67974 0.0386963 0.0981445 3.6203 0.0981445 8.0387C0.0981445 12.4571 3.67974 16.0387 8.09814 16.0387C12.5165 16.0387 16.0981 12.4571 16.0981 8.0387C16.0981 3.6203 12.5165 0.0386963 8.09814 0.0386963ZM10.4981 8.8387H8.89814V10.4387C8.89814 10.8803 8.53974 11.2387 8.09814 11.2387C7.65654 11.2387 7.29814 10.8803 7.29814 10.4387V8.8387H5.69814C5.25654 8.8387 4.89814 8.4803 4.89814 8.0387C4.89814 7.5971 5.25654 7.2387 5.69814 7.2387H7.29814V5.6387C7.29814 5.1971 7.65654 4.8387 8.09814 4.8387C8.53974 4.8387 8.89814 5.1971 8.89814 5.6387V7.2387H10.4981C10.9397 7.2387 11.2981 7.5971 11.2981 8.0387C11.2981 8.4803 10.9397 8.8387 10.4981 8.8387Z"
                      fill="white"
                    />
                  </svg>
                  Add Portfolio Company
                </button>
              </div>
            </form>
          </section>
          <body>
            {companyCount > 4 && (
              <div
                className="c-information-area flex md:flex-row relative mx-7 mt-4"
                style={{ backgroundColor: "#0496FF" }}
              >
                <div className="flex md:flex-1 md:pr-4">
                  <h3>
                    <span className="spacing">🎉</span>Congrats, you’ve got{" "}
                    {totalUserCount} users signed up from {companyCount}{" "}
                    different portfolio companies.
                  </h3>
                </div>
              </div>
            )}
            {filteredData?.map((company, index) => (
              <div class="custom_card">
                <div class="row_c">
                  <div className="flex">
                    {company.logo == null && (
                      <figure
                        class="shrink-0 bg-deep-purple-50 rounded-full w-12 h-12 inline-flex items-center justify-center"
                        style={{ width: "50px", height: "50px", marginLeft: '50px' }}
                      >
                        <span  className="text-lg text-deep-purple-500 font-medium">
                          {handleInitials(company)}
                        </span>
                      </figure>
                    )}
                    {company.logo != null && (
                      <figure
                        class="shrink-0 bg-deep-purple-50 rounded-full w-12 md:w-[60px]  h-12 md:h-[60px] inline-flex items-start justify-center overflow-hidden"
                        style={{ width: "50px", height: "50px", marginLeft: '50px' }}
                      >
                        <img
                          className="max-w-full h-auto min-h-full rounded-full"
                          // src={company.logo}
                          // src={`https://cnp1656-dev-image.s3.amazonaws.com/profilepic/${company.logo}`}
                          src={`https://cnp1656-prod.s3.amazonaws.com/companieslogo/${company.logo}`}
                        />
                      </figure>
                    )}
                  </div>
                  <div class="col_c heading_col">
                    <h4>
                      <a
                        href="javascript:void(0);"
                        onClick={(e) => {
                          handleCompUrl(company, e);
                        }}
                      >
                        {company?.company_name}
                      </a>
                    </h4>
                  </div>
                </div>
                <div
                  class="row_c data_row data_head"
                  style={{ paddingBottom: "0px" }}
                >
                  <div class="col_c">
                    <label>Full Name</label>
                  </div>
                  <div class="col_c">
                    <label>Role</label>
                  </div>
                  <div class="col_c">
                    <label>Email</label>
                  </div>
                  <div class="col_c" style={userRole == "globalvc" || userRole == "venturecapital" ? {marginRight: '20px'} : null}>
                    <label>Date Joined</label>
                  </div>
                  {userRole == "admin" && (
                    <div class="col_c" style={{ width: "10%" }}>
                    <label>Last Seen</label>
                  </div>
                  )}
                  {userRole == "admin" ? (
                    <>
                      <div class="col_c" style={{ width: "10%" }}>
                        <label>VC</label>
                      </div>
                      <div class="col_c">
                        <label>Status</label>
                      </div>
                    </>
                  ) : null}
                </div>
                {company?.users?.map((user, index) => (
                  <div
                    class="row_c data_row data_content"
                    style={{ paddingBottom: "10px" }}
                  >
                    <div class="col_c">
                      <p>
                        {user?.firstname} {user?.lastname}
                      </p>
                    </div>
                    <div class="col_c">
                      <p>{user?.current_role}</p>
                    </div>
                    <div
                      class="col_c"
                      style={
                        userRole == "admin" ? { wordBreak: "break-all" } : null
                      }
                    >
                      <p>{user?.email}</p>
                    </div>
                    <div class="col_c" style={userRole == "globalvc" || userRole == "venturecapital" ? {marginRight: '20px'} : null}>
                      <p>{moment(user?.created_at).format("DD-MM-YYYY")}</p>
                    </div>
                    {userRole == "admin" && (
                      <div class="col_c" style={{ width: "10%" }}>
                      <p>{user?.lastseen == null ? " " : moment(user?.lastseen).format("DD-MM-YYYY")}</p>
                    </div>
                    )}
                    {userRole == "admin" ? (
                      <>
                        <div class="col_c" style={{ width: "10%" }}>
                          <p>{user?.vc}</p>
                        </div>
                        <div class="col_c">
                          <label
                            style={{ fontWeight: "500" }}
                            className={`badge_c pt-1 badge_c-${
                              user?.access == "basic"
                                ? "danger"
                                : user?.access == "premium"
                                ? "success"
                                : "light"
                            }`}
                          >
                            {user?.access == "basic"
                              ? "BASIC"
                              : user?.access == "premium"
                              ? "PREMIUM"
                              : "NO ACCESS"}
                          </label>
                        </div>
                      </>
                    ) : null}
                  </div>
                ))}
              </div>
            ))}
          </body>
        </div>
      </div>
    </>
  );
};

CompanyDetails.propTypes = {
  fetchRegisteredCandidate: PropTypes.func,
  fetchRoles: PropTypes.func,
  fetchVentureData: PropTypes.func,
  UserLogout: PropTypes.func,
};

const mapStateToProps = ({ authentication }) => ({
  authentication_state: authentication,
});

export default connect(mapStateToProps, {
  fetchRegisteredCandidate: getCompanyData,
  fetchRoles: getRoles,
  UserLogout: getLogOut,
  fetchVentureData: getPortfolioComp,
})(CompanyDetails);
