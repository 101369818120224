import React from "react";
import "../../../Login/index.css"


const SkeletonCards = () => {
    return(
        <div className="flex flex-wrap px-2">
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 2xl:w-1/5  px-2.5 mb-5">
          <div className="relative rounded-lg shadow hover:shadow-md text-base-color bg-white overflow-hidden cursor-pointer p-4">
            <div className="animate-pulse">
              <div className="flex align-center border-b border-gray-300 pb-3 mb-2">
                <div className="shrink-0 rounded-full bg-slate-200 w-12 h-12"></div>
                <div className=" w-full px-4 mt-2">
                  <div className="w-3/5 h-2 bg-slate-200 rounded mb-4"></div>
                  <div className="w-full h-2 bg-slate-200 rounded"></div>
                </div>
              </div> 
              <div className="flex-1 space-y-4 py-1">
                <div className="w-3/4 h-2 bg-slate-200 rounded"></div>
                <div className="w-full h-2 bg-slate-200 rounded"></div>
                <div className="space-y-2">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 2xl:w-1/5  px-2.5 mb-5">
          <div className="relative rounded-lg shadow hover:shadow-md text-base-color bg-white overflow-hidden cursor-pointer p-4">
            <div className="animate-pulse">
              <div className="flex align-center border-b border-gray-300 pb-3 mb-2">
                <div className="shrink-0 rounded-full bg-slate-200 w-12 h-12"></div>
                <div className=" w-full px-4 mt-2">
                  <div className="w-3/5 h-2 bg-slate-200 rounded mb-4"></div>
                  <div className="w-full h-2 bg-slate-200 rounded"></div>
                </div>
              </div> 
              <div className="flex-1 space-y-4 py-1">
                <div className="w-3/4 h-2 bg-slate-200 rounded"></div>
                <div className="w-full h-2 bg-slate-200 rounded"></div>
                <div className="space-y-2">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 2xl:w-1/5  px-2.5 mb-5">
          <div className="relative rounded-lg shadow hover:shadow-md text-base-color bg-white overflow-hidden cursor-pointer p-4">
            <div className="animate-pulse">
              <div className="flex align-center border-b border-gray-300 pb-3 mb-2">
                <div className="shrink-0 rounded-full bg-slate-200 w-12 h-12"></div>
                <div className=" w-full px-4 mt-2">
                  <div className="w-3/5 h-2 bg-slate-200 rounded mb-4"></div>
                  <div className="w-full h-2 bg-slate-200 rounded"></div>
                </div>
              </div> 
              <div className="flex-1 space-y-4 py-1">
                <div className="w-3/4 h-2 bg-slate-200 rounded"></div>
                <div className="w-full h-2 bg-slate-200 rounded"></div>
                <div className="space-y-2">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 2xl:w-1/5  px-2.5 mb-5">
          <div className="relative rounded-lg shadow hover:shadow-md text-base-color bg-white overflow-hidden cursor-pointer p-4">
            <div className="animate-pulse">
              <div className="flex align-center border-b border-gray-300 pb-3 mb-2">
                <div className="shrink-0 rounded-full bg-slate-200 w-12 h-12"></div>
                <div className=" w-full px-4 mt-2">
                  <div className="w-3/5 h-2 bg-slate-200 rounded mb-4"></div>
                  <div className="w-full h-2 bg-slate-200 rounded"></div>
                </div>
              </div> 
              <div className="flex-1 space-y-4 py-1">
                <div className="w-3/4 h-2 bg-slate-200 rounded"></div>
                <div className="w-full h-2 bg-slate-200 rounded"></div>
                <div className="space-y-2">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 2xl:w-1/5  px-2.5 mb-5">
          <div className="relative rounded-lg shadow hover:shadow-md text-base-color bg-white overflow-hidden cursor-pointer p-4">
            <div className="animate-pulse">
              <div className="flex align-center border-b border-gray-300 pb-3 mb-2">
                <div className="shrink-0 rounded-full bg-slate-200 w-12 h-12"></div>
                <div className=" w-full px-4 mt-2">
                  <div className="w-3/5 h-2 bg-slate-200 rounded mb-4"></div>
                  <div className="w-full h-2 bg-slate-200 rounded"></div>
                </div>
              </div> 
              <div className="flex-1 space-y-4 py-1">
                <div className="w-3/4 h-2 bg-slate-200 rounded"></div>
                <div className="w-full h-2 bg-slate-200 rounded"></div>
                <div className="space-y-2">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 2xl:w-1/5  px-2.5 mb-5">
          <div className="relative rounded-lg shadow hover:shadow-md text-base-color bg-white overflow-hidden cursor-pointer p-4">
            <div className="animate-pulse">
              <div className="flex align-center border-b border-gray-300 pb-3 mb-2">
                <div className="shrink-0 rounded-full bg-slate-200 w-12 h-12"></div>
                <div className=" w-full px-4 mt-2">
                  <div className="w-3/5 h-2 bg-slate-200 rounded mb-4"></div>
                  <div className="w-full h-2 bg-slate-200 rounded"></div>
                </div>
              </div> 
              <div className="flex-1 space-y-4 py-1">
                <div className="w-3/4 h-2 bg-slate-200 rounded"></div>
                <div className="w-full h-2 bg-slate-200 rounded"></div>
                <div className="space-y-2">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 2xl:w-1/5  px-2.5 mb-5">
          <div className="relative rounded-lg shadow hover:shadow-md text-base-color bg-white overflow-hidden cursor-pointer p-4">
            <div className="animate-pulse">
              <div className="flex align-center border-b border-gray-300 pb-3 mb-2">
                <div className="shrink-0 rounded-full bg-slate-200 w-12 h-12"></div>
                <div className=" w-full px-4 mt-2">
                  <div className="w-3/5 h-2 bg-slate-200 rounded mb-4"></div>
                  <div className="w-full h-2 bg-slate-200 rounded"></div>
                </div>
              </div> 
              <div className="flex-1 space-y-4 py-1">
                <div className="w-3/4 h-2 bg-slate-200 rounded"></div>
                <div className="w-full h-2 bg-slate-200 rounded"></div>
                <div className="space-y-2">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 2xl:w-1/5  px-2.5 mb-5">
          <div className="relative rounded-lg shadow hover:shadow-md text-base-color bg-white overflow-hidden cursor-pointer p-4">
            <div className="animate-pulse">
              <div className="flex align-center border-b border-gray-300 pb-3 mb-2">
                <div className="shrink-0 rounded-full bg-slate-200 w-12 h-12"></div>
                <div className=" w-full px-4 mt-2">
                  <div className="w-3/5 h-2 bg-slate-200 rounded mb-4"></div>
                  <div className="w-full h-2 bg-slate-200 rounded"></div>
                </div>
              </div> 
              <div className="flex-1 space-y-4 py-1">
                <div className="w-3/4 h-2 bg-slate-200 rounded"></div>
                <div className="w-full h-2 bg-slate-200 rounded"></div>
                <div className="space-y-2">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 2xl:w-1/5  px-2.5 mb-5">
          <div className="relative rounded-lg shadow hover:shadow-md text-base-color bg-white overflow-hidden cursor-pointer p-4">
            <div className="animate-pulse">
              <div className="flex align-center border-b border-gray-300 pb-3 mb-2">
                <div className="shrink-0 rounded-full bg-slate-200 w-12 h-12"></div>
                <div className=" w-full px-4 mt-2">
                  <div className="w-3/5 h-2 bg-slate-200 rounded mb-4"></div>
                  <div className="w-full h-2 bg-slate-200 rounded"></div>
                </div>
              </div> 
              <div className="flex-1 space-y-4 py-1">
                <div className="w-3/4 h-2 bg-slate-200 rounded"></div>
                <div className="w-full h-2 bg-slate-200 rounded"></div>
                <div className="space-y-2">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 2xl:w-1/5  px-2.5 mb-5">
          <div className="relative rounded-lg shadow hover:shadow-md text-base-color bg-white overflow-hidden cursor-pointer p-4">
            <div className="animate-pulse">
              <div className="flex align-center border-b border-gray-300 pb-3 mb-2">
                <div className="shrink-0 rounded-full bg-slate-200 w-12 h-12"></div>
                <div className=" w-full px-4 mt-2">
                  <div className="w-3/5 h-2 bg-slate-200 rounded mb-4"></div>
                  <div className="w-full h-2 bg-slate-200 rounded"></div>
                </div>
              </div> 
              <div className="flex-1 space-y-4 py-1">
                <div className="w-3/4 h-2 bg-slate-200 rounded"></div>
                <div className="w-full h-2 bg-slate-200 rounded"></div>
                <div className="space-y-2">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 2xl:w-1/5  px-2.5 mb-5">
          <div className="relative rounded-lg shadow hover:shadow-md text-base-color bg-white overflow-hidden cursor-pointer p-4">
            <div className="animate-pulse">
              <div className="flex align-center border-b border-gray-300 pb-3 mb-2">
                <div className="shrink-0 rounded-full bg-slate-200 w-12 h-12"></div>
                <div className=" w-full px-4 mt-2">
                  <div className="w-3/5 h-2 bg-slate-200 rounded mb-4"></div>
                  <div className="w-full h-2 bg-slate-200 rounded"></div>
                </div>
              </div> 
              <div className="flex-1 space-y-4 py-1">
                <div className="w-3/4 h-2 bg-slate-200 rounded"></div>
                <div className="w-full h-2 bg-slate-200 rounded"></div>
                <div className="space-y-2">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 2xl:w-1/5  px-2.5 mb-5">
          <div className="relative rounded-lg shadow hover:shadow-md text-base-color bg-white overflow-hidden cursor-pointer p-4">
            <div className="animate-pulse">
              <div className="flex align-center border-b border-gray-300 pb-3 mb-2">
                <div className="shrink-0 rounded-full bg-slate-200 w-12 h-12"></div>
                <div className=" w-full px-4 mt-2">
                  <div className="w-3/5 h-2 bg-slate-200 rounded mb-4"></div>
                  <div className="w-full h-2 bg-slate-200 rounded"></div>
                </div>
              </div> 
              <div className="flex-1 space-y-4 py-1">
                <div className="w-3/4 h-2 bg-slate-200 rounded"></div>
                <div className="w-full h-2 bg-slate-200 rounded"></div>
                <div className="space-y-2">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 2xl:w-1/5  px-2.5 mb-5">
          <div className="relative rounded-lg shadow hover:shadow-md text-base-color bg-white overflow-hidden cursor-pointer p-4">
            <div className="animate-pulse">
              <div className="flex align-center border-b border-gray-300 pb-3 mb-2">
                <div className="shrink-0 rounded-full bg-slate-200 w-12 h-12"></div>
                <div className=" w-full px-4 mt-2">
                  <div className="w-3/5 h-2 bg-slate-200 rounded mb-4"></div>
                  <div className="w-full h-2 bg-slate-200 rounded"></div>
                </div>
              </div> 
              <div className="flex-1 space-y-4 py-1">
                <div className="w-3/4 h-2 bg-slate-200 rounded"></div>
                <div className="w-full h-2 bg-slate-200 rounded"></div>
                <div className="space-y-2">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 2xl:w-1/5  px-2.5 mb-5">
          <div className="relative rounded-lg shadow hover:shadow-md text-base-color bg-white overflow-hidden cursor-pointer p-4">
            <div className="animate-pulse">
              <div className="flex align-center border-b border-gray-300 pb-3 mb-2">
                <div className="shrink-0 rounded-full bg-slate-200 w-12 h-12"></div>
                <div className=" w-full px-4 mt-2">
                  <div className="w-3/5 h-2 bg-slate-200 rounded mb-4"></div>
                  <div className="w-full h-2 bg-slate-200 rounded"></div>
                </div>
              </div> 
              <div className="flex-1 space-y-4 py-1">
                <div className="w-3/4 h-2 bg-slate-200 rounded"></div>
                <div className="w-full h-2 bg-slate-200 rounded"></div>
                <div className="space-y-2">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 2xl:w-1/5  px-2.5 mb-5">
          <div className="relative rounded-lg shadow hover:shadow-md text-base-color bg-white overflow-hidden cursor-pointer p-4">
            <div className="animate-pulse">
              <div className="flex align-center border-b border-gray-300 pb-3 mb-2">
                <div className="shrink-0 rounded-full bg-slate-200 w-12 h-12"></div>
                <div className=" w-full px-4 mt-2">
                  <div className="w-3/5 h-2 bg-slate-200 rounded mb-4"></div>
                  <div className="w-full h-2 bg-slate-200 rounded"></div>
                </div>
              </div> 
              <div className="flex-1 space-y-4 py-1">
                <div className="w-3/4 h-2 bg-slate-200 rounded"></div>
                <div className="w-full h-2 bg-slate-200 rounded"></div>
                <div className="space-y-2">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>
          
      </div>
    )
}

export default SkeletonCards