import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Router from "./app/router";
import { Provider } from "react-redux";
import { store, persistor } from "./app/redux/store";
import './App.css';
import ReactGA from "react-ga"


// const TRACKING_ID = "G-KK2EXCWD7Z"
// ReactGA.initialize(TRACKING_ID)

function App() {

  return (
    <Provider store={store}>
     <BrowserRouter>
      <Switch>
        {/* Need to implement the access denied page here  */}
        <Route component= { Router } />
      </Switch>
    </BrowserRouter>
    </Provider>
  );
}

export default App;
