export default {
  GET_RETAILER_AUTHENTICATION_TOKEN:
    "RETAILER/GET_RETAILER_AUTHENTICATION_TOKEN",
  GET_RETAILER_AUTHENTICATION_TOKEN_SUCCESS:
    "RETAILER/GET_RETAILER_AUTHENTICATION_TOKEN_SUCCESS",
  GET_RETAILER_AUTHENTICATION_TOKEN_ERROR:
    "RETAILER/GET_RETAILER_AUTHENTICATION_TOKEN_ERROR",

  POST_LOGIN : "USER/POST_LOGIN",
  POST_LOGIN_SUCCESS : "USER/POST_LOGIN_SUCCESS",
  POST_LOGIN_ERROR : "USER/POST_LOGIN_ERROR" ,

  DASH_DATA : "USER/DASH_DATA",
  DASH_DATA_SUCCESS : "USER/DASH_DATA_SUCCESS",
  DASH_DATA_ERROR : "USER/DASH_DATA_ERROR" ,

  NEWDASH_DATA : "USER/NEWDASH_DATA",
  NEWDASH_DATA_SUCCESS : "USER/NEWDASH_DATA_SUCCESS",
  NEWDASH_DATA_ERROR : "USER/NEWDASH_DATA_ERROR" ,

  ADVISORY_DATA : "USER/ADVISORY_DATA",
  ADVISORY_DATA_SUCCESS : "USER/ADVISORY_DATA_SUCCESS",
  ADVISORY_DATA_ERROR : "USER/ADVISORY_DATA_ERROR" ,

  PORT_DATA : "USER/PORT_DATA",
  PORT_DATA_SUCCESS : "USER/PORT_DATA_SUCCESS",
  PORT_DATA_ERROR : "USER/PORT_DATA_ERROR" ,

  LIST_DATA : "USER/LIST_DATA",
  LIST_DATA_SUCCESS : "USER/LIST_DATA_SUCCESS",
  LIST_DATA_ERROR : "USER/LIST_DATA_ERROR" ,

  COMP_DATA : "USER/COMP_DATA",
  COMP_DATA_SUCCESS : "USER/COMP_DATA_SUCCESS",
  COMP_DATA_ERROR : "USER/COMP_DATA_ERROR" ,

  COMPANY_DATA : "USER/COMPANY_DATA",
  COMPANY_DATA_SUCCESS : "USER/COMPANY_DATA_SUCCESS",
  COMPANY_DATA_ERROR : "USER/COMPANY_DATA_ERROR",

  EXPERT : "USER/EXPERT",
  EXPERT_SUCCESS : "USER/EXPERT_SUCCESS",
  EXPERT_ERROR : "USER/EXPERT_ERROR" ,

  LEVEL : "USER/LEVEL",
  LEVEL_SUCCESS : "USER/LEVEL_SUCCESS",
  LEVEL_ERROR : "USER/LEVEL_ERROR" ,

  LOCATION : "USER/LOCATION",
  LOCATION_SUCCESS : "USER/LOCATION_SUCCESS",
  LOCATION_ERROR : "USER/LOCATION_ERROR" ,

  DESIRE : "USER/DESIRE",
  DESIRE_SUCCESS : "USER/DESIRE_SUCCESS",
  DESIRE_ERROR : "USER/DESIRE_ERROR" ,

  EXPERIENCE : "USER/EXPERIENCE",
  EXPERIENCE_SUCCESS : "USER/EXPERIENCE_SUCCESS",
  EXPERIENCE_ERROR : "USER/EXPERIENCE_ERROR" ,

  GLOBALSEARCH : "USER/GLOBALSEARCH",
  GLOBALSEARCH_SUCCESS : "USER/GLOBALSEARCH_SUCCESS",
  GLOBALSEARCH_ERROR : "USER/GLOBALSEARCH_ERROR" ,

  UniqueLink : "USER/UniqueLink",
  UniqueLink_SUCCESS : "USER/UniqueLink_SUCCESS",
  UniqueLink_ERROR : "USER/UniqueLink_ERROR" ,

  getfetchVentureLog : "USER/getfetchVentureLog",
  getfetchVentureLog_SUCCESS : "USER/getfetchVentureLog_SUCCESS",
  getfetchVentureLog_ERROR : "USER/getfetchVentureLog_ERROR" ,

  Roles : "USER/Roles",
  Roles_SUCCESS : "USER/Roles_SUCCESS",
  Roles_ERROR : "USER/Roles_ERROR" ,

  UserDetail : "USER/UserDetail",
  UserDetail_SUCCESS : "USER/UserDetail_SUCCESS",
  UserDetail_ERROR : "USER/UserDetail_ERROR" ,
};
