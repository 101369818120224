import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { ReactComponent as ProfileIcon } from "../../../Dashboard/images/imageProfile.svg";
import OctupusImg from "../../images/OctopusVentures.svg";
import DummyVcLogo from "../../images/gvc.svg";
import HumanCapital from "../../images/human.svg";
import { ReactComponent as M13Img } from "../../images/m13.svg";
import { ReactComponent as OVToggele } from "../../images/Toggle-OV-Off.svg";
import { ReactComponent as M13Toggele } from "../../images/Toggle-M13-Pressed.svg";
import { ReactComponent as GlobalToggle } from "../../images/Toggle-Global-Pressed.svg";
import { ReactComponent as ArrowToggle } from "../../images/icon_dropdown.svg";
import Oak from "../../../AddMember/images/Oaknew.svg";
import axios from "axios";
import { ReactComponent as AddIcon } from "../NewCAndidatePage/images/add-candidate.svg";

const HeaderUIDash = ({
  authentication_state,
  userRole,
  handleRefreshPage,
  handleModelClose,
  handleReferCandidate,
  showModel,
  buttonRef,
  handleCandidateAddNew,
  addCandidatePopUp,
  handleRagistrationLink,
  handleCandidates,
  handleAdvisors,
  userCompanys,
  menuRef,
  handleProfileIconButton,
  profileIcon,
  handleCampanyMember,
  handleCampanyTeamMember,
  handleCollection,
  LogoutUser,
  handleCandidateDetails,
  handleRegisteredComp,
  handleList,
  handlemanageTeamMember,
  handleCompaniesDetails,
  fetchNewDashboard,
  setDashData,
  setFilterData,
  setColorData,
  userCompany,
  logo,
  Auth,
  handlevcmodel,
}) => {
  const authentication_ref = useRef(authentication_state);
  const modelRef = useRef();
  authentication_ref.current = authentication_state;
  const storedCompanyName = localStorage.getItem("companyName");
  const storedAdvisoryDetails = localStorage.getItem("AdvisoryDetails");
  const [isButtonRendered, setIsButtonRendered] = useState(false);
  const [isContentHidden, setContentHidden] = useState(false);
  const locatiionState = window.location.pathname

  const handleVC1ButtonClick = (companyName) => {
    if (locatiionState == "/dashboard/advisors") {
      if (companyName) {
        localStorage.setItem("companyId", companyName.id);
        localStorage.setItem(
          "MainCompanyId",
          authentication_ref?.current?.port_data?.response?.data?.data[1].id
        );
        localStorage.setItem(
          "MultiUserComp",
          authentication_ref?.current?.port_data?.response?.data?.data[1]
            .company_name
        );
        setContentHidden(false)
        fetchNewDashboard(companyName.id).then(() => {
          if (
            authentication_ref?.current?.advisory_data?.response?.status == 200
          ) {
            setDashData(
              authentication_ref?.current?.advisory_data?.response?.data
            );
            setFilterData(
              authentication_ref?.current?.advisory_data?.response?.data
            );
            setColorData(
              authentication_ref?.current?.advisory_data?.response?.data
            );
            localStorage.setItem(
              "userData",
              authentication_ref?.current?.advisory_data?.response?.data
            );
          }
        });
        localStorage.setItem("companyName", companyName.company_name); // Store in local storage
        localStorage.setItem("AdvisoryDetails", companyName.advisors); // Store in local storage
      }
    } else {
      if (companyName) {
        localStorage.setItem("companyId", companyName.id);
        localStorage.setItem(
          "MainCompanyId",
          authentication_ref?.current?.port_data?.response?.data?.data[1].id
        );
        localStorage.setItem(
          "MultiUserComp",
          authentication_ref?.current?.port_data?.response?.data?.data[1]
            .company_name
        );
        setContentHidden(false)
        fetchNewDashboard(companyName.id).then(() => {
          if (
            authentication_ref?.current?.dash_new_data?.response?.status == 200
          ) {
            setDashData(
              authentication_ref?.current?.dash_new_data?.response?.data
            );
            setFilterData(
              authentication_ref?.current?.dash_new_data?.response?.data
            );
            setColorData(
              authentication_ref?.current?.dash_new_data?.response?.data
            );
            localStorage.setItem(
              "userData",
              authentication_ref?.current?.dash_new_data?.response?.data
            );
          }
        });
        localStorage.setItem("companyName", companyName.company_name); // Store in local storage
        localStorage.setItem("AdvisoryDetails", companyName.advisors); // Store in local storage
      }
    }
  };

  const handleVC2ButtonClick = (companyName) => {
    if (locatiionState == "/dashboard/advisors") {
      if (companyName) {
        localStorage.setItem("companyId", companyName.id);
        localStorage.setItem(
          "MainCompanyId",
          authentication_ref?.current?.port_data?.response?.data?.data[0].id
        );
        localStorage.setItem(
          "MultiUserComp",
          authentication_ref?.current?.port_data?.response?.data?.data[0]
            .company_name
        );
        setContentHidden(false)
        fetchNewDashboard(companyName.id).then(() => {
          if (
            authentication_ref?.current?.advisory_data?.response?.status == 200
          ) {
            setDashData(
              authentication_ref?.current?.advisory_data?.response?.data
            );
            setFilterData(
              authentication_ref?.current?.advisory_data?.response?.data
            );
            setColorData(
              authentication_ref?.current?.advisory_data?.response?.data
            );
            localStorage.setItem(
              "userData",
              authentication_ref?.current?.advisory_data?.response?.data
            );
          }
        });
        localStorage.setItem("companyName", companyName.company_name); // Store in local storage
        localStorage.setItem("AdvisoryDetails", companyName.advisors); // Store in local storage
      }
    } else {
      if (companyName) {
        localStorage.setItem("companyId", companyName.id);
        localStorage.setItem(
          "MainCompanyId",
          authentication_ref?.current?.port_data?.response?.data?.data[0].id
        );
        localStorage.setItem(
          "MultiUserComp",
          authentication_ref?.current?.port_data?.response?.data?.data[0]
            .company_name
        );
        setContentHidden(false)
        fetchNewDashboard(companyName.id).then(() => {
          if (
            authentication_ref?.current?.dash_new_data?.response?.status == 200
          ) {
            setDashData(
              authentication_ref?.current?.dash_new_data?.response?.data
            );
            setFilterData(
              authentication_ref?.current?.dash_new_data?.response?.data
            );
            setColorData(
              authentication_ref?.current?.dash_new_data?.response?.data
            );
            localStorage.setItem(
              "userData",
              authentication_ref?.current?.dash_new_data?.response?.data
            );
          }
        });
        localStorage.setItem("companyName", companyName.company_name); // Store in local storage
        localStorage.setItem("AdvisoryDetails", companyName.advisors); // Store in local storage
      }
    }
  };

  // Use useEffect to listen for changes to buttonRef
  useEffect(() => {
    if (buttonRef) {
      setIsButtonRendered(true);
    }
  }, []);

  useEffect(() => {
    let handler = (event) => {
      if (!modelRef?.current?.contains(event.target)) {
        setContentHidden(false)
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [])

  // Function to toggle content visibility and add/remove class
  const toggleContentVisibility = () => {
    setContentHidden(!isContentHidden);
    document.body.classList.toggle("hidden-body"); // Add or remove the 'hidden-body' class
  };

  function CompanyButtonA({ companyName }) {
    // Customize the UI and icon for the first type of button
    return (
      <li className="">
        <a
          onClick={() => handleVC1ButtonClick(companyName)}
          // style={companyName.company_name == storedCompanyName ? {backgroundColor: '#D9D9D926', color: '#000', boxShadow: '0px 4px 8px 0px #0000000D inset', fontWeight: '700px', fontSize: '14px'} : {background: '#FFF', color: '#434343'}}
          className={companyName.company_name == storedCompanyName ? "block text-center text-sm py-2 px-4 w-full whitespace-nowrap hover:bg-deep-purple-10 text-black font-bold border-b border-solid border-gray-300" : "block text-center text-sm py-2 px-4 w-full whitespace-nowrap bg-white hover:bg-deep-purple-10 text-[#444] font-medium border-b border-solid border-gray-300"}
          href="javascript:void(0);"
        >
          {companyName.company_name == "M13"
            ? "M13 Talent"
            : companyName.company_name ==
              "Global VC Talent Network"
              ? "Global VC Talent"
              : companyName.company_name == "Better Tomorrow Ventures" ? "BTV Talent Network" : companyName.company_name}
        </a>
      </li>
    );
  }
  function CompanyButtonB({ companyName }) {
    // Customize the UI and icon for the second type of button
    return (
      <li className="">
        <a
          onClick={() => handleVC2ButtonClick(companyName)}
          // style={companyName.company_name == storedCompanyName ? {backgroundColor: '#D9D9D926', color: '#000', boxShadow: '0px 4px 8px 0px #0000000D inset', fontWeight: '700px', fontSize: '14px'} : {background: '#FFF', color: '#434343'}}
          className={companyName.company_name == storedCompanyName ? "block text-center text-sm py-2 px-4 w-full whitespace-nowrap bg-white hover:bg-deep-purple-10 text-black font-bold border-b border-solid border-gray-300" : "block text-center text-sm py-2 px-4 w-full whitespace-nowrap bg-white hover:bg-deep-purple-10 text-[#444] font-medium border-b border-solid border-gray-300"}
          href="javascript:void(0);"
        >
          {companyName.company_name == "M13"
            ? "M13 Talent"
            : companyName.company_name ==
              "Global VC Talent Network"
              ? "Global VC Talent"
              : companyName.company_name == "Better Tomorrow Ventures" ? "BTV Talent Network" : companyName.company_name}
        </a>
      </li>
    );
  }
  return (
    <>
      <div className="w-full fixed inset-x-0 z-20 bg-white top-0 border-b border-gray-300] ">
        <div className="w-full mx-auto flex flex-wrap items-center justify-between py-2 px-4">
          <div className="flex mr-auto">
            <a href="/dashboard/candidates" className={locatiionState == "/dashboard/candidates" ? "flex items-center order-1 text-sm md:text-base text-deep-purple-500 font-bold border-b-3 md:border-b-4 border-solid border-deep-purple-500 lg:h-[67px] md:ml-2 -my-2 py-3 md:p-2" : "flex items-center order-1 text-sm md:text-base text-deep-greynew-500 font-bold border-b-3 md:border-b-4 border-solid border-transparent hover:border-deep-greynew-500 lg:h-[67px] md:ml-2 -my-2 py-3 md:p-2"}>
              Candidates
            </a>
            {storedAdvisoryDetails == "yes" && userRole != "companyportfolio" ||  authentication_ref?.current?.port_data?.response?.data?.data[0]?.portco_advisors == "yes" && userRole == "companyportfolio" ? (
              <a href="/dashboard/advisors" className={locatiionState == "/dashboard/advisors" ? "order-1 text-sm md:text-base text-deep-greynew-500 font-bold border-b-3 md:border-b-4 border-solid  border-deep-greynew-500 lg:h-[67px] ml-2 -my-2 py-3 md:p-2 flex items-center" : "order-1 text-sm md:text-base text-deep-purple-500 font-bold border-b-3 md:border-b-4 border-solid border-transparent hover:border-deep-purple-500 lg:h-[67px] ml-2 -my-2 py-3 md:p-2 flex items-center"}>
                Advisors
              </a>
            ) : null}
          </div>
          <div
            ref={modelRef}
            style={authentication_ref?.current?.port_data?.response?.data?.data[0]?.portco_add_candidates == "no" && userRole == "companyportfolio" ? {margin: 'auto'} : null}
            className={
              storedCompanyName == "M13"
                ? "flex items-center justify-center sm:w-full lg:w-auto relative text-center order-4 lg:order-2 lg:ml-[158px] mt-2 lg:mt-0"
                : showModel == true
                  ? "flex items-center justify-center sm:w-full lg:w-auto relative text-center order-4 lg:order-2 lg:ml-[158px] mt-2 lg:mt-0"
                  : "flex items-center justify-center sm:w-full lg:w-auto relative text-center order-4 lg:order-2 lg:ml-[158px] mt-2 lg:mt-0"
            }
          >
            {authentication_ref?.current?.dash_new_data?.loading ? null : (
              <>
                {isButtonRendered && (
                  <h1
                  style={authentication_ref?.current?.port_data?.response?.data?.data[0]?.portco_add_candidates == "no" && userRole == "companyportfolio" ? {marginRight: '308px'} : null}
                  className = {"inline-block font-bold text-base md:text-[19px] relative inline-flex items-center z-10 selection:bg-transparent selection:text-inherit"}>
                    {storedCompanyName == "Startup Lane"
                      ? "Startup Lane Talent"
                      : storedCompanyName == "Octopus Ventures"
                        ? "OV Talent Network"
                        : storedCompanyName == "M13"
                          ? "M13 Talent Network"
                          : storedCompanyName ==
                            "Global VC Talent Network"
                            ? "Global VC Talent"
                            : storedCompanyName == "Human Capital"
                              ? "Human Capital Talent"
                              : storedCompanyName == "Anzu Partners"
                                ? "Anzu Partners Talent Network"
                                : storedCompanyName == "Burnt Island Ventures"
                                ? "Burnt Island Ventures Talent Network"
                                : storedCompanyName == "Left Lane Capital"
                                ? "Left Lane Capital Talent"
                                : storedCompanyName == "Demo VC"
                                ? "Demo VC Talent"
                                : storedCompanyName == "Upfront Ventures"
                                ? "Upfront Ventures Talent"
                                : storedCompanyName == "Better Tomorrow Ventures" ? "BTV Talent Network" : storedCompanyName}

                  </h1>
                )}
                <div className="relative">
                  {userCompanys.length >= 2 &&
                    showModel == false &&
                    userRole != undefined ? (
                    <span
                      onClick={toggleContentVisibility}
                      className="w-[23px] h-[23px] shrink-0 flex items-center justify-center ml-2 border border-solid border-gray-300 rounded-md cursor-pointer"
                    >
                      <svg
                        width="11"
                        height="7"
                        viewBox="0 0 11 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.5 4.30509L1.76822 0.323545C1.36389 -0.107849 0.707584 -0.10785 0.303251 0.323545C-0.101082 0.75494 -0.101082 1.45517 0.303251 1.88657L4.80951 6.69443C5.19138 7.10186 5.8096 7.10186 6.19049 6.69443L10.6968 1.88657C11.1011 1.45517 11.1011 0.754941 10.6968 0.323546C10.2924 -0.107849 9.63611 -0.107849 9.23178 0.323546L5.5 4.30509Z"
                          fill={!isContentHidden ? "#454545" : "#6936FF"}
                        />
                      </svg>
                    </span>
                  ) : null}

                  {isContentHidden == true ? (
                    <>
                      <div
                        className="fixed inset-x-0 top-[6.25rem] md:top-[6.425rem] lg:top-[4.25rem] bottom-0 bg-black opacity-25"
                        onClick={() => setContentHidden(false)}
                      ></div>
                      <div
                        className={
                          !isContentHidden
                            ? "hidden"
                            : "w-48 absolute top-12 md:top-14 lg:top-16 right-0 sm:-mr-[84px] bg-white border border-solid border-gray-300 border-b-0 text-base z-50 list-none text-left rounded-lg shadow-xl bg-clip-padding before:absolute before:-z-[1] before:left-1/2 before:-top-3 before:-mt-[1px] before:-ml-[14px] before:block before:h-[0rem] before:w-[0] before:border-[14px] before:border-l-transparent before:border-r-transparent before:border-t-0 before:border-solid before:border-gray-300 before:bg-transparent before:content-[''] after:absolute after:z-[1] after:left-1/2 after:-top-3 after:mt-0 after:-ml-[13px] after:block after:h-[0rem] after:w-[0] after:border-[13px] after:border-l-transparent after:border-r-transparent after:border-t-0 after:border-solid after:border-white after:bg-transparent after:content-['']"
                        }
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <div className="rounded-lg overflow-hidden">
                          {userCompanys.map((company, index) => (
                            <ul className="m-0 p-0">
                              {index === 0 ? (
                                <CompanyButtonA companyName={company} />
                              ) : (
                                <CompanyButtonB companyName={company} />
                              )}
                            </ul>
                          ))}
                        </div>
                      </div>

                    </>
                  ) : (
                    <></>
                  )}

                </div>

              </>
            )}
          </div>
          <div className="w-full sm:hidden order-3"></div>
          {(userRole == "venturecapital" && showModel == false) ||
            (userRole == "admin" && showModel == false) ||
            (userRole == "globalvc" && showModel == false) ? (
            <div
              className="sm:w-auto relative ml-auto  order-5 sm:order-2 lg:order-3"
              ref={buttonRef}
            >
            {locatiionState == "/dashboard/advisors" ? (
              <ul className="flex flex-wrap  text-sm font-medium text-center">
              <li className="sm:mr-2">
              <button
                    type="button"
                    onClick={handleAdvisors}
                    className={"sm:h-[42px] inline-flex items-center bg-deep-greynew-500 hover:bg-deep-greynew-500 text-white text-base font-medium whitespace-nowrap rounded-lg hover:shadow-md transition duration-200 py-1.5 px-2 sm:py-3 sm:px-4"}
                  >
                    <AddIcon
                      width={"19px"}
                      style={{ marginRight: "5px", marginBottom: "3px" }}
                    />
                    {"Add Advisor"}
                  </button>
              </li>
              </ul>
            ) : null}
            {locatiionState == "/dashboard/candidates" ? (
              <ul className="flex flex-wrap  text-sm font-medium text-center">
              <li className="sm:mr-2">
              <button
                    type="button"
                    onClick={handleCandidates}
                    className={"sm:h-[42px] inline-flex items-center bg-deep-purple-500 hover:bg-deep-purple-400 text-white text-base font-medium whitespace-nowrap rounded-lg hover:shadow-md transition duration-200 py-1.5 px-2 sm:py-3 sm:px-4"}
                  >
                    <AddIcon
                      width={"19px"}
                      style={{ marginRight: "5px", marginBottom: "3px" }}
                    />
                    {"Add Candidate"}
                  </button>
              </li>
              </ul>
            ) : null}
            </div>
          ) : userRole == "anonymous" ? null : userRole ==
            "companyportfolio" && authentication_ref?.current?.port_data?.response?.data?.data[0]?.portco_add_candidates == "yes" ? (
            <div
              className="sm:w-auto relative ml-auto order-4 sm:order-2 lg:order-3"
            >
              <ul className="flex flex-wrap  text-sm font-medium text-center">
                <li className="">
                  <button
                    type="button"
                    onClick={handleReferCandidate}
                    className="sm:h-[42px] inline-flex items-center bg-deep-purple-500 hover:bg-deep-purple-400 text-white text-base font-medium whitespace-nowrap rounded-lg hover:shadow-md transition duration-200 py-1.5 px-2 sm:py-3 sm:px-4"
                  >
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      className="mr-1"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.09814 0.0386963C3.67974 0.0386963 0.0981445 3.6203 0.0981445 8.0387C0.0981445 12.4571 3.67974 16.0387 8.09814 16.0387C12.5165 16.0387 16.0981 12.4571 16.0981 8.0387C16.0981 3.6203 12.5165 0.0386963 8.09814 0.0386963ZM10.4981 8.8387H8.89814V10.4387C8.89814 10.8803 8.53974 11.2387 8.09814 11.2387C7.65654 11.2387 7.29814 10.8803 7.29814 10.4387V8.8387H5.69814C5.25654 8.8387 4.89814 8.4803 4.89814 8.0387C4.89814 7.5971 5.25654 7.2387 5.69814 7.2387H7.29814V5.6387C7.29814 5.1971 7.65654 4.8387 8.09814 4.8387C8.53974 4.8387 8.89814 5.1971 8.89814 5.6387V7.2387H10.4981C10.9397 7.2387 11.2981 7.5971 11.2981 8.0387C11.2981 8.4803 10.9397 8.8387 10.4981 8.8387Z"
                        fill="white"
                      />
                    </svg>
                    Refer Candidate
                  </button>
                </li>
              </ul>
            </div>
          ) : null}
          {userRole == "anonymous" || userRole == "companyportfolio" ? (
            <div
              className={
                userRole == "venturecapital" || userRole == "admin"
                  ? "relative order-2 sm:order-3 lg:order-4 ml-2 md:ml-4 lg:ml-8"
                  : "sm:w-auto relative order-2 sm:order-3 lg:order-4 ml-2 md:ml-4 lg:ml-8"
              }
              ref={menuRef}
            >
              <ProfileIcon
                className="w-8 sm:w-[50px] cursor-pointer"
                onClick={handleProfileIconButton}
              />
              {profileIcon && (
                <ul
                  className="
                w-48
                min-w-max
                absolute
                -right-4
                bg-white
                text-base
                z-50
                float-left
                py-2
                list-none
                text-left
                rounded-lg
                shadow-lg
                mt-1 
                m-0
                bg-clip-padding
                border-none
              "
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <a
                      className=" 
                    text-sm
                    py-2
                    px-4
                    font-normal
                    block
                    w-full
                    whitespace-nowrap
                    bg-transparent
                    text-gray-700
                    hover:bg-gray-100
                  "
                      href="javascript:void(0);"
                      onClick={handleCampanyTeamMember}
                    >
                      Manage Team Members
                    </a>
                  </li>
                  <li>
                    <a
                      className=" 
                    text-sm
                    py-2
                    px-4
                    font-normal
                    block
                    w-full
                    whitespace-nowrap
                    bg-transparent
                    text-gray-700
                    hover:bg-gray-100
                  "
                      href="javascript:void(0);"
                      onClick={LogoutUser}
                    >
                      {" "}
                      Logout
                    </a>
                  </li>
                </ul>
              )}
            </div>
          ) : (
            <div className="relative order-2 sm:order-3 lg:order-4 ml-2 md:ml-4 lg:ml-8" ref={menuRef}>
              <ProfileIcon
                className="w-8 sm:w-[50px] cursor-pointer"
                onClick={handleProfileIconButton}
              />
              {profileIcon && (
                <ul
                  className="
                w-48
                min-w-max
                absolute
                -right-4
                bg-white
                text-base
                z-50
                float-left
                py-2
                list-none
                text-left
                rounded-lg
                shadow-lg
                mt-1 
                m-0
                bg-clip-padding
                border-none
              "
                  aria-labelledby="dropdownMenuButton1"
                >
                  {userRole == "admin" ||
                    (storedCompanyName !=
                      "Global VC Talent Network" &&
                      userRole == "globalvc") ||
                    (userRole == "venturecapital" &&
                      storedCompanyName !=
                      "Global VC Talent Network") ? (
                    <li>
                      <a
                        className="flex 
                      items-center
                      justify-between
                      text-sm
                      py-2
                      px-4
                      font-normal 
                      w-full
                      whitespace-nowrap
                      bg-transparent
                      text-gray-700
                      hover:bg-gray-100
                  "
                        onClick={handleCollection}
                        href={"javascript:void(0)"}
                      >
                        View Connections
                      </a>
                    </li>
                  ) : null}
                  {userRole == "admin" || storedCompanyName != "Global VC Talent Network" && userRole == "globalvc" || userRole == "venturecapital" && storedCompanyName != "Global VC Talent Network" ? (
                      <li>
                        <a
                          className="flex 
                      items-center
                      justify-between
                      text-sm
                      py-2
                      px-4
                      font-normal 
                      w-full
                      whitespace-nowrap
                      bg-transparent
                      text-gray-700
                      hover:bg-gray-100
                  "
                          onClick={handleList}
                          href={"javascript:void(0)"}
                        >
                          Manage Lists
                        </a>
                      </li>
                    ) : null}
                  <li>
                    <a
                      className="flex 
                      items-center
                      justify-between
                      text-sm
                      py-2
                      px-4
                      font-normal 
                      w-full
                      whitespace-nowrap
                      bg-transparent
                      text-gray-700
                      hover:bg-gray-100
                  "
                      onClick={handleCandidateDetails}
                      href={"javascript:void(0)"}
                    >
                      Manage Candidates
                    </a>
                  </li>
                  {userRole == "admin" ||
                    (storedCompanyName !=
                      "Global VC Talent Network" &&
                      userRole == "globalvc") ||
                    (userRole == "venturecapital" &&
                      storedCompanyName !=
                      "Global VC Talent Network") ? (
                        <>
                        <li>
                      <a
                        className="flex 
                      items-center
                      justify-between
                      text-sm
                      py-2
                      px-4
                      font-normal 
                      w-full
                      whitespace-nowrap
                      bg-transparent
                      text-gray-700
                      hover:bg-gray-100
                  "
                        onClick={handleRegisteredComp}
                        href={"javascript:void(0)"}
                      >
                        Manage Portfolio Users
                      </a>
                    </li>
                    <li>
                    <a
                      className="
                    flex 
                    items-center
                    justify-between
                    text-sm
                    py-2
                    px-4
                    font-normal 
                    w-full
                    whitespace-nowrap
                    bg-transparent
                    text-gray-700
                    hover:bg-gray-100
                  "
                      onClick={handlemanageTeamMember}
                      href={"javascript:void(0)"}
                    >
                      Manage Team Members
                    </a>
                  </li>
                        </>
                  ) : null}
                  {userRole == "admin" && (
                    <>
                    <li>
                      <a
                        className="flex 
                      items-center
                      justify-between
                      text-sm
                      py-2
                      px-4
                      font-normal 
                      w-full
                      whitespace-nowrap
                      bg-transparent
                      text-gray-700
                      hover:bg-gray-100
                  "
                        onClick={handleCompaniesDetails}
                        href={"javascript:void(0)"}
                      >
                        Companies Details
                      </a>
                    </li>
                    <li>
                      <a
                        className="flex 
                      items-center
                      justify-between
                      text-sm
                      py-2
                      px-4
                      font-normal 
                      w-full
                      whitespace-nowrap
                      bg-transparent
                      text-gray-700
                      hover:bg-gray-100
                  "
                        onClick={handlevcmodel}
                        href={"javascript:void(0)"}
                      >
                        Create New VC
                      </a>
                    </li>
                    </>
                  )}
                  <li>
                    <a
                      className=" 
                    text-sm
                    py-2
                    px-4
                    font-normal
                    block
                    w-full
                    whitespace-nowrap
                    bg-transparent
                    text-gray-700
                    hover:bg-gray-100
                  "
                      href="javascript:void(0);"
                      onClick={LogoutUser}
                    >
                      {" "}
                      Logout
                    </a>
                  </li>
                </ul>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ authentication }) => ({
  authentication_state: authentication,
});

export default connect(mapStateToProps)(HeaderUIDash);
