import React, { useRef, useEffect } from "react";
import "./index.css";
import Image1 from "./img/Lock.svg";
import Image2 from "./img/Close.svg";
import Image3 from "./img/Tick-1.svg";
import Image4 from "./img/Tick-2.svg";
import Image5 from "./img/Tick-3.svg";

const PrimiumModule = ({ handlePremiumChange }) => {
    const PrimiumRef = useRef();
    useEffect(() => {
        let handler = (event) => {
          if (!PrimiumRef?.current?.contains(event.target)) {
            handlePremiumChange();
          }
        };
        document.addEventListener("mousedown", handler);
    
        return () => {
          document.removeEventListener("mousedown", handler);
        };
      }, []);
  return (
    <>
      <div className="popup-premium modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto show">
        <div className="modal-backdrop fade show"></div>
        <div
         ref={PrimiumRef}
          className="modal-dialog  modal-dialog-centered relative"
        >
          <div className="popupbox">
            <div className="popupbox-inner">
              <div className="popupbox-top">
                <h2>
                  <span>
                    <img src={Image1} alt="icon" />
                  </span>
                  Unlock all Candidates
                </h2>
                <a href="javascript:void(0)" onClick={handlePremiumChange} className="close-btn">
                  <img src={Image2} alt="icon" />
                </a>
              </div>
              <div className="popupbox-contentbox">
                <div className="popupbox-body">
                  <h3>Professionally sourced warm pipeline from day one</h3>
                  <p>
                    We add over 40+ diverse candidates a week who are looking to
                    work with companies like yours.
                  </p>
                  <div className="popupbox-list">
                    <ul>
                      <li>
                        <h3>
                          <span>
                            <img src={Image3} />
                          </span>{" "}
                          Worked at VC backed companies
                        </h3>
                        <p>
                          We use our startup experience and VC connections to
                          source candidates that are suited for early stage and
                          high growth companies.
                        </p>
                      </li>
                      <li>
                        <h3>
                          <span>
                            <img src={Image4} />
                          </span>
                          Actively Looking
                        </h3>
                        <p>
                          We speak to each candidate, share details about the
                          companies we have on our platform, and the candidate
                          opts into joining.
                        </p>
                      </li>
                      <li>
                        <h3>
                          <span>
                            <img src={Image5} />
                          </span>
                          Mix of Senior to Exec candidates
                        </h3>
                        <p>
                          You can reach out to anyone, even if you haven’t yet
                          decided what seniority level you’re hiring for - our
                          candidates understand how startups work.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="popupbox-bottom">
                  <p>Get Startup Lane Premium to start talking to them now:</p>
                  <div className="popupbox-bottom-inn">
                    <div className="popupbox-bottom-box w-thrid">
                      <h2>£150</h2>
                      <span>Per Month</span>
                    </div>
                    <div className="popupbox-bottom-box w-half">
                      <p>
                        Unlimited access to candidates. Everyone at your company
                        can use it. You can cancel any time.
                      </p>
                    </div>
                    <div className="popupbox-bottom-box w-thrid">
                      <a href="https://buy.stripe.com/4gw8xn4Pw0Is5RS6ot" target="_blank" rel="noopener noreferrer" className="buynow">
                        Buy Now
                      </a>
                    </div>
                  </div>
                </div>
                <div className="popupbox-copyright">
                  <p>
                    Any questions or want to discuss? Email{" "}
                    <a href="mailto:georgiana@startuplane.io">
                      georgiana@startuplane.io
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrimiumModule;
