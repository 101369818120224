import React, { useEffect, useMemo, useRef, useState } from "react";
import Select from "react-select";
import { debounce } from "lodash";
import "../../modules/Login/index.css";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

export const GooglePlacesSelect = ({ onChange, value, placeholder, form, formColor }) => {
  const [inputValue, setInputValue] = useState();
  const [localValue, setLocalValue] = useState(null);
  const [options, setOptions] = useState([]);
  const loaded = useRef(false);
  const locatiionState = window.location.pathname;

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places&language=en`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 100),
    []
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);

      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const customStyles = {
    // Add your custom styles for the dropdown indicator here
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: "#2D3958", // Example color change based on focus state
      // Add more styles as needed
    }),
  };

  return (
    <Select
      inputValue={inputValue}
      value={value || localValue}
      placeholder={placeholder}
      isClearable={true}
      onChange={(item) => {
        if (!item) {
          onChange(null);
          setLocalValue(null);

          return;
        }

        setLocalValue(item);

        const map = new window.google.maps.Map(document.createElement("div"));
        const googlePlaces = new window.google.maps.places.PlacesService(map);

        googlePlaces.getDetails(
          {
            placeId: item.place_id,
          },
          (place, status) => {
            onChange(place);
            setLocalValue(null);
          }
        );
      }}
      onInputChange={(newValue) => {
        setInputValue(newValue);
      }}
      noOptionsMessage={() => "Type a location"}
      className={
        placeholder == "Type your location"
          ? "text-base md:text-lg font-normal text-gray-800"
          : null
      }
      styles={
        form == "formModule"
          ? {
            singleValue: (provided) => ({
                    ...provided,
               fontSize: '1.125rem', // Change the font size of the selected value
               fontFamily: 'DM Sans, sans-serif', // Change the font family of the selected value
               color: 'rgb(66 77 106)'
              }),
              control: (provided, state) => ({
                ...provided,
                borderColor: state.isFocused ? "#008CF0" : provided.borderColor,
                boxShadow: state.isFocused
                  ? "0 0 0 1px #008CF0"
                  : provided.boxShadow,
                minHeight: "47px",
              }),
              indicatorsContainer: (provided) => ({
                ...provided,
                svg: {
                  fill: "#008CF0", // Change the fill color of the SVG indicator
                },
              }),
            }
          : form == "formCandidate" ? {

            singleValue: (provided) => ({
                    ...provided,
               fontSize: '1.125rem', // Change the font size of the selected value
               fontFamily: 'DM Sans, sans-serif', // Change the font family of the selected value
               color: 'rgb(66 77 106)',
               '&:hover': {
                 borderColor: `${formColor}`, // Change border color on hover
                  },
              }),
              control: (provided, state) => ({
                ...provided,
                borderColor: state.isFocused ? `${formColor}` : "none",
                boxShadow: state.isFocused
                  ? `0 0 0 1px ${formColor}`
                  : provided.boxShadow,
                minHeight: "47px",
                '&:hover': {
                 borderColor: `${formColor}`, // Change border color on hover
                  },
              }),
              indicatorsContainer: (provided) => ({
                ...provided,
                svg: {
                  fill: `${formColor}`, // Change the fill color of the SVG indicator
                },
              }),

          } : locatiionState != "/dashboard/advisors"
          ? {
              input: (baseStyles, state) => ({ ...baseStyles, width: "160px" }),
            }
          : customStyles
      }
      options={options}
      getOptionLabel={(item) => item?.description || item.formatted_address}
      getOptionValue={(item) => item?.place_id}
    />
  );
};

// export function searchOverGooglePlacesObject(searchObject, placesObject) {
//     if (!placesObject) {
//         return false;
//     }

//     const searchCountry = searchObject.address_components.find(component =>
//         component.types.includes("country")
//     );

//     if (!searchCountry) {
//         return false;
//     }

//     const searchState = searchObject.address_components.find(component =>
//         component.types.includes("administrative_area_level_1")
//     );

//     const objectCountry = placesObject.find(component =>
//         component.types.includes("country")
//     );

//     if (!objectCountry) {
//         return false;
//     }

//     if (searchCountry.long_name !== objectCountry.long_name) {
//         return false;
//     }

//     if (searchState) {
//         const objectState = placesObject.find(component =>
//             component.types.includes("administrative_area_level_1")
//         );

//         // if (searchState) {
//         //     console.log("Search State:", searchState.long_name);
//         //     console.log("Object State:", objectState.long_name);
//         //      }

//         if (!objectState || searchState.short_name !== objectState.short_name) {
//             return false;
//         }
//     }

//     // console.log("Search Country:", searchCountry.long_name);
//     // console.log("Object Country:", objectCountry.long_name);

//     return true;
// }

// export function searchOverGooglePlacesObject(searchObject, placesObject) {
//     if (!placesObject) {
//         return false;
//     }

//     const searchCountry = searchObject.address_components.find(component =>
//         component.types.includes("country")
//     );

//     if (!searchCountry) {
//         return false;
//     }

//     const objectCountry = placesObject.find(component =>
//         component.types.includes("country")
//     );

//     if (!objectCountry) {
//         return false;
//     }

//     return searchCountry.long_name === objectCountry.long_name;
// }

export function searchOverGooglePlacesObject(searchObject, placesObject) {
  if (!placesObject) {
    return false;
  }

  const searchComponents = searchObject?.address_components?.slice()?.reverse();
  const objectComponents = placesObject?.slice()?.reverse();

  if (
    searchComponents.every((searchComponent) =>
      objectComponents.some(
        (objComponent) => searchComponent.long_name === objComponent.long_name
      )
    )
  ) {
    return true;
  }

  return false;
}

// ******************This was the one we were using ***********
// export function searchOverGooglePlacesObject(searchObject, placesObject) {
//     if (!placesObject) {
//         return false;
//     }

//     const searchComponents = searchObject.address_components;
//     console.log(searchComponents, "searchComponents");
//     const objectComponents = placesObject;
//     console.log(objectComponents, "objectComponents");

//     if (searchComponents.length !== objectComponents.length) {
//         return false;  // Different number of components, not a match
//     }

//     // Check for an exact match of each corresponding component
//     if (searchComponents.every((searchComponent, index) =>
//         searchComponent.long_name === objectComponents[index].long_name)) {
//         return true;
//     }

//     return false;
// }
